import React from 'react';
import { Card, Row, Col, Button } from "reactstrap";
import { MdCloudDownload } from 'react-icons/md';
import { IoIosEye } from 'react-icons/io';
import { colors } from "../../../../../config/constants";
import { isMobile } from 'react-device-detect';
import useOrderGuideList from './useOrderGuideList';
import '../OrderGuide.scss';

const OrderGuideRow = (props) => {
    const { data } = props;
    const { setHover, hover, downloadOG, goToOrderGuideDetail } = useOrderGuideList.useRow(props);

    if (isMobile) { 
        return (
            <Card>
                <Col className='pb-4'>
                    <Row>
                        <Col className='py-2'>
                            {data.distributor}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-6'>
                            <Row>
                                <Col className='py-2 text-gray'>
                                    Upload Date
                                </Col>
                            </Row>
                            <Row>
                                <Col className='py-2 text-gray'>
                                    Number of Items
                                </Col>
                            </Row>
                        </Col>
                        <Col className='col-6 py-2 text-right'>
                            <Row>
                                <Col className='py-2'>
                                    {data.upload_date}
                                </Col>
                            </Row>
                            <Row>
                                <Col  className='py-2'>
                                    {data.number_items ? data.number_items : 0}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-6'>
                            <Button color='white' className='og-btn-outline' onClick={goToOrderGuideDetail} style={{width:'100%'}}>
                                <IoIosEye color={colors.lightBlueText} size={20} /> View
                            </Button>
                        </Col>
                        <Col className='col-6'>
                            <Button color='white' className='og-btn-outline' onClick={downloadOG} style={{width:'100%'}}>
                                <MdCloudDownload color={colors.lightBlueText} size={20} /> Download
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Card>
        )

    }

    return (
       <Card>
           <Row className='py-3 px-3' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <Col className='col-4'>
                    {data.distributor}
                </Col>
                <Col className='col-3'>
                    {data.upload_date}
                </Col>
                <Col className='col-xs-3'>
                    {data.number_items ? data.number_items : 0}
                </Col>
                <Col className='col-xs-1'>
                    { hover &&
                        <Col className='clickable' onClick={goToOrderGuideDetail} >
                            <IoIosEye size={20} color={colors.lightBlueText} /> View
                        </Col>
                    }
                </Col> 
                <Col className='col-xs-1'>
                    { hover &&
                        <Col className='clickable' onClick={downloadOG}>
                            <MdCloudDownload size={20} color={colors.lightBlueText} /> Download
                        </Col>
                    }
                </Col>
           </Row>
       </Card>
    )

}

export default OrderGuideRow;