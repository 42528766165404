import React from 'react';
import useModal from '../useModal';
import { isMobile } from 'react-device-detect';
import { Modal, ModalHeader, Row, Col, ModalBody } from 'reactstrap';
import InputSearch from '../../InputSearch/InputSearch';
import useAddInventoryItem from './useAddInventoryItem';
import InfiniteScroll from 'react-infinite-scroller';
import PlusButton from '../../PlusButton/PlusButton';
import noimage from '../../../assets/images/no-image.png'
import comparisonViewsApi from '../../../api/comparisonViewsApi';
import ShowIf from '../../ShowIf/ShowIf';

const AddInventoryItem = (props) => {
  const {
    id, onAdd
  } = props;

  const modalProps = useModal(id, isMobile, true);
  const {
    onClear, onSearch, search, items,
    page, loadMore, isLoading, getInventoryItems
  } = useAddInventoryItem.useApi();

  const clickAdd = (cId, gId) => {
    comparisonViewsApi.getGroup(cId, gId).then(({data}) => {
      modalProps.toggle();
      onAdd(data);
    }).catch(err => console.error(err));
  };

  const itemsMap = items.map(i => 
    <Row className='m-0 border-bottom py-2 w-100 align-items-center' key={`invsearc_${i.id}`}>
      <Col className='w-image'>
        <img className='no-image' src={noimage} alt='subrecipe' />
      </Col>
      <Col className='p-0'>
        <Row><Col className='text-main'>{i.name}</Col></Row>
        <Row><Col className='text-label'>{i.comparisonViewName}</Col></Row>
      </Col>
      <Col className='w-fc'>
        <PlusButton isActive large onClick={() => {clickAdd(i.comparisonViewId, i.id); modalProps.toggle();}} />
      </Col>
    </Row>
  );

  const noItems = items.length === 0 && !isLoading;

  return (
    <Modal {...modalProps}>
      <ModalHeader className='bg-white border-0' toggle={modalProps.toggle}>
        <Row>
          <Col>Add Inventory Item</Col>
        </Row>
        <Row className='align-items-center mt-3 mx-0'>
          <Col className='p-0'>
            <InputSearch onChange={search.set} value={search.value} clearValue={onClear} onEnter={onSearch} />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody className={ isMobile ? 'border-top px-0' : ''}>
        <ShowIf show={isMobile}>
          <ShowIf show={noItems}>
            <Col className='mt-3 text-center'>
              No results found
            </Col>
          </ShowIf>
          <InfiniteScroll
            className='w-100'
            initialLoad={false}
            pageStart={page}
            loadMore={() => getInventoryItems(page)}
            hasMore={loadMore && !isLoading}
            useWindow={false}
          >
            {itemsMap}
          </InfiniteScroll>
        </ShowIf>
        <ShowIf show={!isMobile}>
          <Row className='border m-0 my-3 overflow-auto h-300px mh-300px'>
            <ShowIf show={noItems}>
              <Col className='mt-3 text-center'>
                No results found
              </Col>
            </ShowIf>
            <InfiniteScroll
              className='w-100'
              initialLoad={false}
              pageStart={page}
              loadMore={() => getInventoryItems(page)}
              hasMore={loadMore && !isLoading}
              useWindow={false}
            >
              {itemsMap}
            </InfiniteScroll>
          </Row>
        </ShowIf>
      </ModalBody>
    </Modal>
  );
};

export default AddInventoryItem;