import React from 'react';
import PropTypes from 'prop-types';
import useModal from '../useModal';
import { Modal, ModalBody, ModalHeader, Row, Col, Input, ModalFooter, Button } from 'reactstrap';
import useInput from '../../SharedEffects/useInput';
import { isMobile } from 'react-device-detect';
import MinusButton from '../../MinusButton/MinusButton';
import PlusButton from '../../PlusButton/PlusButton';
import ShowIf from '../../ShowIf/ShowIf';

const CountModal = (props) => {
  const { id, onSave, menu, defaultValue, min, max, buttonTitle, title, description } = props;
  const modalProps = useModal(id, menu);
  const count = useInput.useText(defaultValue ? defaultValue : 0, { isNumber: { min: min, max: max } });

  const clickSave = () => {
    if (onSave) onSave(count.value);
    modalProps.toggle();
  };

  return (
    <Modal {...modalProps}>
      <ShowIf show={title}>
        <ModalHeader className='bg-white ' toggle={modalProps.toggle}>
          {
            description &&
            <p className='text-left textCount'>{description}</p>
          }
          <p className="textTitle">{title}</p>
        </ModalHeader>
      </ShowIf>
      <ModalBody>
        <Row className='align-items-center justify-content-center'>
          <Col className={'w-fc pl-0 justify-content-end' + (isMobile ? ' pr-1' : '')}>
            <MinusButton isBlue onClick={() => count.setValue(count.value - 1)} />
          </Col>
          <Col className='p-0 justify-content-center w-squared-input'>
            <Input type='number' value={count.value} onChange={count.set} />
          </Col>
          <Col className={'w-fc pr-0 justify-content-left' + (isMobile ? ' pl-1' : '')}>
            <PlusButton isBlue onClick={() => count.setValue(count.value + 1)} />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button block={isMobile} className='px-4' color='green' onClick={clickSave}>{buttonTitle}</Button>
      </ModalFooter>
    </Modal >
  );
};

CountModal.propTypes = {
  onSave: PropTypes.func,
  menu: PropTypes.bool,
  defaultValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  buttonTitle: PropTypes.string.isRequired,
  title: PropTypes.string
};

CountModal.defaultProps = {
  buttonTitle: 'Save',
  min: 0
};

export default CountModal;