import React from 'react';
import { Col } from 'reactstrap';
import './Header.scss';
import ShowIf from '../ShowIf/ShowIf';

const HeaderTitle = (props) => {
  const { title, showTitle } = props;

  return (
    <ShowIf show={title || showTitle}>
      <Col className='text-center p-0'>
        <h4 className='m-0'>{title}</h4>
      </Col>
    </ShowIf>
  );
};

export default HeaderTitle;