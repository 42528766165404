import React from "react";
import { Row, Col } from "reactstrap";

const ListGroupItem = (props) => {
  const { className } = props;

  return (
    <Row
      className={["list-group-item"].concat(className || []).join(" ")}
      xs="1"
    >
      <Col>{props.children}</Col>
    </Row>
  );
};

export default ListGroupItem;
