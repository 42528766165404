import axios from 'axios';

const get = () => {    
  return axios.get('shopping-carts');
};

const update = (params) => {
  return axios.post('shopping-carts', params);
};

export default{
  get,
  update
};