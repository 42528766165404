const p = process.env;
export default function reducer(state = {
  id: p.REACT_APP_COMPANY_ID,
  title: p.REACT_APP_COMPANY_TITLE,
  desription: p.REACT_APP_COMPANY_DESCRIPTION,
  domain: p.REACT_APP_COMPANY_DOMAIN,
  website: p.REACT_APP_COMPANY_WEBSITE,
  logo: p.REACT_APP_COMPANY_LOGO,
  spinner: p.REACT_APP_COMPANY_SPINNER,
  favicon: p.REACT_APP_COMPANY_FAVICON,
  colors: {
    main: p.REACT_APP_COMPANY_MAIN_COLOR,
    secondary: p.REACT_APP_COMPANY_SECONDARY_COLOR,
  }
}, action) {
  switch (action.type) {
    case 'SET_PROPERTY':
      return {
        ...state,
        [action.payload.name]: action.payload.value
      };
    default:
      return state;
  }
}
