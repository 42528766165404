import axios from "axios";

const all = (search) => {
  const filters = [{
    field: 'nickname',
    value: search,
    operator: 'like'
  }];
  const string = JSON.stringify(filters);
  const params =  search ? {filters: string} : null;
  return axios.get('locations/all', {
    params: params
  });
};

const users = (id) => {
  return axios.get(`locations/users/${id}`);
}

const getMessage = (merchantId) => {
  const params = {merchant_id: merchantId};
  return axios.get('users/get-message',{params});
};

const readMessage = (params) => {
  return axios.post('users/read-message',params);
};

const getBroadcastMessage = (merchantId) => {
  const params = {merchant_id: merchantId};
  return axios.get('users/get-broadcast-message',{params});
};

const readBroadcastMessage = (params) => {
  return axios.post('users/read-broadcast-message',params);
};

export default {
  all,
  users,
  getMessage,
  readMessage,
  getBroadcastMessage,
  readBroadcastMessage
};