import React from 'react';
import useModal from '../../../../../../SharedComponents/Modals/useModal';
import { Modal, ModalBody, ModalHeader, Col, Input, ModalFooter, Button, CustomInput } from 'reactstrap';
import Select from 'react-select';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';
import useRecipeInfoModal from './useRecipeInfoModal';
import { useSelector } from 'react-redux';

const RecipeInfoModal = (props) => {
  const {
    id, menu,
    lockMenu, isEdit
  } = props;

  const modalProps = useModal(id, menu, true);
  const menuList = useSelector(({recipeCosting}) => recipeCosting.menuList);

  const {
    recipeName, menuCategory, menuPrice,
    isSubrecipe, recipeYield, recipeYieldUom,
    description, procedure, clickSave
  } = useRecipeInfoModal(props, modalProps);

  return (
    <Modal {...modalProps}>
      <ModalHeader toggle={modalProps.toggle}>{ isEdit ? 'Recipe Description' : 'Create New Recipe'}</ModalHeader>
      <ModalBody className='px-0'>
        <Col className='text-gray'>Recipe Name</Col>
        <Col className='mt-2 mb-4'>
          <Input value={recipeName.value} onChange={recipeName.set} placeholder='Recipe Name' className={recipeName.error ? 'border-error' : ''} onBlur={recipeName.onBlur} onFocus={recipeName.onFocus} />
        </Col>
        <Col className='text-gray'>Menu Category</Col>
        <Col className='mt-2 mb-4'>
          <Select className='fb-select' classNamePrefix='select'
            onChange={menuCategory.set} value={menuCategory.value}
            options={menuList} isDisabled={lockMenu}
          />
        </Col>
        <ShowIf show={!isSubrecipe.value}>
          <Col className='text-gray'>Menu Price $</Col>
          <Col className='mt-2 mb-4'>
            <Input type='number' value={menuPrice.value} onChange={menuPrice.set} placeholder='Menu Price' className={menuPrice.error ? 'border-error' : ''} onBlur={menuPrice.onBlur} onFocus={menuPrice.onFocus} />
          </Col>
        </ShowIf>
        <Col className='p-0 d-flex align-items-end mb-4'>
          <Col className='text-gray w-fc'>Is this a subrecipe?</Col>
          <CustomInput className='squared' type='checkbox' id='isSubrecipe' checked={isSubrecipe.value} onChange={isSubrecipe.toggle} />
        </Col>
        <ShowIf show={isSubrecipe.value}>
          <Col className='p-0 d-flex align-items-end mb-4'>
            <Col className='p-0'>
              <Col className='text-gray'>Recipe Yield</Col>
              <Col className='mt-2 mb-4'>
                <Input type='text' value={recipeYield.value} onChange={recipeYield.set} placeholder='Recipe Yield' className={recipeYield.error ? 'border-error' : ''} onBlur={recipeYield.onBlur} onFocus={recipeYield.onFocus} />
              </Col>
            </Col>
            <Col className='p-0'>
              <Col className='text-gray'>Recipe Yield UOM</Col>
              <Col className='mt-2 mb-4'>
                <Input type='text' value={recipeYieldUom.value} onChange={recipeYieldUom.set} placeholder='Recipe Yield UOM' className={recipeYieldUom.error ? 'border-error' : ''} onBlur={recipeYieldUom.onBlur} onFocus={recipeYieldUom.onFocus} />
              </Col>
            </Col>
          </Col>
        </ShowIf>
        <Col className='text-gray'>Description (Optional)</Col>
        <Col className='mt-2 mb-4'>
          <textarea className='w-100' placeholder='Your message' onChange={description.set} value={description.value}></textarea>
        </Col>
        <Col className='text-gray'>Procedure (Optional)</Col>
        <Col className='mt-2 mb-4'>
          <textarea className='w-100' placeholder='Your message' onChange={procedure.set} value={procedure.value}></textarea>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button color='green' onClick={clickSave}>{isEdit ? 'Save' : 'Add Ingredients'}</Button>
      </ModalFooter>
    </Modal>
  );
};

export default RecipeInfoModal;