import axios from 'axios';

const get = (menuId) => {
  const filters = [{
    field: 'recipe_menu_category_id',
    operator: '=',
    value: menuId,
    table: 'recipe',
  }];
  const string = JSON.stringify(filters);
  const params = { filters: string };
  return axios.get('/recipe', {
    params: params
  });
};

const getInRange = (menuId, posBgnDate, posEndDate) => {
  const filters = [
    {
      field: "recipe_menu_category_id",
      operator: "=",
      value: menuId,
      table: "recipe",
    },
  ];
  const string = JSON.stringify(filters);
  const params = {
    filters: string,
    expand: "posTotalSold,posQuantitySold",
    bgn_date: posBgnDate.format("YYYY-MM-DD HH:mm:ss"),
    end_date: posEndDate.format("YYYY-MM-DD HH:mm:ss"),
  };
  return axios.get("/recipe", {
    params: params,
  });
};

const getAllSubrecipes = (page) => {
  const filters = [{
    field: 'is_sub_recipe',
    operator: '=',
    value: 1,
    table: 'recipe',
  }];
  const string = JSON.stringify(filters);
  const params = { filters: string, limit: 100, expand: 'menu', page: page };
  return axios.get('/recipe', {
    params: params
  });
};

const getSubrecipes = (recipeId, page) => {
  const filters = [{
    field: 'is_sub_recipe',
    operator: '=',
    value: 1,
    table: 'recipe',
  }, {
    field: 'id',
    operator: '<>',
    value: recipeId,
    table: 'recipe',
  }];
  const string = JSON.stringify(filters);
  const params = { filters: string, limit: 100, expand: 'menu', page: page };
  return axios.get('/recipe', {
    params: params
  });
};

const patchIngredient = (recipeId, id, params) => {
  return axios.patch(`/recipe/${recipeId}/ingredient/${id}`, params);
};

const postIngredient = (recipeId, params) => {
  return axios.post(`/recipe/${recipeId}/ingredient`, params);
};

const getRecipe = (recipeId, expand) => {
  const params = { expand: expand };
  return axios.get(`/recipe/${recipeId}`, {
    params: params
  });
};

const getGenericItem = (itemId, genericId) => {
  return axios.get(`recipe/item/${itemId}/genericItem/${genericId}`);
};

const getGenericCustomItem = (itemId, genericId) => {
  return axios.get(`recipe/item/${itemId}/genericCustomItem/${genericId}`);
};

const duplicate = (recipeId) => {
  return axios.post(`/recipe/${recipeId}/duplicate`);
};

const copyRecipeToAnotherLocation = (recipeId, locationId) => {
  return axios.post(`/recipe/${recipeId}/copy-to-location/${locationId}`);
};

const deleted = (recipeId) => {
  return axios.delete(`/recipe/${recipeId}`);
};

const deleteIngredient = (rId, ingId) => {
  return axios.delete(`/recipe/${rId}/ingredient/${ingId}`);
};

const patch = (recipeId, params) => {
  return axios.patch(`/recipe/${recipeId}`, params);
};

const post = (params) => {
  return axios.post(`/recipe`, params);
};

export default {
  get, getInRange, getRecipe, patchIngredient, duplicate, copyRecipeToAnotherLocation,
  delete: deleted, post, patch, getGenericItem,
  postIngredient, getSubrecipes, deleteIngredient,
  getAllSubrecipes, getGenericCustomItem
};
