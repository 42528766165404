import React from 'react';
import { FaLink } from 'react-icons/fa';
import { colors } from '../../config/constants';
import { Button } from 'reactstrap';

const LinkButton = ({large, isActive, onClick, ...props}) => {
  return (
    <Button {...props} onClick={onClick} size={large ? 'lg-circle' : 'circle'} color={isActive ? 'green' : 'gray'}>
      <FaLink color={isActive ? 'white' : colors.lightBlueText} size={large ? 20 : 16} style={{transform: 'rotate(45deg)'}} />
    </Button>
  );
};

export default LinkButton;