import React, {Fragment, useState, useEffect} from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../config/constants';
import { isMobile } from 'react-device-detect';
import { selectInventoryList, removeInventoryGroup, editInventoryList, removeInventoryList, sortInventoryGroups } from '../../../reducers/items/itemsActions';
import { FaCheck } from 'react-icons/fa';
import ShowIf from '../../ShowIf/ShowIf';
import comparisonViewsApi from '../../../api/comparisonViewsApi';
import {localStorageConst} from '../../../config/constants';
import { FaPen, FaTrash } from 'react-icons/fa';
import useInput from '../../SharedEffects/useInput';
import * as $ from 'jquery';
import QuestionModal from "../../../SharedComponents/Modals/QuestionModal/QuestionModal";
import { showModal } from '../../../reducers/modals/modalsActions';
import OneInputModal from "../../../SharedComponents/Modals/OneInputModal/OneInputModal";
import InventoryListModal from './InventoryListModal';
import AlertModal from "../../../SharedComponents/Modals/AlertModal/AlertModal";

const InventoryListItem = (props) => {
  const {list, selected, baseProps} = props;
  const {isMove, group, getLists, modalProps} = baseProps;
  const [ isShowInput, setIsShowInput ] = useState(false);
  const dispatch = useDispatch();
  const listInput = useInput.useText(list.name);
  const [hover, setHover] = useState(false);
  const [options, setOptions] = useState('');
  const connection = useSelector(({ connection }) => connection.online);

  const selectList = (list) => {
    if (list.id !== selected.id) {
      if (!isMove) {
        if(!connection){
          dispatch(showModal(AlertModal, {message: `Please enable Online Mode before switching inventory lists`}));
          modalProps.toggle();
          return;
        }
        dispatch(selectInventoryList(list));
        localStorage.setItem(localStorageConst.keys.listSelected, JSON.stringify(list));
        modalProps.toggle();
      } else {
        comparisonViewsApi.moveGroup(list.id, group.id, {name: group.name}).then(() => {
          dispatch(removeInventoryGroup(group.id));
          dispatch(sortInventoryGroups());
          modalProps.toggle();
        }).catch((err) => {          
          if (err.code === 400) {
            dispatch(showModal(AlertModal, {message: err.data.message}));
          } else {
            dispatch(showModal(AlertModal, {message: "Something wen't wrong"}));
          }
          console.error(err);
        });
      }
    }
  };

  const editList = () =>{
    if(!isMobile){
      setIsShowInput(true);
    }else{
      const editProps = {
        title: 'RENAME THIS LIST',
        initValue: list.name,
        menu: isMobile,
        fullMenu: false,
        onSave: updateList
      };
      dispatch(showModal(OneInputModal, editProps));
      modalProps.toggle();
    }    
  };  
  const onEnterKey = ({key}) => {
    if (key === 'Enter') updateList(listInput.value);
  };
  const updateList = (name) =>{
    comparisonViewsApi.updateList(list.id, name).then(() => {
      dispatch(editInventoryList(list.id, name));
      getLists();
      if(!isMobile){
        setIsShowInput(false);
      }else{
        goBack();
      }
    }).catch(err => console.error(err));
  };

  const deleteList = () =>{
    const qProps = {
      message: `Are you sure you want to delete ${list.name} inventory list?`,
      menu: isMobile ? true : false,
      leftButton: {
        title: 'Delete',
        color: 'orange',
        onClick: () => removeList(list.id)
      },
      rightButton: {
        title: 'Cancel',
        color: 'green',
        onClick: () => goBack()
      }
    };
    dispatch(showModal(QuestionModal, qProps));
    if(isMobile){
      modalProps.toggle();
    }    
  };
  const removeList = (listId) => {    
    comparisonViewsApi.deleteList(listId).then(() => {
      dispatch(removeInventoryList(listId));
      getLists();
      if(isMobile){
        goBack();
      }
    }).catch(err => console.error(err));   
  };

  const goBack = () => {
    dispatch(showModal(InventoryListModal));    
  };

  const buildOptions = () => {    
    const icons = <Fragment>
                    <Col className={'text-center' + (isMobile ? ' p-0' : '')}>
                      <FaPen onClick={() => editList() } className='marginL200 clickable' color={colors.lightBlueText}/>
                    </Col>
                    <Col className={'text-center' + (isMobile ? ' p-0' : '')}>
                      {!selected ?
                        <FaTrash onClick={() => deleteList() } className='marginL30 clickable' color={colors.lightBlueText}/> 
                      : '' } 
                    </Col>
                  </Fragment>;
    const nOptions = isMove ? '' : (!isMobile ? (hover ? icons : '') : icons);
    setOptions(nOptions);
  };

  useEffect(() => {
    buildOptions();
  }, [hover]);


  useEffect(() => {
    if (isShowInput) {
      $('#invlist_'+list.id+'_input').focus();
    }
  }, [isShowInput]);

  return (
    <Fragment>
      <ShowIf show={!isMobile}>
        <Row className='py-3 clickable m-0 align-items-center hover-light' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          <Col xs='1' className='pr-0 text-right'>
            {(!isMobile && selected) && <FaCheck color={colors.green} size={20} />}
          </Col>
          <Col xs='8' className='pr-0 text-left' onClick={() => { selectList(list) }}  >
            <ShowIf show={!isShowInput}>
              {listInput.value}
            </ShowIf>
            <ShowIf show={isShowInput}>
              <Input id={`invlist_${list.id}_input`} onChange={listInput.set} value={listInput.value} onKeyDown={onEnterKey}/>
            </ShowIf>
          </Col>
          <Col xs='2' className='pr-0 text-left d-flex flex-direction-row'>
            <ShowIf show={!isShowInput}>
              {options}
            </ShowIf>
            <ShowIf show={isShowInput}>
              <Button onClick={() => updateList(listInput.value)} color='green'>Update</Button>
            </ShowIf>
          </Col>
        </Row>
      </ShowIf>

      <ShowIf show={isMobile}>
        <Row className={'item' + (selected ? ' bg-light' : '')}>
          <Col className='pl-0 d-flex flex-direction-row align-items-center' onClick={() => { selectList(list) }}>
            {(selected) && <FaCheck className='pr-2' color={colors.green} size={25} />} {listInput.value}
          </Col>
          <Col xs='3' className='p-0 d-flex flex-direction-row'>
            {options}
          </Col>
        </Row>
      </ShowIf>
    </Fragment>
    
  );
};

export default InventoryListItem;