import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import Header from '../../../../../SharedComponents/Header/Header';
import useRecipeCostingRecipe from './useRecipeCostingRecipe';
import RecipeInfo from './Subcomponents/RecipeInfo';
import RecipeIngredients from './Subcomponents/RecipeIngredients';
import { isMobile } from 'react-device-detect';
import { GoPlus } from 'react-icons/go';
import { MdEdit } from 'react-icons/md';
import { colors } from '../../../../../config/constants';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setMenuList, setRecipeList, setIngredientsList } from '../../../../../reducers/recipeCosting/recipeCostingActions';
import { FaSortAlphaUp, FaSortAlphaDown } from 'react-icons/fa';

const RecipeCostingRecipe = (props) => {
  const { create, createRecipe } = props;
  const menuList = useSelector(({recipeCosting}) => recipeCosting.menuList);
  const { recipeInfo, ingredientsList, getRecipeData } = useRecipeCostingRecipe.useApi();
  const infoProps = useRecipeCostingRecipe.useInfoForms();
  const { editInfo, saveInfo } = useRecipeCostingRecipe.useEditRecipeInfo(getRecipeData);
  const { addIngredients, addIngredientsMobile } = useRecipeCostingRecipe.useAddIngredients(recipeInfo, getRecipeData);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (create) {
      if (!infoProps.menuCategory.value) {
        dispatch(setMenuList([]));
        dispatch(setRecipeList([]));
        dispatch(setIngredientsList([]));
        history.replace('/recipe-costing');
      }
    }
  }, []);

  const rightButton = isMobile ?
    {icon: [<MdEdit onClick={editInfo} color={colors.dark} size={22} />, <GoPlus onClick={addIngredientsMobile} color={colors.dark} size={22} /> ]} : {button: {color: 'green', title:'Add Ingredients', onClick: addIngredients, disabled: create}};

  return (
    <Fragment>
      <Header title={recipeInfo.recipeName} showTitle showMenu={false} showLogo={false} showBack={true} showCart={false} rightButton={rightButton} />
      <Container fluid={true} className={'m-0 ' + (isMobile ? 'p-0' : 'pt-3 mb-5')}>
        <ShowIf show={!isMobile}>
          <Card>
            <Row className='m-0'>
              <Col className='py-3 border-bottom border-right w-360px'><h6 className='m-0 font-weight-bold'>Recipe Description</h6></Col>
              <Col className='py-3 border-bottom'><h6 className='clickable m-0 font-weight-bold'>Ingredients</h6></Col>
            </Row>
            <Row className='m-0'>
              <RecipeInfo {...infoProps} menuList={menuList} saveInfo={saveInfo} create={create} createRecipe={createRecipe} />
              <RecipeIngredients ingredientsList={ingredientsList} getRecipeData={getRecipeData} {...recipeInfo} isSubrecipe={infoProps.isSubrecipe} />
            </Row>
          </Card>
        </ShowIf>
        <ShowIf show={isMobile}>
          <Row className='m-0'>
            <RecipeIngredients recipeInfo={recipeInfo} ingredientsList={ingredientsList} getRecipeData={getRecipeData} {...recipeInfo} isSubrecipe={infoProps.isSubrecipe} />
          </Row>
        </ShowIf>
      </Container>
    </Fragment>
  );
}

export default RecipeCostingRecipe;