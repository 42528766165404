import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { FiMoreHorizontal } from 'react-icons/fi';
import { colors } from '../../../../../../../config/constants';
import ShowIf from '../../../../../../../SharedComponents/ShowIf/ShowIf';
import OrderGuideBookmark from '../../../../../../../SharedComponents/Bookmark/OrderGuideBookmark';
import LastPurchasedBookmark from '../../../../../../../SharedComponents/Bookmark/LastPurchasedBookmark';
import moment from 'moment';
import HeartBookmark from '../../../../../../../SharedComponents/Bookmark/HeartBookmark';
import { isMobile } from 'react-device-detect';
import AutoAddedBookmark from '../../../../../../../SharedComponents/Bookmark/AutoAddedBookmark';
import SubrecipeBookmark from '../../../../../../../SharedComponents/Bookmark/SubrecipeBookmark';
import CallInItemBookmark from '../../../../../../../SharedComponents/Bookmark/CallInItemBookmark';
import IconBadgeSolid from '../../../../../../../SharedComponents/IconBadge/IconBadgeSolid';
import { FaQuestion } from 'react-icons/fa';
import TooltipMenu from '../../../../../../../SharedComponents/TooltipMenu/TooltipMenu';
import { MdClose } from 'react-icons/md';
import RebateIcon from '../../../../../../../SharedComponents/Icons/RebateIcon/RebateIcon';
import { useSelector } from 'react-redux';

const ItemHeader = (props) => {
  const {
    image, name, description, orderGuide,
    isPurchased, elemId, isPreferred, autoAdded, isUnpurchasable,
    isCustomItem, isSubRecipe, isPunchedOut, orderGuideId,
    itemHeaderProps, showHistoric, showPriceAnalytics
  } = props;

  const lastPurchasedDate = moment(props.lastPurchasedDate).format("MM/DD/YYYY");
  const isStartInventory = useSelector(({ user }) => user.activeLocation.activeInventory);



  const { toggle, clickOptionsState, clickOptions, showOptionsState, options, toggleShowHistoric, togglePriceAnalytics, clickHeart } = itemHeaderProps;

  const styleLastPurchase = {
    position: 'absolute',
    top: 25,
    fontSize: 11,
    opacity: 0.8,
    width: '100%',
    marginLeft: 10
  };

  return (
    <Fragment>
      <Row className='align-items-center pb-3'>
        <Col>
          <ShowIf show={!isCustomItem}>
            <Row className='align-items-center'>
              <ShowIf show={!(isPunchedOut && !orderGuideId)}>
                <HeartBookmark elemId={elemId} fill={isPreferred} onClick={clickHeart} />
              </ShowIf>
              <ShowIf show={isSubRecipe}>
                <SubrecipeBookmark elemId={elemId} />
              </ShowIf>
              <ShowIf show={autoAdded}>
                <AutoAddedBookmark elemId={elemId} />
              </ShowIf>
              <ShowIf show={isUnpurchasable}>
                <CallInItemBookmark elemId={elemId} />
              </ShowIf>
              <ShowIf show={orderGuide}>
                <OrderGuideBookmark elemId={elemId} />
              </ShowIf>
              <ShowIf show={isPurchased}>
                <LastPurchasedBookmark elemId={elemId} />
                <ShowIf show={!isMobile}>
                  <Col className='px-1 w-fc text-label-bold-sm'>{lastPurchasedDate}</Col>
                </ShowIf>
                <ShowIf show={isMobile}>
                  <div className='text-label-bold-sm' style={styleLastPurchase}>Last purchased {lastPurchasedDate}</div>
                </ShowIf>
              </ShowIf>
            </Row>
          </ShowIf>
          <ShowIf show={isCustomItem}>
            <Row>
              <Col className='text-tooltip-transparent font-weight-bold'>Custom Item</Col>
            </Row>
          </ShowIf>
        </Col>
        <Col className='w-fc'>
          <Row className='justify-content-end'>
            <ShowIf show={isPunchedOut}>
              <Col className='w-fc px-1'>
                <IconBadgeSolid Icon={FaQuestion} colorIcon='white' color={colors.blue} elemId={elemId + 'puch'}>
                  <Row className='p-3'>
                    <Col>
                      This item is grayed out because it was not on your most recent order guide sent by your distributor.
                    </Col>
                  </Row>
                </IconBadgeSolid>
              </Col>
            </ShowIf>
            <Col className={'w-fc px-1 clickable' + (showHistoric ? ' d-none' : '')} id={elemId + 'opt'} onClick={clickOptions}>
              <FiMoreHorizontal tabIndex={0} className='clickable no-focus-outline' size={20} color={colors.lightBlueText} />
              <ShowIf show={!isMobile}>
                <TooltipMenu target={elemId + 'opt'} options={options} toggle={toggle} isOpen={clickOptionsState.value && showOptionsState.value} />
              </ShowIf>
            </Col>
            <ShowIf show={showHistoric}>
              <Col className='w-fc px-1 clickable' onClick={toggleShowHistoric}>
                <MdClose className='clickable' size={20} color={colors.lightBlueText} />
              </Col>
            </ShowIf>
            <ShowIf show={showPriceAnalytics}>
              <Col className='w-fc px-1 clickable' onClick={togglePriceAnalytics}>
                <MdClose className='clickable' size={20} color={colors.lightBlueText} />
              </Col>
            </ShowIf>
          </Row>
        </Col>
      </Row>
      <ShowIf show={!showHistoric && !showPriceAnalytics && !isStartInventory}>
        <Row className='justify-content-center pb-3'>
          <Col className='w-image text-center mh-image d-flex align-items-center justify-content-center'>
            <img src={image} alt='item' className='no-image' />
            <RebateIcon item={props} />
          </Col>
        </Row>
      </ShowIf>
      <Row style={{ height: '80px' }}>
        <Col className='text-main text-truncate-3 text-capitalize px-2'>{name || description}</Col>
      </Row>
    </Fragment>
  );
};

export default ItemHeader;