import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUserData } from "../../reducers/user/userAction";
import { clearAllItemsData } from "../../reducers/items/itemsActions";
import { clearCartData } from "../../reducers/cart/cartActions";
import { localStorageConst, fullstory } from '../../config/constants';


export default () => {
  const history = useHistory();
  const dispatch = useDispatch();

  localStorage.clear();  
  dispatch(clearAllItemsData());
  dispatch(clearCartData());
  dispatch(clearUserData());

  localStorage.removeItem(localStorageConst.keys.cartItems);
  localStorage.removeItem(localStorageConst.keys.lastModifiedCart);
  localStorage.removeItem(localStorageConst.keys.savingOptions);
  localStorage.removeItem(localStorageConst.keys.view);
  localStorage.removeItem(localStorageConst.keys.listSelected);
  localStorage.removeItem(localStorageConst.keys.listItems);
  localStorage.removeItem(localStorageConst.keys.listItemsPage);
  localStorage.removeItem(localStorageConst.keys.inventoryItems);
  localStorage.removeItem(localStorageConst.keys.inventoryItemsPage);
  localStorage.removeItem(localStorageConst.keys.connection);
  localStorage.removeItem(fullstory.localstorage);
  

  history.push('/login');

};