import React, { useEffect, useRef, createRef } from 'react';
import { Col, Button } from 'reactstrap';
import './Header.scss';
import ShowIf from '../ShowIf/ShowIf';
import { IoMdSearch, IoIosOptions } from 'react-icons/io';
import InputSearch from '../InputSearch/InputSearch';
import { isMobile } from 'react-device-detect';
import { colors } from '../../config/constants';
import { useDispatch } from 'react-redux';
import { showModal } from '../../reducers/modals/modalsActions';
import SearchFiltersModal from '../Modals/SearchFiltersModal/SearchFiltersModal';
import { useSelector } from 'react-redux';
import useHeader from './useHeader';
import WelComeTourModal from "../../SharedComponents/Modals/WelComeTourModal/WelcomeTourModal";

const HeaderSearch = (props) => {
  const { show, setSearch, search, clearSearch, enterSearch, showFilters } = props;
  const dispatch = useDispatch();

  const openFilters = () => {
    dispatch(showModal(SearchFiltersModal));
  };
  const { clickMenu,
    enterSearchs,
    clearSearchs,
    setSearchs,
    searchs,
    clickBacks,
    openModalWC,
    tourElementSearch,
    tourElementFilters,
    startTour,
    goToNextTourModal,
    skipDemo } = useHeader.useActions(dispatch);


    
  const numTourModal = useSelector(state => state.modals.numTourModal);

  useEffect(() => {
    switch (numTourModal) {
      case 2:
        if (!isMobile)
          clickMenu();
        startTour();
        break;
      case 3:
        startTour();
        break;
      case 4:
        startTour();
        break;
      default:
        break;
    }
  }, [numTourModal])

  return (
    <ShowIf show={show}>
      <Col className='pl-0' >
        <em ref={tourElementSearch}></em>
        <InputSearch type='text' placeholder='Search...'
          onChange={setSearch} value={search}
          clearValue={clearSearch} onEnter={enterSearch}
        />
      </Col>
      <ShowIf show={!isMobile}>
        <Col className='w-fc'>
          <Button color='green' onClick={enterSearch}>
            <IoMdSearch color='white' /> Search
          </Button>
        </Col>
        <ShowIf show={showFilters}>
          <Col className='w-fc'>
            <Button color='green' onClick={openFilters}>
              <em ref={tourElementFilters}></em>

              <IoIosOptions color='white' /> Filters
            </Button>
          </Col>
        </ShowIf>
      </ShowIf>
      <ShowIf show={isMobile && showFilters}>
        <Col className='w-fc px-0' onClick={openFilters}>
          <IoIosOptions size={28} color={colors.dark} />
        </Col>
      </ShowIf>

      <ShowIf show={openModalWC}>
        <WelComeTourModal
          openModal={openModalWC}
          tourElement={numTourModal == 2 ? tourElementSearch : tourElementFilters}
          startTour={startTour}
          skipDemo={skipDemo}
          goToNextTourModal={() => goToNextTourModal(numTourModal + 1)}
        />
      </ShowIf>
    </ShowIf>
  );
};

export default HeaderSearch;