import { isMobile } from "react-device-detect";

export default function reducer(state = {
  index : 3,
  contents: {
    showMenu: isMobile ? true : false,
    showLogo: false,
    showBack: false,
    showCart: isMobile ? true : false,
    showReviewOrder: isMobile ? false : true,
    title: isMobile ? 'Review Order' : '',
    showDet_ReviewOrder: false
  },
}, action) {
  switch (action.type) {
    case 'SET_HEADER_CONTENTS_REVIEW_ORDER':
      return {
        ...state,
        contents: action.payload
      };
    case 'SET_HEADER_INDEX_REVIEW_ORDER':
      return {
        ...state,
        index: action.payload
      };
    default:
      return state;
  }
};