import React from 'react';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import orderGuideApi from '../../../../../api/orderGuideApi';
import OrderGuideRow from "./OrderGuideRow";

const useApi = (props) => {
  const [orderGuideList, setOrderGuideList] = useState([]);

  const getOrdersGuide = () => {
    const params = {
      page : 1,
      limit : 50
    };
    orderGuideApi.getOrderGuideByDistributor(params).then((res) => {
      setOrderGuideList(res.data);
    }).catch(error => {
    });
  };

  useEffect(() => {
    getOrdersGuide();
  }, []);

  const orderGuideMap = orderGuideList.map(c => 
      <OrderGuideRow  key={c.og_id} id={c.og_id} data={c} />
  )


  return { orderGuideList, orderGuideMap };
}

const useRow = (props) => {
  const {id } = props;
  const [hover, setHover] = useState(false);
  const history = useHistory();

  const downloadOG = () => {
    orderGuideApi.downloadOrderGuide(id).then((res) => {
      if(res.data) {
        const excelElement = document.createElement('a');
        excelElement.href = res.data;
        excelElement.click();
      }
    }).catch(error => {
      
    });
  }

  const goToOrderGuideDetail = () => {
    history.push(`/order-guide/detail/${id}`);
  };


  return {
    setHover, hover, downloadOG, goToOrderGuideDetail
  }

}

export default {
  useApi, useRow
};