import americanExpressIcon from '../../../../assets/images/cards/american express.png';
import dinersClubIcon from '../../../../assets/images/cards/diners club.png';
import discoverIcon from '../../../../assets/images/cards/discover.png';
import jcbIcon from '../../../../assets/images/cards/jcb.png';
import mastercardIcon from '../../../../assets/images/cards/mastercard.png';
import unionpayIcon from '../../../../assets/images/cards/unionpay.png';
import visaIcon from '../../../../assets/images/cards/visa.png';

export const cardIconHandler = (card) => {
  const cardType = card && card.cardType || '';
  switch (cardType) {
    case 'american express':
      return americanExpressIcon;
    case 'diners club':
      return dinersClubIcon;
    case 'discover':
      return discoverIcon;
    case 'jcb':
      return jcbIcon;
    case 'mastercard':
      return mastercardIcon;
    case 'unionpay':
      return unionpayIcon;
    case 'visa':
      return visaIcon;
    default:
      return '';
  }
};
