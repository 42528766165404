import React  from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap';
import useModal from '../../../../../../SharedComponents/Modals/useModal';
import noImage from '../../../../../../assets/images/no-image.png';

const OrderInventoryDetailsModal = (props) => {
    const { id, title, menu, fullMenu } = props;
    const modalProps = useModal(id, menu, fullMenu);
    const item  = props.data;
    const imageUrl = item.imageUrl ? item.imageUrl : noImage;

    return (
        <Modal {...modalProps}>
            <ModalHeader toggle={modalProps.toggle}>{title}</ModalHeader>
            <ModalBody className='px-5'>
                <Row className='py-2'>
                    <Col className='text-center'>
                        <Col className='image p-0'>
                            <img src={imageUrl} alt={item.name} className='no-image' />
                        </Col>
                    </Col>
                </Row>
                <Row className='py-2 text-capitalize text-main'>
                    <Col>
                        {item.description}
                    </Col>
                </Row>
                <Row className='py-2'>
                    <Col className='text-gray'>
                        DIN
                    </Col>
                    <Col className='text-right'>
                        {item.din}
                    </Col>
                </Row>
                <Row className='py-2'>
                    <Col className='text-gray'>
                        Price
                    </Col>
                    <Col className='text-right'>
                        ${item.din}
                    </Col>
                </Row>
                <Row className='py-2'>
                    <Col className='text-gray'>
                        Brand
                    </Col>
                    <Col className='text-right'>
                        {item.brand}
                    </Col>
                </Row>
                <Row className='py-2'>
                    <Col className='text-gray'>
                        Distributor
                    </Col>
                    <Col className='text-right'>
                        {item.distributor}
                    </Col>
                </Row>
                <Row className='py-2'>
                    <Col className='text-gray'>
                        UOM
                    </Col>
                    <Col className='text-right'>
                        {item.unitMeasure}
                    </Col>
                </Row>
                <Row className='py-2'>
                    <Col className='text-gray'>
                        Pack Size
                    </Col>
                    <Col className='text-right'>
                        {item.packSize}
                    </Col>
                </Row>
                <Row className='py-2 pb-4'>
                    <Col className='text-gray'>
                        View
                    </Col>
                    <Col className='text-right'>
                        {item.comparisonView}
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )

}

export default OrderInventoryDetailsModal;