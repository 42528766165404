import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import ItemDetailsInventoryLinkItemInfo from './ItemDetailsInventoryLinkItemInfo';
import ItemDetailsInventoryLinkItemImage from './ItemDetailsInventoryLinkItemImage';
import ItemDetailsInventoryLinkItemButton from './ItemDetailsInventoryLinkItemButton';
import ItemDetailsUnitConversion from '../SharedComponents/ItemDetailsUnitConversion';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { colors } from '../../../../../../../config/constants';
import ShowIf from '../../../../../../../SharedComponents/ShowIf/ShowIf';
import ItemDetailsInputCol from '../SharedComponents/ItemDetailsInputCol';
import useInput from '../../../../../../../SharedComponents/SharedEffects/useInput';
import useConversion from '../../../../../../../SharedComponents/Conversion/useConversion';

const ItemDetailsInventoryLinkItem = (props) => {
  const {
    image, isLink,  packSizeUom, isCustomItem,
    recipeUom, setLinkItem, index, isPunchedOut, isSubRecipe
  } = props;
  const waste = useInput.useText('', {isNumber: true, set: (v) => setLinkItem(index, 'waste', v), value: props.waste});
  const conversion = {
    item: {
      packSize: useInput.useText('', {
        validate: {fraction: {strict:false}},
        set: (v) => {
          setLinkItem(index, 'conversion', {...props.conversion, item: {packSize: v}})
        },
        value: props.conversion.item.packSize 
      }),
      uom: packSizeUom
    },
    recipe: {
      packSize: useInput.useText('', {
        validate: {fraction: {strict:false}},
        set: (v) => {
          setLinkItem(index, 'conversion', {...props.conversion, recipe: { packSize: v}})
        },
        value: props.conversion.recipe.packSize
      }),
      uom: recipeUom
    }
  };

  const [ showMore , setShowMore ] = useState(false);
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const toggleIsLink = () => {
    setLinkItem(index, 'isLink', !isLink);
  };

  return (
    <Row className={'m-0 mb-3'+ (isPunchedOut ? ' bg-dim' : '')}>
      <Col className='border'>
        <Row>
          <ShowIf show={isCustomItem}>
            <Col className='text-tooltip-transparent font-weight-bold'>Custom Item</Col>
          </ShowIf>
        </Row>
        <Row className='align-items-center py-3'>
          <ItemDetailsInventoryLinkItemImage image={image} />
          <ItemDetailsInventoryLinkItemInfo {...props} />
          <ItemDetailsInventoryLinkItemButton isLink={isLink} onClick={toggleIsLink} />
        </Row>
        <ItemDetailsUnitConversion canConvert={useConversion.useCanConvert(recipeUom, packSizeUom)} item={{uom: conversion.item.uom, packSize:conversion.item.packSize}} recipe={conversion.recipe} />
        <ShowIf show={showMore}>
          <Row className='py-3 border-top'>
            <ItemDetailsInputCol label='Waste' type='number' {...waste} sideText='%' />
          </Row>
        </ShowIf>
        <Row className='py-3 border-top'>
          <Col className='text-label text-center text-uppercase clickable' onClick={toggleShowMore}>
            {showMore ? 'Show Less' : 'Show More'} {showMore ? <FaCaretUp color={colors.lightBlueText} size={16} /> : <FaCaretDown color={colors.lightBlueText} size={16} /> }
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ItemDetailsInventoryLinkItem;