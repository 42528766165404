import React, { Fragment, useContext } from 'react';
import { ListGroupItem } from 'reactstrap';
import numeral from 'numeral';
import OnHandItemReview from '../../../../../SharedComponents/OnHandItem/OnHandItemReview';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { InventoryContext } from '../../PlaceOrder/PlaceOrderInventory/PlaceOrderInventoryItem/context/InventoryContext';
import { useSelector } from 'react-redux';


const ListItemsGroupInventoryDetail = (props) => {
    const { item, unitToConvert, comparisonViewId, groupId, groupTotalProps } = props;
    const isCustomPackSize = item.customPackSize ? !(item.customPackSize === item.packSize) : false;
    const finalPackSize = isCustomPackSize ? item.customPackSize : item.packSize;
    const finalPackSizeUom = (unitToConvert.label !== 'Original') ? unitToConvert.label : item.packSizeUom;
    const inventoryContext = useContext(InventoryContext);
    const isActiveInventoryEdit = useSelector(({ user }) => user.isActiveInventoryEdit);

    const {
        totalOH,
        totalOHAmount,
        collectiveOH,
        collectiveOHUom,
        totalCase,
        totalPack,
        totalUnit,
    } = inventoryContext;



    return (
        <Fragment>
            <ListGroupItem className={"subItemGroup w-100 " + (isMobile ? " pl-4" : "")} tag="a" >
                <div className="d-flex justify-content-between">

                    {/* <div className="d-flex flex-column " style={{ width: 300 }}>
                    </div> */}
                    <ShowIf show={!isMobile}>
                        <div className="d-flex flex-column mxw-200">
                            <span className="text-sm">{item.description}</span>
                            <div className=" d-flex mt-2" >
                                <p className={"text-sm"}>{item.distributor}</p>
                                <p className={"text-sm ml-2"}>{numeral(item.price).format('$0,0.00')} {item.unitMeasure}</p>
                                <p className={"text-sm ml-2"}>{finalPackSize}</p>
                            </div>
                        </div>
                    </ShowIf>

                    <ShowIf show={isMobile}>
                        <div className="d-flex flex-column w-100">
                            <div className="d-flex justify-content-between">
                                <span className="text-sm">{item.description}</span>
                                <p className="text-sm">{numeral(totalOHAmount).format('$0,0.00')} </p>

                            </div>
                            <div className="d-flex flex-row mt-2" >
                                <p style={{ flex: 0.3333 }} className={"text-sm"}>{item.distributor}</p>
                                <p style={{ flex: 0.3333, textAlign: "center" }} className={"text-sm ml-2"}>{numeral(item.price).format('$0,0.00')} {item.unitMeasure}</p>
                                <p style={{ flex: 0.3333, textAlign: "right" }} className={"text-sm ml-2"}>{finalPackSize}</p>
                            </div>
                        </div>
                    </ShowIf>

                    <ShowIf show={!isMobile}>
                        <div style={{
                            display: "initial !important"
                        }}>
                            <div className={"mb-2" + (isActiveInventoryEdit ? " text-center" : "")}>
                                Case
                            ({numeral(totalCase).format('$0,0.00')})

                        </div>
                            <div className="d-flex align-items-center ww-200">
                                <OnHandItemReview
                                    onHandType="cs"
                                    item={item}
                                    isCustomPackSize={isCustomPackSize}
                                    unitToConvert={unitToConvert}
                                    comparisonViewId={comparisonViewId}
                                    groupId={groupId}
                                    groupTotalProps={groupTotalProps}
                                    type="edit"

                                />
                            </div>
                        </div>



                        <div style={{
                            display: "initial !important"
                        }}>
                            <div className={"mb-2" + (isActiveInventoryEdit ? " text-center" : "")}>
                                Pack
                            ({numeral(totalPack).format('$0,0.00')})
                        </div>
                            <div className="d-flex align-items-center ww-200">
                                <OnHandItemReview
                                    onHandType="pack"
                                    item={item}
                                    isCustomPackSize={isCustomPackSize}
                                    unitToConvert={unitToConvert}
                                    comparisonViewId={comparisonViewId}
                                    groupId={groupId}
                                    groupTotalProps={groupTotalProps}
                                    type="edit"
                                />
                            </div>
                        </div>


                        <div style={{
                            display: "initial !important"
                        }}>
                            <div className={"mb-2" + (isActiveInventoryEdit ? " text-center" : "")}>
                                Unit
                            ({numeral(totalUnit).format('$0,0.00')})
                        </div>
                            <div className="d-flex align-items-center ww-200">
                                <OnHandItemReview
                                    onHandType="uom"
                                    item={item}
                                    isCustomPackSize={isCustomPackSize}
                                    unitToConvert={unitToConvert}
                                    comparisonViewId={comparisonViewId}
                                    groupId={groupId}
                                    groupTotalProps={groupTotalProps}
                                    type="edit"
                                />
                            </div>
                        </div>




                        <div className="text-left" style={{ width: 75 }}>
                            <p className="priceItemDetail">{numeral(totalOHAmount).format('$0,0.00')}</p>
                        </div>
                    </ShowIf>

                </div>



                <ShowIf show={isMobile}>
                    <div className="mt-3">
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <div className="w-100">
                                <p className="text-sm">
                                    Case ({numeral(totalCase).format('$0,0.00')})
                                </p>
                            </div>
                            <div className="d-flex align-items-center w-100">
                                <OnHandItemReview
                                    onHandType="cs"
                                    item={item}
                                    isCustomPackSize={isCustomPackSize}
                                    unitToConvert={unitToConvert}
                                    comparisonViewId={comparisonViewId}
                                    groupId={groupId}
                                    groupTotalProps={groupTotalProps}
                                    type="edit"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3">

                            <div className="w-100">
                                <p className="text-sm">
                                    Pack ({numeral(totalPack).format('$0,0.00')})
                                </p>
                            </div>
                            <div className="d-flex align-items-center w-100">
                                <OnHandItemReview
                                    onHandType="pack"
                                    item={item}
                                    isCustomPackSize={isCustomPackSize}
                                    unitToConvert={unitToConvert}
                                    comparisonViewId={comparisonViewId}
                                    groupId={groupId}
                                    groupTotalProps={groupTotalProps}
                                    type="edit"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3">

                            <div className="w-100">
                                <p className="text-sm">
                                    Unit ({numeral(totalUnit).format('$0,0.00')})
                                </p>
                            </div>
                            <div className="d-flex align-items-center w-100">
                                <OnHandItemReview
                                    onHandType="uom"
                                    item={item}
                                    isCustomPackSize={isCustomPackSize}
                                    unitToConvert={unitToConvert}
                                    comparisonViewId={comparisonViewId}
                                    groupId={groupId}
                                    groupTotalProps={groupTotalProps}
                                    type="edit"
                                />
                            </div>
                        </div>

                    </div>


                </ShowIf>

            </ListGroupItem>
        </Fragment >
    );
};

export default ListItemsGroupInventoryDetail;
