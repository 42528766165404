import React from 'react';
import { MdDelete } from 'react-icons/md';
import { Col, ModalFooter, Row, Button } from 'reactstrap';
import { colors } from '../../../../../../../config/constants';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../../../SharedComponents/ShowIf/ShowIf';

const ItemDetailsFooter = ({onSave, onDelete, label, disabled, hideDelete}) => {
  return (
    <ModalFooter className='px-0'>
      <Row className='w-100 m-0 align-items-center'>
        <ShowIf show={!hideDelete}>
          <Col className='text-label w-fc clickable' onClick={onDelete}>
            <MdDelete size={isMobile ? 26 : 22} color={colors.lightBlueText} /> { isMobile ? 'Delete' : 'Delete this ingredient'}
          </Col>
        </ShowIf>
        <Col className='text-right'>
          <Button disabled={disabled} color='green' className='px-5' onClick={onSave}>{label}</Button>
        </Col>
      </Row>
    </ModalFooter>
  );
};

export default ItemDetailsFooter;