import React, { useState } from 'react';
import { Card, Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { MdAddCircle, MdCancel } from 'react-icons/md';
import numeral from 'numeral';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { colors } from '../../../../../config/constants';
import noImage from '../../../../../assets/images/no-image.png';
import smallCircle from '../../../../../assets/images/small-circle.png';
import uncheckedCircle from '../../../../../assets/images/unchecked-circle.svg';
import checkedCircle from '../../../../../assets/images/checked-circle.svg';
import './ShoppingListItemBlock.scss';

const ShoppingListItemBlock = (props) => {
  const history = useHistory();
  const item = props.children;
  item.name = item.name.toLowerCase();

  const imageUrl = item.imageUrl ? item.imageUrl : noImage;

  const addShoppingListItem = () => {
    props.onInsert(item.itemDistributorId);
  };

  const deleteShoppingListItem = () => {
    props.onDelete(item.itemDistributorId);
  };

  const goToItemDetails = () => {
    history.push(`/item/${item.itemId}`);
  };

  if (isMobile) {
    return (
      <Card className={'shopping-list-item-block' + (item.isPunchedOut ? ' dim' : '')}>
        <Row>
          <Col xs={'auto'} style={{padding: '5px 3px', flex: '0 0 20%', maxWidth: '20%'}} className='text-center'>
            <img src={imageUrl} alt={item.name} className='no-image img-fluid' style={{maxWidth: '100%'}} />
          </Col>
          <Col xs={'auto'} style={{padding: '5px 5px', flex: '0 0 60%', maxWidth: '60%'}}>
            <Row className='mt-1'>
              <Col xs={12}>
                <h4 className='item-name' title={item.name}>{item.name}</h4>
              </Col>
            </Row>
            <ShowIf show={!!item.foodbamCustomDescription}>
              <Row className='mb-0'>
                <Col xs={12} className='brand-distributor' title={item.foodbamCustomDescription}>{item.foodbamCustomDescription}</Col>
              </Row>
            </ShowIf>
            <Row className='mb-0'>
              <ShowIf show={!!item.distributor}><Col xs={5} className='brand-distributor' title={item.distributor}>{item.distributor}</Col></ShowIf>
              <ShowIf show={!!item.distributor && !!item.brand}><Col xs={1} className='text-center'><img src={smallCircle} /></Col></ShowIf>
              <Col xs={5} className='brand-distributor' title={item.brand}>{item.brand}</Col>
            </Row>
            <Row className='mb-0'>
              <ShowIf show={!!item.price}><Col xs={5} className='item-price'>{numeral(item.price).format('$0,0.00')} {item.unitMeasure}</Col></ShowIf>
              <ShowIf show={!!item.price && !!item.packSize}><Col xs={1} className='text-center'><img src={smallCircle} /></Col></ShowIf>
              <Col xs={5} className='item-price'>{item.packSize}</Col>
            </Row>
          </Col>
          <Col xs={'auto'} style={{padding: '5px 3px', flex: '0 0 20%', maxWidth: '20%'}} className='text-center'>
            { props.onInsert ? <img src={uncheckedCircle} className='clickable mt-2' onClick={addShoppingListItem} /> : null }
            { props.onDelete ? <img src={checkedCircle} className='clickable mt-2' onClick={deleteShoppingListItem} /> : null }
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Card className={'shopping-list-item-block mb-3' + (item.isPunchedOut ? ' dim' : '')}>
      <Row>
        <Col xs={12} className='text-center'>
          <div className='img-wrapper'><img src={imageUrl} alt={item.name} className='no-image' /></div>
        </Col>
        <Col xs={12} style={{minHeight: 150}}>
          <Row className='mt-1'>
            <Col xs={12}>
              <h4 className='item-name' title={item.name}>{item.name}</h4>
            </Col>
          </Row>
          <ShowIf show={!!item.foodbamCustomDescription}>
            <Row className='mb-2'>
              <Col xs={12} className='brand-distributor' title={item.foodbamCustomDescription}>{item.foodbamCustomDescription}</Col>
            </Row>
          </ShowIf>
          <Row className='mb-2'>
            <ShowIf show={!!item.distributor}><Col xs={5} className='brand-distributor' title={item.distributor}>{item.distributor}</Col></ShowIf>
            <ShowIf show={!!item.distributor && !!item.brand}><Col xs={2} className='text-center'><img src={smallCircle} /></Col></ShowIf>
            <Col xs={5} className='brand-distributor' title={item.brand}>{item.brand}</Col>
          </Row>
          <Row className='mb-2'>
            <ShowIf show={!!item.price}><Col xs={5} className='item-price'>{numeral(item.price).format('$0,0.00')} {item.unitMeasure}</Col></ShowIf>
            <ShowIf show={!!item.price && !!item.packSize}><Col xs={2} className='text-center'><img src={smallCircle} /></Col></ShowIf>
            <Col xs={5} className='item-price'>{item.packSize}</Col>
          </Row>
        </Col>
        <Col xs={12} className='text-center'>
          { props.onInsert ? <MdAddCircle size={60} color={colors.green} className='clickable mt-2' onClick={addShoppingListItem} /> : null }
          { props.onDelete ? <MdCancel size={60} color={colors.orange} className='clickable mt-2' onClick={deleteShoppingListItem} /> : null }

        </Col>
      </Row>
    </Card>
  );
};

export default ShoppingListItemBlock;
