import axios from 'axios';
import moment from 'moment-timezone';


const get = () => {
  return axios.get('/distributors');
};

const getById = (id) => {
  const timezone=moment.tz.guess();
  const params = {timezone: timezone};
  return axios.get(`/distributors/${id}`, {params});
};

const list = (params) => {
  return axios.get('distributors/list', {
    params: params
  });
};

export default {
  get, getById, list
};