import db from './db';
import {dbStores} from './dbConstants';


// Inventory Lists
const listsTable = dbStores.tables.lists;

export const deleteAllInventoryLists = async () => {
  return await db.table(listsTable).clear();
};

export const saveAllInventoryLists = async (lists) => {
  return await db.table(listsTable).bulkAdd(lists);  
};

export const getInventoryLists = async () => {
  return await db.table(listsTable).toArray();
};


// Groups
const groupsTable = dbStores.tables.groups;
const groupsTableBaseTable = dbStores.tables.groupsBase;

export const deleteAllGroups = async () => {
  return await db.table(groupsTable).clear();  
};

export const saveAllGroups = async (groups) => { 
  return await db.table(groupsTable).bulkAdd(groups);
};

export const getGroupsByList = async (cvId) => {
  return await db.table(groupsTable)
    .where('comparisonViewId')
    .equals(cvId)
    .toArray();
};

export const findAllGroups = async () => {
  return await db.table(groupsTable).toArray();
};

export const findOneGroup = async (id) => {
  return await db.table(groupsTable)
    .where('id')
    .equals(id)
    .toArray();
};

export const updateOneGroup = async (params, id) => {
  return await db.table(groupsTable)
    .where('id')
    .equals(id)
    .modify(params);
};

// GroupsBase
export const saveBaseGroups = async (listSelected, sortBy, search, onHandTotal) => {
  const params = {    
    listSelected: listSelected,
    sortBy: sortBy,
    search: search,
    onHandTotal: onHandTotal
  }
  return await db.table(groupsTableBaseTable).add(params);    
};

export const deleteBaseGroups = async () => {
  return await db.table(groupsTableBaseTable).clear();
};
