import React, { Fragment, useState, useEffect } from 'react';
import { isMobile } from "react-device-detect";
import { Col } from 'reactstrap';
import { colors, currency } from '../../../../../config/constants';
import './PlaceOrderList.scss';
import numeral from 'numeral';
import PriceOutdatedIcon from "../../../../../SharedComponents/Icons/PriceOutdatedIcon/PriceOutdatedIcon";
import PunchedOutIcon from "../../../../../SharedComponents/Icons/PunchedOutIcon/PunchedOutIcon";

const usePriceAndPackSection = (item) => {
    const [priceAndPackSection, setPriceAndPackSection] = useState('');

    const handlePricePackSection = () => {
      let newPriceAndPackSection = '';
      const newPacksize = item.packSize ? item.packSize : '';
      
      //Alert Icons
      const priceOutdatedIcon = (item.isPriceOutdated && !item.isPunchedOut) ? <PriceOutdatedIcon elemId={item.id} item={item}/> : '';  
      const punchedOutIcon = (item.isPunchedOut) ? <PunchedOutIcon elemId={item.id} item={item}/> : '';

      //Building ...
      if (item.isCatchWeight !== 1 && item.isFixedWeight !== 1 && item.isMarketPrice !== 1 && item.price !== 0 && item.price !== null){
        const price = numeral(item.price).format('$0,0.00') +' '+ item.unitMeasure;
        newPriceAndPackSection = (isMobile) ? 
          <Fragment>
            <Col className='col-5 p-0'>{price}</Col>
            <Col className='col-2 p-0 text-left'>{priceOutdatedIcon} {punchedOutIcon}</Col>
            <Col className='col-5 p-0 text-truncate'>{newPacksize}</Col>
          </Fragment>
          :
          <Fragment>
            <Col className='col-4 p-0'>{price} {priceOutdatedIcon} {punchedOutIcon}</Col>
            <Col className='col-6 p-0'>{newPacksize}</Col>
          </Fragment>;
      }
  
      if(item.isMarketPrice === 1 || item.price === 0 || item.price === null){
        const marketPrice = <span className='market-price'>MKT</span>;
        newPriceAndPackSection =  (isMobile) ? 
          <Fragment>
            <Col className='col-5 p-0'>{marketPrice}</Col>
            <Col className='col-2 p-0 text-left'>{priceOutdatedIcon} {punchedOutIcon}</Col>
            <Col className='col-5 p-0 text-truncate'>{newPacksize}</Col>
          </Fragment>
          :
          <Fragment> 
            <Col className='col-4 p-0'>{marketPrice} {priceOutdatedIcon} {punchedOutIcon} {item.unitMeasure}</Col>
            <Col className='col-6 p-0'>{newPacksize}</Col>
          </Fragment>;
      }
  
      if(item.isCatchWeight === 1 && item.isFixedWeight !== 1 && item.isMarketPrice !== 1){
        const price = numeral(item.unitPrice).format('$0,0.00') + ' '+ item.packSizeUom;
        const catchweightFormat = <span className='text-gray'>(~ {numeral(item.price).format('$0,0.00')} {item.unitMeasure})</span>;
        newPriceAndPackSection = (isMobile) ? 
          <Fragment> 
            <Col className='col-5 p-0'>{price}</Col>
            <Col className='col-2 p-0 text-left'>{priceOutdatedIcon} {punchedOutIcon}</Col>
            <Col className='col-5 p-0 text-truncate'>{newPacksize}</Col>
            <Col className='col-10 p-0'>{catchweightFormat}</Col>
          </Fragment>
          :
          <Fragment>
            <Col className='col-4 p-0'>{price} {priceOutdatedIcon} {punchedOutIcon} {catchweightFormat}</Col>
            <Col className='col-6 p-0'>{newPacksize}</Col>
          </Fragment>;
      }
  
      if(item.isFixedWeight === 1 && item.isCatchWeight !== 1 && item.isMarketPrice !== 1){
        const fixedweightFormat = <span className='text-gray'>({currency} {item.price} {item.unitMeasure})</span>;
        const price = numeral(item.unitPrice).format('$0,0.00') + ' '+ item.packSizeUom;
        newPriceAndPackSection = (isMobile) ? 
          <Fragment>
            <Col className='col-5 p-0'>{price}</Col>
            <Col className='col-2 p-0 text-left'>{priceOutdatedIcon} {punchedOutIcon}</Col>
            <Col className='col-5 p-0 text-truncate'>{newPacksize}</Col>
            <Col className='col-10 p-0'>{fixedweightFormat}</Col>
          </Fragment>
          :
          <Fragment>
            <Col className='col-4 p-0'>{price} {priceOutdatedIcon} {punchedOutIcon} {fixedweightFormat}</Col>
            <Col className='col-6 p-0'>{newPacksize}</Col>
          </Fragment>;
      }
  
      setPriceAndPackSection(newPriceAndPackSection);
    };
  
    useEffect(() => {
      handlePricePackSection();      
    }, [])
    
    return {
      priceAndPackSection
    };
};



export default {
  usePriceAndPackSection
};