export default function reducer(state = {
    values: null,
}, action) {
    switch (action.type) {
        case 'SET_VALUES_CHECKOUT':
            return {
                ...state,
                values: action.payload
            };
        default:
            return state;
    }
};