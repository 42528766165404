export const saveConnection = (connection) => {
  return {
    type: 'SET_CONNECTION',
    payload: connection
  }
};

export const saveOpen = (open) => {
  return {
    type: 'SET_OPEN',
    payload: open
  }
};

export const saveModal = (modal) => {
  return {
    type: 'SET_MODAL',
    payload: modal
  }
};