import React, { Fragment, useState, useEffect } from 'react';
import './RebateIcon.scss';
import { isMobile } from "react-device-detect";
import { localStorageConst } from '../../../config/constants';
import PropTypes from 'prop-types';
import ShowIf from '../../ShowIf/ShowIf';
import { useSelector } from 'react-redux';

const RebateIcon = (props) => { 
  const item = props.item
  // const activeLocation = JSON.parse(localStorage.getItem(localStorageConst.keys.activeLocation));
  const displayRebate = useSelector(({user}) => user.activeLocation.display_rebate);
  const [showRebate, setShowRebate] = useState(false);

  useEffect(() => {
    if (displayRebate === 1 && item.isPartner === 1 && item.rebate>0){
      setShowRebate(true);
    }
  }, []);

  return (
    <ShowIf show={showRebate}>
        <div className={`d-flex align-items-center justify-items-center rebate ${isMobile?`mobile`:`desktop`}`}>Rebate</div>
    </ShowIf>
  );
};

RebateIcon.propTypes = {
  item: PropTypes.any
};

RebateIcon.defaultProps = {
  item: {}
};

export default RebateIcon;