import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import Header from '../../../../SharedComponents/Header/Header';
import HelpContent from './HelpContent/HelpContent';
import './Help.scss';

const Help = () => {
  return (
    <Switch>
      <Route exact path='/help'>
        <Container fluid={true} className='m-0 p-0'>
          <Header showMenu={true} showLogo={true} showBack={false} title='Get Help' showCart={true} />
          <HelpContent />
        </Container>
      </Route>
      <Route>
        <Redirect to='/help' />
      </Route>
    </Switch>
  );
};

export default Help;
