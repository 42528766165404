import React, { Fragment, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import ShowItemsIn from './Subcomponents/ShowItemsIn';
import GroupTitle from './Subcomponents/GroupTitle';
import GroupTitleNew from './Subcomponents/GroupTitleNew';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { colors } from '../../../../../config/constants';
import usePlaceOrderInventory from './usePlaceOrderInventory';
import PlaceOrderInventoryItem from './PlaceOrderInventoryItem/PlaceOrderInventoryItem';
import AddNewItem from './Subcomponents/AddNewItem';
import { useSelector } from 'react-redux';

const PlaceOrderInventoryGroup = ({ item, index, dragHandleProps, isDragging, getInventoryItems }) => {
  const {
    id, name, purchased, items, totalOnHandAmount, comparisonViewId, parLevel, expand
  } = item;

  const canEditComparisonView = useSelector(({ user }) => user.editComparisonView) ? true : false;

  const [totalOnHandAmt, setTotalOnHandAmt] = useState(totalOnHandAmount);
  const [totalGroupSpent, setTotalGroupSpent] = useState(purchased);
  const groupTotalProps = { totalOnHandAmt, setTotalOnHandAmt, totalGroupSpent, setTotalGroupSpent, comparisonViewId, id };

  const { canScrollRight, scrollLeft, scrollRight, scrollPos, snapOffset } = usePlaceOrderInventory.useHorizontalScroll(id, item, items);
  const { openAdd } = usePlaceOrderInventory.useAdd(item, getInventoryItems);
  const convertProps = usePlaceOrderInventory.useConversion(item, getInventoryItems);
  const { showAdd, hideAll, ...addProps } = usePlaceOrderInventory.useAddGroups(id);
  const { deleteGroup } = usePlaceOrderInventory.useDeleteGroup(item);
  const editProps = usePlaceOrderInventory.useEditGroupName(item);
  const { openMoveToInventory } = usePlaceOrderInventory.useMoveGroup(item);
  const { openGroupOptions } = usePlaceOrderInventory.useGroupOptionsMenu(addProps, openAdd, deleteGroup, editProps.showEdit, openMoveToInventory);
  // const itemsExpand = useSelector(({ items }) => items.inventory.items);
  // const expand = itemsExpand[0].expand;
  const { sortItems } = usePlaceOrderInventory.useSorting();
  sortItems(items, convertProps.unit);

  const { conectivity } = usePlaceOrderInventory.useStatusConnection();

  const itemsMap = items.map((it, i) =>
    <PlaceOrderInventoryItem items={items} comparisonViewId={comparisonViewId} unitToConvert={convertProps.unit} getInventoryItems={getInventoryItems} group={item} groupTotalProps={groupTotalProps} key={`g_${id}_item_${it.id}`} elemId={`g_${id}_${i}`} {...it} />
  );

  return (
    <Fragment>
      <ShowIf show={showAdd.top}>
        <GroupTitleNew isUp index={index} group={item} hideAll={hideAll} id={`add_top_${id}`} title={isMobile ? 'Tap to add new generic group' : 'Click to add new generic group'} />
      </ShowIf>
      <GroupTitle {...editProps} openMoveToInventory={openMoveToInventory} deleteGroup={deleteGroup} openAdd={openAdd} openGroupOptions={openGroupOptions} {...addProps} expand={expand} dragHandleProps={dragHandleProps} isDragging={isDragging} id={id} title={name} spend={purchased ? purchased : 0} canScrollRight={canScrollRight} scrollLeft={scrollLeft} scrollRight={scrollRight} scrollPos={scrollPos} />
      <ShowIf show={expand}>
        <ShowItemsIn onHand={totalOnHandAmt} {...convertProps} comparisonViewId={comparisonViewId} groupId={item.id} parLevel={parLevel} groupTotalProps={groupTotalProps} />
        <Row className={'mt-3 align-items-center' + (isMobile ? ' m-0' : ' mx-n5')}>
          <ShowIf show={!isMobile}>
            <Col className='position-absolute px-0'>
              <Row className='m-0'>
                <Col className='clickable w-fc z-100' onClick={scrollLeft}>
                  <FaChevronLeft size={28} color={scrollPos > 0 ? colors.dark : colors.lightBlueText} />
                </Col>
                <Col></Col>
                <Col className='text-right w-fc clickable z-100' onClick={scrollRight}>
                  <FaChevronRight size={28} color={canScrollRight ? colors.dark : colors.lightBlueText} />
                </Col>
              </Row>
            </Col>
          </ShowIf>
          <Col className={isMobile ? 'px-0 w-100' : 'px-5'} id={`g_${id}_scroll_container`} style={isMobile ? { overflow: 'hidden' } : null}>
            <Row id={`g_${id}_scroll`} className={isMobile ? 'w-100 m-0 d-flex flex-nowrap pb-3 hide-scrollbar' : 'w-100 m-0 overflow-hidden flex-nowrap pb-3'} style={isMobile ? { overflow: 'auto' } : null} onScroll={isMobile ? snapOffset : null}>
              {itemsMap}
              <ShowIf show={canEditComparisonView}>
                <AddNewItem id={id} openAdd={() => openAdd(conectivity)} />
              </ShowIf>
            </Row>
          </Col>
        </Row>
        <ShowItemsIn bottomMobile onHand={totalOnHandAmount} {...convertProps} comparisonViewId={comparisonViewId} groupId={item.id} parLevel={parLevel} spend={purchased ? purchased : 0} groupTotalProps={groupTotalProps} />
      </ShowIf>
      <ShowIf show={showAdd.down}>
        <GroupTitleNew index={index} group={item} hideAll={hideAll} id={`add_down_${id}`} title={isMobile ? 'Tap to add new generic group' : 'Click to add new generic group'} />
      </ShowIf>
    </Fragment>
  );
};

export default PlaceOrderInventoryGroup;