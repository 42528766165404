export const setMenuList = (list) => {
  return {
    type: 'SET_MENU_LIST',
    payload: list
  }
};

export const setRecipeList = (list) => {
  return {
    type: 'SET_RECIPE_LIST',
    payload: list
  }
};

export const setIngredientsList = (list) => {
  return {
    type: 'SET_INGREDIENTS_LIST',
    payload: list
  }
};

export const setRecipeInfo = (info) => {
  return {
    type: 'SET_RECIPE_INFO',
    payload: info
  }
};

export const clearRecipeInfo = (menuList) => {
  return {
    type: 'SET_RECIPE_INFO',
    payload: {
      id: null,
      recipeName: '',
      menuCategory: menuList ? menuList : null,
      menuPrice: 0,
      isSubrecipe: false,
      isSubrecipeUsed: false,
      recipeYield: '',
      recipeYieldUom: '',
      description: '',
      procedure: '',
      totalCost: 0,
      marginGrossProfit: 0,
      foodCostPercent: 0,
      hasInactiveItems: 0
    }
  }
};

export const setAlphaSort = (value) => {
  return {
    type: 'SET_ALPHA_SORT',
    payload: value
  }
};