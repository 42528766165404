import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { ListGroupItem, Collapse } from 'reactstrap';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import numeral from 'numeral';
import ListItemsGroupInventoryDetail from './ListItemsGroupInventoryDetail';
import { useSelector } from 'react-redux';
import usePlaceOrderInventory from '../../PlaceOrder/PlaceOrderInventory/usePlaceOrderInventory';
import { InventoryContextProvider } from '../../PlaceOrder/PlaceOrderInventory/PlaceOrderInventoryItem/context/InventoryContext';

const ListGroupInventoryDetail = (props) => {


    const { listItems, getInventoryItems, setTotalComparisonView, showOnlyMissingHv, setUpdateComparisonView } = props;
    const expandAll = useSelector(({ inventoryView }) => inventoryView.isExpand);
    const [isOpenItems, setIsOpenItems] = useState(false);
    const convertProps = usePlaceOrderInventory.useConversion(listItems, getInventoryItems);
    const toggleItems = () => setIsOpenItems(!isOpenItems);
    const textSearchValue = useSelector(({ inventoryView }) => inventoryView.textSearchValue);

    const [totalOnHandAmt, setTotalOnHandAmt] = useState(listItems.totalOnHandAmount);
    const [totalGroupSpent, setTotalGroupSpent] = useState(listItems.purchased);
    const [totalOnHandAmtShowOnlyMissing, setTotalOnHandAmtShowOnlyMissing] = useState(listItems.totalOnHandAmount);

    const comparisonViewId = listItems.comparisonViewId;
    const id = listItems.id;

    const groupTotalProps = {
        totalOnHandAmt,
        setTotalOnHandAmt,
        totalGroupSpent,
        setTotalGroupSpent,
        comparisonViewId, id,
        setTotalComparisonView,
        showOnlyMissingHv,
        totalOnHandAmtShowOnlyMissing,
        setTotalOnHandAmtShowOnlyMissing,
    };


    useEffect(() => {
        setIsOpenItems(expandAll);
    }, [expandAll])


    const listItemByGroup = useMemo(() => listItems.items
        .filter(item => String(item.description).toLowerCase().includes(textSearchValue.toLowerCase()))
        .map((item, index) => {

            const onHand = item.onHand == null ? 0 : item.onHand;
            const onHandPack = item.onHandPack == null ? 0 : item.onHandPack;
            const onHandUom = item.onHandUom == null ? 0 : item.onHandUom;
            if (showOnlyMissingHv) {
                return (

                    onHand == 0 && onHandPack == 0 && onHandUom == 0 &&
                    <InventoryContextProvider {...item}
                        key={`itemByGroupDetail${index}`}
                    >
                        <ListItemsGroupInventoryDetail
                            item={item}
                            unitToConvert={convertProps.unit}
                            comparisonViewId={listItems.comparisonViewId}
                            groupId={listItems.id}
                            groupTotalProps={groupTotalProps}
                        />
                    </InventoryContextProvider>


                );
            } else {
                return (
                    <InventoryContextProvider {...item}
                        key={`itemByGroupDetail${index}`}
                    >
                        <ListItemsGroupInventoryDetail
                            item={item}
                            unitToConvert={convertProps.unit}
                            comparisonViewId={listItems.comparisonViewId}
                            groupId={listItems.id}
                            groupTotalProps={groupTotalProps}
                        />
                    </InventoryContextProvider>


                );
            }


        }), [listItems, textSearchValue, showOnlyMissingHv, groupTotalProps]);

    return (
        <Fragment>

            {/* List Group */}

            <ListGroupItem className="subItem w-100 " tag="a" >
                <div className="d-flex justify-content-between">
                    <span className="text-sm">{listItems.name}</span>
                    <div className="d-flex justify-content-center align-items-center">
                        <p>{numeral(listItems.totalOnHandAmount).format('$0,0.00')}</p>
                        {
                            isOpenItems ?
                                <MdExpandLess onClick={toggleItems} color="#c7d2dc" className="ml-2" size="27" />
                                :
                                <MdExpandMore onClick={toggleItems} color="#c7d2dc" className="ml-2" size="27" />
                        }
                    </div>
                </div>
            </ListGroupItem>


            {/*  List items Groups */}
            <Collapse isOpen={isOpenItems}>
                {listItemByGroup}
            </Collapse>

            {/*  */}

        </Fragment>
    );
};

export default ListGroupInventoryDetail;
