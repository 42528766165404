import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useShoppingCartItem from './useShoppingCartItem';
import useShoppingCartItemOffline from './useShoppingCartItemOffline';
import { localStorageConst, placeOrderViews } from '../../config/constants';

const useCartUpdate = (setCartAmount, groupTotalProps) => {
  const storeConnection = useSelector(({connection}) => connection.online);
  const { updateCartAmount } = useShoppingCartItem.useCartUpdate(setCartAmount, groupTotalProps);
  const { updateCartAmountOffline } = useShoppingCartItemOffline.useCartUpdateOffline(setCartAmount, groupTotalProps);

  const updateCart = (newCartAmount, item, setCartAmount, cartAmount, parentItem) => {
    const localConnection = JSON.parse(localStorage.getItem(localStorageConst.keys.connection));
    const currentConnection = (localConnection === undefined) ? storeConnection : localConnection;
    
    if (currentConnection){
      updateCartAmount(newCartAmount, item, setCartAmount, cartAmount, parentItem);
    }else{
      updateCartAmountOffline(newCartAmount, item, setCartAmount, cartAmount, parentItem);
    }
  };

  return { updateCart };

};

const useInputConnection = () => {
  const [disabled, setDisabled] = useState(false);
  const connection = useSelector(({ connection }) => connection.online);

  const lists = useSelector(({ items }) => items.inventory.list);
  const listSelected = useSelector(({ items }) => items.inventory.listSelected);
  const selected = !!lists ? lists.filter(l => l.id === listSelected.id)[0] : null;

  useEffect(() => {
    if (!!selected) {
      setDisabled((connection && selected.onInventory) ? true : false);
    }
  }, [connection, lists, listSelected]);

  return { disabled, setDisabled }
};

export default {
  useCartUpdate, useInputConnection
};