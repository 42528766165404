import React, { useEffect, useState } from 'react';
import useInput from '../../SharedEffects/useInput';
import distributorsApi from '../../../api/distributorsApi';
import shoppingListsApi from '../../../api/shoppingListsApi';
import ordersApi from '../../../api/ordersApi';
import SearchFilterModalCheckbox from './SearchFilterModalCheckbox';

const useDistributors = (init) => {
  const {
    setItems : setDistributors,
    all: allDistributors,
    toggleItem: toggleDistributor,
    items: distributors,
    toggleAll: toggleAllDistributors
  } = useInput.useCheckboxGroup();

  useEffect(() => {
    distributorsApi.get().then(({data}) => {
      const dist = data.map(d => {
        let check = false;
        init.forEach(id => {
          if (d.id === id) {
            check = true;
          }
        });
        return {
          nickname: d.nickname,
          id: d.id,
          check: check
        }
      })
      setDistributors(dist);
    }).catch(err => {
      console.error('DistributorsApi get error:', err);
    })
  }, []);

  const distributorsMapDesktop = distributors.map((d, i) =>
    <SearchFilterModalCheckbox rowClassName='py-2' key={`dist_${d.id}`} id={d.nickname + d.id} label={d.nickname} set={() => {toggleDistributor(i)}} value={d.check} />
  );

  const distributorsMapMobile = distributors.map((d, i) =>
    <SearchFilterModalCheckbox rowClassName='m-2 py-2' key={`dist_${d.id}`} id={d.nickname + d.id} label={d.nickname} set={() => {toggleDistributor(i)}} value={d.check} />
  );

  return {
    allDistributors, toggleAllDistributors, distributors, toggleDistributor,
    distributorsMapDesktop, distributorsMapMobile, setDistributors
  }
};

const useShoppingLists = (init) => {
  const {
    setItems : setShoppingLists,
    all: allShoppingLists,
    toggleItem: toggleShoppingList,
    items: shoppingLists,
    toggleAll: toggleAllShoppingLists
  } = useInput.useCheckboxGroup();

  useEffect(() => {
    shoppingListsApi.get().then(({data}) => {
      const shop = data.map(s => {
        let check = false;
        init.forEach(id => {
          if (s.id === id) {
            check = true;
          }
        });
        return {
          nickname: s.name,
          id: s.id,
          check: check
        }
      })
      setShoppingLists(shop);
    }).catch(err => {
      console.error('ShoppingListApi get error:', err);
    })
  }, []);
  
  const shoppingMapDesktop = shoppingLists.map((s, i) =>
    <SearchFilterModalCheckbox rowClassName='py-2' key={`shop_${s.id}`} id={s.nickname + s.id} label={s.nickname} set={() => {toggleShoppingList(i)}} value={s.check} />
  );

  const shoppingMapMobile = shoppingLists.map((s, i) =>
    <SearchFilterModalCheckbox rowClassName='m-2 py-2' key={`shop_${s.id}`} id={s.nickname + s.id} label={s.nickname} set={() => {toggleShoppingList(i)}} value={s.check} />
  );

  return {
    allShoppingLists, toggleAllShoppingLists, shoppingLists, toggleShoppingList,
    shoppingMapDesktop, shoppingMapMobile, setShoppingLists
  }
};

const usePastOrders = (init) => {
  const {
    setItems : setPastOrders,
    all: allPastOrders,
    toggleItem: togglePastOrder,
    items: pastOrders,
    toggleAll: toggleAllPastOrders
  } = useInput.useCheckboxGroup();

  useEffect(() => {
    ordersApi.get().then(({data}) => {
      const ord = data.map(o => {
        let check = false;
        init.forEach(id => {
          if (o.id === id) {
            check = true;
          }
        });
        return {
          nickname: `${o.createTimeDisplay} - ${o.distributor}`,
          id: o.id,
          check: check
        }
      })
      setPastOrders(ord);
    }).catch(err => {
      console.error('OrdersApi get error:', err);
    })
  }, []);

  const orderMapDesktop = pastOrders.map((o, i) =>
    <SearchFilterModalCheckbox rowClassName='py-2' key={`ord_${o.id}`} id={o.nickname+o.id} label={o.nickname} set={() => {togglePastOrder(i)}} value={o.check} />
  );

  const orderMapMobile = pastOrders.map((o, i) =>
    <SearchFilterModalCheckbox rowClassName='m-2 py-2' key={`ord_${o.id}`} id={o.nickname+o.id} label={o.nickname} set={() => {togglePastOrder(i)}} value={o.check} />
  );

  return {
    allPastOrders, toggleAllPastOrders, pastOrders, togglePastOrder,
    orderMapDesktop, orderMapMobile, setPastOrders
  }
};

export default {
  useDistributors,
  useShoppingLists,
  usePastOrders
};