import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { MdEdit, MdDelete } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { isMobile } from 'react-device-detect';
import useSingleShoppingList from '../SingleShoppingList/useSingleShoppingList';
import Header from '../../../../../SharedComponents/Header/Header';
import ShoppingListItemBlock from '../ShoppingListItemBlock/ShoppingListItemBlock';
import shoppingListsApi from '../../../../../api/shoppingListsApi';
import './ShoppingListItemsManager.scss';

const ShoppingListItemsManager = () => {
  const history = useHistory();
  const params = useParams();

  const filters = useSelector(({items}) => items.list.filters);
  const urlObj = qs.parseUrl(history.location.search);
  let search = urlObj && urlObj.query && urlObj.query.query;

  const { list } = useSingleShoppingList.useSingleListApi();
  const { items: listItems, setItems: setListItems } = useSingleShoppingList.useListItemsApi();

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const getAvailableItems = (page) => {

    let filterOptions = {
      sort: {
        id: 5,
        name: 'frequent',
        type: 1,
        desc: 'Most Purchased'
      },
      product: 'ALL'
    };

    filterOptions = search ? {search, ...filterOptions, ...filters} : {...filterOptions, ...filters};

    setIsLoading(true);
    shoppingListsApi.getAvailableItems(filterOptions, page)
      .then(res => {
        if (page > 1) {
          setItems(items.concat(res.data.filter(item => item.isPunchedOut == 0)));
        } else {
          setItems(res.data.filter(item => item.isPunchedOut == 0));
        }
        setPage(page);
        setLoadMore(res._meta.pageCount > res._meta.currentPage);
        setIsLoading(false);
      })
      .catch(error => console.log('shoppingListspApi getListItems Error:', error));

  };

  const scrollListener = () => {
    const threshold = 600;
    if (window.innerHeight + document.documentElement.scrollTop + threshold >= document.documentElement.offsetHeight
      && loadMore && !isLoading) {
        getAvailableItems(page+1);
    }
  };

  useEffect(() => {
    window.addEventListener('mousewheel', scrollListener);
    window.addEventListener('scroll', scrollListener);
    window.addEventListener('resize', scrollListener);

    return () => {
      window.removeEventListener('mousewheel', scrollListener);
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('resize', scrollListener);
    };
  }, [page, loadMore, isLoading]);

  useEffect(() => {
    if (params.id) {
      getAvailableItems(1);
    }
  }, [history.location.search, filters]);

  const addShoppingListItem = (itemDistributorId) => {
    shoppingListsApi.addListItem(params.id, itemDistributorId).then(({data}) => {
      setListItems(listItems.concat(data));
    }).catch(err=> {
      console.log(err);
    });
  };

  const deleteShoppingListItem = (itemDistributorId) => {
    shoppingListsApi.deleteListItem(params.id, itemDistributorId).then(() => {
      setListItems(listItems.filter(item => item.itemDistributorId != itemDistributorId));
    }).catch(err=> {
      console.log(err);
    });
  };

  const itemsMap = items.map((item) => {
    let props = {};

    const found = listItems.find(element => element.itemDistributorId == item.itemDistributorId);
    if (found) {
      props.onDelete = deleteShoppingListItem;
    } else {
      props.onInsert = addShoppingListItem;
    }

    if (isMobile) {
      return <Col xs={12} key={`available-item-${item.id}`}><ShoppingListItemBlock {...props}>{item}</ShoppingListItemBlock></Col>;
    }

    return <Col xs={3} key={`available-item-${item.id}`}><ShoppingListItemBlock {...props}>{item}</ShoppingListItemBlock></Col>;
  });

  const backFunction = () => {
    if (list && list.id) {
      history.push(`/shopping-lists/update-list/${list.id}`);
    } else {
      history.push('/shopping-lists');
    }
  };

  return (
    <Container fluid={true} className='m-0 p-0'>
      <Header showSearchbar={true} showMenu={false} showLogo={false} showBack={true} backFunct={backFunction} title='' showCart={false} />
      <Container fluid={true} className='mt-4 mb-1 rounded'>
        <Row>
          {itemsMap}
        </Row>
      </Container>
      { isMobile ? <Button color='green' size='lg' block className='fixed-down' onClick={() => history.push('/shopping-lists')}>Back</Button> : null }
    </Container>
  );

};

export default ShoppingListItemsManager;
