import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useModal from '../useModal';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import accountingApi from '../../../api/accountingApi';
import downloadWhiteIcon from '../../../assets/images/download-white-icon.png';

const DownloadQuickbooksModal = (props) => {

  const { id } = props;
  const modalProps = useModal(id);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const downloadQuickbooks = () => {
    accountingApi.downloadOrderQuickbooks(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
      .then((res) => {
        if (res.data) {
          const aElement = document.createElement('a');
          aElement.href = res.data;
          aElement.click();
        }
        modalProps.toggle();
      })
      .catch(error => console.log('accountingApi downloadOrderQuickbooks Error:', error));
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader className='bg-white' toggle={modalProps.toggle}>Quickbooks File</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={isMobile ? 6 : 3}>
            <Label for='startDateInput'>Start Date:</Label>
          </Col>
          <Col xs={6}>
            <DatePicker id='startDateInput' selected={startDate} onChange={date => setStartDate(date)} />
          </Col>
        </Row>
        <Row>
          <Col xs={isMobile ? 6 : 3}>
            <Label for='endDateInput'>End Date:</Label>
          </Col>
          <Col xs={6}>
            <DatePicker id='endDateInput' selected={endDate} onChange={date => setEndDate(date)} />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className='px-0'>
        <Col size={12} className='mx-0'>
          <Button block={true} className='px-4' color='yellow' onClick={downloadQuickbooks}><img src={downloadWhiteIcon} style={{marginTop: '-2px', marginRight: '7px'}} />Download</Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};


export default DownloadQuickbooksModal;
