import React, { useLayoutEffect } from 'react';
import numeral from 'numeral';
import Highcharts from 'highcharts';
import { Row, Col } from 'reactstrap';

const PurchasesGraphs = (props) => {
  let currentDistributor;
  let currentCategory;

  let seriesDistributor = [];
  let totalPurchasesAmountDist = 0;
  let seriesCategory = [];
  let totalPurchasesAmountCat = 0;

  const getRandomColor = () => {
    let letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const addTitle = (chart, totalPurchasesAmount) => {
    if (chart.title) { chart.title.destroy(); }
    if (chart.title2) { chart.title2.destroy(); }
    let r = chart.renderer,
        x = chart.series[0].center[0] + chart.plotLeft,
        y = chart.series[0].center[1] + chart.plotTop;
    chart.title = r.text('<b>' + '$' + Highcharts.numberFormat(totalPurchasesAmount, 2, '.', ',') + '</b>', 0, 0)
      .css({ color: '#143357', fontSize: '22px', 'line-height': '22px', 'font-family': 'Roboto-Regular' })
      .hide()
      .add();
    chart.title2 = r.text('Total Purchases', 0, 0)
      .css({ color: '#143357', fontSize: '16px', 'line-height': '16px', 'font-family': 'Roboto-Regular' })
      .hide()
      .add();
    var bbox = chart.title.getBBox();
    var bbox2 = chart.title2.getBBox();
    chart.title.attr({ x: x - (bbox.width / 2), y: y }).show();
    if (totalPurchasesAmount === 0) {
      chart.title2.attr({ x: x - (bbox2.width / 2), y: y + (bbox.width / 4 + 10) }).show();
    }
    else {
      chart.title2.attr({ x: x - (bbox2.width / 2), y: y + (bbox.width / 4) }).show();
    }
  }

  const setGeneralParams = (type, typeId, name) => {
    if (type == 'Distributor') {
      const distributorId = typeId;
      const distributorName = name;
      currentCategory = null;
      currentDistributor = {distributorId, distributorName};
      if (props.onDistributorClick) {
        props.onDistributorClick(currentDistributor);
      }
    }
    if (type == 'Category') {
      const categoryId = typeId;
      const categoryName = name;
      currentDistributor = null;
      currentCategory = {categoryId, categoryName};
      if (props.onCategoryClick) {
        props.onCategoryClick(currentCategory);
      }
    }
  };

  const distributorGraphAndCategoriesGraph = () => {
    if (!props.analyticsData || !props.analyticsData.total_purchases || !props.analyticsData.total_purchases.detail || !props.analyticsData.total_purchases.detail.distributorData) {
      return;
    }

    let totalPurchases = 0;
    let distributors = null;
    let categories = null;
    seriesDistributor = [];
    totalPurchasesAmountDist = 0;
    seriesCategory = [];
    totalPurchasesAmountCat = 0;
    totalPurchases = { ...props.analyticsData.total_purchases };
    //$scope.dataRetrieved = true;
    //$scope.showItemsFrame = false;
    if (props.analyticsData.total_purchases.detail.distributorData !== null) {
      //Distributors
      distributors = props.analyticsData.total_purchases.detail.distributorData.slice();
      for (let i = 0; i < distributors.length; i++) {
        totalPurchasesAmountDist += distributors[i].total;
        totalPurchasesAmountDist = Math.round(totalPurchasesAmountDist * 100) / 100;
        distributors[i].color = (distributors[i].color !== null) ? '#' + distributors[i].color : getRandomColor();
        let dataObjDist = {
          LocationName: distributors[i].distributor,
          y: distributors[i].total,
          distributorId: distributors[i].distributor_id,
          color: distributors[i].color
        };
        seriesDistributor.push(dataObjDist);
      }
      //Categories
      categories = props.analyticsData.total_purchases.detail.categoryData.slice();
      for (let j = 0; j < categories.length; j++) {
        totalPurchasesAmountCat += categories[j].total;
        totalPurchasesAmountCat = Math.round(totalPurchasesAmountCat * 100) / 100;
        let dataObjCat = {
          LocationName: categories[j].category,
          y: categories[j].total,
          color: getRandomColor()
        };
        seriesCategory.push(dataObjCat);
      }
    }
    //loadDistributor = true;
    //loadCategories = true;
    _distributorGraph();
    _categoriesGraph();
  }

  const _distributorGraph = () => {
    //loadDistributor = false;
    let totalPurchaseDistributorGraph = new Highcharts.chart('container_distributor', {
        chart: {
          height: (5 / 10 * 100) + '%',
          margin: [0, 400, 0, 0],
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          events: {
            load: function () { addTitle(this, totalPurchasesAmountDist); },
            redraw: function () { addTitle(this, totalPurchasesAmountDist); }
          }
        },
        tooltip: {
          formatter: function () {
            return '<span>' + this.point.LocationName + ' : ' + '<b>$' + Highcharts.numberFormat(this.y, 2, '.', ',') + '</b></span>';
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            size: 200,
            dataLabels: {
              enabled: true,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            startAngle: -90,
            endAngle: 270,
            center: ['50%', '50%']
          },
          series: {
            point: {
              events: {
                legendItemClick: function (e) {
                  setGeneralParams('Distributor', this.distributorId, this.LocationName);
                  //$scope.distributorId = this.distributorId;
                  //$scope.categoryName = null;
                  //if (loadCategories === true) {
                  _categoriesGraph();
                  //}
                  let color = Highcharts.color;
                  let series = this.series;
                  for (let i = 0; i < series.data.length; i++) {
                    let point = series.data[i];
                    if (point === this) {
                      point.update({
                        //color: series.chart.options.colors[this.index]
                      });
                    } else {
                      point.update({
                        //color: color(point.color).setOpacity(this === point ? 1 : 0.3).get()
                      });
                    }
                  }
                  return false;
                },
                click: function (e) {
                  setGeneralParams('Distributor', this.distributorId, this.LocationName);
                }
              }
            },
            states: {
              hover: {
                enabled: true,
                halo: {
                  size: 0
                }
              }
            }
          }
        },
        series: [{
          type: 'pie',
          name: 'Total Purchases',
          size: '90%',
          innerSize: '96%',
          data: seriesDistributor,
          showInLegend: true,
          dataLabels: {
            enabled: false
          }
        }],
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          width: 330,
          // margin: 2,
          useHTML: true,
          padding: 0,
          itemMarginTop: 10,
          itemMarginBottom: 10,
          x: -10,
          labelFormatter: function () {
            const distributorId = currentDistributor && currentDistributor.distributorId || null;
            if (this.distributorId === distributorId) {
              return '<div style="color:#006DBA;">' + this.LocationName + '<span class="ml-1">' + Highcharts.numberFormat(this.y, 2, '.', ',') + '</span></div>';
            } else {
              return '<div>' + this.LocationName + '<span class="ml-1">' + Highcharts.numberFormat(this.y, 2, '.', ',') + '</span></div>';
            }
          }
        },
    });
  };

  const _categoriesGraph = () => {
    //loadCategories = false;
    let totalPurchaseCategoryGraph = new Highcharts.chart('container_category', {
        chart: {
          height: (5 / 10 * 100) + '%',
          margin: [0, 400, 0, 0],
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          events: {
            load: function () { addTitle(this, totalPurchasesAmountCat); },
            redraw: function () { addTitle(this, totalPurchasesAmountCat); }
          }
        },
        tooltip: {
          formatter: function () {
            return '<span>' + this.point.LocationName + ' : ' + '<b>$' + Highcharts.numberFormat(this.y, 2, '.', ',') + '</b></span>';
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            size: 200,
            dataLabels: {
              enabled: true,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            startAngle: -90,
            endAngle: 270,
            center: ['50%', '50%']
          },
          series: {
            point: {
              events: {
                legendItemClick: function (e) {
                  setGeneralParams('Category', null, this.LocationName);
                  //$scope.categoryName = this.LocationName;
                  //$scope.distributorId = null;
                  //if (loadDistributor === true) {
                  _distributorGraph();
                  //}
                  let color = Highcharts.color;
                  let series = this.series;
                  for (let i = 0; i < series.data.length; i++) {
                    let point = series.data[i];
                    if (point === this) {
                      point.update({
                        //color: series.chart.options.colors[this.index]
                      });
                    } else {
                      point.update({
                        //color: color(point.color).setOpacity(this === point ? 1 : 0.3).get()
                      });
                    }
                  }
                  return false;
                },
                click: function (e) {
                  setGeneralParams('Category', null, this.LocationName);
                }
              }
            },
            states: {
              hover: {
                enabled: true,
                halo: {
                  size: 0
                }
              }
            }
          }
        },
        series: [{
          type: 'pie',
          name: 'Total Purchases',
          size: '90%',
          innerSize: '96%',
          data: seriesCategory,
          showInLegend: true,
          dataLabels: {
            enabled: false
          }
        }],
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          width: 330,
          // margin: 2,
          useHTML: true,
          padding: 0,
          itemMarginTop: 10,
          itemMarginBottom: 10,
          x: -10,
          labelFormatter: function () {
            const categoryName = currentCategory && currentCategory.categoryName || '';
            if (this.LocationName === categoryName) {
              return '<div style="color:#006DBA;">' + this.LocationName + '<span class="ml-1">' + Highcharts.numberFormat(this.y, 2, '.', ',') + '</span></div>';
            } else {
              return '<div>' + this.LocationName + '<span class="ml-1">' + Highcharts.numberFormat(this.y, 2, '.', ',') + '</span></div>';
            }
          }
        },
    });
  };

  useLayoutEffect(() => {
    distributorGraphAndCategoriesGraph();
  }, [props.analyticsData]);

  return (
    <Row className='text-center mb-2'>
      <Col xs={6} className='border-right'>
        <Row>
          <Col xs={12} className='my-3 text-left analytics-header'>Distributors</Col>
        </Row>
        <Row style={{height: '550px'}}>
          <div className='container-graph' id='container_distributor' style={{height: '500px', width: '99%', margin: '0 auto'}}></div>
        </Row>
      </Col>
      <Col xs={6}>
        <Row>
          <Col xs={12} className='my-3 text-left analytics-header'>Categories</Col>
        </Row>
        <Row style={{height: '550px'}}>
          <div className='container-graph' id='container_category' style={{height: '500px', width: '99%', float: 'right', margin: '0 auto'}}></div>
        </Row>
      </Col>
    </Row>
  );
};

export default PurchasesGraphs;
