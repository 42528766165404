import React from "react";
import { Row, Col } from "reactstrap";
import "./ListGroup.scss";

const ListGroup = (props) => {
  return (
    <div className="list-group-wrapper">
      <Row className="list-group-header">
        <Col xs="12">{props.title}</Col>
      </Row>
      <div className="list-group-items">{props.children}</div>
    </div>
  );
};

export default ListGroup;
