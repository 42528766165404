import React,  { Fragment, useState, useEffect }  from 'react';
import { Button, Tooltip } from 'reactstrap';
import { FiWifiOff, FiWifi } from 'react-icons/fi';
import { FaDownload, FaUpload } from 'react-icons/fa';
import usePlaceOrderSync from '../../../Sync/usePlaceOrderSync';
import { colors, localStorageConst } from '../../../../config/constants';
import useConnection from '../../../OfflineMode/useConnection';
import QuestionModal from '../../../Modals/QuestionModal/QuestionModal';
import AlertModal from '../../../Modals/AlertModal/AlertModal';
import { showModal } from '../../../../reducers/modals/modalsActions';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import './SyncInventory.scss';

const SyncInventory = () => {
  const dispatch = useDispatch();
  const connection = useSelector(({ connection }) => connection.online);
  const inventoryItemsToSync = JSON.parse(localStorage.getItem(localStorageConst.keys.inventoryItemsToSync));
  let [toSync, setToSync] = useState(inventoryItemsToSync);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  // const {getAllViews} = usePlaceOrderSync.useViewsSync();
  const {getAllGroups} = usePlaceOrderSync.useInventoryListSync();
  const {syncToServer} = useConnection.useSyncServer();
  const {status} = useConnection.useStatus();

  const showConnectionFail = (connection) => {
    const aProps = {
      title: 'Connectivity Failed',
      message: 'You are currently not connected to the internet. Please reconnect before you can go '+connection,
      btnLabel: 'Ok',
      btnSize: 'lg',
      color: 'green'
    };
    dispatch(showModal(AlertModal, aProps));
  };

  const showSyncToServer = () => {
    const qProps = {
      message: `You are about to enter online mode. This may take a few minutes`,
      menu: isMobile ? true : false,
      leftButton: {
        title: 'Cancel',
        color: 'orange',
      },
      rightButton: {
        title: 'Go Online',
        color: 'green',
        onClick: () => syncToServer()
      }
    };
    dispatch(showModal(QuestionModal, qProps));
  };

  const showSyncToLocal = () => {
    const qProps = {
      message: `You are about to enter offline mode. This may take a few minutes. Please stay connected to the internet until Offline is ready`,
      menu: isMobile ? true : false,
      leftButton: {
        title: 'Cancel',
        color: 'orange',
      },
      rightButton: {
        title: 'Go Offline',
        color: 'green',
        onClick: () => getAllGroups()
      },
      belowButtons: `Certain features will not be available in offline mode`
    };
    dispatch(showModal(QuestionModal, qProps));
  };

  const handleSync = () => {
    if (connection){
      if (connection !== status){
        showConnectionFail('offline');
        return;
      }

      if(!!toSync){
        if(toSync){ syncToServer(); }        
      }else{
        showSyncToLocal();
      }      
      
    }else{
      if (connection === status){
        showConnectionFail('online');
        return;
      }
      syncToServer();      
    }    
  };

  useEffect(() => {
    if(!!inventoryItemsToSync){
      setToSync(inventoryItemsToSync);
    }else{
      setToSync(false);
    }

  }, [inventoryItemsToSync]);

  const goOnLine = <Fragment><FiWifi color={colors.lightBlueText}/> {!isMobile ? <span>&nbsp;&nbsp;Go Online</span> : ``} </Fragment>;
  const goOffLine = <Fragment><FiWifiOff color={colors.lightBlueText} /> {!isMobile ? <span>&nbsp;&nbsp;Go Offline</span> : ``} </Fragment>;

  return (
    <div className='notification'>
      {toSync ? <span className="badge">!</span> : ``}
      <Button id='tooltip-sync' size={isMobile ? 'square': ''} color='white' onClick={()=> handleSync()} style={{ fontSize: 13 }}>
        { status ? ( connection ? (!toSync ? goOffLine : goOnLine) : goOnLine ) : ( connection ? (!toSync ? goOffLine : goOnLine) : goOnLine) }
      </Button>
      <Tooltip
        placement='bottom'
        isOpen={tooltipOpen}
        target='tooltip-sync'
        toggle={toggle}
      >
        {toSync ? `You must sync your inventory data to the server` : `Sync your data locally`}
      </Tooltip>
    </div>
  );
};

export default SyncInventory
