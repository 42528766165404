import React, { useEffect } from 'react';
import ShowIf from '../ShowIf/ShowIf';
import { Col } from 'reactstrap';
import { MdShoppingCart } from 'react-icons/md';
import { colors } from '../../config/constants';
import { useSelector } from 'react-redux';
import useHeader from './useHeader';
import CartAnimation from '../Animations/CartAnimation';

const HeaderCart = (props) => {
  const { show } = props;
  const cartCount = useSelector(({ cart }) => cart.cartCount);
  const isDisplayShoppingCart = useSelector(({ user }) => user.activeLocation.display_shopping_cart);

  const { goToCart } = useHeader.useActions();

  useEffect(() => {

  }, [cartCount]);

  return (
    <ShowIf show={show && isDisplayShoppingCart !== 0}>
      <Col className='w-fc m-0 clickable' onClick={goToCart}>
        <div className='cart-icon'>
          <span className="cart-count">{cartCount}</span>
          <MdShoppingCart color={colors.dark} size={24} />
        </div>
      </Col>
      <CartAnimation></CartAnimation>
    </ShowIf>
  )
};

export default HeaderCart;
