import React, { Fragment } from 'react';
import { Card, Row, Col, Input } from 'reactstrap';
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import { isMobile } from 'react-device-detect';
import useOrderReceive from './useOrderReceive';
import { colors, orderItemFields } from '../../../../../config/constants';
import noImage from '../../../../../assets/images/no-image.png';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import useInput from '../../../../../SharedComponents/SharedEffects/useInput';
import ordersApi from '../../../../../api/ordersApi';
import { useParams } from 'react-router-dom';
import numeral from 'numeral';
import '../OrderHistory.scss';

const OrderReceiveRow = (props) => {
  const getOrdersById = props.getOrdersById;
  const { rejectItem, acceptItem } = useOrderReceive.useRow(props.orderReceiveList, props.editStatus, props.selectedOrder, getOrdersById);
  const item = props.data;
  const imageUrl = item.imageUrl ? item.imageUrl : noImage;
  const params = useParams();
  const idOrder = parseInt(params.id);
  const { value: quantity, set: setQuantity } = useInput.useText(item.quantity);
  const { value: description, set: setDescription } = useInput.useText(item.item);
  const { value: total, set: setTotal } = useInput.useText(item.price * item.quantity);
  const { value: merchantUnitPrice, set: setMerchantUnitPrice } = useInput.useText(item.unit_price);
  const { value: merchantUnitQuantity, set: setMerchantUnitQuantity } = useInput.useText(item.isEdited ? item.editedUnitQuantity : item.unitQuantity);
  const { value: merchantUnitSize, set: setMerchantUnitSize } = useInput.useText(item.isEdited ? item.editedUnitSize : item.unitSize);
  const { value: merchantPackSizeUnit, set: setMerchantPackSizeUnit } = useInput.useText(item.isEdited ? item.editedPackSizeUnit : item.packSizeUnit);

  const updateEditValues = (modifiedValue, field) => {
    if (!quantity) {
      return;
    }
    let orderItem = {
      order_item_id: item.oi_id,
      item_description: description,
      quantity: quantity,
      pack_size: merchantUnitQuantity + '/' + merchantUnitSize + ' ' + merchantPackSizeUnit,
      merchant_price: item.price,
      merchant_unit_price: merchantUnitPrice,
    };

    if (field === orderItemFields.quantity) {
      orderItem[field] = modifiedValue;
      setTotal(Math.round((merchantUnitQuantity * merchantUnitSize * merchantUnitPrice * modifiedValue) * 100) / 100);
    }
    if (field === orderItemFields.unitPrice) {
      orderItem[field] = modifiedValue;
      orderItem[orderItemFields.price] = Math.round((merchantUnitQuantity * merchantUnitSize * modifiedValue) * 100) / 100;
      setTotal(Math.round((merchantUnitQuantity * merchantUnitSize * modifiedValue * quantity) * 100) / 100);
    }
    if (field === orderItemFields.unitQuantity || field === orderItemFields.unitSize || field === orderItemFields.packSizeUnit) {
      orderItem[orderItemFields.packSize] = merchantUnitQuantity + '/' + merchantUnitSize + ' ' + merchantPackSizeUnit;
      orderItem[orderItemFields.price] = Math.round((merchantUnitQuantity * merchantUnitSize * merchantUnitPrice) * 100) / 100;
      setTotal(Math.round((merchantUnitQuantity * merchantUnitSize * merchantUnitPrice * quantity) * 100) / 100);
    }
    if (field === orderItemFields.total) {
      orderItem[orderItemFields.price] = Math.round((modifiedValue / quantity) * 100) / 100;
      orderItem[orderItemFields.unitPrice] = Math.round((orderItem[orderItemFields.price] / (merchantUnitQuantity * merchantUnitSize)) * 10000) / 10000;;
      setMerchantUnitPrice(orderItem[orderItemFields.unitPrice]);
    }

    const params = {
      items: [orderItem],
    };
    ordersApi.updateItems(idOrder, params).then((res) => {
      getOrdersById();
    }).catch(error => {
    });
  };

  if (isMobile) {
    return (
      <Card className={'pb-2 is-mobile' + (item.isSpecial ? ' oh-special-request-border' : '')} style={{ backgroundColor: (item.status === 'rejected' ? '#fff1ee' : '#ffffff') }}>
        <Row className='oh'>
          <Col>
            <ShowIf show={item.isElectronicInvoiceItem === 1}>
              <span className='float-md-right text-uppercase electronic-invoice'>Electronic Invoice</span>
            </ShowIf>
            <ShowIf show={item.isSpecial === 0}>
              <span className='float-md-right text-uppercase order-guide'>Order Guide</span>
            </ShowIf>
            <ShowIf show={item.isSpecial === 1}>
              <span className='float-md-right text-uppercase special-request'>Special Request</span>
            </ShowIf>
            <ShowIf show={item.isEdited}>
              <span className='float-md-right text-uppercase is-edit'>Edited</span>
            </ShowIf>
            <span className='float-md-right text-uppercase order-none'>{(item.status === 'electronically_received' ? 'Received' : item.status)}</span>
          </Col>
        </Row>
        <Row className='pt-4 pb-1'>
          <Col className='w-fc w-image px-3'>
            <img src={imageUrl} alt={item.name} className='no-image' />
          </Col>
          <Col xs={8} className='px-0'>
            <Row>
              <Col className='text-capitalize text-truncate text-main px-0'>
                {props.editStatus ?
                  <Fragment>
                    <Input type='text' onChange={setDescription} value={description} onBlur={() => updateEditValues(description, orderItemFields.description)} />
                  </Fragment>
                  :
                  <Fragment>
                    {item.item}
                  </Fragment>
                }
              </Col>
            </Row>
            <Row className='text-gray py-2'>
              <Col className='px-0'>
                {item.brand}
              </Col>
            </Row>
            <Row className='text-main'>
              <Col xs={4} className={`d-flex align-items-center text-main `+ props.editStatus ? `` : `px-0`}>
                {props.editStatus ?                  
                  <Row>
                    <Col xs={8} className='d-flex flex-row px-0'>
                      <Input type='number' value={merchantUnitPrice} onChange={setMerchantUnitPrice} onBlur={() => updateEditValues(merchantUnitPrice, orderItemFields.unitPrice)} />
                    </Col>
                    <Col xs={4} className='d-flex flex-row px-0'>
                      {item.packSizeUnit}
                    </Col>
                  </Row>
                  :
                  <Row>
                    <Col className='px-0'>
                      {numeral(item.unit_price).format('$0,0.00')}
                    </Col>
                  </Row>                  
                }
              </Col>
              <Col className='pl-3'>
                {props.editStatus ?
                  <Row>
                    <Col xs={4} className='px-0'>
                      <Input type='number' value={merchantUnitQuantity} onChange={setMerchantUnitQuantity} onBlur={() => updateEditValues(merchantUnitQuantity, orderItemFields.unitQuantity)} />
                    </Col>
                    <Col xs={1} className='text-center px-0'>/</Col>
                    <Col xs={4} className='px-0'>
                      <Input type='number' value={merchantUnitSize} onChange={setMerchantUnitSize} onBlur={() => updateEditValues(merchantUnitSize, orderItemFields.unitSize)} />
                    </Col>
                    <Col xs={3} className='px-0'>
                      {item.packSizeUnit}
                    </Col>
                  </Row>
                  :
                  <Row>
                    <Col className='pl-0'>
                      {item.packSize}
                    </Col>
                  </Row>
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='pt-2 border-top d-flex align-items-center'>
          {props.editStatus ?
            <Fragment>
              <Col xs={2}></Col>
              <Col xs={2} className='px-0 d-flex align-items-center flex-row'>
                <Input type='number' value={quantity} onChange={setQuantity} onBlur={() => updateEditValues(quantity, orderItemFields.quantity)} /> 
              </Col>
              <Col xs={1} className='px-0'>
                {item.unit_measure}
              </Col>
              <Col xs={3} className='px-0'>
                <Input type='number' value={total} onChange={setTotal} onBlur={() => updateEditValues(total, orderItemFields.total)} />
              </Col>
            </Fragment>
            :
            <Fragment>
              <Col xs={2}></Col>
              <Col xs={3} className='px-0'>
                {item.quantity} {item.unit_measure}
              </Col>
              <Col xs={3} className='px-0 pl-0'>
                {numeral(item.price * item.quantity).format('$0,0.00')}
              </Col>
            </Fragment>
          }
          <Col xs={2} className='clickable px-0 text-center' onClick={() => { rejectItem(props.data) }}>
            <IoIosCloseCircle color={item.isRejectSelected ? colors.red : colors.lightBlueText} size={40} />
          </Col>
          <Col xs={2} className='clickable px-0' onClick={() => { acceptItem(props.data) }}>
            <IoIosCheckmarkCircle color={item.isAccepted ? colors.green : colors.lightBlueText} size={40} />
          </Col>
        </Row>
      </Card>
    )
  }

  return (
    <Card className={'px-2' + (item.isSpecial ? ' oh-special-request-border' : ' border')} style={{ backgroundColor: (item.status === 'rejected' ? '#fff1ee' : '#ffffff') }}>
      <Row className='oh'>
        <Col>
          <ShowIf show={item.isElectronicInvoiceItem === 1}>
            <span className='float-md-right text-uppercase electronic-invoice'>Electronic Invoice</span>
          </ShowIf>
          <ShowIf show={item.isSpecial === 0}>
            <span className='float-md-right text-uppercase order-guide'>Order Guide</span>
          </ShowIf>
          <ShowIf show={item.isSpecial === 1}>
            <span className='float-md-right text-uppercase special-request'>Special Request</span>
          </ShowIf>
          <ShowIf show={item.isEdited}>
            <span className='float-md-right text-uppercase is-edit'>Edited</span>
          </ShowIf>
          <span className='float-md-right text-uppercase order-none'>{(item.status === 'electronically_received' ? 'Received' : item.status)}</span>
        </Col>
      </Row>
      <Row className='pt-2 pb-3'>
        <Col xs={2} className='text-center'>
          <img src={imageUrl} alt={item.name} className='no-image' />
        </Col>
        <Col xs={8}>
          <Row>
            {props.editStatus ?
              <Col className='pl-0 text-capitalize text-truncate text-main'>
                <Input type='text' onChange={setDescription} value={description} onBlur={() => updateEditValues(description, orderItemFields.description)} />
              </Col>
              :
              <Col className='pl-0 text-capitalize text-truncate text-main'>
                {item.item}
              </Col>
            }
          </Row>
          <Row className='text-gray pl-0'>
            {item.brand}
          </Row>
          <Row className='text-main'>
            {props.editStatus ?
              <Fragment>
                <Col xs={1} className='px-0 d-flex align-items-center text-gray'>Unit Price</Col>
                <Col xs={3} className='pl-0 d-flex flex-row align-items-center'>
                  <Input type='number' value={merchantUnitPrice} onChange={setMerchantUnitPrice} onBlur={() => updateEditValues(merchantUnitPrice, orderItemFields.unitPrice)} /> {item.packSizeUnit}
                </Col>
                <Col xs={2} className='px-0 d-flex flex-row align-items-center'>
                  <Input type='number' value={merchantUnitQuantity} onChange={setMerchantUnitQuantity} onBlur={() => updateEditValues(merchantUnitQuantity, orderItemFields.unitQuantity)} />&nbsp;/
                </Col>                
                <Col xs={2} className='d-flex flex-row align-items-center'>
                  <Input type='number' value={merchantUnitSize} onChange={setMerchantUnitSize} onBlur={() => updateEditValues(merchantUnitSize, orderItemFields.unitSize)} /> {item.packSizeUnit}
                </Col>
                <Col xs={2} className='pl-0 d-flex flex-row align-items-center'>
                  <Input type='number' value={quantity} onChange={setQuantity} onBlur={() => updateEditValues(quantity, orderItemFields.quantity)} />
                </Col>
                <Col xs={2} className='pl-0 d-flex flex-row align-items-center'>
                  <Input type='number' value={total} onChange={setTotal} onBlur={() => updateEditValues(total, orderItemFields.total)} />
                </Col>
              </Fragment>
              :
              <Fragment>
                <Col xs={4} className='px-0'>
                  ${item.unit_price} {item.packSizeUnit}
                </Col>
                <Col xs={4} className='px-0'>
                  {item.packSize}
                </Col>
                <Col xs={2} className='px-0'>
                  {item.quantity} {item.unit_measure}
                </Col>
                <Col xs={2} className='px-0'>
                  {numeral(item.price * item.quantity).format('$0,0.00')}
                </Col>
              </Fragment>
            }
          </Row>          
        </Col>        
        <Col xs={1} className='pt-4 clickable d-flex align-items-center justify-content-center' onClick={() => { rejectItem(props.data) }}>
          <IoIosCloseCircle color={item.isRejectSelected ? colors.red : colors.lightBlueText} size={40} />
        </Col>
        <Col xs={1} className='pt-4 clickable d-flex align-items-center justify-content-center' onClick={() => { acceptItem(props.data) }}>
          <IoIosCheckmarkCircle color={item.isAccepted ? colors.green : colors.lightBlueText} size={40} />
        </Col>
      </Row>
    </Card>

  )
}

export default OrderReceiveRow;
