import React, {useState, useEffect}  from 'react';
import { Row, Col, Button } from 'reactstrap';
import {colors, cameraConstraints} from '../../../../../config/constants';
import ordersApi from '../../../../../api/ordersApi';
import { useHistory } from "react-router-dom";
import { IoMdCamera, IoMdClose } from "react-icons/io";
import './TakePhoto.scss'; 

let video;
let scaleVideo;

const TakePhoto = () => {
  const history = useHistory();
  const params = history.location.pathname.split('/');
  const id = params[params.length-2];

  const [photos, setPhotos] = useState([]);
  const [photosMap, setPhotosMap] = useState('');
  const [startUpload, setStartUpload] = useState(0);
  const [localStream, setLocalStream] = useState([]);
  const [imageCapture, setImageCapture] = useState([]);
  

  const getSnap = () => {
    let takenPhotos = photos;
    const canvas = document.createElement('canvas');
    canvas.width = video.clientWidth;
    canvas.height = video.clientHeight;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0,0);
    canvas.toBlob(function(blob) {
        const r = Math.random().toString(36).substring(7);
        const url = window.URL.createObjectURL(blob);
        const file = new File([blob], `snap_${r}.png`);
        const obj= { url: url, file: file };
        takenPhotos.push(obj);        
    });
    setPhotos(takenPhotos);
    setPhotosMap(takenPhotos.map((photo, index) => <img key={`pic_${id}_${index+1}`} src={photo.url} className='snap'></img> ));
  };

  const uploadMissingPics = () => {
    const file = photos[startUpload].file;        
    ordersApi.uploadInvoiceImage(id, file)
      .then(res => {
        setStartUpload(startUpload+1);
    }).catch(error => {console.log(error)});    
  };

  const close = () =>{
    // history.push(`/order-history/upload-invoice/${id}`);
    history.goBack();
  };

  useEffect(() => {
    video = document.getElementById('hide-video');
    scaleVideo = document.getElementById('scale-video');

    navigator.mediaDevices.getUserMedia(cameraConstraints)
    .then(function(stream) {
        video.srcObject = stream;
        scaleVideo.srcObject = stream;
        video.play();
        scaleVideo.play();
        setLocalStream(stream);
        setImageCapture(new ImageCapture(stream.getVideoTracks()[0]));
        
    }).catch(function(err){
        console.error(err);
    });
  }, []);


  useEffect(() => {
    if (startUpload === 0 ){ return; }

    if (startUpload < photos.length) {
      uploadMissingPics();            
    } else {
      close();
    }
    
  }, [startUpload]);

  return (
        <div className='take-photo'>
          <Row>
            <Col className='d-flex align-items-center justify-content-end m-2'>
              <IoMdClose onClick={close} className='clickable' color={colors.lightBlueText} size={32}/>
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col className='d-flex align-items-center justify-content-center m-2'>
              Lay invoice flat and please make sure image is legible
            </Col>
          </Row>
          <Row className='mb-2 mx-2'>
            <Col className='d-flex align-items-center justify-content-center m-2 green-border'>
              <video id="scale-video" className='scaled'></video>
            </Col>
          </Row>
          <video id='hide-video' className='hide' ></video>

          <Row className='mb-2 mx-2  canvas'>
            <Col >
              {photosMap}
            </Col>
          </Row>

          <Row className='fixed-down m-1 p-1 w-100'>
            <Col xs={3}></Col>
            <Col xs={6} className='d-flex align-items-center justify-content-center'>
              <Button size='lg-circle' color='white' onClick={()=> getSnap()}><IoMdCamera color='black' size='16'/></Button>
            </Col>
            <Col xs={3} className='d-flex align-items-center justify-content-end option' onClick={()=> uploadMissingPics()}>
              Upload
            </Col>
          </Row>
        </div>
  )

}

export default TakePhoto;