import axios from 'axios';
import { limitOptions } from "../config/constants";

const getList = (sort ,filters, page, search) => {    
  const obj = {
    sort: sort,
    search: search,
    product: (filters.purchasedOnly) ? 'PURCHASED':'ALL',
    distributorIds: filters.distributorIds,
    shoppingIds: filters.shoppingIds,
    pastOrderIds: filters.pastOrderIds
  }
  const filterOptions = JSON.stringify(obj);
  const params = {filterOptions: filterOptions, limit: limitOptions.listView, page: page};
  return axios.get('items', {params});
};

const getListWithSubrecipe = (sort ,filters, page, search) => {    
  const obj = {
    sort: sort,
    search: search,
    product: (filters.purchasedOnly) ? 'PURCHASED':'ALL',
    distributorIds: filters.distributorIds,
    shoppingIds: filters.shoppingIds,
    pastOrderIds: filters.pastOrderIds
  }
  const filterOptions = JSON.stringify(obj);
  const params = {filterOptions: filterOptions, limit: limitOptions.listView, page: page, includeSubrecipe: 1};
  return axios.get('items', {params});
};


const getDetail = (id) => {  
  return axios.get(`/items/${id}`);
};

const orderListItem = (params) => {
  const custom_order_data = {custom_order_data: params}
  return axios.post(`/items/custom-order`, custom_order_data);
};

const getMatches = (id) => {  
  return axios.get(`/items/matches/${id}`);
};

const getCartMatches = () => {
  //retrieve saving items ONLY, inside comparable field, when having regular items (that has those savings) in cart
  return axios.get(`/items/shopping-cart-matches`);
};

const swapAccept = (params) => {
  // when adding saving items to cart
  return axios.post(`/items/swap`, params);
};

const swapRequest = (itemIds) => {
  const params = {itemIds : itemIds}
  return axios.post(`/items/swap-request`, params);
};

const swapReject = (params) => {
  return axios.post(`/items/swap-reject`, params);
};

const getAnalytics = (id) => {
  const params = {analytics: 1};
  return axios.get(`/items/${id}`, {params});
};

export default{
  getList, getDetail, orderListItem,
  getMatches, getCartMatches, swapAccept, swapRequest, swapReject, 
  getListWithSubrecipe, getAnalytics
};