import React from 'react';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {showModal} from '../../../../../reducers/modals/modalsActions';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useHistory } from "react-router-dom";
import ordersApi from '../../../../../api/ordersApi';
import OrderReceiveRow from './OrderReceiveRow';
import OrderReceiveRejectModal from '../Modals/OrderReceive/OrderReceiveRejectModal'
import OrderReceiveCheckedModal from '../Modals/OrderReceive/OrderReceiveCheckedModal';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';

const useApi = (props, editStatus) => {
    const params = useParams();
    const id = parseInt(params.id);
    const [orderReceiveList, setOrderReceiveList] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState([]);

    const getOrdersById = () => {
        ordersApi.getOrdersById(id).then((res) => {
            setOrderReceiveList(res.data.items.map(o => { return {
                ...o,
                receiveChecked: false,
                isRejected : false,
                isRejectSelected: false,
                isAccepted : false
            }}));
            setSelectedOrder(res.data);
        }).catch(error => {
        });
    };
    useEffect(() => {
        getOrdersById();
    }, []);

    const orderReceiveMap = orderReceiveList.map((c, i) =>
        <OrderReceiveRow   key={`or-list-${i}`} data={c}  orderReceiveList = {{value: orderReceiveList, set: setOrderReceiveList }} editStatus={editStatus} selectedOrder={selectedOrder} getOrdersById={getOrdersById} />
    )

    const acceptAllItems = (allState) => {
        if(allState) {
            setOrderReceiveList(orderReceiveList.map(o => {
                return {
                    ...o,
                    receiveChecked: true,
                    isAccepted : true,
                    isRejected : false,
                    isRejectSelected : false,
                    qtyReduction : '',
                    statusReceived : 'received',
                    rejectReason : ''
                }
            }));
        } else {
            setOrderReceiveList(orderReceiveList.map(o => {
                return {
                    ...o,
                    receiveChecked: false,
                    isAccepted : false,
                    isRejected : false,
                    isRejectSelected : false,
                    qtyReduction : '',
                    statusReceived : '',
                    rejectReason : ''
                }
            }));
        }
    }

    return { getOrdersById, orderReceiveList: {value: orderReceiveList, set: setOrderReceiveList}, orderReceiveMap, selectedOrder, acceptAllItems }

}

const useRow = (orderReceiveList, editStatus, selectedOrder, getOrdersById) => {
    const dispatch = useDispatch();

    const acceptItem = (item) => {
        const id = item.oi_id;
        orderReceiveList.set(orderReceiveList.value.map(o => {
            if (o.oi_id !== id) return o;
                return {
                    ...o,
                    receiveChecked: !o.isAccepted,
                    isRejected: false,
                    statusReceived : 'received',
                    isAccepted: !o.isAccepted,
                    qtyReduction: ''
                }
        }));
    }

    const rejectItem = (item) => {
        const id = item.oi_id;
        orderReceiveList.set(orderReceiveList.value.map(o => {
            if (o.oi_id !== id) return o;
                return {
                    ...o,
                    receiveChecked: !o.isRejected,
                    isRejectSelected: !o.isRejected,
                    isRejected: !o.isRejected,
                    isAccepted: false
                }
        }));

        const inputPropsRejectItem = {
            title: 'Reject',
            onSave: preReject,
            placeholder: '',
            menu: isMobile,
            fullMenu: true,
            id : id,
            item,
            selectedOrder : selectedOrder,
            getOrdersById: getOrdersById
        };
        console.log(inputPropsRejectItem);
        const selectedItem = orderReceiveList.value.filter(o => o.oi_id === id && o.isRejected === false)[0];
        if(selectedItem) dispatch(showModal(OrderReceiveRejectModal, inputPropsRejectItem));
    }

    const preReject = (item, close) => {
        const id = item.id;
        if(close) {
            const orl = orderReceiveList.value.map(o => {
                if (o.oi_id === id) {
                    return {
                        ...o,
                        isRejectSelected : false
                    }
                }
                return {
                   ...o
                }
            });
            orderReceiveList.set(orl);
        } else {
            const orl = orderReceiveList.value.map(o => {
                if (o.oi_id === id) {
                    return {
                        ...o,
                        id : o.oi_id,
                        qtyReduction: item.itemQuantity,
                        receiveChecked: true,
                        isRejected : true,
                        isRejectSelected : true,
                        isAccepted: false,
                        statusReceived : 'rejected',
                        rejectReason : item.rejectReason
                    }
                }
                return {
                    ...o
                }
            });
            orderReceiveList.set(orl);
        }
    }

    return { rejectItem, acceptItem, editStatus, orderReceiveList: {value: orderReceiveList} }
}

const useReceive = (orderReceiveList) => {
    const params = useParams();
    const id = parseInt(params.id);
    const dispatch = useDispatch();
    const history = useHistory();

    const orderChecked = () => {
        const orderCheckedEnable = orderReceiveList.every(item => item.receiveChecked === true);
        if(!orderCheckedEnable) return;

        const isNotZeroPrice = orderReceiveList.every(item => (item.price * item.quantity !== 0));
        if(isNotZeroPrice) {
            const inputPropsChecked = {
                title: '',
                onSave: orderCheckedPreReject,
                placeholder: '',
                menu: isMobile ? true : false,
                fullMenu: true
            };
            dispatch(showModal(OrderReceiveCheckedModal, inputPropsChecked));
        } else {
            const props = {
                title: 'Not all your items have prices',
                message: 'To complete the receiving of this order please enter all your price data.',
                btnLabel: 'Got It',
                footerClass: 'text-center',
                color: 'green'
            }
            dispatch(showModal(AlertModal, props))
        }
    }

    const orderCheckedPreReject = (objQuestions) => {
        const orderItemArray = orderReceiveList.map(o => {
            return {
                id: o.oi_id,
                qtyReduction: o.qtyReduction
            }
        })
        const objOrderItem = {};
        orderItemArray.forEach((element, i) => {
            const name = 'OrderItem' + i;
            objOrderItem[name] = element;
        });

        const orderItemAllArray = orderReceiveList.map(o => {
            return {
                id: o.oi_id,
                qtyReduction: o.qtyReduction,
                status: o.statusReceived,
                rejectReason: o.rejectReason
            }
        })

        const objOrderItemAll = {};
        orderItemAllArray.forEach((element, i) => {
            const name = 'OrderItem' + i;
            objOrderItemAll[name] = element;
        });
        ordersApi.preReject(id, objOrderItem).then((res) => {
            history.push({
                pathname: `/order-history/receive/${id}/order-complete`,
                state: {objOrderItem: objOrderItemAll, objQuestions : objQuestions, resPreReject : res.data }
            });
        }).catch(error => {
            dispatch(showModal(AlertModal, {message: error.data.message}));
        });
    }

  return {orderChecked, orderCheckedPreReject}
};

const useReceiveComplete = (props, customInvoiceId) => {
    const params = useParams();
    const id = parseInt(params.id);
    const history = useHistory();

    const orderRedirect = (obj) => {
        if(!obj) {
            history.replace(`/order-history/receive/${id}`);
        }
    }

    const orderComplete = async () => {
        const orderItem = {
            customInvoiceId : customInvoiceId,
            itemList : {
                ...props.objOrderItem
            }
        }

        await ordersApi.checkItems(id, orderItem).then((res) => {
        }).catch(error => {
        });

        await ordersApi.survey(id, props.objQuestions).then((res) => {
            //history.push(`/order-history/receive/${id}`);
            history.push('/order-history');
        }).catch(error => {
        });
    }

    return { orderRedirect, orderComplete }
}

const useCustom = (getOrdersById) => {
    const createCustomItem = (item) => {
        const params = {
            brand: item.brand,
            item_description: item.description,
            din: item.din,
            unit_quantity: item.packSize.innerPack,
            unit_size: item.packSize.outerPack,
            pack_size_unit: item.packSize.uom.shorthand,
            unit_measure: item.unitMeasure.id,
            price: item.price,
            quantity: item.quantity,
            order_id: item.order_id
        }
        ordersApi.createCustomItem(params).then((res) => {
            getOrdersById();
        }).catch(error => {
        });
    }

    return {createCustomItem }

}

export default {
    useApi, useRow, useCustom, useReceive, useReceiveComplete
};
