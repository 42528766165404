import React, {useEffect} from 'react';
import { Container, Row } from 'reactstrap';
import MainAppRouter from './MainAppRouter';
import Sidebar from '../../SharedComponents/Sidebar/Sidebar';
import './MainApp.scss';
import { useSelector } from 'react-redux';
import useShoppingCart from '../../SharedComponents/ShoppingCart/useShoppingCart';

const MainApp = () => {
  const isSidebarOpen = useSelector(state => state.userInterface.isSidebarOpen);
  const activeLocation = useSelector(({user}) => user.activeLocation);
  const { getCartItems } = useShoppingCart.useCartItems();

  useEffect(() => {
    getCartItems();
  }, [activeLocation]);

  return (
    <Container fluid={true} className='main-app p-0'>
      <Row className='m-0'>
        <Sidebar />
        <MainAppRouter isSidebarOpen={isSidebarOpen} />
      </Row>
    </Container>
  );
};

export default MainApp;