import React from 'react';
import { Col } from 'reactstrap';
import './Header.scss';
import ShowIf from '../ShowIf/ShowIf';

const HeaderLogo = (props) => {
  const { show, logo } = props;

  return (
    <ShowIf show={show}>
      <Col className='w-fc'>
        <img src={logo} alt='Company Logo' className='company-logo' />
      </Col>
  </ShowIf>
  );
};

export default HeaderLogo;