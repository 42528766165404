import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import noImage from '../../../../../assets/images/no-image.png';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { colors } from '../../../../../config/constants';
import usePlaceOrderInventory from '../../PlaceOrder/PlaceOrderInventory/usePlaceOrderInventory';
import ShoppingCartItemGroup from '../../../../../SharedComponents/ShoppingCartItem/ShoppingCartItemGroup';
import useShoppingCartItem from '../../../../../SharedComponents/ShoppingCartItem/useShoppingCartItem';

const GroupItemDistributor = (props) => {

    const { item, changeItemGroup } = props;
    const { overlay, setOverlay } = useShoppingCartItem.useOverlay();

    const id = item.id;
    const {
        canScrollRight,
        scrollLeft,
        scrollRight,
        scrollPos,
        snapOffset
    } = usePlaceOrderInventory.useHorizontalScroll(id, item, item.items, true);

    const listCols = item.items.map(res => {
        return (
            <Col className={isMobile ? 'col-6 px-1' : 'mw-260px'} key={'CardColItemDis_' + res.id}>
                <Card style={{ height: '100%' }}>
                    <CardHeader>
                        <h3>{res.description}</h3>
                    </CardHeader>
                    <CardBody>
                        <div className='contentImg'>
                            <img src={res.imageUrl ? res.imageUrl : noImage}></img>
                        </div>
                        <Row>
                            <Col style={{ height : 26}}>
                                <p>{res.brand}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>{res.distributor}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h1>$ {res.price} {res.unitMeasure}</h1>
                            </Col>
                            <Col>
                                <h1>{res.packSize}</h1>
                            </Col>
                        </Row>
                        <div className='contentButton'>
                            <Col className='d-flex align-items-center justify-content-center'>
                                {/* <ShoppingCartItem item={res} useInitialLeft={false} /> */}
                                <ShoppingCartItemGroup
                                    isGroup={true}
                                    item={res}
                                    overlayProps={{ overlay: true, setOverlay }}
                                    changeItemGroup={changeItemGroup} />

                            </Col>
                        </div>

                    </CardBody>
                </Card>
            </Col>
        )
    });


    return (
        <div className='contentAll'>
            <div className='titleGroup'>
                <div></div>
                <h3>{item.name}</h3>
                <div></div>

            </div>
            <Fragment>
                <Row>
                    <FaChevronLeft className='arrowLeft clickable' onClick={scrollLeft} size={28} color={scrollPos > 0 ? colors.dark : colors.lightBlueText} />
                    <FaChevronRight className='arrowRight clickable' onClick={scrollRight} size={28} color={canScrollRight ? colors.dark : colors.lightBlueText} />
                </Row>
                <div id={`g_${id}_scroll_container`} style={isMobile ? { overflow: 'hidden' } : null}>
                    <Row className={isMobile ? 'w-100 m-0 d-flex flex-nowrap pb-3 hide-scrollbar' : 'w-100 m-0 overflow-hidden flex-nowrap pb-3'} id={`g_${id}_scroll`} style={isMobile ? { overflow: 'auto' } : null} onScroll={isMobile ? snapOffset : null}>
                        {listCols}
                    </Row>
                </div>
                {/* <FaChevronLeft className='arrowLeft' onClick={scrollLeft} size={28} color={scrollPos > 0 ? colors.dark : colors.lightBlueText} /> */}
            </Fragment>



        </div>
    )


}

export default GroupItemDistributor;
