import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { IoMdCard } from 'react-icons/io';
import { MdTrendingUp } from 'react-icons/md';
import { isMobile } from 'react-device-detect';
import useSidebar from './useSidebar';
import WelComeTourModal from '../../SharedComponents/Modals/WelComeTourModal/WelcomeTourModal';
import ShowIf from '../ShowIf/ShowIf';
import WhatInventoryViewModal from '../Modals/WhatInventoryViewModal/WhatInventoryViewModal';
import { showModal } from '../../reducers/modals/modalsActions';
import useGoTo from '../GoTo/useGoTo';

const SidebarBody = () => {
  const dispatch = useDispatch();
  const activeLocation = useSelector(({ user }) => user.activeLocation);
  const { addSpecialRequest } = useGoTo.useActions();
  const companyLogoUrl = activeLocation && activeLocation.company && activeLocation.company.logo_url;

  const {
    clickClose,
    openModalWC,
    startTour,
    tourElement,
    goToNextTourModal,
    skipDemo } = useSidebar.useActions(dispatch);

  const colorOrderPlace = openModalWC ? '#6ed79a' : '';

  const startTourComparison = () => {
    dispatch(showModal(WhatInventoryViewModal));
  };

  return (
    <Row className='sidebar-body py-1 px-3'>
      <Col xs={12}>
        <ShowIf show={!isMobile}>
          <h4 className='fb-heading-title'>Dashboard</h4>
          <ul className='main-navigation'>
            <li>
              <NavLink to='/analytics' className='analytics-menu' onClick={clickClose}><MdTrendingUp color='#ffffff' className='analytics-menu' />Analytics</NavLink>
            </li>
          </ul>
        </ShowIf>
        <h4 className='fb-heading-title'>SHOP</h4>
        <ul className='main-navigation'>
          <li ref={tourElement}><NavLink to='/place-order' style={{ color: colorOrderPlace }} className='place-order-menu' onClick={clickClose}>Place Order</NavLink></li>
         
          <ShowIf show={activeLocation.display_accounting && !isMobile}>
            <li><NavLink to='/accounting' className='accounting-menu' onClick={clickClose}>Accounting</NavLink></li>
          </ShowIf>

          <li><NavLink to='/order-history' activeClassName='active' className='order-history-menu' onClick={clickClose}>Order History</NavLink></li>
          
          <ShowIf show={activeLocation.display_comparison_view}>
            <li><NavLink to='/inventory-view/inventories' className='tour-comparison-menu' onClick={clickClose}>Inventories</NavLink></li>
          </ShowIf>
          
          <li><NavLink to='/order-guide' activeClassName='active' className='order-guide-menu' onClick={clickClose}>Order Guide</NavLink></li>
          <ShowIf show={!isMobile}>
            <li><NavLink to='/pos' activeClassName='active' className='pos-menu' onClick={clickClose}>POS</NavLink></li>
          </ShowIf>
          <ShowIf show={activeLocation.display_recipe_costing}>
            <li><NavLink to='/recipe-costing' className='recipe-costing-menu' onClick={clickClose}>Recipe Costing</NavLink></li>
          </ShowIf>
        </ul>
        <h4 className='fb-heading-title'>Settings</h4>
        <ul className='main-navigation'>
          <li><NavLink to='/shopping-lists' className='shopping-lists-menu' onClick={clickClose}>Shopping Lists</NavLink></li>
          <li><NavLink to='/help' activeClassName='active' className='help-report-issue-menu' onClick={clickClose}>Help / Report Issue</NavLink></li>
          <li>
            <NavLink to='/manage-cards' activeClassName='active' className='analytics-menu' onClick={clickClose}><IoMdCard className='analytics-menu' color='#ffffff' style={{ fontSize: '20px' }} />Manage Card</NavLink>
          </li>
          <li><NavLink to='/logout' className='log-out-menu'>Log Out</NavLink></li>
        </ul>
        <h4 className='fb-heading-title'>HOW IT WORKS</h4>
        <ul className='main-navigation'>
          <li><a className='welcome-tour-menu' style={{ color: colorOrderPlace }} onClick={startTour}>Welcome Tour</a></li>
        </ul>
        <ul className='main-navigation'>
          <li><a href='#' className='tour-comparison-menu' onClick={startTourComparison}>What is Inventory View?</a></li>
        </ul>

        <Col xs={12}>
          <Row>
            <Col xs={{ size: 6, offset: 3 }} className='text-center'><img className='img-fluid' src={companyLogoUrl} /></Col>
          </Row>
          <Row>
            <Col xs={12} className='text-center cantfind_txt'>Can't find what you're looking for?</Col>
          </Row>
          <Row className='mt-2'>
            <Col xs={12}>
              <button onClick={() => { clickClose(); addSpecialRequest() }} className='btn btn-block btn-lg special-request-button' style={{ fontSize: '16px !important' }}> Add Special Request </button>
            </Col>
          </Row>
        </Col>
      </Col>

      <Row className='col-12 m-0 p-0'>
        <ShowIf show={openModalWC}>
          <WelComeTourModal
            openModal={openModalWC}
            tourElement={tourElement}
            startTour={startTour}
            skipDemo={skipDemo}
            goToNextTourModal={goToNextTourModal}
          />
        </ShowIf>
      </Row>
    </Row>

  );
};

export default SidebarBody;
