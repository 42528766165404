import React, { useMemo } from 'react';
import useModal from '../useModal';
import { isMobile } from 'react-device-detect';
import { Modal, ModalHeader, Row, Col, ModalBody, ModalFooter, Button } from 'reactstrap';
import InputSearch from '../../InputSearch/InputSearch';
import useAddSubrecipe from './useAddSubrecipe';
import InfiniteScroll from 'react-infinite-scroller';
import PlusButton from '../../PlusButton/PlusButton';
import subImage from '../../../assets/images/subrecipe-image.png'
import ShowIf from '../../ShowIf/ShowIf';
import ItemDetailsHowMuch from '../../../Views/MainApp/Views/RecipeCosting/Modals/ItemDetails/SharedComponents/ItemDetailsHowMuch';
import ItemDetailsUnitConversion from '../../../Views/MainApp/Views/RecipeCosting/Modals/ItemDetails/SharedComponents/ItemDetailsUnitConversion';
import validate from 'validate.js';
import { fraction, number } from 'mathjs';

const AddSubrecipe = (props) => {
  const {
    id, recipeId, onAdd, initItems
  } = props;

  const modalProps = useModal(id, isMobile, true);
  const {
    onClear, onSearch, search, items,
    page, loadMore, isLoading, getSubrecipes,
    toggleAdding, adding, addingItem, addItemForm, uom, canConvert
  } = useAddSubrecipe.useApi(recipeId);

  const initItemsIds = initItems.map(i => i.id);

  const itemsMap = useMemo(() => items.filter(i => !initItemsIds.includes(i.id))
    .filter(f => new RegExp(search.value).test((f.name).toLowerCase()))
    .map(i =>
      <Row className='m-0 border-bottom py-2 w-100 align-items-center' key={`sub_${i.id}`}>
        <Col className='w-image'>
          <img className='no-image' src={subImage} alt='subrecipe' />
        </Col>
        <Col className='p-0'>
          <Row><Col className='text-main'>{i.name}</Col></Row>
          <Row><Col className='text-label'>{i.menu.name}</Col></Row>
        </Col>
        <Col className='w-fc'>
          <PlusButton isActive large onClick={() => toggleAdding(i)} />
        </Col>
      </Row>
    ), [items, loadMore]);

  const noItems = items.length === 0 && !isLoading;

  const cantAdd = () => {
    const form = {
      recipeAmount: addItemForm.recipeAmount.value,
      waste: addItemForm.waste.value,
      itemPackSize: addItemForm.conversion.item.packSize.value,
      recipePackSize: addItemForm.conversion.recipe.packSize.value
    };
    const constrains = {
      recipeAmount: { fraction: { strict: true } },
      waste: { numericality: addItemForm.waste.value ? true : false },
      itemPackSize: { fraction: { strict: true } },
      recipePackSize: { fraction: { strict: true } },
    };
    const result = validate(form, constrains);
    return result ? true : false;
  };

  const clickAdd = () => {
    const params = {
      ingredient_type: "subrecipe",
      ingredient_id: addingItem.id,
      waste_percentage: addItemForm.waste.value,
      recipe_amount: number(fraction(addItemForm.recipeAmount.value)),
      recipe_amount_fraction: fraction(addItemForm.recipeAmount.value).toFraction(),
      conversion_pack_size_value: number(fraction(addItemForm.conversion.item.packSize.value)),
      conversion_pack_size_fraction: fraction(addItemForm.conversion.item.packSize.value).toFraction(),
      conversion_pack_size_uom: addItemForm.conversion.item.uom,
      recipe_pack_size_value: number(fraction(addItemForm.conversion.recipe.packSize.value)),
      recipe_pack_size_fraction: fraction(addItemForm.conversion.recipe.packSize.value).toFraction(),
      recipe_pack_size_uom: addItemForm.conversion.recipe.uom
    }
    onAdd(params);
    modalProps.toggle();
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader className='bg-white border-0' toggle={modalProps.toggle}>
        <Row>
          <Col>Add Subrecipe</Col>
        </Row>
        <Row className='align-items-center mt-3 mx-0'>
          <Col className='p-0'>
            <InputSearch onChange={search.set} value={search.value} clearValue={onClear} onEnter={onSearch} />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody className={isMobile ? 'border-top px-0' : ''}>
        <ShowIf show={adding}>
          <Row className='m-0 py-2 w-100 align-items-center'>
            <Col className='w-image'>
              <img className='no-image' src={subImage} alt='subrecipe' />
            </Col>
            <Col className='p-0'>
              <Row className='m-0'><Col className='p-0 text-main'>{addingItem.name}</Col></Row>
              <Row className='m-0'><Col className='p-0 text-label'>{addingItem.menu.name}</Col></Row>
            </Col>
          </Row>
          <ItemDetailsHowMuch noMar={isMobile} recipeAmount={addItemForm.recipeAmount} waste={addItemForm.waste} uom={uom} />
          <ItemDetailsUnitConversion canConvert={canConvert} noMar={isMobile} item={addItemForm.conversion.item} recipe={addItemForm.conversion.recipe} />
        </ShowIf>
        <ShowIf show={!adding && !isMobile}>
          <Row className='border m-0 my-3 overflow-auto h-300px mh-300px'>
            <ShowIf show={noItems}>
              <Col className='mt-3 text-center'>
                No results found
              </Col>
            </ShowIf>
            <InfiniteScroll
              className='w-100'
              initialLoad={false}
              pageStart={page}
              loadMore={() => getSubrecipes(page)}
              hasMore={loadMore && !isLoading}
              useWindow={false}
            >
              {itemsMap}
            </InfiniteScroll>
          </Row>
        </ShowIf>
        <ShowIf show={!adding && isMobile}>
          <ShowIf show={noItems}>
            <Col className='mt-3 text-center'>
              No results found
            </Col>
          </ShowIf>
          <InfiniteScroll
            className='w-100'
            initialLoad={false}
            pageStart={page}
            loadMore={() => getSubrecipes(page)}
            hasMore={loadMore && !isLoading}
            useWindow={false}
          >
            {itemsMap}
          </InfiniteScroll>
        </ShowIf>
      </ModalBody>
      <ShowIf show={adding}>
        <ModalFooter>
          <Row className='w-100'>
            <Col className='p-0'>
              <Button onClick={toggleAdding} className='px-5' color='green' outline>Cancel</Button>
            </Col>
            <Col className='text-right p-0'>
              <Button disabled={cantAdd()} onClick={clickAdd} className='px-5' color='green'>Add</Button>
            </Col>
          </Row>
        </ModalFooter>
      </ShowIf>
    </Modal>
  );
};

export default AddSubrecipe;