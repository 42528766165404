import React, { useState } from 'react';
import { Card, Row, Col, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { MdEdit, MdDelete } from 'react-icons/md';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { colors } from '../../../../../config/constants';
import { cardIconHandler } from '../cardIconHandler';

const CardRow = (props) => {
  const [showDelete, setShowDelete] = useState(false);
  const card = props.children;

  const history = useHistory();

  const enableShowDelete = () => { setShowDelete(true); };
  const disableShowDelete = () => { setShowDelete(false); };
  const deleteCard = () => {
    props.deleteCard(card.token);
  };

  const goToUpdateCard = () => {
    history.push(`/manage-cards/update-card/${card.token}`);
  };

  if (isMobile) {
    return (
      <Card className='credit-card'>
        <Row noGutters={true}>
          <Col xs={'auto'} style={{flex: '0 0 20%', maxWidth: '20%'}}>
            <img src={cardIconHandler(card)} title={card.cardType} />
          </Col>
          <Col xs={'auto'} style={{flex: '0 0 40%', maxWidth: '40%'}}>
            <div className='my-2'>{card.nickname || card.last4}</div>
          </Col>
          <Col xs={'auto'} style={{flex: '0 0 40%', maxWidth: '40%'}} className='text-right'>
            <ShowIf show={!showDelete}>
              <MdEdit size={22} color={colors.darkBlueText} className='clickable mx-2' onClick={goToUpdateCard} />
              <MdDelete size={22} color={colors.darkBlueText} className='clickable mx-2' onClick={enableShowDelete} />
            </ShowIf>
            <ShowIf show={showDelete}>
              <Button type='button' size='sm' color='green' className='mr-1' onClick={disableShowDelete}>Cancel</Button>
              <Button type='button' size='sm' color='orange' onClick={deleteCard}>Delete</Button>
            </ShowIf>
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Card className='credit-card'>
      <Row>
        <Col xs={1}>
          <img src={cardIconHandler(card)} title={card.cardType} />
        </Col>
        <Col xs={7}>
          <div className='my-2'>{card.nickname || card.last4}</div>
        </Col>
        <Col xs={4} className='text-right'>
          <ShowIf show={!showDelete}>
            <MdEdit size={22} color={colors.darkBlueText} className='clickable mx-2' onClick={goToUpdateCard} />
            <MdDelete size={22} color={colors.darkBlueText} className='clickable mx-2' onClick={enableShowDelete} />
          </ShowIf>
          <ShowIf show={showDelete}>
            <Button type='button' className='btn btn-green mr-1' onClick={disableShowDelete}>Cancel</Button>
            <Button type='button' className='btn btn-orange' onClick={deleteCard}>Delete</Button>
          </ShowIf>
        </Col>
      </Row>
    </Card>
  );
};

export default CardRow;
