import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import SubHeaderPlaceOrderMode from './SubHeaderPlaceOrderMode';
import SubHeaderPlaceOrderViewIcons from './SubHeaderPlaceOrderViewIcons';
import SubHeaderPlaceOrderSortBy from './SubHeaderPlaceOrderSortBy';
import { mobileClassName } from '../../../config/constants';
import SubHeaderPlaceOrderInventoryList from './SubHeaderPlaceOrderInventoryList';
import ShowIf from '../../ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import SubHeaderPlaceOrderInventoryOptions from './SubHeaderPlaceOrderInventoryOptions';
import SubHeaderPlaceOrderOnHand from './SubHeaderPlaceOrderOnHand';
import { useSelector } from 'react-redux';

const SubHeaderPlaceHolder = (props) => {
  const { view, history } = props;
  const displayInventory = useSelector(({user}) => user.activeLocation.display_inventory);

  return (
    <Fragment>
      <Row className={'header m-0 align-items-center' + mobileClassName}>
        <ShowIf show={!isMobile}>
          <SubHeaderPlaceOrderInventoryList view={view} />
        </ShowIf>
        <SubHeaderPlaceOrderSortBy view={view} />
        <SubHeaderPlaceOrderInventoryOptions view={view} />
        <SubHeaderPlaceOrderViewIcons view={view} history={history} />
      </Row>
      <ShowIf show={isMobile && view === 'inventory'}>
        <Row className={'header m-0 align-items-center' + mobileClassName}>
          <SubHeaderPlaceOrderInventoryList view={view} />
        </Row>
      </ShowIf>
      <ShowIf show={view === 'inventory' && displayInventory}>
        <SubHeaderPlaceOrderOnHand />
      </ShowIf>
    </Fragment>
  );
};

SubHeaderPlaceHolder.propTypes = {
  view: PropTypes.string.isRequired
};

export default SubHeaderPlaceHolder;