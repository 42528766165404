import db from './db';
import {dbStores} from './dbConstants';

const cartTable = dbStores.tables.cart;
const cartBaseTable = dbStores.tables.cartBase;

// Cart

export const saveAll = async (items) => {
  let nItems=[];
  items.forEach((item, i) => {
    const nItem = { // id: i+1,
      shoppingCartId : item.shoppingCartId,
      isSpecialItem: item.isSpecialItem,
      itemDistributorId: !item.isSpecialItem ? item.itemDistributorId : null,
      description: item.description,
      distributorId: item.distributorId,
      unit: !item.isSpecialItem ? null : item.unit,
      uom: !item.isSpecialItem ? item.unitMeasure : null,
      packsize: item.packSize,
      quantity: item.caseQuantity,
      toUpdate: 0
    };    
    nItems.push(nItem);
  });

  return await db.table(cartTable).bulkAdd(nItems);  
};

export const deleteAll = async () => {
  return await db.table(cartTable).clear();  
};

export const getAll = async () => {
  return await db.table(cartTable).toArray();
};

export const find = async (param) => {
  return await db.table(cartTable)
    .where(param.field)
    .equals(param.value)
    .toArray();
};

export const findOne = async (itemDistributorId) => {
  return await db.table(cartTable)
    .where("itemDistributorId")
    .equals(itemDistributorId)
    .toArray();
};

export const insertOne = async (item) => {
  return await db.table(cartTable).add(item);      
};

export const updateOne = async (params, itemDistributorId) => {
  return await db.table(cartTable)
    .where('itemDistributorId')
    .equals(itemDistributorId)
    .modify(params); 
};

export const deleteOne = async (item) => {
  return await db.table(cartTable)
    .delete(item);  
};

export const findOneBase = async () => {
  return await db.table(cartBaseTable).toArray();
};

// CartBase
export const saveBase = async (lastModified, cartCount, orderTotal) => {
  const params = {
    lastModified: lastModified,
    cartCount: cartCount,
    orderTotal: orderTotal
  }
  return await db.table(cartBaseTable).add(params);    
};

export const updateBase = async (params, id) => {
  return await db.table(cartBaseTable)
    .where('id')
    .equals(id)
    .modify(params);
};

export const deleteBase= async () => {
  return await db.table(cartBaseTable).clear();
};