import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { colors } from "../../config/constants";
import { Button } from "reactstrap";

const NextButton = ({
  large,
  isActive,
  onClick,
  isBlue,
  square,
  disabled,
  ...props
}) => {
  return (
    <Button
      {...props}
      onClick={onClick}
      size={(large ? "lg-" : "") + (square ? "square" : "circle")}
      color={isActive ? (isBlue ? "dark-blue" : "green") : "gray"}
      disabled={disabled === undefined ? false : disabled}
      // onTouchStart={preventDoubleTapZoom}
    >
      <MdKeyboardArrowRight
        color={isActive ? "white" : colors.lightBlueText}
        size={large ? 30 : 24}
      />
    </Button>
  );
};

export default NextButton;
