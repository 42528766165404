import React, { useState } from 'react';
import useModal from '../useModal';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert, Input, Button } from 'reactstrap';
import validate from 'validate.js';
import ShowIf from '../../ShowIf/ShowIf';
import useInput from '../../../SharedComponents/SharedEffects/useInput';
import usersApi from '../../../api/usersApi';
import './ForgotPasswordModal.scss';

const ForgotPasswordModal = (props) => {

  const { id } = props;
  const modalProps = useModal(id);

  const [errorMessage, setErrorMessage] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const email = useInput.useText('');

  const validateForm = () => {
    const data = {
      email: email.value
    };
    const constrains = {
      email: { presence: { allowEmpty: false }, email: true },
    };

    const result = validate(data, constrains);
    return !!!result;
  };

  const requestPasswordReset = () => {
    if (!validateForm()) {
      setErrorMessage('Please enter a correct email.');
      return;
    }
    setErrorMessage('');
    usersApi.requestPasswordReset(email.value)
      .then(() => {
        setSuccessMsg("You\'ll recieve an email shortly with instructions on how to reset your password.");
        setTimeout(() => {
          modalProps.toggle();
        }, 5000);
      })
      .catch(error => {
        if (error.code === 400) {
          setErrorMessage(error.data.message);
        }
        console.log('usersApi requestPasswordReset Error:', error);
      });
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader className='bg-white' toggle={modalProps.toggle}>Forgot Password</ModalHeader>
      <ModalBody>
        <Row>
          <ShowIf show={errorMessage}>
            <Col xs={12} className='text-center mt-2'>
              <Alert color='danger'>{errorMessage}</Alert>
            </Col>
          </ShowIf>
          <ShowIf show={successMsg}>
            <Col xs={12} className='text-center mt-2'>
              <Alert color='success'>{successMsg}</Alert>
            </Col>
          </ShowIf>
          <Col xs={{offset: 1, size: 10}} className='text-center mt-4'>
            An email with a link to reset your password will be sent to your email address
          </Col>
          <Col xs={{offset: 2, size: 8}} className='mt-2 mb-5'>
            <Input type='email' value={email.value} onChange={email.set} className='mb-3' placeholder='Email' />
            <Button color='blue' size='lg' block onClick={requestPasswordReset}>SUBMIT</Button>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className='forgot-password-footer'>
        <Col xs={{offset: 2, size: 8}} className='my-2'>
          Back to <a href='#' onClick={() => modalProps.toggle()}>Login</a>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default ForgotPasswordModal;
