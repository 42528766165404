import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import numeral from 'numeral';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { colorsGraph } from '../../../../../../config/constants';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import GroupTitle from '../Subcomponents/GroupTitle';
import { FaCircle } from 'react-icons/fa';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';

const PlaceOrderInventoryAnalytics = (props) => {
  const { groups } = props;

  const graphsMap = groups.map(g => {
    const options = {
      title: '',
      chart: {
        type: 'spline'
      },
      credits: false,
      series: g.items.filter(i => !i.isSubRecipe && !i.isCustomItem).sort((a,b) => a.isPunchedOut - b.isPunchedOut || a.unitPrice - b.unitPrice || a.price - b.price).map(i => {
        const data = i.analytics.map(a => {
          return {
            x: new Date(a.startDate),
            y: a.price
          }
        });
        return {
          name: i.description || i.name,
          data: data,
          itemDistributorId: i.itemDistributorId,
          unitMeasure: i.unitMeasure,
          color: colorsGraph[i.itemDistributorId % colorsGraph.length],
          type: 'spline',
          marker: {
            enabled: true,
            radius: 5,
            symbol: 'circle'
          }
        }
      }),
      plotOptions:{
        series: {
          turboThreshold: 0
        },
        spline: {
          connectEnds: true
        }
      },
      tooltip: {
        backgroundColor: '#000000',
        borderColor: '#000000',
        borderRadius: 5,
        followTouchMove: false,
        formatter: function() {
          return '<div><span class="text-capitalize" style="color:#FFFFFF;">'+this.series.userOptions.name+'</span><br/><span style="color:#FFFFFF;">'+numeral(this.y).format('$0,0.00')+' '+this.series.userOptions.unitMeasure+'</span></div>';
        }
      },
      xAxis: {
        max: new Date(),
        // min: new Date(moment().subtract(1, 'years').format('YYYY-MM-DD')),
        // min: new Date('2019-02-01'),
        enabled: true,
        type: 'datetime',
        startOnTick: false,
        endOnTick: false,
        tickInterval: 1 * 31 * 24 * 3600 * 1000,
        range: 31556952*1000,
        clip: true
        // labels: {
        //   style: {
        //     color: '#376386'
        //   },
        //   format: '{value:%b %Y}'
        // }
      },
      legend: {
        enabled: false
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: ''
        },
        style: {
          color: '#376386'
        },
        labels: {
          formatter: function () {
            return numeral(this.value).format('$0,0.00');
          }
        },
      }
    };
    
    console.log(new Date('2019-02-01'));

    const legendMap = g.items.filter(i => !i.isSubRecipe && !i.isCustomItem).sort((a,b) => a.isPunchedOut - b.isPunchedOut || a.unitPrice - b.unitPrice || a.price - b.price).map(i => {
      return (
        <Row key={`listgraph_${i.itemDistributorId}`} className={isMobile ? 'm-0 mt-3' : 'mt-3'}>
          <Col className='text-capitalize'><FaCircle size={8} className='mr-2' color={colorsGraph[i.itemDistributorId % colorsGraph.length]} />{i.description || i.name}</Col>
          <Col className='text-capitalize text-label-dark'>{i.brand}</Col>
          <Col className='text-capitalize text-label-dark'>{i.distributor}</Col>
        </Row>
      )
    });

    return (
      <Row key={`line_${g.id}`} className={'mb-3' + isMobile ? '': ' no-gutters'}>
        <Col>
          <Row>
            <Col>
              <GroupTitle expand={false} title={g.name} />
            </Col>
          </Row>
          <ShowIf show={!isMobile}>
            <Row className='bg-white'>
              <Col xs={isMobile ? 12 : 6}>
                <HighchartsReact highcharts={Highcharts} options={options} />
              </Col>
              <Col>
                <Row className='mt-3 border-bottom'>
                  <Col className='text-label-sm text-center' >Item Name</Col>
                  <Col className='text-label-sm text-center' >Brand</Col>
                  <Col className='text-label-sm text-center' >Distributor</Col>
                </Row>
                {legendMap}
              </Col>
            </Row>
          </ShowIf>
          <ShowIf show={isMobile}>
            <Row className='bg-white'>
              <Col xs={isMobile ? 12 : 6}>
                <HighchartsReact highcharts={Highcharts} options={options} />
              </Col>
            </Row>
            <Row className='mt-3 border-bottom m-0'>
              <Col className='text-label-sm text-center' >Item Name</Col>
              <Col className='text-label-sm text-center' >Brand</Col>
              <Col className='text-label-sm text-center' >Distributor</Col>
            </Row>
            {legendMap}
          </ShowIf>
        </Col>
      </Row>
    );
  })

  return (
    <Container fluid={true} className={isMobile ? 'p-0 overflow-hidden' : 'px-5'}>
      {graphsMap}
    </Container>
  );
};
      
export default PlaceOrderInventoryAnalytics;