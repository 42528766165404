import { useState, useEffect } from 'react';
import { usState, validateZipcode } from '../../config/constants';
import useInput from '../../SharedComponents/SharedEffects/useInput';
import { useHistory, useParams } from 'react-router-dom';
import qs from 'query-string';
import distributorsApi from '../../api/distributorsApi';
import validate from 'validate.js';
import singupApi from '../../api/singupApi';
import { useDispatch } from 'react-redux';
import { showModal } from '../../reducers/modals/modalsActions';
import ConfirmationModal from '../../SharedComponents/Modals/ConfirmationModal/ConfirmationModal';
import SimpleModal from '../../SharedComponents/Modals/SimpleModal/SimpleModal';


const useSteps = (howManyLocations) => {
  const url = '/sign-up';
  const history = useHistory();
  const params = useParams();

  const [step, setStep] = useState(1);
  const [lastStep, setLastStep] = useState(1);
  const [showLocation, setShowLocation] = useState(1);
  const [showDistributor, setShowDistributor] = useState(0);

  useEffect(() => {
    // Get every update
    const futureStep = parseInt(params.step);
    const urlObj = qs.parseUrl(window.location.href.replace('#', ''));
    if (history.action === 'POP') {
      if ((step < futureStep) && (futureStep > lastStep)) {
        setLastStep(step > lastStep ? step : lastStep);
        history.replace(`${url}/${step}`);
      } else if (futureStep > lastStep) {
        setStep(lastStep);
        history.push(`${url}/${lastStep}`);
      } else if (futureStep !== step) {
        setStep(futureStep);
        setLastStep(futureStep > lastStep ? futureStep : lastStep);
      }
      if (urlObj.query.location > howManyLocations && step === 1) {
        goTo(step, '', true);
      } else if (urlObj.query.location && urlObj.query.location !== showLocation && showLocation > 1) {
        goTo(step, `?location=${showLocation}`, true);
      }
      if (urlObj.query.distributor && parseInt(urlObj.query.distributor) !== showDistributor) {
        goTo(step, `?distributor=${showDistributor}`, true);
      } else if (!urlObj.query.distributor && showDistributor) {
        setShowDistributor(0);
      }
    } else {
      if (futureStep !== step) {
        setStep(futureStep);
        setLastStep(futureStep > lastStep ? futureStep : lastStep);
      }
      setShowLocation(urlObj.query.location ? parseInt(urlObj.query.location) : showLocation);
      setShowDistributor(urlObj.query.distributor ? parseInt(urlObj.query.distributor) : 0);
    }
  }, [history, params]);

  const goTo = (step, search, replace) => {
    if (replace) {
      history.replace({
        pathname: `${url}/${step}`,
        search: search
      });
    } else {
      history.push({
        pathname: `${url}/${step}`,
        search: search
      });
    }
  };

  return { step, goTo, showLocation, showDistributor };
}

const useContactInformation = () => {
  const titleOptions = [
    { value: 0, label: 'Owner' },
    { value: 1, label: 'Chef' },
    { value: 2, label: 'Jr. Chef' },
    { value: 3, label: 'General Manager' }
  ];
  const stateOptions = usState.map(s => { return { label: s.name, value: s.abbreviation } });
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const locationOptions = numbers.map(n => { return { label: n.toString(), value: n } });

  const firstName = useInput.useText();
  const lastName = useInput.useText();
  const [title, setTitle] = useState(null);
  const businessName = useInput.useText();
  const locationAddress = useInput.useText();
  const city = useInput.useText();
  const [state, setState] = useState(null);
  const zipcode = useInput.useText('', { validate: validateZipcode, isNumber: true });

  const phoneNumber = useInput.useText();
  const email = useInput.useText();
  const confirmEmail = useInput.useText();
  const password = useInput.useText();
  const confirmPassword = useInput.useText();
  const [howManyLocations, setHowManyLocations] = useState({ label: '1', value: 1 });

  return {
    titleOptions, stateOptions, locationOptions,
    firstName, lastName, title: { set: setTitle, value: title },
    businessName, locationAddress, city, state: { set: setState, value: state },
    zipcode, phoneNumber, email, confirmEmail, password, confirmPassword,
    howManyLocations: { set: setHowManyLocations, value: howManyLocations }
  };
};

const useDistributorInformation = () => {
  const [distributorArray, setDistributorArray] = useState([]);

  const setDistributorData = (n, data, d) => {

    console.log(data, 'final');

    if (d == true) {
      const newVal = distributorArray.filter((d, i) => i !== n);
      console.log(newVal)
      setDistributorArray(distributorArray.filter((d, i) => i !== n));

      return;
    }
    if (n === distributorArray.length) {
      // Add
      setDistributorArray([...distributorArray, data]);
    } else {
      // Edit
      setDistributorArray(distributorArray.map((d, i) => {
        if (i !== n) return d;
        return data;
      }));
    }
  };

  return {
    setDistributorData,
    distributorsData: distributorArray
  };
};

const useDistributor = (props) => {
  const {
    locationsData, distributorsAdded, index, editDistributor
  } = props;


  const isAdd = distributorsAdded.length === index;
  const [distributorsList, setDistributorsList] = useState([]);

  const locs = locationsData.filter(l => !l.skip).map((l, i) => {
    if (isAdd) {
      return {
        ...l,
        check: false,
        dcn: '',
        dsr: {
          fullName: '',
          phoneNumber: '',
          email: '',
          dcn: ''
        }
      };
    }
    return distributorsAdded[index].locations[i];
  });

  const distributorsAddedIds = distributorsAdded.map(d => {
    return d.distributorName.value
  });

  const locationsCheckboxes = editDistributor.locations;

  const getDistributorsList = (search) => {
    const params = {
      search: search
    };
    distributorsApi.list(params).then(({ data }) => {
      setDistributorsList(data.filter(d => !distributorsAddedIds.includes(d.id)).map(d => {
        return {
          label: d.nickname,
          value: d.id
        };
      }));
    }).catch(err => {
      console.error('DistributorsApi list error:', err);
    });
  };

  const updateEditLocation = (index, data) => {
    locationsCheckboxes.setItem(index, data);
  };

  useEffect(() => {
    getDistributorsList('');
    if (isAdd) {
      editDistributor.name.set(null);
    } else {
      editDistributor.name.set(distributorsAdded[index].distributorName);
    }
    locationsCheckboxes.setItems(locs);
  }, []);

  return {
    distributorName: { set: editDistributor.name.set, value: editDistributor.name.value },
    locations: locationsCheckboxes.items, toggleLocation: locationsCheckboxes.toggleItem,
    setLocation: updateEditLocation, distributorsOptions: distributorsList,
    allLocations: { toggle: locationsCheckboxes.toggleAll, value: locationsCheckboxes.all },
    getDistributorsList
  }
};

const useLocation = (init, setData, index) => {
  const locationName = useInput.useText('', { value: init.locationName, set: (nv) => { setData(index, { ...init, locationName: nv }) } });
  const phoneNumber = useInput.useText('', { value: init.phoneNumber, set: (nv) => { setData(index, { ...init, phoneNumber: nv }) } });
  const locationAddress = useInput.useText('', { value: init.locationAddress, set: (nv) => { setData(index, { ...init, locationAddress: nv }) } });
  const city = useInput.useText('', { value: init.city, set: (nv) => { setData(index, { ...init, city: nv }) } });
  const [state, setState] = useState(init ? init.state : null);
  const zipcode = useInput.useText('', { value: init.zipcode, validate: validateZipcode, set: (nv) => { setData(index, { ...init, zipcode: nv }) } });
  const stateOptions = usState.map(s => { return { label: s.name, value: s.abbreviation } });

  useEffect(() => {
    if (init.state) {
      setState(init.state);
    }
  }, [init.state]);

  useEffect(() => {
    if (state) {
      setData(index, {
        ...init,
        state: state
      });
    }
  }, [state]);

  return {
    locationName, phoneNumber, locationAddress, city,
    state: { set: setState, value: state }, zipcode, stateOptions
  };
};

const useLocationsInformation = (howManyLocations) => {

  const emptyLocation = {
    locationName: '',
    phoneNumber: '',
    locationAddress: '',
    city: '',
    state: '',
    zipcode: ''
  };

  let locations = [];
  for (let i = 0; i < howManyLocations; i++) {
    locations.push(i);
  };

  const [locationArray, setLocationArray] = useState([emptyLocation]);

  const setLocationData = (n, data) => {
    setLocationArray(locations.map(i => {
      if (i !== n) {
        if (locationArray[i]) {
          return locationArray[i];
        } else {
          return emptyLocation;
        }
      } else {
        return data;
      }
    }));
  };

  return {
    locations, setLocationData, locationsData: locationArray
  };
};

const useLegalInformation = () => {
  const fullName = useInput.useText();
  const agreement = useInput.useCheckbox(false);

  return {
    fullName, agreement
  };
};

const useContent = (props) => {
  const {
    contactInformation, step, goTo, showLocation, showDistributor,
    distributors, locations, legalInformation
  } = props;


  const dispatch = useDispatch();
  const history = useHistory();
  const [editDistributorName, setEditDistributorName] = useState(null);
  const editDistributorLocations = useInput.useCheckboxGroup([]);
  const [errors, setErrors] = useState();
  const [dataCard, setDataCard] = useState(null)


  const validateDisabldDone = () => {
    if (nextTitle == "Done")
      if (dataCard && dataCard.complete) {
        return false;
      } else {
        return true;

      };
  };

  const handleNext = () => {
    if (step === 1) {
      const ci = contactInformation;
      const form = {
        firstName: ci.firstName.value,
        lastName: ci.lastName.value,
        title: ci.title.value ? ci.title.value.label : null,
        businessName: ci.businessName.value,
        locationAddress: ci.locationAddress.value,
        city: ci.city.value,
        state: ci.state.value ? ci.state.value.label : null,
        zipcode: ci.zipcode.value.toString(),
        phoneNumber: ci.phoneNumber.value,
        email: ci.email.value,
        confirmEmail: ci.confirmEmail.value,
        password: ci.password.value,
        confirmPassword: ci.confirmPassword.value,
        locations: locations.locationsData.filter((l, i) => showLocation > i).map(l => {
          return {
            locationName: l.locationName,
            phoneNumber: l.phoneNumber,
            locationAddress: l.locationAddress,
            city: l.city,
            state: l.state ? l.state.label : null,
            zipcode: l.zipcode.toString()
          }
        })
      };
      const constrains = {
        firstName: { presence: { allowEmpty: false }, length: { minimum: 3 } },
        lastName: { presence: { allowEmpty: false }, length: { minimum: 3 } },
        title: { presence: { allowEmpty: false } },
        businessName: { presence: { allowEmpty: false }, length: { minimum: 3 } },
        locationAddress: { presence: { allowEmpty: false }, length: { minimum: 3 } },
        city: { presence: { allowEmpty: false }, length: { minimum: 2 } },
        state: { presence: { allowEmpty: false } },
        zipcode: { presence: { allowEmpty: false }, numericality: true, length: { minimum: 5, message: 'is too short (minimum is 5 digits)' } },
        phoneNumber: { presence: { allowEmpty: false } },
        email: { presence: { allowEmpty: false }, email: true },
        confirmEmail: { equality: 'email' },
        password: { presence: { allowEmpty: false }, length: { minimum: 6 } },
        confirmPassword: { equality: 'password' },
        locations: {
          array: {
            length: 1,
            key: 'locationName',
            locationName: { presence: { allowEmpty: false }, length: { minimum: 3 } },
            phoneNumber: { presence: { allowEmpty: false } },
            locationAddress: { presence: { allowEmpty: false }, length: { minimum: 3 } },
            city: { presence: { allowEmpty: false }, length: { minimum: 2 } },
            state: { presence: { allowEmpty: false } },
            zipcode: { presence: { allowEmpty: false }, numericality: true, length: { minimum: 5, message: 'is too short (minimum is 5 digits)' } },
          }
        }
      };
      const result = validate(form, constrains, { format: "flat" });
      // const result = null;
      if (result) {
        setErrors(result);
      } else {
        setErrors(null);
        if (contactInformation.howManyLocations.value.value > 1 && showLocation < contactInformation.howManyLocations.value.value) {
          goTo(step, `?location=${showLocation + 1}`);
        } else {
          goTo(2, '');
        }
      }
    } else if (step === 2) {



      if (showDistributor) {
        // Save Distributor

        const form = {
          distributorName: editDistributorName ? editDistributorName.label : null,
          locations: editDistributorLocations.items.filter(l => l.check).map(l => {
            return {
              locationName: l.locationName,
              dsrEmail: l.dsr.email,
              dsrFullName: l.dsr.fullName
            }
          })
        };

        const constrains = {
          distributorName: { presence: { allowEmpty: false } },
          locations: {
            array: {
              length: 1,
              key: 'locationName',
              locationName: { presence: { allowEmpty: false } },
              dsrEmail: { presence: true, email: true },
              dsrFullName: { presence: { allowEmpty: false }, length: { minimum: 3 } }
            }
          }
        };
        const result = validate(form, constrains, { format: "flat" });
        if (result) {
          setErrors(result);
        } else {
          setErrors(null);
          const editDistributor = {
            distributorName: editDistributorName,
            locations: editDistributorLocations.items
          };

          distributors.setDistributorData(showDistributor - 1, editDistributor);
          goTo(step, '');
        }
      } else {
        goTo(3, '');

      }
    } else if (step == 3) {
      setErrors('');
      if (legalInformation.fullName.value == ''
        || !legalInformation.agreement.value) {
        setErrors(['Required field missing']);
        return;
      };
      goTo(4, '');

    } else if (step == 4) {

      const findDistributors = (locationName) => {
        const distributorsList = [];
        distributors.distributorsData.forEach(res => {
          res.locations.forEach(loc => {
            if (locationName == loc.locationName && loc.check) {
              distributorsList.push({
                id: res.distributorName.value,
                nickname: res.distributorName.label,
                dcn: loc.dsr.dcn,
                email: loc.dsr.email,
                phoneNumber: loc.dsr.phoneNumber,
                name: loc.dsr.fullName
              });
            };
          });
        });

        return distributorsList;
      };


      const valuesSend = {
        paymentMethod: {
          token: dataCard.token,
          cardId: dataCard.cardId
        },
        companyId: "1",
        firstName: contactInformation.firstName.value,
        lastName: contactInformation.lastName.value,
        title: contactInformation.title.value.label,
        businessName: contactInformation.businessName.value,
        businessAddress: contactInformation.locationAddress.value,
        businessCity: contactInformation.city.value,
        businessState: contactInformation.state.value.value,
        businessPostcode: contactInformation.zipcode.value,
        phoneNumber: contactInformation.phoneNumber.value,
        email: contactInformation.email.value,
        password: contactInformation.password.value,
        passwordRepeat: contactInformation.password.value,
        totalReportingLocations: contactInformation.howManyLocations.value.value,
        locations: locations.locationsData.map(loc => {
          return {
            nickname: loc.locationName,
            phoneNumber: loc.phoneNumber,
            address: loc.locationAddress,
            city: loc.city,
            state: loc.state.value,
            zipcode: loc.zipcode,
            distributors: findDistributors(loc.locationName)
          }
        }),
      }

      singupApi.addSingup(valuesSend).then(result => {
        openModalConfirm();
      }, err => {
        const props = {
          header: 'Failet',
          message: `<p style='color: #535a5d;
          font-size: 13px;
          font-weight: bold;'>${err.data.message}</p>`,
        };
        dispatch(showModal(SimpleModal, props));
      });

    }
  };
  const openModalConfirm = () => {
    const props = {
      header: 'Confirmation',
      message: `Singup Success`,
      onOk: () => {
        history.push('/login');
      }
    };
    dispatch(showModal(ConfirmationModal, props));
  }
  const handleBack = () => {
    if (step === 1 && showLocation == 1) {
      history.push('/login');
      return;
    };
    if (step === 1 && contactInformation.howManyLocations.value.value > 1 && showLocation > 1 && (showLocation - 1) > 0) {
      setErrors(null);
      goTo(step, `?location=${showLocation - 1}`, true);
    } else {
      setErrors(null);
      goTo(step === 2 && showDistributor ? step : step - 1, step === 2 ? (showDistributor ? `` : `?location=${showLocation}`) : '', true);
    }
  };

  const handleSkip = () => {
    if (step === 1) {
      // On Location
      locations.setLocationData(showLocation - 1, {
        ...locations.locationsData[showLocation - 1],
        skip: true
      });
    }
    goTo(step + 1, '');
  }

  const backTitle = showDistributor ? 'Cancel' : 'Back';
  let nextTitle = step === 2 ? (showDistributor ? 'Save' : 'Skip') : 'Next';
  if (step === 4) {
    nextTitle = 'Done'
  } else if (step === 2 && distributors.distributorsData.length > 0) {
    nextTitle = 'Next'
  };
  const showSkip = step === 1 && showLocation > 1;

  return {
    handleNext, handleBack, nextTitle, backTitle,
    showSkip, handleSkip, showSkip, errors,
    editDistributor: {
      name: { set: setEditDistributorName, value: editDistributorName },
      locations: { ...editDistributorLocations }
    }, setDataCard, validateDisabldDone
  };
};

export default {
  useContactInformation, useLocationsInformation, useLocation, useSteps,
  useDistributor, useContent, useDistributorInformation, useLegalInformation
};