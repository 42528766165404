import useInput from "../../SharedEffects/useInput";
import { useState, useEffect } from "react";
import comparisonViewsApi from "../../../api/comparisonViewsApi";

const useApi = () => {
  const search = useInput.useText('');
  const [ page, setPage ] = useState(1);
  const [ loadMore, setLoadMore] = useState(true);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getItems = (page, search, loadMoreForce) => {
    if (page && (loadMore || loadMoreForce)) {
      setIsLoading(true);
      const params = {
        limit: 30,
        page: page
      };
      comparisonViewsApi.getAllGroups(params, search).then(res => {
        setIsLoading(false);
        const loadMoreNew = res._meta.pageCount > res._meta.currentPage;
        const pageNew = loadMoreNew  ? page + 1 : 1;
        setLoadMore(loadMoreNew);
        if (page === 1) {
          setItems(res.data);
        } else {
          setItems(items.concat(res.data));
        }
        setPage(pageNew);
      });
    }
  };

  const onClear = () => {
    search.clearValue();
    setPage(1);
    setLoadMore(true);
    setIsLoading(false);
    // Set reset values, values get update in the next render
    getItems(1, '', true);
  };

  const onSearch = (s) => {
    setPage(1);
    setLoadMore(true);
    setIsLoading(false);
    setItems([]);
    // Set reset values, values get update in the next render
    getItems(1, s, true);
  };

  useEffect(() => {
    getItems(1, '');
  }, []);

  return {
    page, items, getInventoryItems: getItems, loadMore,
    isLoading, onClear, onSearch, search
  };
};

export default {
  useApi
}