import React from 'react';
import ShowIf from '../ShowIf/ShowIf';
import { useSelector } from 'react-redux';
import './Spinner.scss';

const SpinnerOnline = () => {
  const show = useSelector(state => state.userInterface.spinner);
  const image = useSelector(state => state.company.spinner);

  return (
    <ShowIf show={show}>
      <div className='mask'>
        <div className='spinner'>
          <img className='spinner-img' src={image} alt='spinner' />
        </div>
      </div>
    </ShowIf>
  );
};

export default SpinnerOnline;