import React, { useEffect } from 'react';
import { Row, Col, Input, Card } from 'reactstrap';
import numeral from 'numeral';
import { FaChevronRight } from 'react-icons/fa';
import { colors } from '../../../../../../config/constants';
import useInput from '../../../../../../SharedComponents/SharedEffects/useInput';

const RecipeIngredientsItemRowMobile = (props) => {
  const {
    image, name, brand, amount, uom, price, updateAmount, data, openDetails
  } = props;

  const { value : amountValue, set: setAmount } = useInput.useText(amount, { isFraction: true });

  const onBlur = () => {
    updateAmount(data.recipe_id, data.id, data.ingredientType, data.ingredientId, amountValue);
  };
  useEffect(() => {
    setAmount(amount ? amount : '');
  }, [amount]);

  return (
    <Card className='mx-2 my-1 border-0'>
      <Row className='m-0 border-bottom align-items-center py-1'>
        <Col className='w-image'>
          <img src={image} className='no-image'/>
        </Col>
        <Col className='p-0'>
          <Col className='p-0 text-main text-capitalize'>{name}</Col>
          <Col className='p-0 text-gray'>{brand}</Col>
          <Col className='p-0 w-80px mt-2 text-truncate text-gray'>
            {numeral(price).format('$0,0.00')}
          </Col>
        </Col>
        <Col className='text-gray w-fc clickable' onClick={openDetails}>
          <FaChevronRight size={18} color={colors.lightBlueText} />
        </Col>
      </Row>
      <Row className='m-0 align-items-center py-3'>
        <Col className='w-image'></Col>
        <Col className='p-0 text-gray'>
          {uom}
        </Col>
        <Col className='w-squared-input'>
          <Input type='text' value={amountValue} onChange={setAmount} onBlur={onBlur} />
        </Col>
      </Row>
    </Card>
  );
};

export default RecipeIngredientsItemRowMobile;