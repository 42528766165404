import axios from "axios";

const getAnalytics = (filters) => {
  return axios.get("analytics", { params: filters });
};

const getAnalyticsDetail = (filters) => {
  return axios.get("analytics/detail", { params: filters });
};

const getStatistics = (filters) => {
  return axios.get("analytics/lookup", { params: filters });
};

const getItems = (filterOptions, page = 1) => {
  const params = {
    filterOptions: JSON.stringify(filterOptions),
    page,
  };
  return axios.get("items", { params });
};

const getGreatestSavings = (page = 1, limit = 5) => {
  const params = {
    page,
    limit,
  };
  return axios.get("items/get-savings-for-purchased-items", { params });
};

const getSavingsItemPrice = (itemDistributorIds) => {
  return axios.post("items/get-savings-items-price", { itemDistributorIds });
};

const getPurchasedItems = (page = 1, limit = 3) => {
  const params = {
    page,
    limit,
  };

  return axios.get("items/get-last-purchased", { params });
};

export default {
  getAnalyticsDetail,
  getStatistics,
  getAnalytics,
  getItems,
  getGreatestSavings,
  getSavingsItemPrice,
  getPurchasedItems
};
