import { sortOptions } from "../../config/constants";

export default function reducer(state = {
  view: '',
  inventory: {
    items: [],
    pageCount: 1,
    totalPageCount: 1,
    list: [],
    listSelected: null,
    sortBy: sortOptions.inventoryView.filter(iv => iv.default)[0],
    onHandTotal: 0,
    search: ''
  },
  list: {
    items: [],
    pageCount: 1,
    loadMore: true,
    isLoading: false,
    sortByOptions:sortOptions.listView,
    sortBy: sortOptions.listView.filter(lw => lw.default === 1)[0],
    product: 'ALL',
    search: '',
    filters: {
      purchasedOnly: false,
      distributorIds: [],
      shoppingIds: [],
      pastOrderIds: [],
    }
  }
}, action) {
  switch (action.type) {
    case 'SET_VIEW':
      return {
        ...state,
        view: action.payload
      };
    case 'SET_INVENTORY_LIST':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          list:action.payload[0],
          listSelected: action.payload[1] ? action.payload[1] : state.inventory.listSelected
        }
      };
    case 'SET_INVENTORY_LIST_SELECTED':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          listSelected: action.payload
        }
      };
    case 'INSERT_INVENTORY_LIST':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          list: state.inventory.list.slice(state.inventory.list.length,0,action.payload.list)
        }
      };
    case 'EDIT_INVENTORY_LIST':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          list: state.inventory.list.map(l => {
            if (l.id !== action.payload.listId) return l;
            return {
              ...l,
              ...action.payload.value
            };
          })
        }
      };
    case 'REMOVE_INVENTORY_LIST':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          list: state.inventory.list.filter(l => l.id !== action.payload)
        }
      };
    
    case 'SET_INVENTORY_ITEMS':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          items: action.payload
        }
      };
    case 'SET_INVENTORY_ITEMS_PAGE':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          pageCount: action.payload
        }
      };
    case 'SET_INVENTORY_ITEMS_PAGE_TOTAL':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          totalPageCount: action.payload
        }
      };
    case 'INSERT_INVENTORY_GROUP':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          items: [
            ...state.inventory.items.slice(0, action.payload.index),
            action.payload.group,
            ...state.inventory.items.slice(action.payload.index)
          ]
        }
      };
    case 'REMOVE_INVENTORY_GROUP':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          items: state.inventory.items.filter(g => g.id !== action.payload)
        }
      };
    case 'EDIT_ONE_INVENTORY_GROUP':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          items: state.inventory.items.map(g => {
            if (g.id !== action.payload.groupId) return g;
            return {
              ...g,
              ...action.payload.value
            };
          })
        }
      };
    case 'SORT_INVENTORY_GROUPS':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          items: state.inventory.items.sort((a, b) => {
            return a.sort > b.sort ? 1 : -1
          })
          .map( (g, i) => {
            return {
              ...g,
              sort: i
            }
          })
        }
      };
      case 'SORT_INVENTORY_GROUPS_NEW':
        return {
          ...state,
          inventory: {
            ...state.inventory,
            items: state.inventory.items.sort((a, b) => {
              return a.sort > b.sort ? 1 : -1
            })
            .map( (g, i) => {
              if(i >= action.payload.index){ i++; }
              return {
                ...g,
                sort: i
              }
            })
          }
        };
    case 'REMOVE_INVENTORY_GROUP_ITEM':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          items: state.inventory.items.map(g => {
            if (g.id !== action.payload.groupId) return g;
            return {
              ...g,
              items: g.items.filter(i => i[action.payload.idLabel] !== action.payload.itemId)
            }
          })
        }
      };
    case 'EDIT_ONE_INVENTORY_GROUP_ITEM':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          items: state.inventory.items.map(g => {
            if (g.id !== action.payload.groupId) return g;
            return {
              ...g,
              items: g.items.map(i => {
                if (i[action.payload.idLabel] !== action.payload.itemId) return i;
                return {
                  ...i,
                  ...action.payload.value
                };
              })
            };
          })
        }
      };
    case 'SET_INVENTORY_SORT':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          sortBy: action.payload
        }
      };
    case 'SET_INVENTORY_SEARCH':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          search: action.payload
        }
      };
    case 'SET_ON_HAND_TOTAL':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          onHandTotal: action.payload
        }
      };
    case 'SET_LIST_ITEMS':
      return {
        ...state,
        list: {
          ...state.list,
          items: action.payload
        }
      };
    case 'SET_LIST_PAGE_COUNT':
      return {
        ...state,
        list: {
          ...state.list,
          pageCount: action.payload
        }
      };
    case 'SET_LIST_LOAD_MORE':
      return {
        ...state,
        list: {
          ...state.list,
          loadMore: action.payload
        }
      };
    case 'SET_LIST_IS_LOADING':
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: action.payload
        }
      };
    case 'SET_LIST_SEARCH':
      return {
        ...state,
        list: {
          ...state.list,
          search: action.payload
        }
      };
    case 'SET_LIST_SORT':
      return {
        ...state,
        list: {
          ...state.list,
          sortBy: action.payload
        }
      };
    case 'SET_LIST_SORT_OPTIONS':
      return {
        ...state,
        list: {
          ...state.list,
          sortByOptions: action.payload
        }
      };
    case 'SET_LIST_FILTERS':
      return {
        ...state,
        list: {
          ...state.list,
          filters: {
            purchasedOnly: action.payload.purchasedOnly,
            distributorIds: action.payload.distributorIds,
            shoppingIds: action.payload.shoppingIds,
            pastOrderIds: action.payload.pastOrderIds
          }
        }
      };
    
    case 'CLEAR_ITEMS_DATA':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          items: [],
          pageCount: 1,
          totalPageCount: 1,
          sortBy: sortOptions.inventoryView.filter(iv => iv.default)[0],
          // onHandTotal: 0,
          search: ''
        },
        list: {
          ...state.list,
          items: [],
          pageCount: 1,
          loadMore: true,
          isLoading: false,
          sortByOptions:sortOptions.listView,
          sortBy: sortOptions.listView.filter(lw => lw.default === 1)[0],
          product: 'ALL',
          search: '',
          filters: {
            purchasedOnly: false,
            distributorIds: [],
            shoppingIds: [],
            pastOrderIds: [],
          }
        }
      };

    case 'CLEAR_ALL_ITEMS_DATA':
      return {
        view: '',
        inventory: {
          items: [],
          pageCount: 1,
          totalPageCount: 1,
          list: [],
          listSelected: null,
          sortBy: sortOptions.inventoryView.filter(iv => iv.default)[0],
          onHandTotal: 0,
          search: ''
        },
        list: {
          items: [],
          pageCount: 1,
          loadMore: true,
          isLoading: false,
          sortByOptions:sortOptions.listView,
          sortBy: sortOptions.listView.filter(lw => lw.default === 1)[0],
          product: 'ALL',
          search: '',
          filters: {
            purchasedOnly: false,
            distributorIds: [],
            shoppingIds: [],
            pastOrderIds: [],
          }
        }
      };
      
    default:
      return state;
  }
};