import { useState, useEffect } from "react";
import useInput from "../../SharedEffects/useInput";
import { packSizeUomsCustomItem, unitsOfMeasure } from "../../../config/constants";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { showModal } from "../../../reducers/modals/modalsActions";
import OptionsModal from "../OptionsModal/OptionsModal";
import useConnection from '../../OfflineMode/useConnection';
import AlertModal from '../AlertModal/AlertModal';

const useForm = (init) => {
  const dispatch = useDispatch();
  const packSizeUomOptions = packSizeUomsCustomItem.map(u => {
    return {
      label: u.shorthand,
      value: u
    };
  });

  const unitOfMeasureOptions = unitsOfMeasure.map(u => {
    return {
      label: u.name,
      value: u
    };
  });

  const quantity = useInput.useText('', {isNumber: {min: 1, allowEmpty: true}});
  const description = useInput.useText(init ? init.description : '');
  const unitMeasure = useInput.useObject(init ? unitOfMeasureOptions.filter( u => u.label === init.unitMeasure)[0] : unitOfMeasureOptions[0]);
  const distributor = useInput.useText(init ? init.distributor : '');
  const price = useInput.useText(init ? init.price : '', {isNumber: {min: 0, allowEmpty: true}});
  const packSize = {
    outerPack: useInput.useText(init ? init.unitQuantity : '', {isNumber: {min: 0, allowEmpty: true}}),
    innerPack: useInput.useText(init ? init.unitSize : '', {isNumber: {min: 0, allowEmpty: true}}),
    uom: useInput.useObject(packSizeUomOptions[0])
  };
  const din = useInput.useText(init ? init.din : '');
  const brand = useInput.useText(init ? init.brand : '');

  const openUnitOfMeasure = () => {
    const optionsProps = {
      options: unitOfMeasureOptions.map(u => {
        return {
          ...u,
          selected: u.label === unitMeasure.value.label,
          onClick: () => { unitMeasure.set(u) }
        }
      })
    };
    dispatch(showModal(OptionsModal, optionsProps));
  };

  const openPackSizeUom = () => {
    const optionsProps = {
      options: packSizeUomOptions.map(u => {
        return {
          ...u,
          selected: u.label === packSize.uom.value.label,
          onClick: () => { packSize.uom.set(u) }
        }
      })
    };
    dispatch(showModal(OptionsModal, optionsProps));
  };

  return {
    description: { value: description.value, onChange: description.set },
    quantity : { value: quantity.value, onChange: quantity.set},
    unitMeasure: {
      value: unitMeasure.value,
      onChange: !isMobile && unitMeasure.set,
      options: unitOfMeasureOptions,
      components: isMobile && {Menu: () => null},
      onMenuOpen: isMobile && openUnitOfMeasure
    },
    distributor: { value: distributor.value, onChange: distributor.set },
    price: { value: price.value, onChange: price.set },
    packSize: {
      outerPack: { value: packSize.outerPack.value, onChange: packSize.outerPack.set },
      innerPack: { value: packSize.innerPack.value, onChange: packSize.innerPack.set },
      uom: {
        value: packSize.uom.value,
        onChange: !isMobile && packSize.uom.set,
        options: packSizeUomOptions,
        components: isMobile && {Menu: () => null, IndicatorsContainer: () => null},
        onMenuOpen: isMobile && openPackSizeUom
      }
    },
    din: { value: din.value, onChange: din.set },
    brand: { value: brand.value, onChange: brand.set },
  }
};

const useModalConnection = () => {
  const [disabled, setDisabled] = useState(false);
  let {status} = useConnection.useStatus();

  useEffect(() => {
    setDisabled(status ? false : true);
  }, [status]);

  return { disabled, setDisabled }
};

export default {
  useForm, useModalConnection
};