import React from 'react';
import { Row, Col } from 'reactstrap';

const ItemDetailsImage = (props) => {
  const {
    image, name
  } = props;

  return (
    <Row className='pb-3 align-items-center'>
      <Col className='w-image pr-0'>
        <img src={image} alt='item' className='no-image' />
      </Col>
      <Col className='font-weight-bold text-capitalize'>
        {name}
      </Col>
    </Row>
  );
};

export default ItemDetailsImage;