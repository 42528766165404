export const spinner = (bool) => {
  return {
    type: 'SET_SPINNER',
    payload: bool
  };
};

export const toggleSidebar = () => {
  return {
    type: 'TOGGLE_SIDEBAR',
  };
};

export const closeSidebar = () => {
  return {
    type: 'CLOSE_SIDEBAR',
  };
};

export const setSize = (w, h) => {
  return {
    type: 'SET_SIZE',
    payload: {
      width: w,
      height: h
    }
  };
};
