import React, { Fragment, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import SignUpInput from '../Subcomponents/SignUpInput';
import { isMobile } from 'react-device-detect';

const SignUpContactInformation = (props) => {
  const {
    titleOptions, stateOptions, locationOptions,
    firstName, lastName, title, businessName,
    locationAddress, city, state, zipcode, phoneNumber,
    email, confirmPassword, confirmEmail, password,
    howManyLocations, setLocationData
  } = props;

  useEffect(() => {
    setLocationData(0, {
      locationName: businessName.value,
      phoneNumber: phoneNumber.value,
      locationAddress: locationAddress.value,
      city: city.value,
      state: state.value,
      zipcode: zipcode.value
    });
  }, [businessName.value, locationAddress.value, city.value, state.value, zipcode.value, phoneNumber.value]);


  return (
    <Fragment>
      <Row>
        <Col className={isMobile ? 'font-weight-bold mb-3 text-center' : 'font-weight-bold mb-3'}>Restaurant Contact Information</Col>
      </Row>
      <Row className='mb-3'>
        <SignUpInput label='Contact First Name *' placeholder='First Name' {...firstName} />
        <SignUpInput label='Contact Last Name *' placeholder='Last Name' {...lastName} />
        <SignUpInput label='Title *' options={titleOptions} isDropdown {...title} />
      </Row>
      <Row className='mb-3'>
        <SignUpInput label='Official Business Name *' placeholder='Business Name' {...businessName} />
        <SignUpInput label='Location Address *' placeholder='Location Address' {...locationAddress} />
      </Row>
      <Row className='mb-3'>
        <SignUpInput label='City *' placeholder='City' {...city} />
        <SignUpInput label='State *' options={stateOptions} isDropdown {...state} />
        <SignUpInput label='Zipcode *' placeholder='12345' {...zipcode} />
      </Row>
      <Row className='mb-3'>
        <SignUpInput label='Phone Number *' placeholder='(123) 123-1234' {...phoneNumber} />
        <SignUpInput label='Email *' placeholder='Email' {...email} />
        <SignUpInput label='Confirm Email *' placeholder='Confirm Email' {...confirmEmail} />
      </Row>
      <Row className='mb-3'>
        <SignUpInput label='Password *' placeholder='Password' {...password} />
        <SignUpInput label='Confirm Password *' placeholder='Confirm Password' {...confirmPassword} />
      </Row>
      <Row className='mb-3'>
        <SignUpInput xs={6} label='How Many Locations? *' options={locationOptions} isDropdown {...howManyLocations} />
      </Row>
    </Fragment>
  );
};

export default SignUpContactInformation;