import React, { Fragment, useState } from 'react';
import { Row, Col, Button, Container } from 'reactstrap';
import InputSearch from '../../../../../SharedComponents/InputSearch/InputSearch';
import Select from 'react-select';
import CustomInput from 'reactstrap/lib/CustomInput';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { mobileClassName, colors } from '../../../../../config/constants';
import { useDispatch } from 'react-redux';
import { saveExpandAll, textSearchInventory } from '../../../../../reducers/inventoyView/inventoryViewActions';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { FaSortAmountDown, FaRegClipboard } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

const HeaderReviewInventory = (props) => {

    const { setShowOnlyMissingHv, search, showOnlyMissing } = props;
    const dispatch = useDispatch();
    const [showItemHand, setShowItemHand] = useState(false);
    const [expandAll, setExpandAll] = useState(false);
    const history = useHistory();
    const options = [
        { value: true, label: 'A-Z' },
        { value: false, label: 'Z-A' },
    ];

    const onSelectSort = (value) => {
        // dispatch(setAlphaSort(value));
    };

    const onSearch = (params) => {
        setExpandAll(true);
        dispatch(saveExpandAll(true));
        dispatch(textSearchInventory(search.value));
    };


    const changeCheckItemHand = () => {
        setShowItemHand(!showItemHand)
        setShowOnlyMissingHv(!showItemHand)
    };

    const clickExpand = () => {
        setExpandAll(!expandAll);
        const newExpand = !expandAll;
        dispatch(saveExpandAll(newExpand));
    };

    const onClear = () => {
        search.clearValue();
        dispatch(textSearchInventory(''));
    };

    const goToInventoryView = (params) => {
        history.push('/place-order/inventory');
    };



    // <InputSearch value={search.value} onChange={search.set} clearValue={onClear} onEnter={onSearch} />

    return (
        <Fragment>
            <ShowIf show={!isMobile}>
                <Row className={'header align-items-center ' + mobileClassName}>
                    <Col md="3">
                        <InputSearch type='text' placeholder='Search...'
                            value={search.value} onChange={search.set}
                            clearValue={onClear} onEnter={onSearch}
                        />
                    </Col>
                    <Col md="3" className="d-flex align-items-center">
                        <Col className='w-fc text-light-blue p-0'>Sort generic items by:</Col>
                        <Col className='w-200px pr-0'>
                            <Select className='fb-select' classNamePrefix='select' options={options} onChange={onSelectSort} />
                        </Col>
                    </Col>
                    <Col md="4">
                        <ShowIf show={showOnlyMissing}>
                            <CustomInput type='checkbox' checked={showItemHand} onChange={changeCheckItemHand} id="payTerms" label='Show only items with missing on hand value' />
                        </ShowIf>
                    </Col>
                    <Col md="2" className="d-flex justify-content-end">
                        <Button size='square' color='white' onClick={clickExpand} className="mr-3">
                            {
                                expandAll ?
                                    <MdFullscreen size={20} color={colors.lightBlueText} />
                                    :
                                    <MdFullscreenExit size={20} color={colors.lightBlueText} />
                            }
                        </Button>
                        {/* <Button color='white' className='d-flex align-items-center ' style={{ fontSize: 12 }} onClick={goToInventoryView} >
                            <FaRegClipboard size={20} color={colors.lightBlueText} />
                            <span>&nbsp;&nbsp;Inventory View</span>
                        </Button> */}
                    </Col>

                </Row>
            </ShowIf>

            <ShowIf show={isMobile}>
                <Container>
                    <Row className="m-0 mt-2 mb-2">
                        <Col xs="7" className="pr-0">
                            <InputSearch type='text' placeholder='Search...'
                                value={search.value} onChange={search.set}
                                clearValue={onClear} onEnter={onSearch}
                            />
                        </Col>
                        <Col xs="1" className="d-flex align-items-center p-0">
                            <Col className='w-fc pr-0'>
                                <Button size='square' color='white'>
                                    <FaSortAmountDown size={20} color={colors.lightBlueText} />
                                </Button>
                            </Col>
                        </Col>
                        <Col xs="1" className="d-flex align-items-center mr-4">
                            <Col className='w-fc pr-0'>
                                <Button size='square' color='white' onClick={clickExpand}>
                                    {
                                        expandAll ?
                                            <MdFullscreen size={20} color={colors.lightBlueText} />
                                            :
                                            <MdFullscreenExit size={20} color={colors.lightBlueText} />
                                    }
                                </Button>
                            </Col>
                        </Col>
                        <Col xs="1" className="d-flex align-items-center p-0 ml-2">
                            <Col className='w-fc pr-0'>
                                <Button size='square' color='white' onClick={goToInventoryView}>
                                    <FaRegClipboard size={20} color={colors.lightBlueText} />
                                </Button>
                            </Col>
                        </Col>
                    </Row>
                    <ShowIf show={showOnlyMissing}>
                        <Row className="m-0 mt-3">
                            <Col xs="12" className="pr-0">
                                <CustomInput type='checkbox' checked={showItemHand} onChange={changeCheckItemHand} id="payTerms" label='Show only items with missing on hand value' />
                            </Col>
                        </Row>
                    </ShowIf>

                </Container>

            </ShowIf>
        </Fragment>
    );
};

export default HeaderReviewInventory;
