import React, { useState } from "react";
import { Row, Col, Input } from "reactstrap";
import { isMobile } from "react-device-detect";
import numeral from "numeral";
import { itemCart } from "../../../../../config/constants";
import MinusButton from "../../../../../SharedComponents/MinusButton/MinusButton";
import PlusButton from "../../../../../SharedComponents/PlusButton/PlusButton";
import ShowIf from "../../../../../SharedComponents/ShowIf/ShowIf";
import useShoppingCartItem from "../../../../../SharedComponents/ShoppingCartItem/useShoppingCartItem";
import useShoppingCartItemInterface from "../../../../../SharedComponents/ShoppingCartItem/useShoppingCartItemInterface";
import noImage from "../../../../../assets/images/no-image.png";
import NextButton from "../../../../../SharedComponents/NextButton/NextButton";

const SavingSuggestion = (props) => {
  const { item, parentItem, groupTotalProps, useShoppingCartItemProps } = props;
  const itemImage = item.brandImageUrl || item.brandImage || item.imageUrl || noImage;
  const [isAddToCartOpen, setAddToCartOpen] = useState(false);
  const { caseQuantity, setCaseQuantity } =
    useShoppingCartItemProps || useShoppingCartItem.useCartItemMerge(item);
  const cartAmount = caseQuantity;
  const setCartAmount = setCaseQuantity;
  const { updateCart } = useShoppingCartItemInterface.useCartUpdate(
    setCartAmount,
    groupTotalProps
  );
  const { valid } = useShoppingCartItem.useCartValidate();
  const { disabled } = useShoppingCartItemInterface.useInputConnection();

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      updateCart(cartAmount, item, setCartAmount, cartAmount, parentItem);
    }
  };

  const cartAmountHandler = (e) => {
    if (!valid(e.target.value)) {
      return;
    }
    updateCart(e.target.value, item, setCartAmount, cartAmount, parentItem);
  };

  const cartAmountClick = (newCartAmount) => {
    if (!valid(newCartAmount)) {
      return;
    }
    updateCart(newCartAmount, item, setCartAmount, cartAmount, parentItem);
  };

  const minusImg = (
    <MinusButton
      onClick={() => {
        cartAmountClick(cartAmount - 1);
      }}
      isBlue
      isActive={cartAmount >= itemCart.min}
      disabled={disabled}
    />
  );

  const plusImg = (
    <PlusButton
      onClick={() => {
        cartAmountClick(cartAmount + 1);
      }}
      isBlue
      isActive={cartAmount >= itemCart.min && cartAmount <= itemCart.max}
      disabled={disabled}
    />
  );

  return (
    <>
      <Row className="item-bookmark-float">
        <Col xs="auto" className="item-savings-amount">
          Save {numeral(item.savings).format("$0,0.00")} per
          {" " + item.unitMeasure}!
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Row>
            <Col xs={12} sm={4} md={2} className="item-image">
              <img src={itemImage} alt={item.description || item.name} />
            </Col>

            <Col xs={12} sm={8} md={10} className="item-details">
              <Row>
                <Col xs={9} sm={10}>
                  <Row className="item-name">
                    <Col xs="12">{item.description || item.name}</Col>
                  </Row>
                  <Row className="item-manufacturer-details">
                    <Col xs="12" md="6" className="item-manufacturer-name">
                      {item.brand && item.brand.trim()
                        ? item.brand.trim()
                        : "-"}
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                      className="item-manufacturer-distributor"
                    >
                      {item.distributor}
                    </Col>
                  </Row>
                  <Row className="item-package-details">
                    <Col xs="12" md="6" className="item-package-price">
                      {numeral(item.price).format("$0,0.00") +
                        " " +
                        item.unitMeasure}
                    </Col>
                    <Col xs="12" md="6" className="item-package-size">
                      {item.packSize}
                    </Col>
                  </Row>
                </Col>
                <Col xs={3} sm={2} className="icon-wrapper">
                  <PlusButton
                    isActive={false}
                    onClick={() => setAddToCartOpen(true)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Row className="item-estimated-savings">
        <Col xs={12}>
          Estimated Yearly Savings: {numeral(item.yearlySavings).format("$0,0")}
        </Col>
      </Row> */}

      <ShowIf show={isAddToCartOpen}>
        <div className="item-savings-add-to-cart-wrapper">
          <Col
            className={
              "w-fc pl-0 justify-content-end" + (isMobile ? " pr-1" : " ")
            }
          >
            {minusImg}
          </Col>
          <Col className="p-0 justify-content-center w-squared-input">
            <Input
              type="text"
              pattern="[0-9]*"
              className={isMobile ? "input p-0 text-center" : "input"}
              value={cartAmount}
              onChange={cartAmountHandler}
              onKeyPress={handleKeypress}
              placeholder="0"
              disabled={disabled ? "disabled" : ""}
            />
          </Col>
          <Col
            className={
              "col w-fc justify-content-left" + (isMobile ? " px-1" : "")
            }
          >
            {item.unitMeasure}
          </Col>
          <Col className="w-fc p-0 justify-content-start pl-3">{plusImg}</Col>
          <Col className="w-fc p-0 justify-content-start pl-3">
            <NextButton
              isBlue
              isActive={
                cartAmount >= itemCart.min && cartAmount <= itemCart.max
              }
              onClick={() => setAddToCartOpen(false)}
            />
          </Col>
        </div>
      </ShowIf>
    </>
  );
};

export default SavingSuggestion;
