import { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import comparisonViewsApi from '../../api/comparisonViewsApi';
import { saveInventoryOnHandTotal } from '../../reducers/items/itemsActions';
import { debounce } from 'lodash';
import { delay, processStatus, onHandTypes } from '../../config/constants';
import useConversion from '../Conversion/useConversion';
import { InventoryContext } from "../../Views/MainApp/Views/PlaceOrder/PlaceOrderInventory/PlaceOrderInventoryItem/context/InventoryContext";
import { showModal } from "../../reducers/modals/modalsActions";
import AlertModal from '../Modals/AlertModal/AlertModal';
import { useHistory } from "react-router-dom";
import { spinner } from '../../reducers/userInterface/userInterfaceActions';
import { saveInventoryEditAll } from '../../reducers/inventoyView/inventoryViewActions';

const useDebounce = (callback) => {
  const d = callback;
  const callbackfunc = useCallback(debounce(d, delay.onHand), []);
  return [callbackfunc];
};

const useOnHandUpdate = (onHandProps, setOnHandAmount, groupTotalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const listSelected = useSelector(({ items }) => items.inventory.listSelected);
  const lists = useSelector(({ items }) => items.inventory.list);
  const [updating, setUpdating] = useState(processStatus.start);
  const [responses, setResponses] = useState(null);
  const onHandTotal = useSelector(({ items }) => items.inventory.onHandTotal);

  const { comparisonViewId, groupId, onHandType,
    itemDistributorId, isCustomItem, genericCustomItemId, isSubRecipe, subRecipeId,
    unitQuantity, unitSize, purchasedPrice, unitToConvert, isCustomPackSize, packSizeUom } = onHandProps;

  const price = purchasedPrice;
  const { totalOnHandAmt,
    setTotalOnHandAmt,
    setTotalGroupSpent,
    setTotalComparisonView,
    setTotalOnHandAmtShowOnlyMissing,
    setTotalComparisonViewShowMissing,
    setTotalOnHandTotalShowMissing,
    showOnlyMissingHv } = groupTotalProps;

  const inventoryContext = useContext(InventoryContext);
  const { setOH, setOHUom, setOHPack,
    setTotalOH, setTotalOHAmount,
    setCollectiveOH, setCollectiveOHUom,
    totalCase, setTotalCase,
    totalPack, setTotalPack,
    totalUnit, setTotalUnit } = inventoryContext;

  useEffect(() => {

  }, [])

  const updateTotals = () => {

    comparisonViewsApi.getGroupTotals(comparisonViewId, groupId).then((res) => {

      const totalOHDiff = Math.round((Math.abs(parseFloat(res.data.totalOnHandAmount) - parseFloat(totalOnHandAmt))) * 100) / 100;


      const calcOnHandTotal = responses.operation === 'add' ? (Math.round(onHandTotal * 100) / 100) + totalOHDiff : (Math.round(onHandTotal * 100) / 100) - totalOHDiff;
      const newOnHandTotal = (calcOnHandTotal < 0) ? 0 : calcOnHandTotal;

      if (onHandType === onHandTypes.cs) {
        setOH(responses.newVal);
      } else if (onHandType === onHandTypes.pack) {
        setOHPack(responses.newVal);
      } else if (onHandType === onHandTypes.uom) {
        setOHUom(responses.newVal);
      };

      // console.log(responses)

      setTotalOH(responses.totalOH);
      setTotalOHAmount(responses.totalOHAmt);
      setCollectiveOH(responses.collectiveOH);
      setCollectiveOHUom(responses.collectiveOHU);
      setTotalOnHandAmt(res.data.totalOnHandAmount);
      setTotalGroupSpent(res.data.purchased);

      if (!showOnlyMissingHv) {
        if (setTotalOnHandAmtShowOnlyMissing)
          setTotalOnHandAmtShowOnlyMissing(res.data.totalOnHandAmount);
        if (setTotalComparisonViewShowMissing)
          setTotalComparisonViewShowMissing(res.data.comparisonViewTotalOnHandAmount);
        if (setTotalOnHandTotalShowMissing)
          setTotalOnHandTotalShowMissing(newOnHandTotal)
      }

      if (setTotalComparisonView) setTotalComparisonView(res.data.comparisonViewTotalOnHandAmount);

      dispatch(saveInventoryOnHandTotal(newOnHandTotal));

    }).catch(err => console.error(err));

  };


  const update = (newOnHandAmount, prevOnHandAmount, OH, OHUom, OHPack) => {
    //checking if others are taking inventory offline/ udpdating values online
    const selected = lists.filter(l => l.id === listSelected.id)[0];
    dispatch(spinner(false));
    comparisonViewsApi.viewList(selected.id).then((res) => {
      dispatch(spinner(true));
      if (selected.onInventory !== res.data.onInventory) {
        const props = { message: `${res.data.name} list is under inventory, you need to refresh the page to have latest updates`, onOk: refresh() };
        dispatch(showModal(AlertModal, props));
        return;
      }
    }).catch(err => console.error(err));


    setUpdating(processStatus.processing);
    const onHandBase = onHandType === onHandTypes.cs ? newOnHandAmount : OH;
    const onHandUomBase = onHandType === onHandTypes.uom ? newOnHandAmount : OHUom;
    const onHandPackBase = onHandType === onHandTypes.pack ? newOnHandAmount : OHPack;

    let nUnitSize = unitSize;

    if (unitToConvert.label !== 'Original') {
      const { conversion } = useConversion.usePackSizeParts(unitQuantity, unitSize, packSizeUom, unitToConvert.value);
      nUnitSize = conversion === 0 ? unitSize : conversion;
    };

    const totalPackSizeValue = unitQuantity * nUnitSize;
    const unitPriceBase = price / totalPackSizeValue;
    const totalOnHand = ((onHandBase) * totalPackSizeValue) + (onHandUomBase + (onHandPackBase * nUnitSize));

    const totalCase = onHandUomBase;
    const totalPack = (onHandPackBase * nUnitSize);
    const totalUnit = ((onHandBase) * totalPackSizeValue);



    const totalOnHandAmount = Math.round((totalOnHand * unitPriceBase) * 100) / 100;
    const totalOnHandUnit = Math.round((totalCase * unitPriceBase) * 100) / 100;
    const totalOnHandPack = Math.round((totalPack * unitPriceBase) * 100) / 100;
    const totalOnHandCase = Math.round((totalUnit * unitPriceBase) * 100) / 100;

    setTotalCase(totalOnHandCase);
    setTotalPack(totalOnHandPack);
    setTotalUnit(totalOnHandUnit);


    const getParamsByHand = () => {
      switch (onHandType) {
        case 'cs': return {
          onHand: newOnHandAmount,
          totalOnHand: totalOnHand,
          totalOnHandAmount: totalOnHandAmount,
        }; break;
        case 'uom': return {
          onHandUom: newOnHandAmount,
          totalOnHand: totalOnHand,
          totalOnHandAmount: totalOnHandAmount,
        }; break;
        case 'pack': return {
          onHandPack: newOnHandAmount,
          totalOnHand: totalOnHand,
          totalOnHandAmount: totalOnHandAmount,
        }; break;
        default:
          break;
      }
    };
    const params = getParamsByHand();


    const operation = newOnHandAmount > prevOnHandAmount ? 'add' : 'sub';
    if (isCustomItem) {
      comparisonViewsApi.patchGroupCustomItem(comparisonViewId, groupId, genericCustomItemId, params).then((res) => {
        setResponses({
          totalOH: totalOnHand,
          totalOHAmt: totalOnHandAmount,
          collectiveOH: res.data.collectiveOnHand,
          collectiveOHU: res.data.collectiveOnHandUom,
          operation: operation,
          newVal: newOnHandAmount,
        });
        setUpdating(processStatus.end);
      }).catch(err => console.error(err));

    } else if (isSubRecipe) {
      comparisonViewsApi.patchGroupSubrecipeItem(comparisonViewId, groupId, subRecipeId, params).then((res) => {
        setResponses({
          totalOH: totalOnHand,
          totalOHAmt: totalOnHandAmount,
          collectiveOH: res.data.collectiveOnHand,
          collectiveOHU: res.data.collectiveOnHandUom,
          operation: operation,
          newVal: newOnHandAmount,
        });
        setUpdating(processStatus.end);
      }).catch(err => console.error(err));

    } else {
      comparisonViewsApi.patchGroupItem(comparisonViewId, groupId, itemDistributorId, params).then((res) => {
        setResponses({
          totalOH: totalOnHand,
          totalOHAmt: totalOnHandAmount,
          collectiveOH: res.data.collectiveOnHand,
          collectiveOHU: res.data.collectiveOnHandUom,
          operation: operation,
          newVal: newOnHandAmount,
        });
        setUpdating(processStatus.end);
      }).catch(err => console.error(err));
    }

  };


  const updateInventoryEdit = (newOnHandAmount, prevOnHandAmount, OH, OHUom, OHPack, inventoryId, itemId) => {
    // setUpdating(processStatus.processing);
    const onHandBase = onHandType === onHandTypes.cs ? newOnHandAmount : OH;
    const onHandUomBase = onHandType === onHandTypes.uom ? newOnHandAmount : OHUom;
    const onHandPackBase = onHandType === onHandTypes.pack ? newOnHandAmount : OHPack;

    let nUnitSize = unitSize;

    if (unitToConvert.label !== 'Original') {
      const { conversion } = useConversion.usePackSizeParts(unitQuantity, unitSize, packSizeUom, unitToConvert.value);
      nUnitSize = conversion === 0 ? unitSize : conversion;
    };

    const totalPackSizeValue = unitQuantity * nUnitSize;
    const unitPriceBase = price / totalPackSizeValue;
    const totalOnHand = ((onHandBase) * totalPackSizeValue) + (onHandUomBase + (onHandPackBase * nUnitSize));

    const totalCase = onHandUomBase;
    const totalPack = (onHandPackBase * nUnitSize);
    const totalUnit = ((onHandBase) * totalPackSizeValue);

    const totalOnHandAmount = Math.round((totalOnHand * unitPriceBase) * 100) / 100;
    const totalOnHandUnit = Math.round((totalCase * unitPriceBase) * 100) / 100;
    const totalOnHandPack = Math.round((totalPack * unitPriceBase) * 100) / 100;
    const totalOnHandCase = Math.round((totalUnit * unitPriceBase) * 100) / 100;

    setTotalCase(totalOnHandCase);
    setTotalPack(totalOnHandPack);
    setTotalUnit(totalOnHandUnit);

    const getParamsByHand = () => {
      switch (onHandType) {
        case 'cs': return {
          on_hand: newOnHandAmount,
          total_on_hand: totalOnHand,
          total_on_hand_amount: totalOnHandAmount,
        }; break;
        case 'uom': return {
          on_hand_uom: newOnHandAmount,
          total_on_hand: totalOnHand,
          total_on_hand_amount: totalOnHandAmount,
        }; break;
        case 'pack': return {
          on_hand_pack: newOnHandAmount,
          total_on_hand: totalOnHand,
          total_on_hand_amount: totalOnHandAmount,
        }; break;
        default:
          break;
      }
    };
    const params = getParamsByHand();

    const operation = newOnHandAmount > prevOnHandAmount ? 'add' : 'sub';

    comparisonViewsApi.patchEditInventoryItem(inventoryId, itemId, params).then((res) => {

      setResponses({
        totalOH: totalOnHand,
        totalOHAmt: totalOnHandAmount,
        // collectiveOH: res.data.collectiveOnHand,
        // collectiveOHU: res.data.collectiveOnHandUom,
        operation: operation,
        newVal: newOnHandAmount,
      });
      if (onHandType === onHandTypes.cs) {
        setOH(newOnHandAmount);
      } else if (onHandType === onHandTypes.pack) {
        setOHPack(newOnHandAmount);
      } else if (onHandType === onHandTypes.uom) {
        setOHUom(newOnHandAmount);
      };
      setTotalOHAmount(totalOnHandAmount);

      dispatch(saveInventoryEditAll(true))


      // setUpdating(processStatus.end);
    }).catch(err => console.error(err));

  };

  const updateInit = (OH, OHUom, OHPack) => {
    const onHandBase = OH;
    const onHandUomBase = OHUom;
    const onHandPackBase = OHPack;


    let nUnitSize = unitSize;
    // console.log(unitToConvert)
    if (unitToConvert.label !== 'Original') {
      const { conversion } = useConversion.usePackSizeParts(unitQuantity, unitSize, packSizeUom, unitToConvert.value);
      console.log(conversion)
      nUnitSize = conversion;
    };

    const totalPackSizeValue = unitQuantity * nUnitSize;
    const unitPriceBase = price / totalPackSizeValue;
    const totalOnHand = ((onHandBase) * totalPackSizeValue) + (onHandUomBase + (onHandPackBase * nUnitSize));
    const totalCase = onHandUomBase;
    const totalPack = (onHandPackBase * nUnitSize);
    const totalUnit = ((onHandBase) * totalPackSizeValue);

    const totalOnHandUnit = Math.round((totalCase * unitPriceBase) * 100) / 100;
    const totalOnHandPack = Math.round((totalPack * unitPriceBase) * 100) / 100;
    const totalOnHandCase = Math.round((totalUnit * unitPriceBase) * 100) / 100;

    setTotalCase(totalOnHandCase);
    setTotalPack(totalOnHandPack);
    setTotalUnit(totalOnHandUnit);

  };

  const [callback] = useDebounce(update);
  const [callbackEdit] = useDebounce(updateInventoryEdit);


  const updateOnHandAmount = (newOnHandAmount, prevOnHandAmount, OH, OHUom, OHPack) => {
    const newOH = (newOnHandAmount === '') ? newOnHandAmount : newOnHandAmount;
    setOnHandAmount(newOH);
    if (newOnHandAmount === '') {
      return;
    }
    callback(parseFloat(newOnHandAmount), parseFloat(prevOnHandAmount), OH, OHUom, OHPack);
  };


  const updateOnHandAmountEdit = (newOnHandAmount, prevOnHandAmount, OH, OHUom, OHPack, inventoryId, itemId) => {
    const newOH = (newOnHandAmount === '') ? newOnHandAmount : newOnHandAmount;
    setOnHandAmount(newOH);
    if (newOnHandAmount === '') {
      return;
    }
    callbackEdit(parseFloat(newOnHandAmount), parseFloat(prevOnHandAmount), OH, OHUom, OHPack, inventoryId, itemId);
  };


  const updateTotalInit = (OH, OHUom, OHPack) => {
    updateInit(OH, OHUom, OHPack)
  };

  const refresh = () => {
    history.replace(`/`);
  };

  useEffect(() => {
    if (updating === processStatus.end && responses !== null) {
      updateTotals();
    }
  }, [updating, responses]);

  return { updateOnHandAmount, updateTotalInit, updateOnHandAmountEdit };
};


export default {
  useOnHandUpdate,
};
