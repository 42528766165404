import React from 'react';
import useModal from '../useModal';
import { Modal, ModalBody, ModalHeader, Col } from 'reactstrap';
import { modalMenuClassName, sortOptions } from '../../../config/constants';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { setInventorySortBy } from '../../../reducers/items/itemsActions';

const InventorySortByModal = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const modalProps = useModal(id, true);
  const selected = useSelector(({items}) => items.inventory.sortBy);

  const selectSort = (sort) => {
    dispatch(setInventorySortBy(sort));
    modalProps.toggle();
  };

  const optionsMap = sortOptions.inventoryView.map(o =>
    <Col onClick={() => { selectSort(o) }} className={'item' + (o.label === selected.label ? ' bg-light' : '')} key={`sort_${o.value}`}>{o.label}</Col>
  );

  return (
    <Modal {...modalProps}>
      <ModalHeader className='bg-white border-0' toggle={ !isMobile ? modalProps.toggle : null}>Sort By</ModalHeader>
      <ModalBody className='px-0 pt-0'>
        {optionsMap}
      </ModalBody>
    </Modal>
  );
};

export default InventorySortByModal;