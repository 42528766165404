import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import ItemDetailsInputCol from '../SharedComponents/ItemDetailsInputCol';

const ItemDetailsInventoryInfo = ({description, comparisonViewName}) => {
  return (
    <Fragment>
      <Row className='mb-3'>
        <ItemDetailsInputCol full label='Description' disabled value={description} />
      </Row>
      <Row className='mb-3'>
        <ItemDetailsInputCol full label='Comparison View Name' disabled value={comparisonViewName} />
      </Row>
    </Fragment>
  );
};

export default ItemDetailsInventoryInfo;