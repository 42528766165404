import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import noImage from '../../../assets/images/no-image.png';
import ShowIf from '../../ShowIf/ShowIf';
import numeral from 'numeral';
import { isMobile } from 'react-device-detect';
import LinkButton from '../../LinkButton/LinkButton';
import PlusButton from '../../PlusButton/PlusButton';
import subrecipeImage from '../../../assets/images/subrecipe-image.png';
import AddFoodbamItemModalCart from './AddFoodbamItemModalCart';

const AddFoodbamItemModalRow = (props) => {
  const {
    description, imageUrl, brandImageUrl, isLink, linkItems, din,
    brand, distributor, price, packSize, item, onAdd, exit, isOrder,
    isSubRecipe, name, uniqid
  } = props;

  const isLinkActive = linkItems.items.filter(l => l.uniqid === uniqid)[0] ? true : false;

  const [overlay, setOverlay] = useState(false);

  const image = isSubRecipe ? subrecipeImage : (imageUrl ? imageUrl : (brandImageUrl ? brandImageUrl : noImage));

  const toggleActive = () => {
    if (!isLinkActive) {
      linkItems.add(item);
    } else {
      linkItems.remove(uniqid);
    }
  };

  const action = () => {
    if (isLink) {
      toggleActive();
    } else {
      if (onAdd) {
        onAdd(item);
      }
      exit();
    }
  };

  return (
    <Row className={'m-0 py-2 w-100 align-items-center position-relative' + (!isSubRecipe ? ' border-bottom' : ' border border-purple')}>
      <Col className='w-image'>
        <img src={image} alt='item' className='no-image' />
      </Col>
      <ShowIf show={isMobile}>
        <Col xs={7} className='mh-90px p-0' style={{wordBreak: 'break-all', wordWrap: 'break-word'}}>
          <Row className='text-main text-capitalize mb-2'>
            <Col>{description || name}</Col>
          </Row>
          <Row className='text-label'>
            <Col className='text-truncate'>{din}</Col>
          </Row>
          <Row className='text-label'>
            <Col xs={6} className='text-truncate'>{brand}</Col>
            <Col xs={6} className='text-truncate'>{distributor}</Col>
          </Row>
          <Row className='text-label'>
            <Col className={isSubRecipe ? 'd-none' : ''}>{numeral(price).format('$0,0.00')}</Col>
          </Row>
        </Col>
      </ShowIf>
      <ShowIf show={!isMobile}>
        <Col className='mh-90px d-flex flex-column p-0'>
          <Row className='text-main text-capitalize'>
            <Col>{description || name}</Col>
          </Row>
          <Row className='text-label'>
            <Col className='text-truncate'>{din}</Col>
          </Row>
          <Row className='text-label mt-auto'>
            <Col xs={4}>{brand}</Col>
            <Col xs={7} className='text-truncate'>{distributor}</Col>
          </Row>
          <Row className='font-weight-bold'>
            <Col className={isSubRecipe ? 'd-none' : ''} xs={4}>{numeral(price).format('$0,0.00')}</Col>
            <Col xs={4}>{packSize}</Col>
          </Row>
        </Col>
      </ShowIf>
      <ShowIf show={isOrder && isMobile}>
        <Col className={(isMobile ? 'align-items-center w-fc pr-0' : 'w-fc')}>
          <AddFoodbamItemModalCart {...props} overlay={false} setOverlay={setOverlay} />
        </Col>
        <Col className='align-items-center position-absolute bg-white h-100 d-flex animate-left' style={{left: overlay ? '0%' : '100%'}}>
          <AddFoodbamItemModalCart {...props} overlay={overlay} setOverlay={setOverlay} />
        </Col>
      </ShowIf>
      <ShowIf show={!isOrder || !isMobile}>
        <Col className={(isMobile ? 'align-items-center w-fc pr-0' : 'w-fc')}>
          <ShowIf show={isLink}>
            <LinkButton onClick={action} isActive={isLinkActive} large />
          </ShowIf>
          <ShowIf show={!isLink && !isOrder}>
            <PlusButton onClick={action} isActive large />
          </ShowIf>
          <ShowIf show={isOrder}>
            <AddFoodbamItemModalCart {...props} overlay={overlay} setOverlay={setOverlay} />
          </ShowIf>
        </Col>
      </ShowIf>
    </Row>
  );
};

export default AddFoodbamItemModalRow;