import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { ListGroupItem, Collapse } from 'reactstrap';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import ListGroupInventory from './ListGroupInventory';
import numeral from 'numeral';
import { useSelector } from 'react-redux';

const ListComparisonView = (props) => {

    const { listGroups, getInventoryItems, showOnlyMissingHv, setTotalOnHandTotalShowMissing } = props;
    const [isOpen, setIsOpen] = useState(false);
    const initTotalComparisonView = listGroups.items.length > 0 ? listGroups.items[0].comparisonViewTotalOnHandAmount : 0;
    const [totalComparisonView, setTotalComparisonView] = useState(initTotalComparisonView);
    const [totalComparisonViewShowMissing, setTotalComparisonViewShowMissing] = useState(initTotalComparisonView);
    const textSearchValue = useSelector(({ inventoryView }) => inventoryView.textSearchValue);
  
    const expandAll = useSelector(({ inventoryView }) => inventoryView.isExpand);

    const toggle = () => setIsOpen(!isOpen);


    useEffect(() => {
        setIsOpen(expandAll);
    }, [expandAll])


    const listGroupsItems = useMemo(() => listGroups.items.map((item) => {
        const ifExist = item.items.some(i => String(i.description).toLowerCase().includes(textSearchValue.toLowerCase()));

        return (
            ifExist &&
            <ListGroupInventory
                key={`itemGroup${item.id}`}
                listItems={item}
                getInventoryItems={getInventoryItems}
                setTotalComparisonView={setTotalComparisonView}
                setTotalComparisonViewShowMissing={setTotalComparisonViewShowMissing}
                setTotalOnHandTotalShowMissing={setTotalOnHandTotalShowMissing}
                showOnlyMissingHv={showOnlyMissingHv}
            />
        );
    }), [listGroups, showOnlyMissingHv,textSearchValue]);

    useEffect(() => {
        setTotalComparisonViewShowMissing(totalComparisonView);
    }, [showOnlyMissingHv]);
    return (
        <Fragment>
            <ListGroupItem tag="a" action onClick={toggle}>
                <div className="d-flex justify-content-between">
                    <p className="text-sm">{listGroups.name}</p>
                    <div className="d-flex justify-content-center align-items-center">
                        <p>{showOnlyMissingHv ?
                            numeral(totalComparisonView - totalComparisonViewShowMissing).format('$0,0.00')
                            : numeral(totalComparisonView).format('$0,0.00')}</p>
                        {
                            isOpen ?
                                <MdExpandLess color="#c7d2dc" className="ml-2" size="27" />
                                :
                                <MdExpandMore color="#c7d2dc" className="ml-2" size="27" />
                        }
                    </div>
                </div>
            </ListGroupItem>

            {/* List Group */}
            <Collapse isOpen={isOpen}>
                {listGroupsItems}
            </Collapse>

            {/*  */}

        </Fragment>
    );
};

export default ListComparisonView;
