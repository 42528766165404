import React from "react";
import { Card, Row, Col } from "reactstrap";
import { MdEdit, MdDelete } from 'react-icons/md';
import { FiMoreHorizontal } from 'react-icons/fi';
import { colors } from "../../../../../config/constants";
import ShowIf from "../../../../../SharedComponents/ShowIf/ShowIf";
import { isMobile } from "react-device-detect";
import useRecipeCostingCategory from "./useRecipeCostingCategory";

const RecipeCostingMenuRow = (props) => {
  const { name } = props;

  const {
    setHover, hover, goToCategory,
    editCategory, deleteCategory, openCategoryOption
  } = useRecipeCostingCategory.useRow(props);

  return (
    <Card>
      <Row className='m-0 align-items-center' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Col onClick={goToCategory} className='py-4 clickable text-truncate'>{name}</Col>
        <ShowIf show={hover && !isMobile}>
          <Col className='w-fc text-light-blue clickable' onClick={editCategory}>
            <MdEdit size={22} color={colors.lightBlueText} /> Edit
          </Col>
          <Col className='w-fc text-light-blue clickable' onClick={deleteCategory}>
            <MdDelete size={22} color={colors.lightBlueText} /> Delete
          </Col>
        </ShowIf>
        <ShowIf show={isMobile}>
          <Col className='w-fc text-light-blue clickable' onClick={openCategoryOption}>
            <FiMoreHorizontal size={24} color={colors.lightBlueText} />
          </Col>
        </ShowIf>
      </Row>
    </Card>
  );
};

export default RecipeCostingMenuRow;