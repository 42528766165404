import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { limitOptions } from '../../../../config/constants';
import comparisonViewsApi from '../../../../api/comparisonViewsApi';

const useApi = (props) => {
  const param = useParams();
  const sort = useSelector(({ items }) => items.inventory.sortBy);
  const [groups, setGroups] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getGroups = () => {
    if (!page){ return; }
    const params = {
      page: page,
      limit: limitOptions.inventoryView,
      filterOptions: JSON.stringify({ sort: sort }),
      filters: null,
      analytics: 1
    };

    // dispatch(spinner(false));
    setIsLoading(true);
    comparisonViewsApi.groups(param.inventory, params)
    .then((res) => {
      setLoadMore(res._meta.pageCount>page);
      setPage( page + 1 );
      setGroups([...groups, ...res.data]);
      setIsLoading(false);
      // dispatch(spinner(true));
    }).catch(err => {
      // dispatch(spinner(true));
      console.error(err)
    });
  };

  useEffect(() => {
    if(param.inventory){
      setGroups([]);
      setLoadMore(true);
      setPage(1);
    }
  }, []);

  useEffect(() => {
    if(loadMore && !isLoading){
      getGroups();
    }
  }, [page, isLoading]);

  return {
    groups, setGroups
  };
};


export default {
  useApi
};
