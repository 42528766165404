import React,{ Fragment, useState, useEffect } from 'react';
import itemApi from '../../api/itemApi';
import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io';
import PlaceOrderListRow from '../../Views/MainApp/Views/PlaceOrder/PlaceOrderList/PlaceOrderListRow';
import {savings} from '../../config/constants';

const useApi = (parentItem) => {
  const [savingItems, setSavingItems] = useState([]);
  const [savingsMap, setSavingsMap] = useState('');
  const [savingsNumber, setSavingsNumber] = useState(0);
  const [parent, setParent] = useState(parentItem);

  const buildMap = (nSavings) => {
    if(nSavings.length === 0){ return;}
    const map = nSavings.map((item) => (
      <PlaceOrderListRow key={`sitem_`+item.id} id={item.id} showCart={true} parentItem={parent}>{item}</PlaceOrderListRow>
    ));
    setSavingsMap(map);
  };

  const getSavings = (id) => {    
    itemApi.getMatches(id)
      .then(res => {
        const sitems = res.data;
        if(sitems){
          setSavingsNumber(sitems.length);
          setSavingItems(sitems);          
          buildMap(sitems);
        }        
    })
    .catch(error => {
      console.log(error);
    });
  };

  return {getSavings, savingItems, savingsMap, savingsNumber};
};

const useOpen = (item, parentItem) => {
  const {getSavings, savingItems, savingsMap, savingsNumber} = useApi(parentItem);
  const [savingsOpen, setSavingsOpen] = useState(false);

  const [buttonTitle, setButtonTitle] = useState('');
  const [buttonColor, setButtonColor] = useState('');

  useEffect(() => {
    if (savingsOpen) {
      getSavings(item.itemDistributorId);      
    }
  }, [savingsOpen])

  useEffect(() => {
    setButtonTitle((item.matchType === savings.exactMatch) ? 'Exact match' : 'Savings Options');
    const nColor = item.savings > 0 ? (item.matchType === savings.exactMatch) ? 'orange' : 'yellow' : (item.matchType !== null ? 'blue-sav' : '');
    setButtonColor(nColor);
  }, [])

  return {    
    savingsOpen,
    setSavingsOpen,
    savingItems, 
    savingsMap,
    savingsNumber, 
    displaySavings : savingsOpen ? <IoMdArrowDropup size={14}/> : <IoMdArrowDropdown size={14}/>,
    buttonTitle,
    buttonColor
  }
};

export default {
  useOpen, useApi
};