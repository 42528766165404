
import React, { useState }  from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button, CustomInput } from 'reactstrap';
import useModal from '../../../../../../SharedComponents/Modals/useModal';
import { MdCloudDownload } from 'react-icons/md';
import {colors} from '../../../../../../config/constants';



const OrderViewDownloadModal = (props) => {
    const { id, fullMenu, onSave, titleOption } = props;
    const modalProps = useModal(id, true, fullMenu);
    const [order, setOrder] = useState(true);

    const clickDownload = () => {
        if (onSave) {
            onSave(order);
        }
        modalProps.toggle();
    };

    const onChangeOrder = (option, value) => {
        switch (option) {
            case 1: 
                setOrder(value);
                break;
            case 2:
                setOrder(value);
                break;
            default:
                break;
        }
    }

    return (
        <Modal {...modalProps} size='sm'>
            <ModalHeader toggle={modalProps.toggle}>Select...</ModalHeader>
            <ModalBody className='px-4'>
                <Row className='py-2 clickable'>
                    <Col>
                        <CustomInput id='idPurcharseOrder' type='checkbox' label={titleOption} checked={order === true} onChange={() => onChangeOrder(1, true)} />
                    </Col>
                </Row>
                <Row className='py-2 clickable'>
                    <Col>
                        <CustomInput id='idOrder360' type='checkbox' label='Restaurant 365 Format' checked={order === false} onChange={() => onChangeOrder(2, false)} />
                    </Col>
                </Row>

            </ModalBody>
            <ModalFooter>
                <Col className='text-center'>
                    <Button size='lg' color='yellow' onClick={clickDownload}>
                        <MdCloudDownload color={colors.white} size={20} />
                        &nbsp;Download
                    </Button>
                </Col>
            </ModalFooter>
        </Modal>
    )

}

export default OrderViewDownloadModal;

