import React from 'react';
import { colors } from '../../../config/constants';
import { Button } from 'reactstrap';
import { MdAdd } from 'react-icons/md';
import { IoIosArrowForward } from "react-icons/io";

const Circle = ({large, isActive, onClick, isBlue, content, overlay, disabled, ...props}) => {
  const iconFor = <IoIosArrowForward  color={isActive ? 'white' : colors.lightBlueText} size={28}/>;
  const iconAdd = <MdAdd color={isActive ? 'white' : colors.lightBlueText} size={large ? 30 : 24}/>;
  return (
    <Button {...props} onClick={onClick} size={large ? 'lg-circle' : 'circle'} color={isActive ? ( isBlue ? 'dark-blue' : 'green') : 'gray'} disabled={(disabled === undefined) ? false : disabled}>
      {content > 0 ? (overlay ? iconFor : <div className='text-circle'>{content}</div> ) 
        : (overlay ? iconFor : iconAdd)}
    </Button>
  );
};

export default Circle;