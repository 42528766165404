import React from 'react';
import { ModalFooter, Button, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setListFilters } from '../../../reducers/items/itemsActions';

const SearchFiltersModalFooter = (props) => {
  const dispatch = useDispatch();
  const {
    distributors, pastOrders, shoppingLists,
    setDistributors, setPastOrders, setShoppingLists,
    allDistributors, toggleAllDistributors,
    toggleAllPastOrders, allPastOrders,
    allShoppingLists, toggleAllShoppingLists, purchasedOnly,
    togglePurchasedOnly, toggleModal
  } = props;

  const clickReset = () => {
    setDistributors(distributors.map(d => {
      return {
        ...d,
        check: false
      }
    }));
    if (allDistributors) toggleAllDistributors();
    setPastOrders(pastOrders.map(d => {
      return {
        ...d,
        check: false
      }
    }));
    if (allPastOrders) toggleAllPastOrders();
    setShoppingLists(shoppingLists.map(d => {
      return {
        ...d,
        check: false
      }
    }));
    if (allShoppingLists) toggleAllShoppingLists();
    if (purchasedOnly) togglePurchasedOnly();
    dispatch(setListFilters(false, [], [], []));
  };

  const clickApply = () => {
    const distIds = distributors.filter(d => d.check).map(d => d.id);
    const shopIds = shoppingLists.filter(s => s.check).map(s => s.id);
    const ordIds = pastOrders.filter(o => o.check).map(o => o.id);
    dispatch(setListFilters(purchasedOnly, distIds, shopIds, ordIds));
    toggleModal();
  };

  return (
    <ModalFooter>
      <Col className='text-left'>
        <Button onClick={clickReset} className='px-4' color='secondary'>Reset</Button>
      </Col>
      <Col className='text-right'>
        <Button onClick={clickApply} className='px-4' color='green'>Apply</Button>
      </Col>
    </ModalFooter>
  );
};

export default SearchFiltersModalFooter;