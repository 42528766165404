export default function reducer(state = {
  openSync: true,
  modalSync: null,
  syncInventory: false,
}, action) {
  switch (action.type) {
    case 'SET_OPEN_SYNC':
      return {
        ...state, 
        openSync: action.payload
    };
    case 'SET_MODAL_SYNC':
      return {
        ...state, 
        modalSync: action.payload
    };
    case 'SET_SYNC_INVENTORY':
      return {
        ...state, 
        syncInventory: action.payload
    };
    default:
      return state;
  }
};