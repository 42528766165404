import React, { useState, useEffect } from 'react'
import { localStorageConst } from '../../../../../config/constants';
import itemApi from '../../../../../api/itemApi';

const useSavings = () => {

    const transformItemsSavings = (listItemsSavings) => {
        const localCartItems = JSON.parse(localStorage.getItem(localStorageConst.keys.cartItems));
        // console.log(localCartItems)
        const newResult = listItemsSavings.filter(item => {
            item.detailItem = localCartItems.find(cart => cart.itemDistributorId == item.itemDistributorId);
            // item.detailItem = localCartItems.find(cart => cart.itemDistributorId == 6679588);
            item.swap = false;
            item.reject = false;
            item.idItemSwap = '';
            item.idItemReject = '';
            item.optionReject = '';
            // test doubl
            // if (item.itemDistributorId == 4944050) {
            //     let newItem = `{"itemDistributorId":6679664,"itemId":876431,"din":"940829 ","name":"avocado hass fresh packer","description":"avocado hass fresh packer","foodbamCustomDescription":"","brand":"Packer","count":6,"status":"active","price":30.37,"unitPrice":0.6062,"distributorId":181,"distributor":"PFG - Performance Foodservice Milton's","packSize":"1/60 CT","imageUrl":"https://foodbam-east.s3.amazonaws.com/itemdistributor/AVOCADO%20HASS%20FRSH-6679663.jpg","brandImageUrl":null,"updateTime":"2020-04-01 21:30:34","unitMeasure":"cs","isCatchWeight":0,"isFixedWeight":0,"averageWeight":60,"isMarketPrice":0,"type":"order_guide","unitType":"Amount","unitQuantity":1,"unitSize":60,"rebate":null,"packSizeUom":"ct","isPriceOutdated":1,"purchasedPrice":68.43,"isPurchased":1,"isPunchedOut":0,"orderGuideId":61704,"belongsToGroup":1,"lastPurchasedDate":"2020-04-01 21:30:34","lastPurchasedQty":1,"isPartner":1,"notes":null,"accountingId":null,"accountingSupplierId":null,"accountingSupplier":null,"accountingCategoryId":null,"accountingCategory":null,"accountingCodeId":null,"accountingCode":null,"matchType":"SimilarMatch","isUnpurchasable":0,"matchId":83786302,"savings":0.23195833333333327,"caseSavings":13.92,"currentRebateAmount":0,"newRebateAmount":0}`;
            //     item.comparable.push(JSON.parse(newItem));
            //     newItem = `{"itemDistributorId":6679665,"itemId":876432,"din":"940829 ","name":"avocado hass fresh packer","description":"avocado hass fresh packer","foodbamCustomDescription":"","brand":"Packer","count":6,"status":"active","price":20.37,"unitPrice":0.6062,"distributorId":181,"distributor":"PFG - Performance Foodservice Milton's","packSize":"1/60 CT","imageUrl":"https://foodbam-east.s3.amazonaws.com/itemdistributor/AVOCADO%20HASS%20FRSH-6679663.jpg","brandImageUrl":null,"updateTime":"2020-04-01 21:30:34","unitMeasure":"cs","isCatchWeight":0,"isFixedWeight":0,"averageWeight":60,"isMarketPrice":0,"type":"order_guide","unitType":"Amount","unitQuantity":1,"unitSize":60,"rebate":null,"packSizeUom":"ct","isPriceOutdated":1,"purchasedPrice":68.43,"isPurchased":1,"isPunchedOut":0,"orderGuideId":61704,"belongsToGroup":1,"lastPurchasedDate":"2020-04-01 21:30:34","lastPurchasedQty":1,"isPartner":1,"notes":null,"accountingId":null,"accountingSupplierId":null,"accountingSupplier":null,"accountingCategoryId":null,"accountingCategory":null,"accountingCodeId":null,"accountingCode":null,"matchType":"SimilarMatch","isUnpurchasable":0,"matchId":83786303,"savings":0.23195833333333327,"caseSavings":13.92,"currentRebateAmount":0,"newRebateAmount":0}`;
            //     item.comparable.push(JSON.parse(newItem));

            // };
            //
            if (item.detailItem && item.matches.length > 0)
                return item;
        });
        return newResult;
    };

    const swapAcceptApi = async (params) => {

        const res = await itemApi.swapAccept(params);
        return res;
    };

    const swapRejectApi = async (params) => {

        const res = await itemApi.swapReject(params);
        return res;
    };


    return { transformItemsSavings, swapAcceptApi, swapRejectApi }

}

const useOptionsSavings = () => {

    const [optionsReject, setOptionReject] = useState([]);

    const listReject = [
        'Recommended product is not a match',
        'Quality Concern',
        'Current item preferred',
        'Current pack size preferred',
        'Sensitive Distributor Relationship',
        'Not interested in changing this item',
        'Other'
    ];

    const options = listReject.map(res => {
        return {
            label: res,
            value: res
        };
    });

    useEffect(() => {
        setOptionReject(options);
    }, [])

    return { optionsReject }
}

export default {
    useSavings, useOptionsSavings
}
