import { useState } from 'react';
import { useDispatch } from 'react-redux';
import validate from 'validate.js';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import useInput from '../../../../../SharedComponents/SharedEffects/useInput';
import helpApi from '../../../../../api/helpApi';

const useForm = () => {

  const dispatch = useDispatch();
  const { set : setDescription, value: description } = useInput.useText();
  const [error, setError] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    const form = {
      description: description
    };
    const constrains = {
      description: {
        presence: {
          allowEmpty: false
        }
      }
    };

    const result = validate(form, constrains);
    setError(result ? result : {});
    if (!result) {
      helpApi.sendHelpEmail(description).then(() => {
        dispatch(showModal(AlertModal, {
          message: 'Thank you for reporting the issue.',
          onOk: () => { setDescription('') }
        }));
      }).catch(err => {
        console.error(err);
        const status = err.code;
        if (status === 400) {
          setError({error: err.data.message});
        } else {
          setError({error: 'Something went wrong.'});
        }
      });
    }
  };

  return { setDescription, description, error, onSubmit };

};

export default {
  useForm
};
