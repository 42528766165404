import React, { Fragment } from 'react';
import { Row, Col, Button } from 'reactstrap';
import InputSearch from '../../InputSearch/InputSearch';
import { IoIosOptions } from 'react-icons/io';
import { colors } from '../../../config/constants';
import ShowIf from '../../ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import LinkButton from '../../LinkButton/LinkButton';

const AddFoodbamItemModalHeader = (props) => {
  const {
    search, onClear, onSearch, toggleFilters, isLink, linkAll, isAllLink, showFilters, disabled
  } = props;

  return (
    <Fragment>
      <Row className='align-items-center mt-3 mx-0'>
        <Col className='p-0'>
          <InputSearch value={search.value} onChange={search.set} clearValue={onClear} onEnter={onSearch} disabled={disabled ? 'disabled' : ''}/>
        </Col>
        <ShowIf show={!isMobile}>
          <Col className='w-fc pr-0'>
            <Button onClick={toggleFilters} outline={!showFilters} size={isMobile ? 'square' : ''} className={ isMobile ? '' : 'px-5'} color='green'><IoIosOptions color={ !showFilters ? colors.green : 'white'} /> Filters</Button>
          </Col>
        </ShowIf>
        <ShowIf show={isMobile}>
          <Col className='w-fc pr-0' onClick={toggleFilters}>
            <IoIosOptions size={28} color={colors.dark} />
          </Col>
        </ShowIf>
        <ShowIf show={isLink && !isMobile}>
          <Col className='w-fc text-label clickable pr-0' onClick={linkAll}>
            Link All Items <LinkButton className='ml-2' isActive={ isAllLink() }  />
          </Col>
        </ShowIf>
      </Row>
    </Fragment>
  );
};

export default AddFoodbamItemModalHeader;