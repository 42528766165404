export const setUserData = (data) => {
  return {
    type: 'SET_USER_DATA',
    payload: data
  };
};

export const clearUserData = (data) => {
  return {
    type: 'CLEAR_USER_DATA',
  };
};

export const setUserActiveLocation = (data) => {
  return {
    type: 'SET_USER_ACTIVE_LOCATION',
    payload: data
  };
};

export const setActiveInventory = (data) => {
  return {
    type: 'SET_ACTIVE_INVENTORY',
    payload: data
  };
};

export const setActiveInventoryEdit = (data) => {
  return {
    type: 'SET_ACTIVE_INVENTORY_EDIT',
    payload: data
  };
};
