import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../SharedComponents/ShowIf/ShowIf';
import { FiMenu } from 'react-icons/fi';
import { colors } from '../../../../config/constants';

const GroupRow = ({group, index, dragHandleProps, isDragging }) => {
  const { id, name, expand } = group;

  return (
    <Card className='mb-2'>
      <ShowIf show={!isMobile}>
        <Row className='align-items-center'>
          <Col className='w-fc p-4' {...dragHandleProps}>
             <FiMenu color='white' color={colors.lightBlueText}/>
          </Col>
          <Col className='text-label-sm text-center text-uppercase py-4 text-nowrap'>
            {name}
          </Col>          
        </Row>
      </ShowIf>

      <ShowIf show={isMobile}>
        <Row className={'align-items-center m-0' + (expand ? '' : ' border-bottom') + (isDragging ? ' border bg-light-blue' : '')} style={isDragging ? {margin: '-1px 0 -1px -1px'} : null}>
          <Col className='w-fc p-2' {...dragHandleProps}>
             <FiMenu color='white' color={colors.lightBlueText}/>
          </Col>
          <Col className='py-3 text-center text-label-sm text-uppercase'>
            {name}
          </Col>          
        </Row>
      </ShowIf>
    </Card>
  );
};

export default GroupRow;
