
import axios from 'axios';


const addSingup = (params) => {
    return axios.post('sign-up', params);
};

export default {
    addSingup
};
