import React, { Fragment } from 'react';
import { Col, Tooltip } from 'reactstrap';
import useInput from '../SharedEffects/useInput';
import { isMobile } from 'react-device-detect';
import ShowIf from '../ShowIf/ShowIf';

const Bookmark = ({elemId, img, label}) => {
  const tooltip = useInput.useCheckbox(false);
  return (
    <Fragment>
      <Col className='w-fc px-1' id={`${elemId}`}>
        <img src={img} alt={label} />
      </Col>
      <ShowIf show={!isMobile}>
        <Tooltip innerClassName='text-tooltip' placement='top-start'
          hideArrow={true} isOpen={tooltip.value}
          toggle={tooltip.toggle}
          target={`${elemId}`}>
            {label}
        </Tooltip>
      </ShowIf>
    </Fragment>
  );
};

export default Bookmark