import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import useInput from '../../SharedEffects/useInput';
import useSearchFiltersModal from './useSearchFiltersModal';
import useModal from '../useModal';
import { modalMenuClassName } from '../../../config/constants';
import SearchFiltersModalFooter from './SearchFiltersModalFooter';
import SearchFiltersModalBody from './SearchFiltersModalBody';
import { useSelector } from 'react-redux';

const SearchFiltersModal = (props) => {
  const { id } = props;
  const modalProps = useModal(id);
  const filters = useSelector(({items}) => items.list.filters);

  const { toggle : togglePurchasedOnly, value : purchasedOnly } = useInput.useCheckbox(filters.purchasedOnly);
  const dist = useSearchFiltersModal.useDistributors(filters.distributorIds);
  const past = useSearchFiltersModal.usePastOrders(filters.pastOrderIds);
  const shop = useSearchFiltersModal.useShoppingLists(filters.shoppingIds);

  const bodyProps = {
    togglePurchasedOnly, purchasedOnly,
    ...dist, ...past, ...shop
  };

  return (
    <Modal {...modalProps} size='lg' modalClassName={modalMenuClassName}>
      <ModalHeader className='pb-0' toggle={modalProps.toggle} />
      <SearchFiltersModalBody {...bodyProps} />
      <SearchFiltersModalFooter {...bodyProps} toggleModal={modalProps.toggle} />
    </Modal>
  );
};

export default SearchFiltersModal;