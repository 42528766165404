import React  from 'react';
import { Modal, ModalBody, ModalHeader, Button, Row, Col } from 'reactstrap';
import {showModal} from '../../../../../../reducers/modals/modalsActions';
import { useDispatch } from 'react-redux';
import useModal from '../../../../../../SharedComponents/Modals/useModal';
import AddNewItem from './AddNewItem';

const RejectCreateModal = (props) => {
    const dispatch = useDispatch();
    const { id, title, menu, fullMenu, selectedOrder, getOrdersById } = props;
    const modalProps = useModal(id, menu, fullMenu);

    const close = () => {
        modalProps.toggle();
    }

    const create = () => {
        modalProps.toggle();
        dispatch(showModal(AddNewItem, {selectedOrder: selectedOrder, getOrdersById: getOrdersById}));
    }

    return (
        <Modal {...modalProps}>
            <ModalHeader toggle={modalProps.toggle}>{title}</ModalHeader>
            <ModalBody className='px-5'>
                <Row>
                    <Col className='text-center'>
                        Do you want to create a new item instead?
                    </Col>
                </Row>
                <Row className='pt-5 pb-3'>
                    <Col className='text-center'>
                        <Button color='gray' onClick={close}>No Thanks</Button>
                    </Col>
                    <Col className='text-center'>
                        <Button color='green' onClick={create}>Create</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )

}

export default RejectCreateModal;