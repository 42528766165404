import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Button, Row, ListGroup, ListGroupItem } from 'reactstrap';
import HeaderReviewInventory from './HeaderReviewInventory';
import './ReviewInventory.scss';
import ListComparisonView from './ListComparisonView';
import timestampedInventoryApi from '../../../../../api/timestampedInventoryApi';
import usePlaceOrderInventory from '../../PlaceOrder/PlaceOrderInventory/usePlaceOrderInventory';
import { useSelector, useDispatch } from 'react-redux';
import numeral from 'numeral';
import { isMobile } from 'react-device-detect';
import QuestionModal from '../../../../../SharedComponents/Modals/QuestionModal/QuestionModal';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import { saveInventoryItems, saveInventoryOnHandTotal } from '../../../../../reducers/items/itemsActions';
import { setUserData } from '../../../../../reducers/user/userAction';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from '../../../../../SharedComponents/Modals/ConfirmationModal/ConfirmationModal';
import useInput from '../../../../../SharedComponents/SharedEffects/useInput';
import { textSearchInventory } from '../../../../../reducers/inventoyView/inventoryViewActions';

const ReviewInventory = (props) => {

    const [listComparisonViewGroups, setListComparisonViewGroups] = useState([]);
    const { items, page, loadMore, isLoading, getInventoryItems, listSelected } = usePlaceOrderInventory.useApi();
    const dataUser = useSelector(({ user }) => user);
    const onHandTotal = useSelector(({ items }) => items.inventory.onHandTotal);
    const inventoryItems = useSelector(({ items }) => items.inventory.items);
    const dispatch = useDispatch();
    const [showOnlyMissingHv, setShowOnlyMissingHv] = useState(false);
    const search = useInput.useText();
    const [textSearch, setTextSearch] = useState();
    const [totalOnHandTotalShowMissing, setTotalOnHandTotalShowMissing] = useState(0);
    const textSearchValue = useSelector(({ inventoryView }) => inventoryView.textSearchValue);

    const history = useHistory();

    const getComparisonAllViews = () => {
        timestampedInventoryApi.comparisonAllViews()
            .then(res => {
                const newList = [];
                res.data.forEach(item => {
                    const ifExist = newList.find(_i => _i.id == item.comparisonView.id);
                    if (!ifExist) {
                        newList.push({
                            ...item.comparisonView,
                            items: res.data
                                .filter(_ii => _ii.comparisonView.id == item.comparisonView.id)
                        });
                    };
                });

                setListComparisonViewGroups(newList);
            })
            .catch(error => console.log(error));
    };

    const completeInventory = () => {
        timestampedInventoryApi.completeInventory()
            .then(res => {
                dispatch(saveInventoryItems([]));
                dispatch(saveInventoryOnHandTotal(0.0));
                dataUser.activeLocation.activeInventory = null;
                dispatch(setUserData(dataUser));
                dispatch(saveInventoryItems(inventoryItems.map(i => { return { ...i, items: i.items.map(j => { return { ...j, onHand: 0, onHandPack: 0, onHandUom: 0 } }) } })));
                openModalConfirm();
            })
            .catch(err => console.log(err));
    };

    const openModalConfirm = () => {
        const props = {
            header: 'Confirmation',
            message: `The inventory's complete!`,
            onOk: () => {
                history.push({
                    pathname: `/place-order/inventory`
                });
            }
        };
        dispatch(showModal(ConfirmationModal, props));
    }


    const openModalConfirmComplete = (params) => {
        const props = {
            message: `Are you sure you want to complete inventory?`,
            menu: isMobile ? true : false,
            leftButton: {
                title: 'Cancel',
                color: 'orange',
            },
            rightButton: {
                title: 'Confirm',
                color: 'green',
                onClick: () => completeInventory()
            }
        };
        dispatch(showModal(QuestionModal, props));
    };


    const listComparisonView = useMemo(() => listComparisonViewGroups.map((item) => {
        let ifExist = false;
        item.items.forEach(itemsGroup => {
            let resultFind = itemsGroup.items.some(i => String(i.description).toLowerCase().includes(textSearchValue.toLowerCase()));
            if (resultFind) ifExist = true;
        });
        return (
            ifExist &&
            <ListComparisonView
                key={`itemComparison${item.id}`}
                listGroups={item}
                getInventoryItems={getInventoryItems}
                showOnlyMissingHv={showOnlyMissingHv}
                setTotalOnHandTotalShowMissing={setTotalOnHandTotalShowMissing}
            />
        );
    }), [listComparisonViewGroups, showOnlyMissingHv, textSearchValue]);


    // useEffect(() => {
    //     getComparisonAllViews();
    // }, []);




    useEffect(() => {
        getComparisonAllViews();
        setTotalOnHandTotalShowMissing(onHandTotal)
        // if (!showOnlyMissingHv)
    }, [showOnlyMissingHv])

    return (
        <div style={{ marginRight: (isMobile) ? "" : "-5px" }}>
            <Row className={isMobile ? "" : "m-0 pl-4"}>
                <HeaderReviewInventory
                    setShowOnlyMissingHv={setShowOnlyMissingHv}
                    search={search}
                    showOnlyMissing={true}
                />
            </Row>
            <Row className={"mt-3 contentGroupRI" + (!isMobile ? " px-5" : " px-3")}>
                <ListGroupItem className="itemHeader w-100" tag="a" >
                    <div className="d-flex justify-content-between">
                        <p className="text-sm">Storage</p>
                        <p className="text-sm">Total On Hand Value</p>
                    </div>
                </ListGroupItem>
                <ListGroup className="w-100">
                    {listComparisonView}
                </ListGroup>
                <ListGroupItem className="itemFooter w-100" tag="a" >
                    <div className="d-flex justify-content-between">
                        <p className="text-sm">Total On Hand</p>
                        <p className="text-sm mr5">{showOnlyMissingHv ? numeral(onHandTotal - totalOnHandTotalShowMissing).format('$0,0.00') : numeral(onHandTotal).format('$0,0.00')}</p>
                    </div>
                </ListGroupItem>
            </Row>
            <div className={"float-right buttonComplete" + (isMobile ? " w-100 p-0" : "")}>
                <Button className='buttonComplete  w-100' color='green' onClick={openModalConfirmComplete}>Complete Inventory</Button>
            </div>
        </div>
    );
};

export default ReviewInventory;
