import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { insertInventoryGroup, sortInventoryGroupsNew } from '../../../../../../reducers/items/itemsActions';
import comparisonViewsApi from '../../../../../../api/comparisonViewsApi';
import useInput from '../../../../../../SharedComponents/SharedEffects/useInput';
import * as $ from 'jquery';
import validate from 'validate.js';
import AlertModal from '../../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import { showModal } from '../../../../../../reducers/modals/modalsActions';
import usePlaceOrderInventory from '../usePlaceOrderInventory';

const useNewGroup = ({group, index, isUp, hideAll, id, listSelected}, type) => {
  const comparisonViewId = group ? group.comparisonViewId : null;

  const dispatch = useDispatch();
  const [ isShowInput, setIsShowInput ] = useState(false);
  const groups = useSelector(({items}) => items.inventory.items);
  const name = useInput.useText();

  const { conectivity } = usePlaceOrderInventory.useStatusConnection();

  const nameInputProps = {
    value: name.value,
    onChange: name.set,
    className: name.error ? 'border-orange' : ''
  };

  const showInput = () => {
    setIsShowInput(true);
  };

  const hideInput = () => {
    setIsShowInput(false);
    if(hideAll){
      hideAll();
    }
  };

  useEffect(() => {
    if (isShowInput) {
      $('#'+id+'_input').focus();
    }
  }, [isShowInput]);

  const onEnterKey = ({key}) => {
    if (key === 'Enter') {
      if(!conectivity){
        dispatch(showModal(AlertModal, {message:'You need internet connectivity to use this feature', footerClass: 'justify-content-center'}));
        return;
      }
      const r = validate.single(name.value, {length: {minimum: 3}});
      if (!r) {
        hideInput();
        name.setError(false);
        name.setValue('');

        let newSort = (isUp) ? index : index+1;
        const params = {
          name: name.value,
          new_sort: newSort
        };
        const insertFunc = () => {
          comparisonViewsApi.postGroup(comparisonViewId, params).then(({data}) => {
            let isAllCollapse = true;
            for (let i = 0; i < groups.length; i++) {
              if (groups[i].expand) {
                isAllCollapse = false;
              }
            };

            dispatch(sortInventoryGroupsNew(newSort));
            const newGroup = {
              id: data.id,
              name: data.name,
              sort: newSort,
              comparisonViewId: comparisonViewId,
              expand: !isAllCollapse,
              items: []
            };
            dispatch(insertInventoryGroup(newGroup, newSort));

            if(hideAll) hideAll();
          }).catch((err) => {
            name.setValue('');
            if (err.code === 400) {
              dispatch(showModal(AlertModal, {message: 'This generic item already exists'}));
            } else {
              dispatch(showModal(AlertModal, {message: "Something wen't wrong"}));
            }
            console.error(err);
          });
        };
        insertFunc();

      } else {
        name.setError(true);
      }
    }
  };

  const onEnterKeyExtreme = ({key}) => {
    if (key === 'Enter') {
      if(!conectivity){
        dispatch(showModal(AlertModal, {message:'You need internet connectivity to use this feature', footerClass: 'justify-content-center'}));
        return;
      }
      if(listSelected){
        const r = validate.single(name.value, {length: {minimum: 3}});
        if (!r) {
          hideInput();
          name.setValue('');
          name.setError(false);

          const params = {
            name: name.value,
            new_sort: index
          };
          comparisonViewsApi.postGroup(listSelected.id, params).then(({data}) => {
            dispatch(sortInventoryGroupsNew(index));
            const newGroup = {
              id: data.id,
              name: data.name,
              sort: index,
              comparisonViewId: listSelected.id,
              expand: true,
              items: []
            };
            dispatch(insertInventoryGroup(newGroup, index));
            if(hideAll) hideAll();
          }).catch((err) =>{
            name.setValue('');
            if (err.code === 400) {
              dispatch(showModal(AlertModal, {message: 'This generic item already exists'}));
            } else {
              dispatch(showModal(AlertModal, {message: "Something wen't wrong"}));
            }
            console.error(err);
          });
        } else {
          name.setError(true);
        }
      }
    }
  };

  return {
    nameInputProps, showInput, onEnterKey, isShowInput, hideInput, onEnterKeyExtreme
  }
};

export default {
  useNewGroup
};
