import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import Highcharts from 'highcharts';
import { Row, Col } from 'reactstrap';
import { FaInfo } from 'react-icons/fa';
import { MdFiberManualRecord } from 'react-icons/md';
import { colors } from '../../../../../config/constants';
import IconBadgeSolid from '../../../../../SharedComponents/IconBadge/IconBadgeSolid';

const SavingsGraph = (props) => {

  const activeLocation = useSelector(({user}) => user.activeLocation);

  const humanizeString = (str) => {
    str = '' + str;
    str = str.split(/(?=[A-Z])/);
    str = str.join(' ');
    return str.charAt(0).toUpperCase() + str.substr(1);
  }

  const _getSavingsColor = (i) => {
    const color = ['#6ED79A', '#FEBA5D', '#6ED3D7', '#766ED7'];
    return color[i];
  }

  function addTitle(chart, totalOrderSavingsAmount) {
    if (chart.title) { chart.title.destroy(); }
    if (chart.title2) { chart.title2.destroy(); }
    let r = chart.renderer;
    let x = chart.series[0].center[0] + chart.plotLeft;
    let y = chart.series[0].center[1] + chart.plotTop;
    chart.title = r.text('<b>' + '$' + Highcharts.numberFormat(totalOrderSavingsAmount, 2, '.', ',') + '</b>', 0, 0)
      .css({ color: '#143357', fontSize: '22px', 'line-height': '22px', 'font-family': 'Roboto-Regular' })
      .hide()
      .add();
    chart.title2 = r.text('Total Savings', 0, 0)
      .css({ color: '#143357', fontSize: '16px', 'line-height': '16px', 'font-family': 'Roboto-Regular' })
      .hide()
      .add();
    let bbox = chart.title.getBBox();
    let bbox2 = chart.title2.getBBox();
    chart.title.attr({ x: x - (bbox.width / 2), y: y }).show();
    if (totalOrderSavingsAmount === 0) {
      chart.title2.attr({ x: x - (bbox2.width / 2), y: y + (bbox.width / 4 + 10) }).show();
    } else {
      chart.title2.attr({ x: x - (bbox2.width / 2), y: y + (bbox.width / 4) }).show();
    }
  }

  const savingsGraph = () => {
    if (!props.analyticsData || !props.analyticsData.total_savings || !props.analyticsData.total_savings.detail) {
      return;
    }
    let seriesSavings = [];
    let totalSavingsAmount = props.analyticsData.total_savings.total;
    let savingString = '';
    if (props.analyticsData.total_savings.detail) {
      for (let i = 0; i < props.analyticsData.total_savings.detail.length; i++) {
        if (props.analyticsData.total_savings.detail[i][1] === null) {
          props.analyticsData.total_savings.detail[i][1] = 0;
        }
        if (props.analyticsData.total_savings.detail[i].color === undefined) {
          props.analyticsData.total_savings.detail[i].color = _getSavingsColor(i);
        }
        let savingArray = props.analyticsData.total_savings.detail[i][0].split(/(?=[A-Z])/);
        savingString = savingArray.join(' ');
        let dataObj = {
          LocationName: savingString.charAt(0).toUpperCase() + savingString.substr(1),
          y: props.analyticsData.total_savings.detail[i][1],
          color: props.analyticsData.total_savings.detail[i].color
        };
        props.analyticsData.total_savings.detail[i].SavingsName = dataObj.LocationName;
        seriesSavings.push(dataObj);
      }

      const savingsGraph = new Highcharts.chart('container_savings', {
        chart: {
          height: (5 / 10 * 100) + '%',
          margin: [0, 350, 0, 0],
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          events: {
            load: function () { addTitle(this, totalSavingsAmount); },
            redraw: function () { addTitle(this, totalSavingsAmount); }
          }
        },
        tooltip: {
          formatter: function () {
            return '<span>' + this.point.LocationName + ' : ' + '<strong>$' + Highcharts.numberFormat(this.y, 2, '.', ',') + '</strong></span>';
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            size: 250,
            dataLabels: {
              enabled: true,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            startAngle: -90,
            endAngle: 270,
            center: ['50%', '50%']
          },
          series: {
            point: {
              events: {
                legendItemClick: function (e) {
                  var color = Highcharts.color;
                  var series = this.series;
                  for (let i = 0; i < series.data.length; i++) {
                    let point = series.data[i];
                    if (point === this) {
                      point.update({
                        //color: series.chart.options.colors[this.index]
                      });
                    } else {
                      point.update({
                        //color: color(point.color).setOpacity(this === point ? 1 : 0.3).get()
                      });
                    }
                  }
                  return false;
                }
              }
            },
            states: {
              hover: {
                enabled: true,
                halo: {
                  size: 0
                }
              }
            }
          }
        },
        series: [{
          type: 'pie',
          name: 'Total Purchases',
          size: '90%',
          innerSize: '96%',
          data: seriesSavings,
          showInLegend: true,
          dataLabels: {
            enabled: false
          },
        }],
        legend: {
          enabled: false,
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          width: 260,
          itemWidth: 10,
          // margin: 2,
          useHTML: true,
          padding: 0,
          itemMarginTop: 10,
          itemMarginBottom: 10,
          x: -80
        },
      });

    }
  }

  useLayoutEffect(() => {
    savingsGraph();
  }, [props.analyticsData]);

  return (
    <Row className='text-center mb-2'>
      <Col xs={2}>
      </Col>
      <Col xs={3}>
        <div style={{display:'flex', height: '400px'}}>
          <div id='container_savings' style={{height: '300px'}}></div>
        </div>
      </Col>
      <Col xs={5} className='text-left mt-5'>
        {
          props.analyticsData.total_savings.detail.map((item, index) => {
            return (
              <Row key={item[0]+item[1]} className='mt-3'>
                <Col xs={1}>
                  <MdFiberManualRecord color={_getSavingsColor(index)} style={{'verticalAlign': 'middle'}} />
                </Col>
                <Col xs={5} className='analytics-savings-name p-0'>
                  {humanizeString(item[0])}
                </Col>
                <Col xs={1}>
                  <IconBadgeSolid Icon={FaInfo} colorIcon='white' color={colors.blue} elemId={'tooltip'+item[0]}>
                    <Row className='p-3'>
                      <Col>
                        {item[0] == 'matchSavings' ? "This number is the total savings accumulated by buying an item's suggested match. This amount is specific to the time period selected." : ''}
                        {item[0] == 'distributorSavings' ? 'This number is the total savings accumulated by general distributor price decreases caused by ' + activeLocation.company.nickname + "'s price comparison. This amount is specific to the time period selected." : ''}
                        {item[0] == 'rebateSavings' ? "This number is the total savings accumulated by buying item's with rebates. This amount is specific to the time period selected." : ''}
                        {item[0] == 'inventoryViewSavings' ? 'This number is the total savings accumulated by buying a lower priced item while using comparison view. This amount is specific to the time period selected.' : ''}
                      </Col>
                    </Row>
                  </IconBadgeSolid>
                </Col>
                <Col xs={5} className='analytics-savings-name'>
                  {numeral(item[1]).format('$0,0.00')}
                </Col>
              </Row>
            );
          })
        }
      </Col>
      <Col xs={2}>
      </Col>
    </Row>
  );
};

export default SavingsGraph;
