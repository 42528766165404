import uniqid from 'uniqid';

export const showModal = (component, props) => {
  return {
    type: 'ADD_MODAL',
    payload: {
      component: component,
      id: `modal-${uniqid()}`,
      props: props
    }
  }
};

export const hideModal = (id) => {
  return {
    type: 'REMOVE_MODAL',
    payload: id
  }
};

export const changeTourMoral = (id) => {
  return {
    type: 'NEXT_TOUR_MODAL',
    payload: id
  }
}