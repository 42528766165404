import { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../reducers/modals/modalsActions";
import { modalMenuClassName, modalFullMenuClassName } from '../../config/constants';
import * as $ from 'jquery';
import { isIOS, isMobile } from "react-device-detect";

const useModal = (id, isMenu, fullMenu) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  const onClosed = () => {
    dispatch(hideModal(id));
  };

  const h = useSelector(({userInterface}) => userInterface.size.height);

  useLayoutEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        const header = $(`#${id} .modal-header`).outerHeight();
        const body = $(`#${id} .modal-body`);
        const footer = $(`#${id} .modal-footer`).outerHeight();
        const style = fullMenu ? `${h-(header ? header : 0)-(footer ? footer : 0)-(isIOS ? 110 : 0)}px` : '80vh';
        body.css(fullMenu ? 'height' : 'max-height', style);
      }, 100);
    }
  }, [h]);

  const toggle = () => setOpen(!open);

  const modalClassName = isMenu ? modalMenuClassName : '';

  return {
    id: id,
    onClosed, isOpen: open, toggle, modalClassName
  }
};

export default useModal;