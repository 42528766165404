import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../../../../SharedComponents/Header/Header';
import { GoPlus } from 'react-icons/go';
import { colors } from '../../../../../config/constants';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import OneInputModal from '../../../../../SharedComponents/Modals/OneInputModal/OneInputModal';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';

const RecipeCostingCategory = ({menuListMap, createMenu, menuList}) => {
  const dispatch = useDispatch();
  const spinner = useSelector(({userInterface}) => userInterface.spinner);
  
  const openCreateMenu = () => {
    const inputProps = {
      title: 'Create Menu Category',
      label: 'Menu Category Name',
      onSave: createMenu,
      placeholder: 'Enter Category Name',
      menu: isMobile,
      fullMenu: true
    };
    dispatch(showModal(OneInputModal, inputProps));
  };

  const rightButton = isMobile ? {icon: <GoPlus onClick={openCreateMenu} color={colors.dark} size={22} />} : {button: {onClick: openCreateMenu, color: 'green', title:'New Menu Category'}};

  return (
    <Fragment>
      <Header title='Menu' showCart={false} rightButton={rightButton} />
      <Container fluid={true} className={'m-0 ' + (isMobile ? 'p-0' : 'pt-3')}>
        {menuListMap}
        <ShowIf show={menuList.length === 0 && !spinner}>
          <Row className='m-0 mt-5 text-center'>
            <Col>
              {"There are no menu here yet!"}
              <br/>
              {"Press the " + ( isMobile ? "'+'" : "'New Menu Category'") + " button above to begin adding a menu."}
            </Col>
          </Row>
        </ShowIf>
      </Container>
    </Fragment>
  );
}

export default RecipeCostingCategory;