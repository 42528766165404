import axios from 'axios';

const get = () => {
  return axios.get('/comparison-views');
};

const viewList = (id) => {
  return axios.get(`/comparison-views/${id}`);
};

const createList = (name) => {
  const params = { name: name };
  return axios.post(`/comparison-views`, params);
};

const updateList = (id, name) => {
  const params = { name: name };
  return axios.patch(`/comparison-views/${id}`, params);
};

const takeInventory = (id, value) => {
  const params = { onInventory: value };
  return axios.patch(`/comparison-views/${id}`, params);
};

const deleteList = (id) => {
  return axios.delete(`/comparison-views/${id}`);
};

const getAllGroups = (params, search) => {
  const filters = [{
    field: "name",
    operator: "like",
    value: search,
    table: "generic_group"
  }];

  return axios.get('/comparison-views/all-groups', {
    params: {
      ...params,
      filters: search ? JSON.stringify(filters) : null
    }
  });
};

const getAllViews = (params) => {

  return axios.get('/comparison-views/all-views', {
    params: {
      ...params,
      expand: 'items,purchased,onHandTotal,orderTotal,globalOnHandTotal,units',
    }
  });
};

const getItemDistributor = (idDistributor) => {
  const params = {
    expand: 'groups',
    id: idDistributor,
    showItems: 1,
    // key : 'nMhe5ekpg4SKVlcT9SxVreSGUwhlDmq3'
  }
  return axios.get('/comparison-views/from-item-distributor', {
    params
  });
};


const deleteGroupItem = (cId, groupId, itemId) => {
  return axios.delete(`/comparison-views/${cId}/groups/${groupId}/item/${itemId}`);
};

const deleteGroupSubRecipeItem = (cId, groupId, itemId) => {
  return axios.delete(`/comparison-views/${cId}/groups/${groupId}/subrecipe/${itemId}`);
};

const deleteGroupCustomItem = (cId, groupId, itemId) => {
  return axios.delete(`/comparison-views/${cId}/groups/${groupId}/custom/${itemId}`);
};

const groups = (id, params) => {
  return axios.get(`/comparison-views/${id}/groups`, {
    params: params
  });
};


const getGroup = (id, groupId) => {
  return axios.get(`/comparison-views/${id}/groups/${groupId}`, {
    params: {
      expand: 'items,purchased,onHandTotal,orderTotal,globalOnHandTotal,units,comparisonView'
    }
  });
};

const getGroupTotals = (id, groupId) => {
  return axios.get(`/comparison-views/${id}/groups/${groupId}`, {
    params: {
      expand: 'purchased,onHandTotal,orderTotal,globalOnHandTotal,units,comparisonView,comparisonViewTotalOnHandAmount'
    }
  });
};

const postGroup = (id, params) => {
  return axios.post(`/comparison-views/${id}/groups`, params);
};

const deleteGroup = (id, groupId) => {
  return axios.delete(`/comparison-views/${id}/groups/${groupId}`);
};

const patchGroup = (id, groupId, params) => {
  return axios.patch(`/comparison-views/${id}/groups/${groupId}`, params);
};

const moveGroup = (id, groupId, params) => {
  return axios.patch(`/comparison-views/${id}/groups/${groupId}/move`, params);
};

const patchGroupItem = (id, groupId, itemId, params) => {
  return axios.patch(`/comparison-views/${id}/groups/${groupId}/item/${itemId}`, params);
};

const postGroupCustomItem = (id, groupId, params) => {
  return axios.post(`/comparison-views/${id}/groups/${groupId}/custom`, params);
};

const patchGroupCustomItem = (id, groupId, itemId, params) => {
  return axios.patch(`/comparison-views/${id}/groups/${groupId}/custom/${itemId}`, params);
};


const patchEditInventoryItem = (inventoryId, itemId, params) => {
  return axios.patch(`/inventory/${inventoryId}/item/${itemId}`, params);
};

const patchGroupSubrecipeItem = (id, groupId, itemId, params) => {
  return axios.patch(`/comparison-views/${id}/groups/${groupId}/subrecipe/${itemId}`, params);
};

const patchGroupItemBatch = (params) => {
  const batch = { batch: params };
  return axios.patch(`/comparison-views/update-item-batch`, batch);
};

const postGroupUnit = (groupId, unitId) => {
  return axios.post(`/comparison-views/group-unit`, [{ groupId: groupId, unitId: unitId }]);
};

const getHistoricInventory = (id, groupId, itemId) => {
  return axios.get(`/comparison-views/${id}/groups/${groupId}/item/${itemId}/historic-inventory`);
};

const getInventory = () => {
  return axios.get(`/comparison-views/inventory`);
};

const getSorts = (id) => {
  return axios.get(`/comparison-views/${id}/get-sorts`);
};

const saveSorts = (id, params) => {
  params = { groups: params };
  return axios.post(`/comparison-views/${id}/reset-sorts`, params);
};

export default {
  get, viewList, createList, updateList, deleteList, takeInventory,
  groups, patchGroup, getAllGroups, getGroup, getGroupTotals, getAllViews,
  patchGroupItem, postGroupUnit,
  postGroup, deleteGroup, getItemDistributor, moveGroup,
  getHistoricInventory, deleteGroupItem,
  patchGroupCustomItem, patchGroupSubrecipeItem, patchGroupItemBatch,
  deleteGroupSubRecipeItem, deleteGroupCustomItem,
  postGroupCustomItem, getInventory, getSorts, saveSorts,patchEditInventoryItem
};
