import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Label, Input, Button } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GoPlus } from 'react-icons/go';
import { MdEdit, MdDelete } from 'react-icons/md';
import validate from 'validate.js';
import { isMobile } from 'react-device-detect';
import useInput from '../../../../../SharedComponents/SharedEffects/useInput';
import Header from '../../../../../SharedComponents/Header/Header';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import QuestionModal from '../../../../../SharedComponents/Modals/QuestionModal/QuestionModal';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import shoppingListsApi from '../../../../../api/shoppingListsApi';
import ShoppingListItemBlock from '../ShoppingListItemBlock/ShoppingListItemBlock';
import shoppingListSvg from '../../../../../assets/images/shopping-list.svg';
import shoppingListAddedOrUpdatedPng from '../../../../../assets/images/shopping-list-added-or-updated.png';

const ShoppingListForm = () => {

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState('');
  const [list, setList] = useState(null);
  const [listItems, setListItems] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const invalidNameMsg = 'Name cannot be blank.';

  const onListNameChange = (listName) => {
    listName = listName + '';
    if (listName.length > 0)  {
      setErrorMsg('');
    } else {
      setErrorMsg(invalidNameMsg);
    }
  };

  const listName = useInput.useText('', {onChange: onListNameChange});

  const validateForm = () => {
    const data = {
      listName: listName.value
    };
    const constrains = {
      listName: {
        length: {
          minimum: 1
        }
      }
    };

    const result = validate(data, constrains);
    return !!!result;
  };

  const addOrUpadateShoppingList = (redirectToItems = false, displayAlert = true) => {
    if (listName.value.length == 0) {
      setErrorMsg(invalidNameMsg);
      return;
    }

    if (list) {
      shoppingListsApi
        .updateList(list.id, {name: listName.value})
        .then(() => {
          const redirectPath = (redirectToItems ? `/shopping-lists/items/${list.id}` : `/shopping-lists/${list.id}`);
          history.push(redirectPath);
          if (displayAlert) {
            dispatch(showModal(AlertModal, {
              message: <div className='text-center'><img className='d-block mx-auto my-5' src={shoppingListAddedOrUpdatedPng} />Your shopping list is edited!</div>,
              footerClass: 'justify-content-center',
              btnLabel: 'Got It',
              btnSize: 'lg'
            }));
          }
        }).catch(err=> {
          console.log(err);
        });
    } else {
      shoppingListsApi
        .addList({name: listName.value, itemList: []})
        .then(({data}) => {
          const redirectPath = (redirectToItems ? `/shopping-lists/items/${data.id}` : `/shopping-lists/${data.id}`);
          history.push(redirectPath);
          dispatch(showModal(AlertModal, {
            message: <div className='text-center'><img className='d-block my-5 mx-auto' src={shoppingListAddedOrUpdatedPng} />Your shopping list is added!</div>,
            footerClass: 'justify-content-center',
            btnLabel: 'Got It',
            btnSize: 'lg'
          }));
        }).catch(err=> {
          console.log(err);
        });
    }
  };

  const addOrUpadateShoppingListAndRedirectToList = () => {
    addOrUpadateShoppingList(false, true);
  };

  const openDeleteListModal = () => {
    const modelProps = {
      message: 'Are you sure you want to delete this shopping list?',
      leftButton: {
        title: 'Yes',
        color: 'orange',
        onClick: () => {
          shoppingListsApi.deleteList(params.id).then(() => {
            history.push('/shopping-lists');
          }).catch(err=> {
            console.log(err);
          });
        }
      },
      rightButton: {
        title: 'No',
        color: 'green'
      }
    }
    dispatch(showModal(QuestionModal, modelProps));
  };

  const rightButton = {button: {onClick: addOrUpadateShoppingListAndRedirectToList, color: 'green', title: (params.id ? 'Update' : 'Add')}};

  const getshoppingListItems = (page) => {
    setIsLoading(true);
    shoppingListsApi.getListItems(params.id, page).then(res => {
      setListItems(listItems.concat(res.data));
      setPage(page);
      setLoadMore(res._meta.pageCount > res._meta.currentPage);
      setIsLoading(false);
    }).catch(err=> {
      console.error('getListItems get Error:', err);
    });
  };

  const scrollListener = () => {
    const threshold = 600;
    if (window.innerHeight + document.documentElement.scrollTop + threshold >= document.documentElement.offsetHeight
      && loadMore && !isLoading) {
        getshoppingListItems(page+1);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('mousewheel', scrollListener);
      window.addEventListener('scroll', scrollListener);
      window.addEventListener('resize', scrollListener);
      return () => {
        window.removeEventListener('mousewheel', scrollListener);
        window.removeEventListener('scroll', scrollListener);
        window.removeEventListener('resize', scrollListener);
      };
    }
  }, [page, loadMore, isLoading]);

  useEffect(() => {
    if (params.id) {
      shoppingListsApi.getListById(params.id).then(({data}) => {
        setList(data);
        listName.set(data.name);
      }).catch(err=> {
        if (history.location.pathname.startsWith('/shopping-lists/update-list')) {
          history.push('/shopping-lists');
        }
        console.error('getListById get Error:', err);
      });

      if (!isMobile) {
        getshoppingListItems(page);
      }
    }
  }, []);


  const deleteShoppingListItem = (itemDistributorId) => {
    shoppingListsApi.deleteListItem(params.id, itemDistributorId).then(() => {
      setListItems(listItems.filter(item => item.itemDistributorId != itemDistributorId));
    }).catch(err=> {
      console.log(err);
    });
  };

  const listItemsMap = listItems.map(item => <Col xs={3} key={`shopping-list-item-${item.itemId}-${item.itemDistributorId}`}><ShoppingListItemBlock onDelete={deleteShoppingListItem}>{item}</ShoppingListItemBlock></Col>);

  const backFunction = () => {
    if (params && params.id) {
      history.push(`/shopping-lists/${params.id}`);
    } else {
      history.push('/shopping-lists');
    }
  };

  if (isMobile) {
    return (
      <Container fluid={true} className='m-0 p-0'>
        <Header showMenu={false} showLogo={false} showBack={true} backFunct={backFunction} title={params.id ? 'Edit Shopping List' : 'Add Shopping List'} showCart={false} rightButton={rightButton} />
        <Container fluid={true}>
          <Row>
            <Col xs={12} className='my-5 text-center'>
              <img src={shoppingListSvg} />
            </Col>
          </Row>
          <ShowIf show={errorMsg}>
            <Row>
              <Col xs={12}>
                <div className='text-danger'>
                  { errorMsg }
                </div>
              </Col>
            </Row>
          </ShowIf>
          <Row>
            <Col xs={12} className='mb-3'>
              <Input type='text' value={listName.value} onChange={listName.set} style={{minHeight: '42px'}} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Button className='btn btn-lg btn-block btn-green' disabled={!validateForm()} onClick={() => addOrUpadateShoppingList(true, false)}>
                {params.id ? 'Edit' : 'Add'} Items
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }

  return (
    <Container fluid={true} className='m-0 p-0'>
      <Header showMenu={false} showLogo={false} showBack={true} backFunct={backFunction} title={params.id ? 'Edit Shopping List' : 'Add Shopping List'} showCart={false} rightButton={rightButton} />
      <Container fluid={true}>
        <Row>
          <Col xs={12} className='mb-3'>
            <Card className='mt-4 mb-3 py-4'>
              <Col xs={12}>
                <Label className='font-weight-bold'>
                  {params.id ? 'Edit' : 'Add'} List Name
                </Label>
              </Col>
              <Col xs={12}>
                <ShowIf show={errorMsg}>
                  <div className='text-danger'>
                    { errorMsg }
                  </div>
                </ShowIf>
                <Row>
                  <Col xs={6}>
                    <Input type='text' value={listName.value} onChange={listName.set} style={{minHeight: '42px'}} />
                  </Col>
                  <Col xs={3}>
                    <Button className='btn btn-lg btn-block btn-green' disabled={!validateForm()} onClick={() => addOrUpadateShoppingList(true, false)}>
                      <GoPlus size={22} style={{marginTop: '-5px'}} /> Add New Items
                    </Button>
                  </Col>
                  <Col xs={3}>
                    <ShowIf show={params.id}>
                      <Button className='btn btn-lg btn-block btn-orange' onClick={openDeleteListModal}>
                        <MdDelete size={22} style={{marginTop: '-5px'}} /> Delete This List
                      </Button>
                    </ShowIf>
                  </Col>
                </Row>
              </Col>
            </Card>
          </Col>
        </Row>

        <Row>
          {listItemsMap}
        </Row>

      </Container>
    </Container>
  );

};

export default ShoppingListForm;
