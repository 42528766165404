import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from "reactstrap";
import { IoMdClose, IoMdSearch } from "react-icons/io";
import { colors } from '../../config/constants';
import ShowIf from '../ShowIf/ShowIf';

const InputSearch = (props) => {
  const { onChange, value, clearValue, onEnter } = props;

  const onEnterKey = ({key}) => {
    if (onEnter) {
      if (key === 'Enter') {
        onEnter(value);
      }
    }
  };

  return (
    <Fragment>
      <ShowIf show={!value}>
        <IoMdSearch className='search-placeholder' color={colors.lightBlueText} />
      </ShowIf>
      <ShowIf show={value}>
        <IoMdClose onClick={clearValue} className='search-placeholder clickable' color={colors.lightBlueText} />
      </ShowIf>
      <Input className='search-input' type='text' placeholder='Search...'
        onChange={onChange} onKeyDown={onEnterKey}
        value={value}
      />
    </Fragment>
  );
};

InputSearch.propTypes  = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  clearValue: PropTypes.func.isRequired,
  onEnter: PropTypes.func
}

export default InputSearch;