import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button, CustomInput } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { colors } from '../../../../../../config/constants';
import useModal from '../../../../../../SharedComponents/Modals/useModal';
import useInput from "../../../../../../SharedComponents/SharedEffects/useInput";

const OrderReceiveCheckedModal = (props) => {
  const { id, menu, fullMenu, onSave } = props;
  const modalProps = useModal(id, menu, fullMenu);

  const [question1, setQuestion1] = useState(false);
  const [question2, setQuestion2] = useState(false);
  const [question3, setQuestion3] = useState(false);
  const [question4, setQuestion4] = useState(false);
  const [question5, setQuestion5] = useState(false);
  const { value: comment, set: setComment } = useInput.useText();

  const onChangeChecked = (question, res) => {
    switch (question) {
      case 1:
        setQuestion1(res);
        break;
      case 2:
        setQuestion2(res);
        break;
      case 3:
        setQuestion3(res);
        break;
      case 4:
        setQuestion4(res);
        break;
      case 5:
        setQuestion5(res);
        break;
      default:
        break;
    }
  }

  const clickSave = () => {
    if (onSave) {
      const data = {
        question_1: question1,
        question_2: question2,
        question_3: question3,
        question_4: question4,
        question_5: question5,
        comment: comment
      }
      onSave(data);
    }
    modalProps.toggle();
  };


  return (
    <Modal {...modalProps}>
      <ModalHeader toggle={modalProps.toggle}>Order Checked</ModalHeader>
      <ModalBody className='px-4'>
        <Row className='py-2'>
          <Col>
            Did driver arrive during agreed time?
                    </Col>
        </Row>
        <Row className='py-2'>
          <Col xs={3}>
            <CustomInput id='idQ1_1' type='checkbox' label='Yes' checked={question1 === 1} onChange={() => onChangeChecked(1, 1)} />
          </Col>
          <Col xs={3}>
            <CustomInput id='idQ1_2' type='checkbox' label='No' checked={question1 === 0} onChange={() => onChangeChecked(1, 0)} />
          </Col>
          <Col xs={6}>
          </Col>
        </Row>
        <Row className='py-2'>
          <Col>
            Were all weighted items verified?
                    </Col>
        </Row>
        <Row className='py-2'>
          <Col xs={3}>
            <CustomInput id='idQ2_1' type='checkbox' label='Yes' checked={question2 === 1} onChange={() => onChangeChecked(2, 1)} />
          </Col>
          <Col xs={3}>
            <CustomInput id='idQ2_2' type='checkbox' label='No' checked={question2 === 0} onChange={() => onChangeChecked(2, 0)} />
          </Col>
          <Col xs={6}>
          </Col>
        </Row>
        <Row className='py-2'>
          <Col>
            Were there any product substitutions?
                    </Col>
        </Row>
        <Row className='py-2'>
          <Col xs={3}>
            <CustomInput id='idQ3_1' type='checkbox' label='Yes' checked={question3 === 1} onChange={() => onChangeChecked(3, 1)} />
          </Col>
          <Col xs={3}>
            <CustomInput id='idQ3_2' type='checkbox' label='No' checked={question3 === 0} onChange={() => onChangeChecked(3, 0)} />
          </Col>
          <Col xs={6}>
          </Col>
        </Row>
        <Row className='py-2'>
          <Col>
            Were there any missing items?
                    </Col>
        </Row>
        <Row className='py-2'>
          <Col xs={3}>
            <CustomInput id='idQ4_1' type='checkbox' label='Yes' checked={question4 === 1} onChange={() => onChangeChecked(4, 1)} />
          </Col>
          <Col xs={3}>
            <CustomInput id='idQ4_2' type='checkbox' label='No' checked={question4 === 0} onChange={() => onChangeChecked(4, 0)} />
          </Col>
          <Col xs={6}>
          </Col>
        </Row>
        <Row className='py-2'>
          <Col>
            Do you have any additional questions?
                    </Col>
        </Row>
        <Row className='py-2'>
          <Col xs={3}>
            <CustomInput id='idQ5_1' type='checkbox' label='Yes' checked={question5 === 1} onChange={() => onChangeChecked(5, 1)} />
          </Col>
          <Col xs={3}>
            <CustomInput id='idQ5_2' type='checkbox' label='No' checked={question5 === 0} onChange={() => onChangeChecked(5, 0)} />
          </Col>
          <Col xs={6}>
          </Col>
        </Row>
        <Row>
          <Col>
            <textarea id='idComment' className='w-100' placeholder='Please be as specific as possible...' onChange={setComment} value={comment}></textarea>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color='green' className={isMobile ? 'btn-block' : ''} onClick={clickSave}>Submit</Button>
      </ModalFooter>
    </Modal >
  )

}

export default OrderReceiveCheckedModal;