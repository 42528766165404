import React, { Fragment, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Tooltip } from 'reactstrap';
import MinusButton from '../MinusButton/MinusButton';
import PlusButton from '../PlusButton/PlusButton';
import { isMobile } from 'react-device-detect';
import useOnHandItemInterface from './useOnHandItemInterface';
import useConversion from '../Conversion/useConversion';
import { InventoryContext } from "../../Views/MainApp/Views/PlaceOrder/PlaceOrderInventory/PlaceOrderInventoryItem/context/InventoryContext";
import { onHandTypes } from '../../config/constants';
import { useSelector } from 'react-redux';
import ShowIf from '../ShowIf/ShowIf';

const OnHandItem = ({ onHandType, item, group, groupTotalProps }) => {
  const { disabled } = useOnHandItemInterface.useInputConnection();
  
  const { id: groupId, comparisonViewId } = group;
  const { unitQuantity, unitSize, packSizeUom, unitToConvert, isCustomPackSize, customPackSize, packSize } = item;
  const isStartInventory = useSelector(({ user }) => user.activeLocation.activeInventory);

  const inventoryContext = useContext(InventoryContext);
  const { OH, OHUom, OHPack } = inventoryContext;

  const onHandProps = { comparisonViewId, groupId, onHandType, ...item };

  const getHandBase = (onHandType) => {
    switch (onHandType) {
      case onHandTypes.cs: return OH; break;
      case onHandTypes.pack: return OHPack; break;
      case onHandTypes.uom: return OHUom; break;
      default: break;
    }
  };
  let onHandBase = getHandBase(onHandType);
  onHandBase = (onHandBase === undefined || onHandBase === null || onHandBase === '') ? 0 : onHandBase;
  const [onHandAmount, setOnHandAmount] = useState(onHandBase);

  const { updateOnHand, updateTotalInit } = useOnHandItemInterface.useOnHandUpdate(onHandProps, setOnHandAmount, groupTotalProps);
  const { valid } = useOnHandItemInterface.useOnHandValidate();

  let finalPackSize = isCustomPackSize ? customPackSize : packSize;
  let finalPackSizeUom = packSizeUom;

  if (item.unitToConvert.label !== 'Original') {
    finalPackSize = useConversion.usePackSize(unitQuantity, unitSize, packSizeUom, unitToConvert.value);
    finalPackSizeUom = finalPackSize ? unitToConvert.label : finalPackSizeUom;
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    updateTotalInit(OH, OHUom, OHPack);
  }, []);

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      updateOnHand(onHandAmount, onHandAmount, OH, OHUom, OHPack);
    }
  };

  const onHandHandler = (e) => {
    if (!valid(e.target.value)) { return; }
    updateOnHand(e.target.value, onHandAmount, OH, OHUom, OHPack);
  };

  const onHandClick = (newOnHandAmount) => {
    if (!valid(newOnHandAmount)) { return; }
    updateOnHand(newOnHandAmount, onHandAmount, OH, OHUom, OHPack);
  };



  return (
    <Fragment>
      <Col className={'w-fc justify-content-end' + (isMobile ? ' pr-1 pl-0' : ' pl-0')}>
        <MinusButton square onClick={() => { onHandClick(onHandAmount === '' ? parseFloat(0) : parseFloat(onHandAmount) - 1) }} isBlue isActive={onHandAmount > 0} disabled={disabled} />
      </Col>
      <Col className={'p-0 justify-content-center' + (isMobile ? '' : ' w-squared-input ')}>
        <Input type='text' className={isMobile ? 'text-center p-0' : ''} value={onHandAmount} onChange={onHandHandler} onKeyPress={handleKeypress} placeholder='0' disabled={disabled ? 'disabled' : ''} />
      </Col>
      <ShowIf show={!isStartInventory}>
        <Col className={'col w-fc justify-content-left ' + (isMobile ? ' px-1' : '')} id={`packsize_item_${onHandType}_${item.id}`}>
          {onHandType === onHandTypes.cs ? item.unitMeasure : (!finalPackSizeUom ? '' :  (finalPackSizeUom.length > 3 ? '...' : finalPackSizeUom))}
        </Col>
        {
          !finalPackSizeUom ? '' : 
            (finalPackSizeUom.length > 3 && (onHandType !== onHandTypes.cs) ? 
            <Tooltip
              placement='top-start'
              isOpen={tooltipOpen}
              target={`packsize_item_${onHandType}_${item.id}`}
              toggle={toggle}
            >
              {finalPackSizeUom}
            </Tooltip> : '')
        }
        
      </ShowIf>

      <Col className={'w-fc p-0 justify-content-start ' + (isStartInventory ? " pl-3" : "")}>
        <PlusButton square onClick={() => { onHandClick(onHandAmount === '' ? parseFloat(0) : parseFloat(onHandAmount) + 1) }} isBlue isActive={onHandAmount > 0} disabled={disabled} />
      </Col>
    </Fragment>
  );
};


OnHandItem.propTypes = {
  onHandType: PropTypes.string
};

OnHandItem.defaultProps = {
  onHandType: onHandTypes.cs
};

export default OnHandItem;