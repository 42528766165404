import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useOnHandItem from './useOnHandItem';
import useOnHandItemOffline from './useOnHandItemOffline';
import { localStorageConst } from '../../config/constants';

const useOnHandUpdate = (onHandProps, setOnHandAmount, groupTotalProps) => {
  const storeConnection = useSelector(({ connection }) => connection.online);
  const { updateOnHandAmount, updateTotalInit, updateOnHandAmountEdit } = useOnHandItem.useOnHandUpdate(onHandProps, setOnHandAmount, groupTotalProps);
  const { updateOnHandAmountOffline } = useOnHandItemOffline.useOnHandUpdateOffline(onHandProps, setOnHandAmount, groupTotalProps);

  const updateOnHand = (onHandAmount, prevOnHandAmount, OH, OHUom, OHPack) => {
    const localConnection = JSON.parse(localStorage.getItem(localStorageConst.keys.connection));
    const currentConnection = (localConnection === undefined) ? storeConnection : localConnection;

    if (currentConnection) {
      updateOnHandAmount(onHandAmount, prevOnHandAmount, OH, OHUom, OHPack);
    } else {
      updateOnHandAmountOffline(onHandAmount, prevOnHandAmount, OH, OHUom, OHPack);
    }
  };

  const updateOnHandEdit = (onHandAmount, prevOnHandAmount, OH, OHUom, OHPack, inventoryId, itemId) => {

    updateOnHandAmountEdit(onHandAmount, prevOnHandAmount, OH, OHUom, OHPack, inventoryId, itemId);

  };

  return { updateOnHand, updateTotalInit, updateOnHandEdit };

};

const useOnHandValidate = () => {

  const valid = (value) => {
    const re = /^\d*\.?(?:\d{1,2})?$/;

    if (value === '' || value === 0 || re.test(value)) {
      return true;
    }
    if (value < 0) {
      return false;
    }
    return false;
  };

  return { valid };

};

const useInputConnection = () => {
  const [disabled, setDisabled] = useState(false);
  const connection = useSelector(({ connection }) => connection.online);

  const lists = useSelector(({ items }) => items.inventory.list);
  const listSelected = useSelector(({ items }) => items.inventory.listSelected);
  const selected = !!lists ? lists.filter(l => l.id === listSelected.id)[0] : null;

  useEffect(() => {
    if (!!selected) {
      setDisabled((connection && selected.onInventory) ? true : false);
    }
  }, [connection, lists, listSelected]);

  return { disabled, setDisabled }
};

export default {
  useOnHandUpdate, useOnHandValidate, useInputConnection
};
