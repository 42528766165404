import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import { colors } from '../../../../../config/constants';
import IconBadge from '../../../../../SharedComponents/IconBadge/IconBadge';
import { FaExclamation } from 'react-icons/fa';
import numeral from 'numeral';


const SavingsFirstCard = (props) => {


    const { cartItem } = props;
    const price = `${numeral(cartItem.price).format('$0,0.00')} ${cartItem.unitMeasure}`;
    const newImage = cartItem.imageUrl ? cartItem.imageUrl : (cartItem.brandImageUrl ? cartItem.brandImageUrl : null);

    return (
        <Card>
            <Row className='contentSavings'>
                <ShowIf show={!isMobile}>
                    <Col xs='4' className='clickable content-align-center'>
                        <img src={newImage}></img>
                    </Col>
                    <Col xs='8' className='details clickable'>
                        <Row>
                            <h3>{cartItem.description}</h3>
                        </Row>
                        <Row>
                            <p>{cartItem.din}</p>
                        </Row>
                        <Row>
                            <Col>
                                <p>{cartItem.brand}</p>
                            </Col>
                            <Col>
                                <p>{cartItem.distributor}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='form-inline pop'>
                                <h3>{price}</h3>
                                <IconBadge idPop='SAVINGPop1' text='This price is outdated' FaIcon={FaExclamation} size={8} color={colors.white} />

                            </Col>
                            <Col>
                                <h3>{cartItem.packSize}</h3>
                            </Col>
                        </Row>
                    </Col>
                </ShowIf>

                <ShowIf show={isMobile}>
                    <div className='m-auto pb-4'>
                        <img src={newImage}></img>
                    </div>
                    <div className='details'>
                        <Row className='mb-4'>
                            
                                <h3>{cartItem.description}</h3>
                            
                        </Row>
                        <Row>
                            <Col xs='6'>
                                <p>{cartItem.din}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='6'>
                                <p>{cartItem.brand}</p>
                            </Col>
                            <Col xs='6'>
                                <p>{cartItem.distributor}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='6' className='form-inline pop'>
                                <h3>{price}</h3>
                            </Col >
                            <Col xs='6'>
                                <h3>{cartItem.packSize}</h3>
                            </Col>
                        </Row>
                    </div>

                </ShowIf>
                <div className='orderGuideIcon'>
                    <span className={isMobile ? 'iconText iconTextM  right' : 'iconText right'} >ORDER GUIDE</span>
                </div>
            </Row>
        </Card >
    )
};


export default SavingsFirstCard

