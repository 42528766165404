import { useState } from "react";
import validate from 'validate.js';
import useInput from "../../SharedComponents/SharedEffects/useInput";
import authenticateApi from "../../api/authenticateApi";
import { useDispatch, useSelector } from "react-redux";
import { setUserData, setUserActiveLocation } from "../../reducers/user/userAction";
import { saveInventoryOnHandTotal } from '../../reducers/items/itemsActions';
import { localStorageConst, placeOrderViews } from '../../config/constants';
import { saveView } from '../../reducers/items/itemsActions';
import { saveConnection } from '../../reducers/connection/connectionActions';
import useFullStory from '../../SharedComponents/FullStory/useFullStory';

const useLogin = () => {
  const dispatch = useDispatch();
  const {identifyFullStory} = useFullStory.useActions();

  const doLogin = (data) => {
    if (!data.authKey) {
      data.authKey = localStorage.getItem(localStorageConst.keys.authKey);
    }
    const activeLocation = JSON.stringify(data.activeLocation);
    const locations = JSON.stringify(data.locations);
    dispatch(setUserActiveLocation(data.activeLocation));
    dispatch(saveInventoryOnHandTotal(data.activeLocation ? data.activeLocation.onHandTotal : 0));
    localStorage.setItem(localStorageConst.keys.userName, data.firstName +' '+ data.lastName);
    localStorage.setItem(localStorageConst.keys.authKey, data.authKey);
    localStorage.setItem(localStorageConst.keys.currentRole, data.currentRole);
    localStorage.setItem(localStorageConst.keys.activeLocation, activeLocation);
    localStorage.setItem(localStorageConst.keys.locations, locations);
    dispatch(setUserData(data));
    const defaultView = data && data.activeLocation && data.activeLocation.display_comparison_view ? placeOrderViews.inventory : placeOrderViews.list;
    localStorage.setItem(localStorageConst.keys.view, defaultView);
    dispatch(saveView(defaultView));
    localStorage.setItem(localStorageConst.keys.connection, true);
    dispatch(saveConnection(true));

    identifyFullStory(data.activeLocation.id, data.activeLocation.nickname, data.email);
  };

  return { doLogin };
}

const useForm = () => {
  const companyId = useSelector(state => state.company.id);
  const [error, setError] = useState({});
  const { set : setEmail, value: email } = useInput.useText();
  const { set : setPassword, value: password } = useInput.useText();
  const { doLogin } = useLogin();

  const onSubmit = (e) => {
    e.preventDefault();
    const form = {
      email: email,
      password: password
    };
    const constrains = {
      email: {
        presence: {
          allowEmpty: false
        },
        email: true
      },
      password: {
        presence: {
          allowEmpty: false
        }
      }
    };
    const result = validate(form, constrains);
    setError(result ? result : {});
    if (!result) {
      const params = {
        secret: "asd123",
        email, password,
        company_id: companyId
      };
      authenticateApi.post(params).then(({data}) => {
        doLogin(data);
      }).catch(err => {
        console.error(err);
        const status = err.code;
        if (status === 400) {
          setError({error: err.data.message});
        } else {
          setError({error: 'Something went wrong.'});
        }
      });
    }
  };

  return { error, onSubmit, setEmail, setPassword, password, email }
};

export default {
  useForm, useLogin
};
