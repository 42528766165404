import { useState, useEffect, useCallback } from 'react';
import itemApi from '../../api/itemApi';
import cartApi from '../../api/cartApi';
import comparisonViewsApi from '../../api/comparisonViewsApi';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveCartItems, saveUnsyncedItems, saveLastModified, saveCartCount, saveAnimation, saveOrderTotal } from '../../reducers/cart/cartActions';
import { showModal } from '../../reducers/modals/modalsActions';
import ItemUnavailableModal from '../Modals/ItemUnavailableModal/ItemUnavailableModal';
import PunchedOutModal from '../Modals/PunchedOutModal/PunchedOutModal';
import { debounce } from 'lodash';
import { delay, itemCart } from '../../config/constants';
import useGoTo from '../GoTo/useGoTo';
import AlertModal from '../Modals/AlertModal/AlertModal';
import { cartAnimations, processStatus, localStorageConst } from '../../config/constants';


const useDebounce = (callback, delay) => {
  const d = callback;
  const callbackfunc = useCallback(debounce(d, delay), []);
  return [callbackfunc];
};

const useCartUpdate = (setCartAmount, groupTotalProps) => {

  const dispatch = useDispatch();
  const { reviewOrder } = useGoTo.useActions();
  const { validate } = useCartValidate();

  const cartCount = useSelector(({ cart }) => cart.cartCount);
  const [prevCartCount, setPrevCartCount] = useState(cartCount);

  const [updating, setUpdating] = useState(processStatus.start);
  const [operation, setOperation] = useState('');
  const [differ, setDiffer] = useState(0);
  const [prevCartAmount, setPrevCartAmount] = useState(0);

  const [uItem, setUItem] = useState(null);
  const [uParentItem, setUParentItem] = useState(null);
  const [uNewCartAmount, setUNewCartAmount] = useState(0);
  const [uSameModifiedItems, setUSameModifiedItems] = useState(null);


  const cartItems = useSelector(({ cart }) => cart.cartItems);
  const { updateItemsInCart } = useCartItemUpdate(cartItems);

  const verifyUpdate = () => {
    let verify = false;
    const item = uItem; const parentItem = uParentItem; const newCartAmount = uNewCartAmount; const sameModifiedItems = uSameModifiedItems;
    if (!item) return verify;

    if (!item.itemDistributorId && !item.shoppingCartId) return verify;

    //Verify sameModifiedItems
    const alertModalprops = {
      title: 'Alert',
      message: 'It appears, someone was shopping in your account at the same time. Please review your cart to make sure your order is correct.',
      footerClass: 'justify-content-center',
      btnLabel: 'Continue',
      btnSize: 'lg',
      color: 'dark-blue',
      onOk: reviewOrder
    };
    if (sameModifiedItems.length > 0) {
      dispatch(showModal(AlertModal, alertModalprops));
      return;
    }

    //Verify updated Item
    // const nCartCount = operation === 'add' ? parseFloat(prevCartCount + differ) : parseFloat(prevCartCount - differ);
    // if (cartCount !== nCartCount) {
    //   const caseQty = operation === 'add' ? newCartAmount - differ : newCartAmount + differ;
    //   if (setCartAmount !== undefined && setCartAmount !== null) {
    //     setCartAmount(caseQty);
    //     dispatch(saveAnimation({ type: cartAnimations.type.cartUpdated, data: null }));
    //   }
    //   return;
    // }

    //Verify savings
    if (parentItem) {
      if (item.savings > 0) {
        if (operation === 'add') {
          dispatch(saveAnimation({ type: cartAnimations.type.savingItemAdded, data: null }));
        }
      }
    }

    //Verify rebates
    if (item.rebate !== null && item.rebate > 0) {
      if (operation === 'add') {
        const data = (parseFloat(newCartAmount) * parseFloat(item.rebate));
        dispatch(saveAnimation({ type: cartAnimations.type.rebatesEarned, data: data }));
      }
    }

    //Update Totals on Inventory view
    if (groupTotalProps) {
      const { setTotalGroupSpent, comparisonViewId, id } = groupTotalProps;
      comparisonViewsApi.getGroupTotals(comparisonViewId, id).then((res) => {
        setTotalGroupSpent(res.data.purchased);
      }).catch(err => console.error(err));
    }

    updateItemsInCart(item, newCartAmount);
  };

  const getPreviousValue = (item) => {
    const pCartItems = cartItems;
    let preValue = 0;
    if (!!item.itemDistributorId) {
      preValue = pCartItems.filter(pci => pci.itemDistributorId === item.itemDistributorId)[0];
    }
    if (!!item.shoppingCartId) {
      preValue = pCartItems.filter(pci => pci.shoppingCartId === item.shoppingCartId)[0];
    }

    return preValue;
  };

  const update = (newCartAmount, oldCartAmount, item, parentItem, setCartAmount) => {

    const prevCartAmt = getPreviousValue(item);
    setPrevCartAmount(prevCartAmt);
    setOperation(newCartAmount > prevCartAmount ? 'add' : 'sub');
    setUpdating(processStatus.processing);

    let itemList = []; let params = [];
    const nLastModified = localStorage.getItem(localStorageConst.keys.lastModifiedCart);

    if (item.itemDistributorId !== null) {
      let nitemList = {
        ...item.itemDistributorId ?
          { itemDistributorId: item.itemDistributorId }
          : { shoppingCartId: item.shoppingCartId },
        quantity: parseInt(newCartAmount),
        description: item.name ? item.name : item.description
      };
      itemList.push(nitemList);

      if (parentItem !== undefined && parentItem !== null) {
        const swapParams = {
          match_id: item.matchId,
          current_item_id: item.itemDistributorId,
          new_item_id: parentItem.itemDistributorId
        };

        // itemApi.swapAccept(swapParams).then(res => {
          let newItemList = itemList.map((iteml) => {
            // return { ...iteml, swapAcceptId: res.data.id, caseSavings: item.caseSavings };
            return { ...iteml };
          });

          params = {
            itemList: newItemList,
            lastModified: nLastModified
          };

          cartApi.update(params).then(res => {

            setDiffer(prevCartAmt > newCartAmount ? prevCartAmt - newCartAmount : newCartAmount - prevCartAmt);
            // setDiffer(prevCartCount > parseFloat(res.data.count) ? prevCartCount - parseFloat(res.data.count) : parseFloat(res.data.count) - prevCartCount);
            dispatch(saveLastModified(res.data.lastModified));
            localStorage.setItem(localStorageConst.keys.lastModifiedCart, res.data.lastModified);
            setUSameModifiedItems(res.data.sameModifiedItems);
            setUpdating(processStatus.end);

            if (newCartAmount === 0) setCartAmount(newCartAmount);

          }).catch(error => { console.log(error) });

        // }).catch(error => { console.log(error) });

      } else {

        params = {
          itemList: itemList,
          lastModified: nLastModified
        };

        cartApi.update(params).then(res => {


          setDiffer(prevCartAmt > newCartAmount ? prevCartAmt - newCartAmount : newCartAmount - prevCartAmt);
          // setDiffer(prevCartCount > parseFloat(res.data.count) ? prevCartCount - parseFloat(res.data.count) : parseFloat(res.data.count) - prevCartCount);
          dispatch(saveLastModified(res.data.lastModified));
          localStorage.setItem(localStorageConst.keys.lastModifiedCart, res.data.lastModified);
          setUSameModifiedItems(res.data.sameModifiedItems);
          setUpdating(processStatus.end);

          if (newCartAmount === 0) setCartAmount(newCartAmount);


        }).catch(error => { console.log(error) });
      }

    }
  };

  const updateGroup = async (newCartAmount, oldCartAmount, item, parentItem, setCartAmount) => {

    const prevCartAmt = getPreviousValue(item);
    setPrevCartAmount(prevCartAmt);
    setOperation(newCartAmount > prevCartAmount ? 'add' : 'sub');
    setUpdating(processStatus.processing);

    let itemList = []; let params = [];
    const nLastModified = localStorage.getItem(localStorageConst.keys.lastModifiedCart);

    if (item.itemDistributorId !== null) {
      let nitemList = {
        ...item.itemDistributorId ?
          { itemDistributorId: item.itemDistributorId }
          : { shoppingCartId: item.shoppingCartId },
        quantity: parseInt(newCartAmount),
        description: item.name ? item.name : item.description
      };
      itemList.push(nitemList);

      params = {
        itemList: itemList,
        lastModified: nLastModified
      };
      return cartApi.update(params);

    }
  };
  const [callback] = useDebounce(update, delay.cart);


  const updateCartAmount = (newCartAmount, item, setCartAmount, cartAmount, parentItem) => {
    setUItem(item);
    setUParentItem(parentItem);
    setUNewCartAmount(newCartAmount);

    if (!validate(item, newCartAmount)) {
      return;
    }

    if (newCartAmount === '') {
      setCartAmount(newCartAmount);
      return;
    }

    if (setCartAmount && newCartAmount != 0) {
      setCartAmount(newCartAmount);
    }

    callback(newCartAmount, cartAmount, item, parentItem, setCartAmount);

  };

  const updateCartAmountGroup = async (newCartAmount, item, setCartAmount, cartAmount, parentItem) => {
    setUItem(item);
    setUParentItem(parentItem);
    setUNewCartAmount(newCartAmount);

    if (!validate(item, newCartAmount)) {
      return;
    }

    if (newCartAmount === '') {
      setCartAmount(newCartAmount);
      return;
    }

    if (setCartAmount && newCartAmount != 0) {
      setCartAmount(newCartAmount);
    }

    return updateGroup(newCartAmount, cartAmount, item, parentItem, setCartAmount);

  };

  useEffect(() => {
    if (updating === processStatus.end) {
      verifyUpdate();
    }
  }, [updating]);

  return { updateCartAmount, updateCartAmountGroup };
};

const useCartValidate = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const validate = (item, value) => {

    if (!!item.isUnpurchasable && item.isUnpurchasable === 1) {
      const props = {
        title: 'Call-In Item',
        message: 'This item is not available for purchase.',
        footerClass: 'justify-content-center',
        btnLabel: 'Ok',
        btnSize: 'lg',
        color: 'green'
      };
      dispatch(showModal(AlertModal, props));
      return false;
    }

    if (!!item.status && item.status !== 'active') {
      dispatch(showModal(ItemUnavailableModal));
      return false;
    }

    if (!!item.isPunchedOut && item.isPunchedOut === 1) {
      if (history.location.pathname !== '/checkout-ordering/review-order') {
        const mprops = {
          item: item
        }
        dispatch(showModal(PunchedOutModal, mprops));
        return false;
      }
    }

    return true;
  };

  const valid = (value) => {
    const re = /^[0-9\b]+$/;
    if (value === '' || value === 0 || re.test(value)) {
      return true;
    }
    if (value < 0) {
      return false;
    }
    return false;
  };

  return { validate, valid };

};

const useCartItemMerge = (item) => {
  const cartItems = useSelector(({ cart }) => cart.cartItems);
  const [caseQuantity, setCaseQuantity] = useState(0);

  const mergeItemWithCart = () => {
    cartItems.map((ci) => {
      if (!!ci.itemDistributorId) {
        if (item.itemDistributorId === ci.itemDistributorId) {
          setCaseQuantity(ci.caseQuantity);
        }
      }
    });
  };

  useEffect(() => {
    mergeItemWithCart()
  }, []);

  return {
    caseQuantity, setCaseQuantity
  };
};

const useCartItemUpdate = (prevCartItems) => {
  const dispatch = useDispatch();
  let newCartItems = prevCartItems;

  const updateItemsInCart = (item, newCartAmount) => {
    let itemFound = false;
    let totalQty = 0;
    let totalOrder = 0;
    let index = null;

    newCartItems.map((ci, i) => {
      if (ci.isSpecialItem) {
        if (item.shoppingCartId === ci.shoppingCartId) {
          itemFound = true;
          ci.caseQuantity = newCartAmount;
          if (newCartAmount === 0) { index = i; }
        }
      } else {
        if (item.itemDistributorId === ci.itemDistributorId) {
          itemFound = true;
          ci.caseQuantity = newCartAmount;
          if (newCartAmount === 0) { index = i; }
        }
      }
      totalQty = totalQty + parseInt(ci.caseQuantity);
      totalOrder = totalOrder + (parseFloat(ci.caseQuantity) * parseFloat(ci.price));
    });

    if (!itemFound) {
      const newItem = (!item.itemDistributorId) ? {
        caseQuantity: parseFloat(newCartAmount),
        price: 0,
        id: Math.random(),
        distributorId: parseInt(item.distributorId),
        din: null,
        packSize: null,
        // unitMeasure: item.uom,
        unit: item.uom,
        description: item.description,
        searchText: undefined,
        rebate: undefined,
        swapAcceptId: undefined,
        isSpecialItem: true,
        distributor: null,
        shoppingCartId: null,
        isMarketPrice: 0,
        active: 0,
        caseSavings: undefined,
        belongsToGroup: 0
      } : {
          caseQuantity: parseFloat(newCartAmount),
          price: (item.isMarketPrice === 1 ? 0 : parseFloat(item.price)),
          itemDistributorId: item.itemDistributorId,
          brand: item.brand,
          brandImageUrl: item.brandImageUrl,
          imageUrl: item.imageUrl,
          name: item.name,
          packSize: item.packSize,
          status: item.status,
          type: item.type,
          unitPrice: item.unitPrice,
          distributorId: parseInt(item.distributorId),
          unitMeasure: item.unitMeasure,
          description: item.description,
          searchText: item.searchText,
          rebate: parseFloat(item.rebate),
          swapAcceptId: (item.swapAcceptId !== undefined && item.swapAcceptId !== null) ? item.swapAcceptId : null,
          isSpecialItem: false,
          distributor: item.distributor,
          shoppingCartId: null,
          isPriceOutdated: item.isPriceOutdated,
          isMarketPrice: item.isMarketPrice,
          active: item.active,
          din: item.din,
          isPunchedOut: item.isPunchedOut,
          belongsToGroup: item.belongsToGroup,
          // parLevel: item.parLevel,
          // onHand: item.onHand,
          isPartner: item.isPartner,
          isPurchased: item.isPurchased,
          lastPurchasedDate: item.lastPurchasedDate,
          lastPurchasedQty: item.lastPurchasedQty,
          savings: (item.swapAcceptId !== null && item.savings !== null) ? parseFloat(item.savings) : null
        };
      newCartItems.push(newItem);
      totalQty = totalQty + parseInt(newItem.caseQuantity);
      totalOrder = totalOrder + (parseFloat(newItem.caseQuantity) * parseFloat(newItem.price));
    }

    if (newCartAmount === 0 && index !== null) {
      newCartItems.splice(index, 1);
    }

    dispatch(saveCartItems(newCartItems));
    localStorage.setItem(localStorageConst.keys.cartItems, JSON.stringify(newCartItems));
    dispatch(saveCartCount(totalQty));
    dispatch(saveOrderTotal(totalOrder));
  };

  return {
    updateItemsInCart
  };
};

const useOverlay = () => {
  const [overlay, setOverlay] = useState(false);

  return { overlay, setOverlay }
};


export default {
  useDebounce, useCartUpdate, useCartValidate, useCartItemMerge, useCartItemUpdate, useOverlay
};
