import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {showModal} from '../../../../../reducers/modals/modalsActions';
import { isMobile } from 'react-device-detect';
import ordersApi from '../../../../../api/ordersApi';
import OrderViewRow from '../OrderView/OrderViewRow';
import OrderViewDetailsModal from '../Modals/OrderView/OrderViewDetailsModal';
import OrderViewDownloadModal from '../Modals/OrderView/OrderViewDownloadModal';

const useApi = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    const id = parseInt(params.id);
    const [orderViewList, setOrderViewList] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState([]);
    const itemsCount = orderViewList.reduce((total, item) => total + item.quantity, 0);

    const getOrdersById = () => {
        ordersApi.getOrdersById(id).then((res) => {
            setOrderViewList(res.data.items);
            setSelectedOrder(res.data);
        }).catch(error => {
        });
    };
    useEffect(() => {
        getOrdersById();
    }, []);


    const showDetails = () => {
        const inputProps = {
            title: '',
            placeholder: '',
            menu: isMobile ? true : false,
            fullMenu: true,
            selectedOrder
        };

        dispatch(showModal(OrderViewDetailsModal, inputProps));
    }


    const openDownload = () => {
        const inputPropsDownload = {
            onSave : clickDownload,
            placeholder: '',
            menu: isMobile ? true : false,
            fullMenu: true,
            titleOption: selectedOrder.invoiceUrl ? 'Invoice PDF': 'Purchase Order',
        };
        dispatch(showModal(OrderViewDownloadModal, inputPropsDownload));
    }

    const clickDownload = (order) => {
        if(order) {
            const elem = document.createElement('a');
                if(selectedOrder.purchaseOrderUrl || selectedOrder.purchaseOrderUrl ) {
                    elem.href = selectedOrder.invoiceUrl ? selectedOrder.invoiceUrl : selectedOrder.purchaseOrderUrl
                    elem.target='_blank'
                    elem.click();
                } else {
                    if(selectedOrder.purchaseOrderUrlExcel) {
                        elem.href = selectedOrder.purchaseOrderUrlExcel
                        elem.click();
                    }
                }
        } else {
            ordersApi.downloadOrder365(id).then((res) => {
                if(res.data) {
                  const elem = document.createElement('a');
                  elem.href = res.data.order365Url;
                  elem.click();
                }
              }).catch(error => {
            });
        }
        
    }

    const orderViewMap = orderViewList.map((c, i) => 
        <OrderViewRow   key={`or-list-${i}`} data={c} selectedOrder={selectedOrder} />
    )

    return { orderViewList, orderViewMap, selectedOrder, itemsCount, openDownload, showDetails}

}




export default {
    useApi
};
