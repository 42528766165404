import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card, Button } from 'reactstrap';
import { IoMdSearch, IoIosOptions } from 'react-icons/io';
import moment from 'moment';
import numeral from 'numeral';
import InfiniteScroll from 'react-infinite-scroller';
import useInput from '../../../../../SharedComponents/SharedEffects/useInput';
import InputSearch from '../../../../../SharedComponents/InputSearch/InputSearch';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import ItemGraph from '../ItemGraph/ItemGraph';
import noImage from '../../../../../assets/images/no-image.png';
import comingSoon from '../../../../../assets/images/coming-soon.png';
import searchBox from '../../../../../assets/images/search-box.png';
import analyticsApi from '../../../../../api/analyticsApi';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import SearchFiltersModal from '../../../../../SharedComponents/Modals/SearchFiltersModal/SearchFiltersModal';
import * as $ from 'jquery';

const ItemLookup = (props) => {

  const dispatch = useDispatch();
  const spinner = useSelector(({ userInterface }) => userInterface.spinner);
  const filters = useSelector(({items}) => items.list.filters);

  const isFirstRun = useRef(true);
  const lastSearch = useRef('');

  const [listStatistics, setListStatistics] = useState(null);

  const search = useInput.useText('');

  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const getItems = (newPage, searchValue) => {
    if (isLoading) return;
    $('scrollDiv').scrolltop = 0;

    let filterOptions = {
      sort: {
        id: 5,
        name: 'frequent',
        type: 1,
        desc: 'Most Purchased'
      },
      product: 'ALL'
    };

    filterOptions = searchValue ? {search: searchValue, ...filterOptions, ...filters} : {...filterOptions, ...filters};
    lastSearch.current = searchValue;

    setIsLoading(true);
    setPage(newPage);
    analyticsApi.getItems(filterOptions, newPage).then(res => {
      if (newPage > 1) {
        setItems(items.concat(res.data));
      } else {
        setItems(res.data);
      }
      setLoadMore(res._meta.pageCount > res._meta.currentPage);
      setIsLoading(false);
    })
    .catch(error => console.log('analyticsApi getItems Error:', error));
  };

  const onClear = () => {
    search.clearValue();
    setListStatistics(null);
    setItems([]);
    getItems(1, '');
  };

  const onSearch = () => {
    setListStatistics(null);
    setItems([]);
    getItems(1, search.value);
  };

  const openFilterModal = () => {
    $('scrollDiv').scrolltop = 0;
    dispatch(showModal(SearchFiltersModal));
  };

  const _changePriceZero = (listSeries) => {
    if (listSeries) {
      var series_length = listSeries.length;
      for (let i = 0; i < series_length; i++) {
        if (listSeries[i] === 0 && i !== 0) {
          listSeries[i] = listSeries[i - 1];
        }
      }
    }
  }

  const getStatistics = (itemDistributorId) => {
    let filter = {
      filter_type: props.filterType,
      item_distributor_id: itemDistributorId
    };
    if (props.filterType == 'custom') {
      filter = {
        ...filter,
        start_date: moment(props.startDate).format('YYYY-MM-DD'),
        end_date: moment(props.endDate).format('YYYY-MM-DD')
      };
    }
    analyticsApi.getStatistics(filter).then(({data}) => {
      _changePriceZero(data.series);
      setListStatistics(data);
    }).catch(err=> {
      console.error('getStatistics get Error:', err);
    });
  };

  useEffect(() => {
    getStatistics('default');
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    setListStatistics(null);
    setItems([]);
    getItems(1, search.value);
  }, [filters]);

  return (
    <Card className='my-3 p-3'>
      <Row>
        <Col xs={12}>
          <Row className='border-bottom'>
            <Col xs={12} className='analytics-header'>Item Lookup</Col>
          </Row>

          <Row className='my-3'>
            <Col xs={8}>
              <InputSearch type='text' onChange={search.set} value={search.value} clearValue={onClear} onEnter={onSearch} placeholder='Enter SKU, Item # or Item Name' />
            </Col>
            <Col xs={2}>
              <Button className='btn btn-sm btn-block btn-green' onClick={onSearch}>
                <IoMdSearch color='white' size={22} /> Search
              </Button>
            </Col>
            <Col xs={2}>
              <Button className='btn btn-sm btn-block btn-green' onClick={openFilterModal}>
                <IoIosOptions color='white' size={22} /> Filters
              </Button>
            </Col>
          </Row>

          <ShowIf show={listStatistics && listStatistics.itemData}>
            <Row className='my-3'>
              <Col xs={1} className='d-flex align-items-center justify-content-center'>
                <img className='no-image' src={listStatistics && listStatistics.itemData && (listStatistics.itemData.imageUrl || listStatistics.itemData.brandImageUrl || noImage)} alt='' />
              </Col>
              <Col xs={3}>
                <Row className='mb-4'>
                  <Col xs={12} className='analytics-lookup-header'>
                    {listStatistics && listStatistics.itemData && listStatistics.itemData.brand}
                  </Col>
                </Row>
                <Row className='text-capitalize'>
                  <Col xs={12}>
                    <span className='analytics-lookup-detail'>
                      {listStatistics && listStatistics.itemData && listStatistics.itemData.name}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col xs={2} className='textarea-chart-right' style={{padding: '0 0 0 60px'}}>
                <Row className='mb-4'>
                  <Col xs={12} className='analytics-lookup-header text-left'>
                    Average Price
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className='text-left'>
                    <span className='analytics-lookup-detail'>
                      {listStatistics && listStatistics.itemData && numeral(listStatistics.itemData.average_price).format('$0,0.00')} {listStatistics && listStatistics.itemData && listStatistics.itemData.unit_measure}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col xs={2} style={{padding: '0 0 0 80px'}}>
                <Row className='mb-4'>
                  <Col xs={12} className='analytics-lookup-header text-left'>
                    Best Price
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className='text-left'>
                    <img src={comingSoon} alt='' />
                  </Col>
                </Row>
              </Col>
              <Col xs={2} className='textarea-chart-left' style={{padding: '0 0 0 60px'}}>
                <Row className='mb-4'>
                  <Col xs={12} className='analytics-lookup-header text-left'>
                    Number of Cases
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className='text-left'>
                    <span className="analytics-lookup-detail">
                      {listStatistics && listStatistics.itemData && listStatistics.itemData.num_cases}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col xs={2} className='textarea-chart-left' style={{padding: '0 0 0 40px'}}>
                <Row className='mb-4'>
                  <Col xs={12} className='analytics-lookup-header text-left'>
                    Total Spent per Item
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className='text-left'>
                    <span className='analytics-lookup-detail'>
                      {listStatistics && listStatistics.itemData && numeral(listStatistics.itemData.total_purchases).format('$0,0.00')}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ShowIf>

          <ShowIf show={listStatistics && listStatistics.itemData}>
            <ItemGraph analyticsData={listStatistics} />
          </ShowIf>

          <ShowIf show={!listStatistics}>
            <div id='scrollDiv' className={'mb-4' + (items.length > 0 ? ' border' : '')} style={{overflowX: 'hidden', overflowY: 'auto', maxHeight: '400px'}}>

              <InfiniteScroll
                threshold={800}
                initialLoad={false}
                pageStart={page}
                loadMore={(discardPage) => {
                  if (lastSearch.current === search.value) {
                    getItems(page+1, search.value);
                  } else {
                    getItems(1, search.value);
                  }
                }}
                hasMore={loadMore && !isLoading}
                useWindow={false}
              >
                {
                  items.map((item, index) => {
                    return (

                      <Col xs={12} key={'item-lookup-'+index+item.merchantId+item.distributorId+item.itemDistributorId}>
                        <ShowIf show={item.isPurchased === 1}><span className='purchased-icon-analytics'>Purchased</span></ShowIf>
                        <ShowIf show={item.type === 'order_guide'}><span className='order-guide-icon-analytics'>Order Guide</span></ShowIf>
                        <Row className='border-bottom'>
                          <Col xs={2} className='d-flex align-items-center justify-content-center'>
                            <img className='no-image' src={item.imageUrl || item.brandImageUrl || noImage} alt='' />
                          </Col>
                          <Col xs={8}>
                            <Row className='mt-3 mb-2'>
                              <Col xs={12} className='text-capitalize'>
                                <span className='analytics-grid-detail'>{item.description}</span>
                              </Col>
                            </Row>
                            <Row className='mt-2'>
                              <Col xs={4} className='analytics-grid-header' style={{color: '#376386'}}>{item.brand}</Col>
                              <Col xs={8} className='analytics-grid-header' style={{color: '#376386'}}>{item.distributor}</Col>
                            </Row>
                            <Row className='mt-2 mb-3'>
                              <Col xs={4} className='analytics-grid-detail'>
                                {numeral(item.price).format('$0,0.00')} {item.unitMeasure}
                              </Col>
                              <Col xs={4} className='analytics-grid-detail'>
                                {item.packSize}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={2}>
                            <Row>
                              <Col xs={12} className='mb-3'>
                                &nbsp;
                              </Col>
                            </Row>
                            <Row className='border clickable' style={{marginRight: '20px'}}>
                              <Col xs={12} className='my-2 text-center analytics-grid-detail' onClick={() => getStatistics(item.itemDistributorId)}>
                                Show Stats
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                &nbsp;
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>

                    );
                  })
                }
              </InfiniteScroll>

              <ShowIf show={!spinner && !isLoading && items && items.length == 0}>
                <Row className='mt-4'>
                  <Col xs={2} className='d-flex align-items-center justify-content-center'>
                    <img src={searchBox} className='img-fluid' alt='' />
                  </Col>
                  <Col xs={10} className='supporting-text' style={{color: '#143357'}}>
                    <p>Sorry, there are no exact match results found</p>
                    <p>Search Suggestions</p>
                    <ul>
                      <li>Check your spelling</li>
                      <li>Try to use less specific words</li>
                    </ul>
                  </Col>
                </Row>
              </ShowIf>

            </div>
          </ShowIf>

        </Col>
      </Row>
    </Card>
  );
};

export default ItemLookup;
