import React, { useState } from 'react';
import { CustomInput, Row, Col } from 'reactstrap';

const AddIngredientModalRow = (props) => {
  const {
    label, description, id,
    value, set
  } = props;

  return (
    <Row className='m-0 mb-4'>
      <Col className='w-fc pr-0'>
        <CustomInput id={id} type='radio' checked={value} onChange={set} />
      </Col>
      <Col>
        <Row className='font-weight-bold'><label className='m-0' htmlFor={id}>{label}</label></Row>
        <Row className='text-label'>{description}</Row>
      </Col>
    </Row>
  );
};

export default AddIngredientModalRow;