import React from 'react'
import { Row, Col, Card, Button, CardHeader, CardBody } from 'reactstrap'
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { colors } from '../../../../../config/constants';
import IconBadge from '../../../../../SharedComponents/IconBadge/IconBadge';
import { FaQuestion } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const ReviewOrderCard = (props) => {
    const { special, priceTotal, lineItems, totalItems, distributorName, checkout } = props;
    const canPlaceOrder = useSelector(({user}) => user.activeLocation.canPlaceOrder) ? true : false;
    const texts = {
        totalSaving: `All the savings you've made with Foodbam on this order!`,
        lineItems: `Excluding Special Request Items!`
    }

    return (
        <Card className='review-order-card'>
            <CardHeader>
                {distributorName ? distributorName : '..'}
                <span>{priceTotal}</span>
            </CardHeader>
            <CardBody>
                <h1>SUMMARY</h1>
                <Row>
                    <Col className='inline'>
                        <p>Total Savings</p>
                        <div className='marginIcon' >
                            <IconBadge idPop='ROCpop1' text={texts.totalSaving} FaIcon={FaQuestion} size={7} color={colors.white} />
                        </div>
                    </Col>
                    <Col className='text-end'>
                        <p>$0.00</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='inline'>
                        <p>Line Items</p>
                        <div className='marginIcon' >
                            <IconBadge idPop='ROCpop2' text={texts.lineItems} FaIcon={FaQuestion} size={7} color={colors.white} />
                        </div>
                    </Col>
                    <Col className='text-end'>
                        <p>{lineItems}</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='inline'>
                        <p>Items</p>
                        <div className='marginIcon' >
                            <IconBadge idPop='ROCpop3' text={texts.lineItems} FaIcon={FaQuestion} size={7} color={colors.white} />

                        </div>
                    </Col>
                    <Col className='text-end'>
                        <p>{totalItems}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            {distributorName ? distributorName : '..'} Sub Total</p>
                    </Col>
                    <Col className='text-end'>
                        <p>{priceTotal}</p>
                    </Col>
                </Row>
                <hr></hr>

                <ShowIf show={!special}>
                    <Row className='mbot'>
                        <Col>
                            <span>Do you have a promocode?</span>
                        </Col>
                    </Row>
                </ShowIf>
                <Row>
                    <Col>
                        <Button disabled={!canPlaceOrder} className='buttonCheck' color='green' onClick={checkout}>Checkout</Button>
                    </Col>
                </Row>
                <div className='review-order-footer'>
                </div>
            </CardBody>
        </Card>
    )
}

export default ReviewOrderCard;