import React, { useState, useEffect, Fragment } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Input, ModalFooter, Button, NavLink } from 'reactstrap';
import useModal from '../../../../../SharedComponents/Modals/useModal';
import { cardIconHandler } from '../../ManageCards/cardIconHandler';
const ShippingAndPaymentCardModal = (props) => {

    const { id, listCard, onSave } = props;

    const modalProps = useModal(id);


    const onClickOk = (card) => {
        if (onSave) onSave(card);
        modalProps.toggle();
    };

    return (
        <Modal {...modalProps} className='modal-card-list'>
            <ModalHeader className='bg-white' toggle={modalProps.toggle}>Select Credit Card</ModalHeader>
            <ModalBody>
                {
                    listCard.map(card => {
                        return (
                            <Row onClick={() => onClickOk(card)} key={'checkCardM-' + card.token} >
                                <Col size='1'>
                                    <img src={cardIconHandler(card)}></img>
                                </Col>
                                <Col size='1'>
                                    <p>{card.last4}</p>
                                </Col>
                                <Col size='2'>
                                </Col>
                            </Row>

                        )
                    })
                }
            </ModalBody>
        </Modal>
    );
};


export default ShippingAndPaymentCardModal;