import { closeSidebar, toggleSidebar } from '../../reducers/userInterface/userInterfaceActions';
import { useRef, useState } from 'react';
import { changeTourMoral } from '../../reducers/modals/modalsActions';
import { isMobile } from "react-device-detect";

const useActions = (dispatch) => {
  const clickClose = () => {
    dispatch(closeSidebar());
  };

  const [openModalWC, setOpenModalWC] = useState(false);

  const tourElement = useRef();

  const goToNextTourModal = () => {
    dispatch(changeTourMoral(2));
    setOpenModalWC(!openModalWC);
  }
  const clickMenu = () => {
    dispatch(toggleSidebar());
  };

  const startTour = () => {
    setOpenModalWC(!openModalWC);
    if (!openModalWC && isMobile)
      clickMenu();
  };
  const skipDemo = () => {
    setOpenModalWC(false);
    dispatch(changeTourMoral(1));
  }

  return {
    clickClose,
    openModalWC,
    startTour,
    tourElement,
    goToNextTourModal,
    skipDemo,

  };
};

export default {
  useActions
};
