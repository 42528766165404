import React, { useState } from 'react';
import { Row, Col, Input,  Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { colors } from '../../../../../../config/constants';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../../../reducers/modals/modalsActions';
import { isMobile } from 'react-device-detect';
import useModal from '../../../../../../SharedComponents/Modals/useModal';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';
import Select from 'react-select';
import validate from 'validate.js';
import useAddCustomItemModal from '../../../../../../SharedComponents/Modals/AddCustomItemModal/useAddCustomItemModal';
import AddFoodbamItemModal from '../../../../../../SharedComponents/Modals/AddFoodbamItemModal/AddFoodbamItemModal';
import useOrderReceive from '../../OrderReceive/useOrderReceive';
import ordersApi from '../../../../../../api/ordersApi';


const AddNewItem = (props) => {
    const dispatch = useDispatch();
    const {id, selectedOrder, getOrdersById } = props;
    const {createCustomItem} = useOrderReceive.useCustom(getOrdersById);
    const formProps = useAddCustomItemModal.useForm();
    const {description, quantity, unitMeasure, price, packSize, din, brand} = formProps;
    const [showMore, setShowMore] = useState(false);
    const modalProps = useModal(id, isMobile, true);
    const saveIsDisabled = () => {
        const data = {
        description: formProps.description.value,
        price: formProps.price.value,
        packSize: {
            outerPack: formProps.packSize.outerPack.value,
            innerPack: formProps.packSize.innerPack.value,
        }
        };
        const constrains = {
            description: {presence: {allowEmpty: false}, length: {minimum: 2}},
            price: {presence: {allowEmpty: false}, numericality: true},
            "packSize.outerPack": {presence: {allowEmpty: false}, numericality: {onlyInteger: true, greaterThanOrEqualTo: 0}},
            "packSize.innerPack": {presence: {allowEmpty: false}, numericality: {onlyInteger: true, greaterThanOrEqualTo: 0}}
        };
        return validate(data, constrains) ? true : false;
    }

    const clickSearch = () => {
        const modalPropsCustom = {
            title: 'Add Items to Order ',
            initSearch: '',
            initItems: selectedOrder.items,
            includeSubrecipe: false,
            isOrder: true,
            idLabel: 'itemDistributorId',
            clickCustomCustom : () => {
                dispatch(showModal(AddNewItem, props));
            },
            onAdd : (allItems, newItems)=>{
                console.log(allItems);
                console.log(newItems);
                const itemsNew = [];
                newItems.forEach((element, i) => {
                    const name = i;
                    itemsNew[name] = element;
                });
                const items = itemsNew.map(o => { 
                    return {
                        item_distributor_id: o.itemDistributorId,
                        quantity: o.quantity
                    }
                })
                const params = {
                    orderId : selectedOrder.id,
                    items
                }
                //console.log(params);
                ordersApi.bulkCreate(params).then((res) => {
                    getOrdersById();
                }).catch(error => {
                });
                //console.log(allItems);
                //console.log(newItems);
            }
        }
        dispatch(showModal(AddFoodbamItemModal, modalPropsCustom));
        modalProps.toggle();
    };

    const clickSave = () => {
        const data = {
            description: formProps.description.value,
            quantity : formProps.quantity.value,
            unitMeasure: formProps.unitMeasure.value.value,
            distributor: formProps.distributor.value,
            price: formProps.price.value,
            packSize: {
            outerPack: formProps.packSize.outerPack.value,
            innerPack: formProps.packSize.innerPack.value,
            uom: formProps.packSize.uom.value.value
            },
            din: formProps.din.value,
            brand: formProps.brand.value,
            order_id: selectedOrder.id
        };
        createCustomItem(data)
        modalProps.toggle();
    };


  return (
    <Modal {...modalProps}>
        <ModalHeader toggle={modalProps.toggle}>Add New Item</ModalHeader>
        <ModalBody >
            <Row>
                <Col className='text-label'>Description *</Col>
            </Row>
            <Row className='mt-2 mb-3'>
                <Col>
                    <Input  type='text'  {...description} placeholder='Enter Item Name' />
                </Col>
            </Row>
            <Row className='mt-2 mb-3'>
                <Col className={'text-label' + (isMobile ? 'pr-2' : '')}>Quantity *</Col>
                <Col className={'text-label' + (isMobile ? 'pr-2' : '')}>Price *</Col>
            </Row>
            <Row className='mt-2 mb-3'>
                <Col className={isMobile ? 'pr-2' : ''}>
                    <Input type='number' {...quantity} placeholder='Enter Quantity' />
                </Col>
                <Col className={isMobile ? 'pr-2' : ''}>
                    <Input type='number' {...price} placeholder='$' />
                </Col>
            </Row>
            <Row className='align-items-center py-2 clickable mt-3 w-fc' onClick={() => setShowMore(!showMore)}>
                <ShowIf show={!showMore}>
                    <Col className='text-label w-fc'>Show Details<FaCaretDown className='ml-1' color={colors.lightBlueText} /></Col>
                </ShowIf>
                <ShowIf show={showMore}>
                    <Col className='text-label w-fc'>Hide Details<FaCaretUp className='ml-1' color={colors.lightBlueText} /></Col>
                </ShowIf>
            </Row>
            <ShowIf show={showMore}>
                <Row className='mt-2 mb-3'>
                    <Col className={'text-label' + (isMobile ? 'pr-2' : '')}>DIN</Col>
                    <Col className={'text-label' + (isMobile ? 'pl-2' : '')}>Brand</Col>
                </Row>
                <Row className='mt-2 mb-3'>
                    <Col className={isMobile ? 'pr-2' : ''}>
                        <Input {...din} placeholder='Item or SKU Number' />
                    </Col>
                    <Col className={isMobile ? 'pl-2' : ''}>
                        <Input {...brand} placeholder='For Example Tyson' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className={'text-label' + (isMobile ? 'pr-2' : '')}>Unit of Measure *</Col>
                    <Col className={'text-label' + (isMobile ? 'pl-2' : '')}>Pack Size *</Col>
                </Row>
                <Row className='mt-2 mb-3'>
                    <Col className={isMobile ? 'pr-2' : ''}>
                        <Select isSearchable={false} className='fb-select' classNamePrefix='select' {...unitMeasure} />
                    </Col>
                    <Col className={isMobile ? 'pl-2' : ''}>
                        <Row className='align-items-center'>
                            <Col className={'pr-0' + (isMobile ? '' : ' w-squared-input')}>
                                <Input type='number' {...packSize.outerPack} />
                            </Col>
                            <Col className='w-fc px-1'>/</Col>
                            <Col className={'p-0' + (isMobile ? '' : ' w-squared-input')}>
                                <Input type='number' {...packSize.innerPack} />
                            </Col>
                            <Col className='pl-2'>
                                <Select isSearchable={false} className='fb-select' classNamePrefix='select' {...packSize.uom} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ShowIf>
        </ModalBody>
        <ModalFooter className='px-0'>
            <ShowIf show={!isMobile}>
                <Row className='w-100 m-0'>
                    <Col>
                        <Button onClick={clickSearch} className='px-2' color='green' outline size='lg'>Search Items</Button>
                    </Col>
                    <Col className='text-right'>
                        <Button onClick={clickSave} disabled={saveIsDisabled()} className='px-5' color='green' size='lg'>Save</Button>
                    </Col>
                </Row>
            </ShowIf>
            <ShowIf show={isMobile}>
                <Row className='w-100 m-0'>
                    <Col>
                        <Button onClick={clickSearch} className='px-5' block color='green' outline>Search Items</Button>
                    </Col>
                </Row>
                <Row className='w-100 m-0 mt-2'>
                    <Col className='text-right'>
                        <Button onClick={clickSave} disabled={saveIsDisabled()} className='px-5' block color='green'>Save</Button>
                    </Col>
                </Row>
            </ShowIf>
        </ModalFooter>
    </Modal>
  );
};

export default AddNewItem;