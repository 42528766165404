import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button, ModalFooter, Input } from 'reactstrap';
import useModal from '../useModal';
import ShowIf from '../../ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../reducers/modals/modalsActions';
import AddFoodbamItemModal from '../AddFoodbamItemModal/AddFoodbamItemModal';
import AddCustomItemModalContent from './AddCustomItemModalContent';
import useAddCustomItemModal from './useAddCustomItemModal';
import validate from 'validate.js';
import AlertModal from '../AlertModal/AlertModal';

const AddCustomItemModal = (props) => {
  const dispatch = useDispatch();
  const {
    id, title, foodbamItemProps, onSave,
    hideSearch, init
  } = props;

  const { disabled } = useAddCustomItemModal.useModalConnection();

  const modalProps = useModal(id, isMobile, true);
  const formProps = useAddCustomItemModal.useForm(init);

  const clickSearch = () => {
    modalProps.toggle();
    dispatch(showModal(AddFoodbamItemModal, foodbamItemProps));
  };

  const saveIsDisabled = () => {
    const data = {
      description: formProps.description.value,
      distributor: formProps.distributor.value,
      price: formProps.price.value,
      packSize: {
        outerPack: formProps.packSize.outerPack.value,
        innerPack: formProps.packSize.innerPack.value,
      }
    };
    const constrains = {
      description: {presence: {allowEmpty: false}, length: {minimum: 2}},
      distributor: {presence: {allowEmpty: false}, length: {minimum: 2}},
      price: {presence: {allowEmpty: false}, numericality: true},
      "packSize.outerPack": {presence: {allowEmpty: false}, numericality: {onlyInteger: false, greaterThanOrEqualTo: 0}},
      "packSize.innerPack": {presence: {allowEmpty: false}, numericality: {onlyInteger: false, greaterThanOrEqualTo: 0}}
    };
    return validate(data, constrains) ? true : false;
  }

  const clickSave = () => {
    if(disabled){
      dispatch(showModal(AlertModal, {message:'Please enable online mode to add a new generic ingredient.'}));
      return;
    }
    if (onSave) {
      const data = {
        description: formProps.description.value,
        unitMeasure: formProps.unitMeasure.value.value,
        distributor: formProps.distributor.value,
        price: formProps.price.value,
        packSize: {
          outerPack: formProps.packSize.outerPack.value,
          innerPack: formProps.packSize.innerPack.value,
          uom: formProps.packSize.uom.value.value
        },
        din: formProps.din.value,
        brand: formProps.brand.value
      };
      onSave(data);
    };
    modalProps.toggle();
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader className='bg-white' toggle={modalProps.toggle}>
        <Row>
          <Col>{title}</Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <AddCustomItemModalContent {...formProps} />
      </ModalBody>
      <ShowIf show={!isMobile}>
        <ModalFooter className='px-0'>
          <Row className='w-100 m-0'>
            <ShowIf show={!hideSearch}>
              <Col>
                <Button onClick={clickSearch} className='px-5' color='green' outline size='lg'>Search Items</Button>
              </Col>
            </ShowIf>
            <Col className='text-right'>
              <Button onClick={clickSave} disabled={saveIsDisabled()} className='px-5' color='green' size='lg'>Save</Button>
            </Col>
          </Row>
        </ModalFooter>
      </ShowIf>
      <ShowIf show={isMobile}>
        <ModalFooter className='px-0'>
          <ShowIf show={!hideSearch}>
            <Row className='w-100 m-0'>
              <Col>
                <Button onClick={clickSearch} className='px-5' block color='green' outline>Search Items</Button>
              </Col>
            </Row>
          </ShowIf>
          <Row className='w-100 m-0 mt-2'>
            <Col className='text-right'>
              <Button onClick={clickSave} disabled={saveIsDisabled()} className='px-5' block color='green'>Save</Button>
            </Col>
          </Row>
        </ModalFooter>
      </ShowIf>
    </Modal>
  );
};

export default AddCustomItemModal;
