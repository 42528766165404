import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import useModal from '../../../../../../../SharedComponents/Modals/useModal';
import { isMobile } from 'react-device-detect';
import ItemDetailsHowMuch from '../SharedComponents/ItemDetailsHowMuch';
import ItemDetailsFooter from '../SharedComponents/ItemDetailsFooter';
import useItemDetails from '../useItemDetails';
import ItemDetailsInventoryInfo from './ItemDetailsInventoryInfo';
import ItemDetailsInventoryLinkItem from './ItemDetailsInventoryLinkItem';
import { fraction, number } from 'mathjs';
import recipeApi from '../../../../../../../api/recipeApi';
import comparisonViewsApi from '../../../../../../../api/comparisonViewsApi';
import validate from 'validate.js';
import LinkButton from '../../../../../../../SharedComponents/LinkButton/LinkButton';

const ItemDetailsInventory = (props) => {
  const {
    id, ingredient, isAdd, getRecipeData
  } = props;
  const modalProps = useModal(id, isMobile, true);

  const  comparisonViewName = ingredient.item.comparisonView.name;
  const description = ingredient.item.name;
  const init = {
    name: ingredient.item.name,
    recipeId: ingredient.recipe_id,
    ingredientItemId: ingredient.id,
    recipeAmount: ingredient.recipe_amount_fraction ? ingredient.recipe_amount_fraction : ingredient.recipe_amount,
    recipeUom: ingredient.recipe_pack_size_uom,
    linkedItems: ingredient.item.items
  };

  const { recipeAmount, uom, linkedItems, openLinkItem, setLinkItem, setLinkedItems } = useItemDetails.useInventory(init, props.ingredient.item, getRecipeData);
  const { onDelete } = useItemDetails.useDeleteIngredient(ingredient.recipe_id, ingredient.id, modalProps.toggle, getRecipeData);

  useEffect(() => {
    if (!isAdd) {
      let newItems = [...linkedItems];
      const apiCall = () => {
        if (i < linkedItems.length) {
          const id = linkedItems[i].genericCustomItemId || linkedItems[i].genericItemId;
          const api = linkedItems[i].isCustomItem ? recipeApi.getGenericCustomItem : recipeApi.getGenericItem;
          api(ingredient.id, id).then(({data}) => {
            newItems[i] = {
              ...newItems[i],
              waste: data.wastePercentage || '',
              conversion: {
                item: {
                  packSize: data.conversionPackSizeFraction || data.conversionPackSizeValue || ''
                },
                recipe: {
                  packSize: data.recipePackSizeFraction || data.conversionPackSizeValue || ''
                }
              }
            }
            i = i+1;
            apiCall();
          });
        } else {
          setLinkedItems(newItems);
        }
      };
      let i = 0;
      apiCall();
    }
  }, []);

  const cantSave = () => {
    const form = {
      recipeAmount: recipeAmount.value,
      linkedItems: linkedItems.filter(i => i.isLink).map(i => {
        return {
          itemPackSize: i.conversion.item.packSize,
          recipePackSize: i.conversion.recipe.packSize
        }
      })
    };
    const constrains = {
      recipeAmount: {
        fraction: { strict: true }
      },
      linkedItems: {
        array: {
          itemPackSize: { fraction: { strict: true }},
          recipePackSize: { fraction: { strict: true }}
        }
      }
    };
    const result = validate(form, constrains);
    return result ? true : false;
  };

  const onClickSave = () => {
    const addItems = linkedItems.filter(i => i.isLink && !i.isCustomItem && !i.isSubRecipe).map(i => {
      return i.itemDistributorId;
    });
    const addSubrecipeItems = linkedItems.filter(i => i.isSubRecipe).map(i => {
      return i.subRecipeId;
    });
    const deleteItems = linkedItems.filter(i => !i.isLink);
    const cparams = {
      items: JSON.stringify(addItems),
      subrecipes: JSON.stringify(addSubrecipeItems)
    };
    deleteGroupItems(deleteItems, () => {
      patchGroup(cparams, (params) => {
        if (isAdd) {
          onAdd(params)
        } else {
          onEdit(params);
        }
      })
    });
  };

  const deleteGroupItems = (deleteItems, finish) => {
    const apiCall = () => {
      if (i < deleteItems.length) {
        const item = deleteItems[i]
        const id = item.isCustomItem ? item.genericCustomItemId : ( item.isSubRecipe ? item.subRecipeId : item.itemDistributorId);
        const comparisonViewId = ingredient.item.comparisonView.id;
        const groupId = ingredient.item.id;
        i = i+1;
        if (item.isCustomItem) {
          comparisonViewsApi.deleteGroupCustomItem(comparisonViewId, groupId, id).then((res) => apiCall());
        } else if (item.isSubRecipe) {
          comparisonViewsApi.deleteGroupSubRecipeItem(comparisonViewId, groupId, id).then((res) => apiCall());
        } else {
          comparisonViewsApi.deleteGroupItem(comparisonViewId, groupId, id).then((res) => apiCall());
        }
      } else {
        finish();
      }
    };
    let i = 0;
    if (deleteItems.length) {
      apiCall();
    } else {
      finish();
    }
  };

  const patchGroup = (cparams, finish) => {
    comparisonViewsApi.patchGroup(ingredient.item.comparisonView.id, ingredient.item.id, cparams).then(({data}) => {
      comparisonViewsApi.getGroup(ingredient.item.comparisonView.id, ingredient.item.id).then(({data}) => {
        const params = {
          ingredient_type: "generic",
          ingredient_id: ingredient.item.id,
          recipe_pack_size_uom: uom.value.label,
          recipe_amount: number(fraction(recipeAmount.value)),
          recipe_amount_fraction: fraction(recipeAmount.value).toFraction(),
          link_items: linkedItems.filter(l => l.isLink).map(i => {
            return {
              [i.isCustomItem ?'generic_custom_item_id' : 'generic_item_id' ]: i.isCustomItem ? i.genericCustomItemId : i.genericItemId,
              conversion_pack_size_value: number(fraction(i.conversion.item.packSize)),
              conversion_pack_size_fraction: fraction(i.conversion.item.packSize).toFraction(),
              conversion_pack_size_uom: i.packSizeUom,
              recipe_pack_size_value: number(fraction(i.conversion.recipe.packSize)),
              recipe_pack_size_fraction: fraction(i.conversion.recipe.packSize).toFraction(),
              recipe_pack_size_uom: uom.value.label,
              waste_percentage: i.waste
            }
          })
        };
        finish(params);
      });
    }).catch(err => {
      console.error(err);
    });
  };

  const onAdd = (params) => {
    recipeApi.postIngredient(ingredient.recipe_id, params).then(({data}) => {
      modalProps.toggle();
      getRecipeData();
    }).catch(err => {
      console.error(err);
    });
  };

  const onEdit = (params) => {
    recipeApi.patchIngredient(ingredient.recipe_id, ingredient.id, params).then(({data}) => {
      modalProps.toggle();
      getRecipeData();
    }).catch(err => {
      console.error(err);
    });
  };

  const linkedItemsMap = linkedItems.map((l, i) =>{
    return (
      <ItemDetailsInventoryLinkItem index={i} key={`linkitem_${l.id}`} {...l}
        isAdd={isAdd} recipeUom={uom.value.label} setLinkItem={setLinkItem} allItems={linkedItems} setAllItems={setLinkedItems} />
    );
  });

  return (
    <Modal {...modalProps}>
      <ModalHeader toggle={modalProps.toggle}>Inventory Item Details</ModalHeader>
      <ModalBody>
        <ItemDetailsInventoryInfo comparisonViewName={comparisonViewName} description={description} />
        <ItemDetailsHowMuch isInventory recipeAmount={recipeAmount} uom={uom} />
        <Row className='border-top mb-3 pt-3 align-items-center'>
          <Col className='text-label font-weight-bold text-uppercase'>Linked Items</Col>
        </Row>
        {linkedItemsMap}
        <Row className='m-0 mb-3 clickable' onClick={openLinkItem}>
          <Col className='border-dash text-label align-items-center text-center py-4'>
            <LinkButton className='mr-2' large={!isMobile} outline /> Link Items
          </Col>
        </Row>
      </ModalBody>
      <ItemDetailsFooter onDelete={onDelete} hideDelete={isAdd} disabled={cantSave()} onSave={onClickSave} label='Save' />
    </Modal>
  );
};

export default ItemDetailsInventory;