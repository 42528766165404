import React from 'react';
import { Row, Col } from 'reactstrap';
import numeral from 'numeral';
import ShowIf from '../../../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import PriceOutdatedIcon from '../../../../../../../SharedComponents/Icons/PriceOutdatedIcon/PriceOutdatedIcon';
import useConversion from '../../../../../../../SharedComponents/Conversion/useConversion';
import CantConvertIcon from '../../../../../../../SharedComponents/Icons/CantConvertIcon/CantConvertIcon';
import PunchedOutIcon from "../../../../../../../SharedComponents/Icons/PunchedOutIcon/PunchedOutIcon";
import useConversions from '../../../../../../../SharedComponents/Conversion/useConversion';

const ItemPriceInfo = (item) => {
  const {
    elemId, isCustomItem, isSubRecipe, purchasedPrice,
    price, packSize, customPackSize, unitPrice,
    unitMeasure, packSizeUom, isCatchWeight,
    isMarketPrice, isPriceOutdated, unitToConvert,
    unitQuantity, unitSize, unitType, isCustomPackSize, isPunchedOut
  } = item;

  const { convertAll } = useConversions.useConvertAll();
  const { finalPackSize, finalPackSizeUom, finalUnitPrice } = convertAll(item, unitToConvert);

  return (
    <Row style={{ height: isMobile ? '90px' : '80px' }}>
      <Col className='d-flex flex-column justify-content-start px-2 pt-2'>
        <Row className='mt-1'>
          <Col xs={6} className={'text-truncate ' + ' pr-0 d-flex align-items-center' + (isCatchWeight && !isMarketPrice ? (isMobile ? ' text-label' : ' text-label-lg') : ' font-weight-bold')}>
            {isCatchWeight && !isMarketPrice ? '~' : ''} {numeral(isPriceOutdated ? purchasedPrice : price).format('$0,0.00')} {unitMeasure}
          </Col>
          <Col xs={1} className={'px-0'}>
            <ShowIf show={isPriceOutdated && !isPunchedOut}>
              <PriceOutdatedIcon elemId={elemId} item={item} />
            </ShowIf>
            <ShowIf show={isPunchedOut}>
              <PunchedOutIcon elemId={elemId} item={item} />
            </ShowIf>
          </Col>
          {/* <Col xs={5} className={'font-weight-bold pl-1 p-1 text-truncate ' + (isCustomPackSize ? ' font-italic' : '')}>{finalPackSize ? finalPackSize : <CantConvertIcon elemId={elemId} />}</Col> */}
          <Col xs={5} className={'font-weight-bold pl-1 text-truncate ' + (isCustomPackSize ? ' font-italic' : '')}>{finalPackSize ? finalPackSize : <CantConvertIcon elemId={elemId} />}</Col>

        </Row>
        <ShowIf show={!isCustomItem}>
          <Row className='mt-1' style={{ height: '24px' }}>
            <ShowIf show={finalUnitPrice >= 0.01 && !isSubRecipe}>
              <Col className='font-weight-bold'>{(finalUnitPrice ? numeral(finalUnitPrice).format('$0,0.00') + ' ' + finalPackSizeUom : '')}</Col>
            </ShowIf>
            <ShowIf show={finalUnitPrice < 0.01 && !isSubRecipe}>
              <Col className='text-blue text-label-sm w-fc'>
                <div className='border border-blue rounded-pill px-2'>
                  Unit Price below {numeral(0.01).format('$0,0.00')} {finalPackSizeUom}
                </div>
              </Col>
            </ShowIf>
          </Row>
        </ShowIf>
      </Col>
    </Row>
  );
};

export default ItemPriceInfo;