import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Inventories from '../InventoryView/Inventories/Inventories';
import InventoriesDetail from './InventoriesDetail/InventoriesDetail';
import ReviewInventory from './ReviewInventory/ReviewInventory';



const InventoryViewContent = (props) => {

    return (
        <div>
            <Switch>
                <Route exact path='/inventory-view/inventories' component={Inventories} />
                <Route exact path='/inventory-view/inventories-detail' component={InventoriesDetail} />
                <Route exact path='/inventory-view/review-inventory' component={ReviewInventory} />
            </Switch >
        </div>
    )
};

export default InventoryViewContent;
