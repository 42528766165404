import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { MdCloudDownload } from 'react-icons/md';
import { colors } from "../../../../../config/constants";
import useOrderGuideDetail from './useOrderGuideDetail';
import Header from '../../../../../SharedComponents/Header/Header';
import InfiniteScroll from 'react-infinite-scroller';

const OrderGuideDetail = (props) => {
    const {orderGuideDetailMap, downloadOG, selectedDistributor, hasMore, isLoading, getOrderGuideDetail, orderGuideDetailList } = useOrderGuideDetail.useApi(props);
    const rightButtonDownload = isMobile ? null :  { icon: [ 
        <Button size='lg' color={'green'} onClick={downloadOG} > <MdCloudDownload size={20} color={colors.white} /> &nbsp; Download </Button>
    ]}

    const SubHeaderOrderGuideDetail= (
        <Row className='header h-inherit py-3 align-items-center m-0'>
            <Col xs={1} className='pl-4'>
                DIN
            </Col>
            <Col xs={3} className='pl-4'>
                Description
            </Col>
            <Col xs={1}>
                UOM
            </Col>
            <Col xs={1}>
                UOM Price
            </Col>
            <Col xs={1}>
                Packsize
            </Col>
            <Col xs={1}>
                Unit Price
            </Col>
            <Col xs={2}>
                Catch Weight
            </Col>
            <Col xs={1}>
                Brand
            </Col>
        </Row>
    )

    return (
       <Switch>
            <Route exact path='/order-guide/detail/:id' >
                <Header showMenu={false} showLogo={false} showBack={true} title={selectedDistributor ? selectedDistributor.distributor : ''} showCart={false} rightButton={rightButtonDownload} >
                    { !isMobile ? SubHeaderOrderGuideDetail : null}
                </Header>
                    <Container fluid={true} className={isMobile ? 'px-0' : 'mt-2' } >
                        <InfiniteScroll
                            initialLoad = {false}
                            pageStart={1}
                            loadMore={getOrderGuideDetail}
                            hasMore={hasMore && !isLoading}
                            useWindow={true}>
                            {orderGuideDetailMap}
                        </InfiniteScroll>
                    </Container>
                    { orderGuideDetailList.length === 0 && !isLoading &&
                        <Card className='mt-2'>
                            <Col className='text-center p-3' xs={12}>
                                No Items Found.
                            </Col>
                        </Card>
                    }
            </Route>
            <Route>
                <Redirect to='/order-guide' />
            </Route>
       </Switch>
    )

}

export default OrderGuideDetail;