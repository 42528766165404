import React, {Fragment, useState, useEffect}  from 'react';
import { Container, Card, Row, Col } from 'reactstrap';
import Header from '../../../../../SharedComponents/Header/Header';
import {colors, orderImageUpload} from '../../../../../config/constants';
import ordersApi from '../../../../../api/ordersApi';
import InvoiceImage from './InvoiceImage';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import * as $ from 'jquery';
import { GoPlus } from 'react-icons/go';
import { useHistory } from "react-router-dom";
import { showModal } from "../../../../../reducers/modals/modalsActions";
import OptionsModal from "../../../../../SharedComponents/Modals/OptionsModal/OptionsModal";
import { useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";


const OrderUploadInvoiceMobile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultModalTitle = 'Invoices';
  const [images, setImages] = useState([]);  
  const [openView, setOpenView] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [modalTitle, setModalTitle] = useState(defaultModalTitle);  
  const params = history.location.pathname.split('/');
  const id = params[params.length-1];

  const getImages = () => {
    ordersApi.getInvoiceImages(id)
      .then(res => {
        const images = res.data;
        const functionProps = {getImages, setOpenView, setModalTitle, setImageSrc};
        setImages(images.map((image, index) => 
          <Card className='m-0 p-0' key={`order_${id}_image_${index+1}_card`}><InvoiceImage key={`order_${id}_image_${index+1}`} name={`image ${index+1}`} index={index+1} image={image} functionProps={functionProps}/></Card>
        ));
    }).catch(error => {console.log(error)});
  };

  const newInvoice = () => {
    const optionProps = {
      title: 'Select',
      options: [
        {label:  'Take a Photo', onClick: takePhoto},
        {label: 'Upload Photo from Library', onClick: uploadPhoto}
      ]
    }
    dispatch(showModal(OptionsModal, optionProps));
  };

  const selectFile = () =>{
    const file = $('#img-upload').prop('files')[0];

    const typeAllowed = orderImageUpload.allowTypes.filter(type => type === file.type)[0];
    const sizeAllowed = (file.size <= orderImageUpload.sizeLimit );

    if (!typeAllowed) {
      $('#img-upload').val('');
      return;
    }

    if (!sizeAllowed) {
      $('#img-upload').val('');
      return;
    }

    ordersApi.uploadInvoiceImage(id, file)
      .then(res => {
        $('#img-upload').val('');
        getImages();
    }).catch(error => {console.log(error)});
  };

  const uploadPhoto = () => {
    $('#img-upload').click();
  };

  const takePhoto = () => {
    history.push(`/order-history/upload-invoice/${id}/take-photo`);
  };

  const closeImage = () =>{
    setOpenView(false);
    setModalTitle(defaultModalTitle);
  };

  const rightButton = {icon: <GoPlus onClick={newInvoice} color={colors.dark} size={22} />};

  useEffect(() => {    
    getImages();
  }, []);

  return (
    <Fragment>
        <Header showMenu={false} showLogo={false} showBack={true} title={modalTitle} showCart={false} rightButton={rightButton} />
        <Container fluid={true} className='m-0 p-0'>
          <ShowIf show={!openView}>
            <input className='d-none' onChange={() => selectFile()} type='file' id='img-upload' accept=".jpg,.jpeg,.png,.pdf"/>
            {images}
          </ShowIf>
          <ShowIf show={openView}>
            <Card className='m-2'>
              <Row>
                <Col className='d-flex align-items-center justify-content-end m-2'>
                  <IoMdClose onClick={closeImage} className='clickable' color={colors.lightBlueText} />
                </Col>
              </Row>
              <Row>
                <Col className='d-flex align-items-center justify-content-center m-2'>
                  <img className='w-100' src={imageSrc}/>
                </Col>
              </Row>
            </Card>
          </ShowIf>
        </Container>        
    </Fragment>
  );

}

export default OrderUploadInvoiceMobile;