import React from 'react';
import SignUpContactInformation from '../Sections/SignUpContactInformation';
import SignUpLocationInformation from '../Sections/SignUpLocationInformation';
import ShowIf from '../../../SharedComponents/ShowIf/ShowIf';

const SignUpStep1 = (props) => {
  const {
    contactInformation, setLocationData, step, showLocation, locationsData
  } = props;

  return (
    <ShowIf show={step === 1}>
      <ShowIf show={showLocation === 1}>
        <SignUpContactInformation {...contactInformation} setLocationData={setLocationData} locationsData={locationsData} />
        <SignUpLocationInformation key='loc_1' number={1} index={0} setData={setLocationData} init={locationsData[0]} />
      </ShowIf>
      <ShowIf show={showLocation > 1}>
        <SignUpLocationInformation key={`loc_${showLocation}`} number={showLocation} index={showLocation-1} setData={setLocationData} init={locationsData[showLocation-1]} />
      </ShowIf>
    </ShowIf>
  );
};

export default SignUpStep1;