import React from 'react'
import { Row, Col, Card, Button, CardHeader, CardBody } from 'reactstrap'
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { colors } from '../../../../../config/constants';
import IconBadge from '../../../../../SharedComponents/IconBadge/IconBadge';
import { FaQuestion, FaCalendarDay, FaClock, FaPen } from 'react-icons/fa';
import { cardIconHandler } from '../../ManageCards/cardIconHandler';


const FinalReviewCard = (props) => {

    const { nameDistributor, priceTotalCard, paymentMethod, deliveryDetail, submit } = props;
    const texts = {
        totalSaving: `All the savings you've made with Foodbam on this order!`,
        lineItems: `Excluding Special Request Items!`
    }

    return (
        <Card className='review-order-card'>
            <CardHeader>
                {nameDistributor}
                <span>{priceTotalCard}</span>
            </CardHeader>
            <CardBody>
                <h1>SUMMARY</h1>
                <Row>
                    <Col className='inline'>
                        <p>Total Savings</p>
                        <div className='marginIcon' >
                            <IconBadge idPop='ROCpop1' text={texts.totalSaving} FaIcon={FaQuestion} size={7} color={colors.white} />

                        </div>

                    </Col>
                    <Col className='text-end'>
                        <p>$0.00</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            {nameDistributor} Sub Total</p>
                    </Col>
                    <Col className='text-end'>
                        <p>{priceTotalCard}</p>
                    </Col>
                </Row>
                <hr></hr>

                <h1>SHIPPING DETAILS</h1>
                <Row className="contentShippingDetail">
                    <Col lg='1'>
                        <FaCalendarDay />
                    </Col>
                    <Col className='inline'>
                        <p>{deliveryDetail.valueDeliveryDate}</p>
                    </Col>
                </Row>
                <ShowIf show={deliveryDetail.valueDeliveryTime}>
                    <Row className="contentShippingDetail">
                        <Col lg='1'>
                            <FaClock />
                        </Col>
                        <Col>
                            <p>{deliveryDetail.valueDeliveryTime}</p>
                        </Col>
                    </Row>
                </ShowIf>

                <ShowIf show={deliveryDetail.valueNote}>
                    <Row className="contentShippingDetail">
                        <Col lg='1'>
                            <FaPen />
                        </Col>
                        <Col>
                            <p>{deliveryDetail.valueNote}</p>
                        </Col>
                    </Row>
                </ShowIf>

                <hr></hr>
                <h1>PAYMENT DETAILS</h1>
                <Row>

                    <ShowIf show={paymentMethod.type == 'credit_card'}>
                        <Col lg='2'>
                            <img src={cardIconHandler(paymentMethod.card)}></img>
                        </Col>
                        <Col className='inline'>
                            <p style={{ 'marginTop': 10 }}>{paymentMethod.card.last4}</p>
                        </Col>
                    </ShowIf>
                    <ShowIf show={paymentMethod.type == 'terms'}>
                        <Col className='inline'>
                            <p>Pay with Terms</p>
                        </Col>
                    </ShowIf>


                </Row>
                <hr></hr>

                <Row>
                    <Col className='inline'>
                        <p>{nameDistributor}</p>

                    </Col>
                    <Col className='text-end'>
                        <p>{priceTotalCard}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button className='buttonCheck' color='green' onClick={submit}>Submit</Button>
                    </Col>
                </Row>
                <div className='review-order-footer'>
                </div>
            </CardBody>
        </Card>
    )
}

export default FinalReviewCard;