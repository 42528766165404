import React, {useState, useEffect}  from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FaEye, FaTrash } from 'react-icons/fa';
import {colors, orderImageUpload} from '../../../../../config/constants';
import ordersApi from '../../../../../api/ordersApi';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';

const InvoiceImage = (props) => {
  const { image, index, name, functionProps } = props;
  const [showNormal, setShowNormal] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [showView, setShowView] = useState(false);
  const {getImages, setOpenView, setModalTitle, setImageSrc} = functionProps;

  const seeNormal = () => {
    setShowNormal(true);
    setShowDelete(false);
    setShowView(false);
  };

  const seeDelete = () => {
    setShowNormal(false);
    setShowDelete(true);
    setShowView(false);
  };

  const seeView = () => {
    if(image.image_path){
      const parts = image.image_path.split('.');
      const extension = parts[parts.length-1];
      const exists = orderImageUpload.downloadTypes.filter(type => type === extension)[0];

      if(!exists){
        setShowNormal(false);
        setShowDelete(true);
        setShowView(false);

        setOpenView(true);
        setModalTitle(`Invoice ${index}`);
        setImageSrc(image.image_path);
      }else{
        let link = document.createElement('a');
        link.href = image.image_path;
        link.download = '' ;
        link.dispatchEvent(new MouseEvent('click'));
      }
    }  
  };

  const execDelete = () => {
    ordersApi.deleteInvoiceImage(image.order_id, image.id)
      .then(res => {
        getImages();
        seeNormal();
    }).catch(error => {console.log(error)});

  };

  
  return (    
    <Row className={`py-2`}>
      <Col xs={isMobile ? 7 : 8} className={`d-flex align-items-center justify-content-left ${isMobile ? `pl-4 pr-0` : `px-4`}`}>
          {name}
      </Col>
      <Col xs={isMobile ? 5 : 4} className={`d-flex align-items-center ${isMobile ? `px-0` : `m-0 p-0`} `}>
        <ShowIf show={showNormal}>
          <Col className='text-right'>
            <FaEye onClick={()=> seeView()} className='clickable' color={colors.lightBlueText}/>
          </Col>
          <Col className='text-left'>
            <FaTrash onClick={()=> seeDelete()} className='clickable' color={colors.lightBlueText}/>
          </Col>
        </ShowIf>
        <ShowIf show={showDelete}>
          <Col className={`text-right ${isMobile ? `p-0`:`p-1`}`}>
            <Button color='orange' onClick={()=> execDelete()}>Delete</Button>
          </Col>
          <Col className={`text-left ${isMobile ? `p-0`:`p-1`}`}>
            <Button color='green' onClick={()=> seeNormal()}>Cancel</Button>
          </Col>          
        </ShowIf>
      </Col>
    </Row>
    
  )

}

export default InvoiceImage;