import { useState, useEffect } from "react";
import validate from 'validate.js';

const onChange = (evt, value, setValue, props, setError) => {
  let newValue = '';
  if (evt) {
    if (evt.currentTarget) {
      newValue = evt.currentTarget.value !== null && evt.currentTarget.value !== undefined  ? evt.currentTarget.value : '';
    } else {
      newValue = evt ? evt : '';
    }
  }
  if (props) {
    if (props.validate) {
      const result = validate.single(newValue, props.validate);
      if (result) {
        newValue = value; //Didn't pass validation, update input to old state.
      }
      if (setError) {
        setError(result);
      }
    }
    if (props.isNumber) {
      if (newValue) {
        newValue = parseFloat(newValue);
      }
      if (props.isNumber.min !== null || props.isNumber.min !== undefined) {
        newValue = newValue !== null && newValue !== undefined ? (newValue < props.isNumber.min ? props.isNumber.min : newValue) : ( props.isNumber.allowEmpty ? '' : props.isNumber.min);
      }
      if (props.isNumber.max) {
        newValue = newValue !== null && newValue !== undefined ? (newValue > props.isNumber.max ? props.isNumber.max : newValue) : '';
      }
    }
    if (props.isInvalid) {
      const result = validate.single(newValue, props.isInvalid);
      setError(result);
    }
    if (props.onChange) {
      props.onChange(newValue);
    }
    if (props.set) {
      props.set(newValue);
    }
  }
  setValue(newValue);
};

const useText = (init, props) => {
  const [value, setStateValue] = useState(init || init === 0 ? init : '');
  const [error, setError] = useState(null);

  const set = (evt) => {
    onChange(evt, value, setStateValue, props, setError);
  };

  const setValue = (val) => {
    onChange({currentTarget: {value: val}}, value, setStateValue, props, setError);
  };

  const onBlur = (evt) => {
    if (props) {
      const val = props ? (props.value === '' || props.value ? props.value : value) : value;
      const result = validate.single(val, props.isInvalidOnBlur);
      setError(result);
    }
  };

  const onFocus = () => {
    setError(null);
  };

  const clearValue = () => {
    if (props) {
        if (props.set) {
          props.set('');
        }
      }
    setValue('');
  };

  return {
    value: props ? (props.value === '' || props.value ? props.value : value) : value,
    set, clearValue, setValue, error, setError, onBlur, onFocus
  };
};

const useCheckbox = (initValue) => {
  const [value, setValue] = useState(initValue ? true : false);

  const toggle = (evt) => {
    const newValue = !value;
    setValue(newValue);
  };

  const set = (value) => {
    setValue(value ? true : false);
  };

  return { value, toggle, set };
};

const useCheckboxGroup = (initItems) => {
  const [items, setItems] = useState(initItems ? initItems : []);
  const { toggle: toggleAll, value: all, set: setAll} = useCheckbox();

  useEffect(() => {
    if (all) {
      setItems(items.map(i => {
        return {
          ...i,
          check: all
        }
      }));
    } else {
      let allCheck = true;
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        if (!element.check) {
          allCheck = false;
        }
      }
      if (allCheck) {
        setItems(items.map(i => {
          return {
            ...i,
            check: all
          }
        }));
      }
    }
  }, [all]);

  useEffect(() => {
    let allCheck = true;
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (!element.check) {
        allCheck = false;
      }
    }
    if ((allCheck && !all) || (!allCheck && all)) {
      toggleAll();
    }
  }, [items]);

  const toggleItem = (index) => {
    setItems(items.map((item, i) => {
      if (i !== index) return item;
      return {
        ...item,
        check: !item.check
      }
    }));
  };

  const setItem = (index, newData) => {
    setItems(items.map((item, i) => {
      if (i !== index) return item;
      return {
        ...item,
        ...newData
      };
    }));
  };

  return { toggleItem, items, setItems, toggleAll, all, setAll, setItem };
};

const useRadioGroup = (radios) => {
  let init = radios.map((r, i) => {
    return i === 0 ? true : false;
  });
  const [value, setValue] = useState(init);

  const obj = {};

  radios.forEach((r, i) => {
    obj[r] = {
      value: value[i],
      set: () => {
        setValue(value.map((v, ii) => {
          if (i === ii) return true;
          return false;
        }))
      }
    };
  });

  return {...obj};
};

const useObject = (init) => {
  const [value, set] = useState(init);
  return {
    set: set,
    value: value
  };
};

export default {
  useText, useCheckbox, useCheckboxGroup,
  useRadioGroup, onChange, useObject
};
