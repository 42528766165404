import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import shoppingListsApi from '../../../../api/shoppingListsApi';
import ShoppingListsRow from './ShoppingListsRow';

const useApi = () => {
  const [lists, setLists] = useState([]);
  const history = useHistory();

  const getShoppingLists = () => {
    shoppingListsApi.get().then(({data}) => {
      setLists(data);
    }).catch(err=> {
      console.error('shoppingListspApi get Error:', err);
    });
  }

  useEffect(() => {
    getShoppingLists();

    return () => {
      setLists([]);
    };
  }, [history.length]);

  const listsMap = lists.map(list => <ShoppingListsRow key={`shopping-list-${list.id}`}>{list}</ShoppingListsRow>);

  return { lists, listsMap };
};

export default {
  useApi
};
