import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Container, Row, Col, Card, Label } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import { isMobile } from 'react-device-detect';
import { FaInfo } from 'react-icons/fa';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PurchasesGraphs from './PurchasesGraphs/PurchasesGraphs';
import ItemsList from './ItemsList/ItemsList';
import OrdersGraph from './OrdersGraph/OrdersGraph';
import OrdersList from './OrdersList/OrdersList';
import SavingsGraph from './SavingsGraph/SavingsGraph';
import ItemLookup from './ItemLookup/ItemLookup';
import Header from '../../../../SharedComponents/Header/Header';
import ShowIf from '../../../../SharedComponents/ShowIf/ShowIf';
import IconBadgeSolid from '../../../../SharedComponents/IconBadge/IconBadgeSolid';
import { colors } from '../../../../config/constants';
import noImage from '../../../../assets/images/no-image.png';
import analyticsApi from '../../../../api/analyticsApi';
import ItemsPurchased from './ItemsPurchased/ItemsPurchased';
import { FaArrowDown } from "react-icons/fa";
import './Analytics.scss';
import SavingsSuggestions from './SavingsSuggestions/SavingsSuggestions';
import useItemsPurchased from "./ItemsPurchased/useItemsPurchased";

const Analytics = () => {
  const [filterType, setFilterType] = useState('month');
  const [startDate, setStartDate] = useState(new Date());

  const [endDate, setEndDate] = useState(new Date());
  const [analyticsData, setAnalyticsData] = useState({
    total_purchases: { detail: [] },
    total_orders: { detail: { distributorData: [] } },
    total_savings: { detail: [] }
  });
  const [itemDistributorId, setItemDistributorId] = useState('default');
  const [listStatistics, setListStatistics] = useState(null);
  const [distributor, setDistributor] = useState(null);
  const [category, setCategory] = useState(null);
  const history = useHistory();

  const activeLocation = useSelector(({ user }) => user.activeLocation);

  const getHeaderText = () => {
    switch (history.location.pathname) {
      case '/analytics/total-purchases':
        return 'Total Purchases';
      case '/analytics/total-orders':
        return 'Total Orders';
      case '/analytics/total-savings':
        return 'Total Savings';
      default:
        return (activeLocation.nickname ? activeLocation.nickname : 'N/A') + ' Highlights';
    }
  }

  const isAnalytics = () => {
    return history.location.pathname == '/analytics';
  }

  const isTotalPurchases = () => {
    return history.location.pathname == '/analytics/total-purchases';
  }

  const isTotalOrders = () => {
    return history.location.pathname == '/analytics/total-orders';
  }

  const isTotalSavings = () => {
    return history.location.pathname == '/analytics/total-savings';
  }

  const goToAnalytics = () => {
    return history.push('/analytics');
  }

  const goToTotalPurchases = () => {
    return history.push('/analytics/total-purchases');
  }

  const goToTotalOrders = () => {
    return history.push('/analytics/total-orders');
  }

  const goToTotalSavings = () => {
    return history.push('/analytics/total-savings');
  }

  const _changePriceZero = (listSeries) => {
    if (listSeries) {
      var series_lenght = listSeries.length;
      for (let i = 0; i < series_lenght; i++) {
        if (listSeries[i] === 0 && i !== 0) {
          listSeries[i] = listSeries[i - 1];
        }
      }
    }
  }

  const getAnalytics = (filters) => {
    setDistributor(null);
    analyticsApi.getAnalytics(filters).then(({ data }) => {
      setAnalyticsData(data);
    }).catch(err => {
      console.error('getAnalytics get Error:', err);
    });
  };

  useEffect(() => {
    let filter = { filter_type: filterType };
    if (filterType == 'custom') {
      filter = { ...filter, start_date: moment(startDate).format('YYYY-MM-DD'), end_date: moment(endDate).format('YYYY-MM-DD') };
    }
    getAnalytics(filter);
  }, [filterType]);

  useEffect(() => {
    if (filterType == 'custom') {
      getAnalytics({
        filter_type: 'custom',
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD')
      });
    }
  }, [startDate, endDate]);

  const companyNickName = '' + (activeLocation && activeLocation.company && activeLocation.company.nickname);
  const isFoodbam = !(companyNickName.toLowerCase() !== 'foodbam');
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPurchasedApi = useItemsPurchased.useApi();
  // const [moreItemsPurchased, setMoreItemsPurchased] = useState(itemsPurchasedApi.canLoadMore);
  const itemsPurchased = itemsPurchasedApi.items;
  const [savingsSuggestions, setSavingsSuggestions] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(false);

  // const getGreatestSavings = () => {
  //   let itemDistributorIds = [];
  //   let greatestSavingItems = [];
  //   analyticsApi.getGreatestSavings(page).then((res) => {
  //     setShowLoadMore(res.data._meta.pageCount > res.data._meta.currentPage);

  //     const items = res.data.items;
  //     for (let i = 0; i < items.length; i++) {
  //       itemDistributorIds.push(items[i].currentItemDistId);
  //       itemDistributorIds.push(items[i].newItemDistId);
  //     }

  //     analyticsApi.getSavingsItemPrice(itemDistributorIds).then((responseData) => {
  //       for (let i = 0; i < items.length; i++) {
  //         for (let j = 0; j < responseData.data.length; j++) {
  //           if (items[i].currentItemDistId === responseData.data[j].itemDistributorId) {
  //             items[i].currentItemPrice = responseData.data[j].price;
  //             items[i].currentType = responseData.data[j].type;
  //           }
  //           else if (items[i].newItemDistId === responseData.data[j].itemDistributorId) {
  //             items[i].newItemPrice = responseData.data[j].price;
  //           }
  //           items[i].showOverlayItem = false;
  //           items[i].distributorId = items[i].newDistributorId;
  //           items[i].Id = items[i].newId;
  //         }
  //       }
  //       for (let k = 0; k < items.length; k++) {
  //         greatestSavingItems.push(items[k]);
  //       }
  //       setItems(items.concat(greatestSavingItems));
  //     });

  //   }).catch(err => {
  //     console.error('getAnalytics get getGreatestSavings:', err);
  //   });
  // }

  // useEffect(() => {
  //   getGreatestSavings();
  // }, [page]);

  // const getSavingSuggestions = () => {
  //   const suggestions = analyticsApi.getSavingSuggestions(1);
  //   setSavingsSuggestions(savingsSuggestions.concat(suggestions));
  // };

  return (

    <Container fluid={true} className='m-0 p-0'>
      <Header showMenu={true} showLogo={true} showBack={false} title='Analytics' showCart={true} />
      <Container fluid={true} className='analytics my-5 rounded'>

        <ShowIf show={!isAnalytics()}>
          <Row className='mb-2'>
            <Col xs={12}>
              <span className='clickable analytics-legend' style={{ color: '#006DBA' }} onClick={goToAnalytics}>Analytics</span> > <span className='analytics-legend'>{getHeaderText()}</span>
            </Col>
          </Row>
        </ShowIf>

        <Card className='p-3'>
          <Row>
            <Col xs={12}>
              <Row className='border-bottom'>
                <Col xs={isMobile ? 4 : 8} className='analytics-header'>
                  {getHeaderText()}
                </Col>
                <Col xs={isMobile ? 8 : 4}>
                  <Row>
                    <Col xs={3} className={'analytics-tab ' + (filterType == 'week' ? 'active-tab' : '')} onClick={() => setFilterType('week')} title='WEEKLY'>WEEKLY</Col>
                    <Col xs={3} className={'analytics-tab ' + (filterType == 'month' ? 'active-tab' : '')} onClick={() => setFilterType('month')} title='MONTHLY'>MONTHLY</Col>
                    <Col xs={3} className={'analytics-tab ' + (filterType == 'year' ? 'active-tab' : '')} onClick={() => setFilterType('year')} title='YEARLY'>YEARLY</Col>
                    <Col xs={3} className={'analytics-tab ' + (filterType == 'custom' ? 'active-tab' : '')} onClick={() => setFilterType('custom')} title='CUSTOM'>CUSTOM</Col>
                  </Row>
                </Col>
              </Row>

              <ShowIf show={filterType == 'custom'}>
                <Row>
                  <Col xs={12} className={(isMobile ? 'd-flex flex-column justify-content-center' : 'd-flex justify-content-center') + ' pt-5 pb-2'}>
                    <Label for='startDateInput' className={'pr-2' + (isMobile ? '' : ' pl-2')}>Start Date:</Label>
                    <DatePicker id='startDateInput' className={isMobile ? 'mb-3' : ''} selected={startDate} onChange={date => setStartDate(date)} />
                    <Label for='endDateInput' className={'pr-2' + (isMobile ? '' : ' pl-2')}>End Date:</Label>
                    <DatePicker id='endDateInput' className={isMobile ? 'mb-3' : ''} selected={endDate} onChange={date => setEndDate(date)} />
                  </Col>
                </Row>
              </ShowIf>

              <ShowIf show={isAnalytics()}>
                <Row className='my-2 py-3 text-center'>
                  <Col xs={4} className='clickable' onClick={goToTotalPurchases}>
                    <Row>
                      <Col xs={12} className='font-size-36'>
                        {analyticsData && analyticsData.total_purchases && numeral(analyticsData.total_purchases.total).format('$0,0.00')}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className='brand-distributor'>
                        <span className='analytics-stat'> Total purchases</span>
                        <IconBadgeSolid Icon={FaInfo} colorIcon='white' color={colors.blue} elemId='total-purchases'>
                          <Row className='p-3'>
                            <Col>This number is the total purchases made within the specified time period.</Col>
                          </Row>
                        </IconBadgeSolid>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={4} className='clickable' onClick={goToTotalOrders}>
                    <Row>
                      <Col xs={12} className='font-size-36 border-right border-left'>
                        {analyticsData && analyticsData.total_orders && analyticsData.total_orders.total || 0}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className='brand-distributor border-right border-left'>
                        <span className='analytics-stat'> Total orders</span>
                        <IconBadgeSolid Icon={FaInfo} colorIcon='white' color={colors.blue} elemId='total-orders'>
                          <Row className='p-3'>
                            <Col>This number is the total orders made within the specified time period.</Col>
                          </Row>
                        </IconBadgeSolid>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={4} className='clickable' onClick={goToTotalSavings}>
                    <Row>
                      <Col xs={12} className='font-size-36'>
                        {analyticsData && analyticsData.total_savings && numeral(analyticsData.total_savings.total).format('$0,0.00')}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className='brand-distributor'>
                        <span className='analytics-stat'> Total savings</span>
                        <IconBadgeSolid Icon={FaInfo} colorIcon='white' color={colors.blue} elemId='total-savings'>
                          <Row className='p-3'>
                            <Col>This number is the total savings accumulated via comparison view, match swaps, rebates, and just general distributor price decreases caused by {activeLocation && activeLocation.company && activeLocation.company.nickname}{"'"}s price comparison. This amount is specific to the time period selected. </Col>
                          </Row>
                        </IconBadgeSolid>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ShowIf>

              <ShowIf show={isTotalPurchases()}>
                <PurchasesGraphs analyticsData={analyticsData} onDistributorClick={distributor => { setCategory(null); setDistributor(distributor); }} onCategoryClick={category => { setDistributor(null); setCategory(category); }} />
              </ShowIf>

              <ShowIf show={isTotalOrders()}>
                <OrdersGraph analyticsData={analyticsData} onDistributorClick={distributor => setDistributor(distributor)} />
              </ShowIf>

              <ShowIf show={isTotalSavings()}>
                <SavingsGraph analyticsData={analyticsData} />
              </ShowIf>
            </Col>
          </Row>
        </Card>

        <ShowIf show={isTotalPurchases()}>
          <ItemsList filterType={filterType} startDate={startDate} endDate={endDate} distributor={distributor} category={category} />
        </ShowIf>

        <ShowIf show={isTotalOrders()}>
          <OrdersList filterType={filterType} startDate={startDate} endDate={endDate} distributor={distributor} />
        </ShowIf>

        <ShowIf show={isAnalytics()}>
          <ItemLookup filterType={filterType} startDate={startDate} endDate={endDate} />
        </ShowIf>

        <ShowIf show={false && isAnalytics()}>
          <Card>
            <Col xs={12} className='fb-analytics-content'>
              <Row className='text-center mb-0 border-bottom analytics-header'>
                <Col xs={isFoodbam ? 6 : 12} className='border-right' style={{ padding: '15px' }}>Items You Have Purchased</Col>
                <ShowIf show={isFoodbam}>
                  <Col xs={6} style={{ padding: '15px' }}>{companyNickName} Savings Suggestions</Col>
                </ShowIf>
              </Row>
              <Row>
                <Col xs={isFoodbam ? 6 : 12} className='border-right'>
                  &nbsp;
                </Col>
              </Row>
              <Row>
                <Col xs={isFoodbam ? 6 : 12} className='border-right'>
                  {
                    items.map((item, index) => {
                      return (
                        <div className='analytics-border mb-2' style={{ borderRadius: '5px' }}>
                          <Container>
                            <Row>
                              <Row>
                                <Col xs={6}></Col>
                                <Col xs={6}>
                                  <ShowIf show={item.currentType === 'order_guide'}><span className='order-guide-icon-analytics'>Order Guide</span></ShowIf>
                                </Col>
                              </Row>
                              <Row className='mt-2'>
                                <Col xs={2}>
                                  <Row>
                                    <Col xs={12} className='d-flex align-items-center justify-content-center'>
                                      <img className='no-image' src={item.imageUrl || item.brandImageUrl || noImage} />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col xs={10}>
                                  <Row>
                                    <Col xs={12}>
                                      <ShowIf show={!item.currentDescription}>
                                        <h4 className='itemname text-capitalize' title={item.currentName}>{item.currentName}</h4>
                                      </ShowIf>
                                      <ShowIf show={item.currentDescription}>
                                        <h4 className='itemname text-capitalize' title={item.currentDescription}>{item.currentDescription}</h4>
                                      </ShowIf>
                                    </Col>
                                  </Row>
                                  <Row className='additional-info mt-2'>
                                    <Col xs={12} className='brand-distributor fb-singleliner text-capitalize' title={item.foodbamCustomDescription}>{item.foodbamCustomDescription}</Col>
                                  </Row>
                                  <Row className='additional-info mt-2'>
                                    <Col xs={5} className='brand-distributor fb-singleliner'>{item.currentBrand || ''}</Col>
                                    <Col xs={7} className='brand-distributor fb-singleliner '>{item.currentDistributor || ''}</Col>
                                  </Row>
                                  <Row className='additional-info mt-2'>
                                    <Col xs={5} className='fb-singleliner'>{numeral(item.currentItemPrice).format('$0,0.00')} {item.currentUnitMeasure}</Col>
                                    <Col xs={7} className='fb-singleliner'>{item.currentPackSize || ''}</Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Row>
                            <Row className='text-center size-12'>
                              <Col xs={12} className='mt-2' style={{ marginBottom: '21px' }}>
                                &nbsp;
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      );
                    })
                  }
                </Col>

                <ShowIf show={isFoodbam}>
                  <Col xs={6}>
                    {
                      items.map((item, index) => {
                        return (
                          <div className='analytics-border mb-2' style={{ borderRadius: '5px' }}>
                            <Container>
                              <Row>
                                <Row className='mt-2'>
                                  <Col xs={6}></Col>
                                  <Col xs={6}>
                                    <ShowIf show={item.caseSavings > 0 && !item.showOverlayItem}>
                                      <span className='analytics-save'>Save {numeral(item.caseSavings).format('$0,0.00')} per {item.currentUnit ? item.currentUnit : item.currentPackSizeUom}!</span>
                                    </ShowIf>
                                  </Col>
                                </Row>
                                <Row className={!item.showOverlayItem ? 'textarea-chart-bottom' : ''}>
                                  <Col xs={2}>
                                    <Row>
                                      <ShowIf show={!item.showOverlayItem}>
                                        <Col xs={12} className='text-center'>
                                          <div embedly='getNewItemImageUrl(item)' embedly-key='server_embedly_key' error-url='no_image_url' em-height='80' em-length='80'></div>
                                        </Col>
                                      </ShowIf>
                                    </Row>
                                  </Col>
                                  <Col xs={8}>
                                    <ShowIf show={!item.showOverlayItem}>
                                      <Row>
                                        <Col xs={12}>
                                          <ShowIf show={!item.newDescription}>
                                            <h4 className='itemname text-capitalize' title={item.newName}>{item.newName}</h4>
                                          </ShowIf>
                                          <ShowIf show={item.newDescription}>
                                            <h4 className='itemname text-capitalize' title={item.newDescription}>{item.newDescription}</h4>
                                          </ShowIf>
                                        </Col>
                                      </Row>
                                      <Row className='additional-info mt-2'>
                                        <Col xs={12} className='brand-distributor fb-singleliner text-capitalize' title={item.foodbamCustomDescription}>{item.foodbamCustomDescription}</Col>
                                      </Row>
                                      <Row className='additional-info mt-2'>
                                        <Col xs={5} className='brand-distributor fb-singleliner'>{item.newBrand || ''}</Col>
                                        <Col xs={7} className='brand-distributor fb-singleliner '>{item.newDistributor || ''}</Col>
                                      </Row>
                                      <Row className='additional-info my-2'>
                                        <Col xs={5} className='fb-singleliner'>{numeral(item.newItemPrice).format('$0,0.00')} {item.newUnitMeasure}</Col>
                                        <Col xs={7} className='fb-singleliner'>{item.newPackSize || ''}</Col>
                                      </Row>
                                    </ShowIf>
                                    <ShowIf show={item.showOverlayItem}>
                                      <Row>
                                        <Col xs={5}>
                                        </Col>
                                        <Col xs={2} className='mt-2'>
                                          <img src='assets/images/subtract-active.png' className='cart-buttons clickable' ng-click='minusItem(item)' />
                                        </Col>
                                        <Col xs={3} className='mt-3'>
                                          <input type='number' ng-model='item.caseQuantity' className='fb-input-xsmall text-center'
                                            ng-blur='updateQuantityBlur(item)' restrict-decimal />
                                        </Col>
                                        <Col xs={2} className='mt-2'>
                                          <img src='assets/images/add-active.png' className='cart-buttons clickable' ng-click='plusItem(item)' />
                                        </Col>
                                      </Row>
                                    </ShowIf>
                                  </Col>
                                  <Col xs={2}>
                                    <Row className='mt-2'>
                                      <ShowIf show={!item.showOverlayItem && !item.caseQuantity}>
                                        <Col xs={6}>
                                          <img src='assets/images/add.png' className='cart-buttons clickable' ng-click='showPlusMinus(item)' />
                                        </Col>
                                      </ShowIf>
                                      <ShowIf show={item.showOverlayItem}>
                                        <Col xs={6}>
                                          <img src='assets/images/next.png' className='cart-buttons clickable' ng-click='hidePlusMinus(item)' />
                                        </Col>
                                      </ShowIf>
                                      <ShowIf show={!item.showOverlayItem && item.caseQuantity}>
                                        <Col xs={6} className='text-left mt-3' ng-click='showPlusMinus(item)'>
                                          <div className='blue-circle clickable'>{item.caseQuantity}</div>
                                        </Col>
                                      </ShowIf>
                                    </Row>
                                  </Col>
                                </Row>
                              </Row>
                              <ShowIf show={!item.showOverlayItem}>
                                <Row className='text-center size-12'>
                                  <Col xs={12} className='my-2' style={{ fontWeight: '500' }}>
                                    ESTIMATED YEARLY SAVINGS : {numeral(item.yearAvgSavings).format('$0,0.00')}
                                  </Col>
                                </Row>
                              </ShowIf>
                              <ShowIf show={item.showOverlayItem}>
                                <Row>
                                  <Col xs={12} className='my-2'>
                                    &nbsp;
                                  </Col>
                                </Row>
                              </ShowIf>
                            </Container>
                          </div>
                        );
                      })
                    }
                  </Col>
                </ShowIf>
              </Row>
            </Col>
            <ShowIf show={showLoadMore}>
              <Col xs={12} className='fb-analytics-content'>
                <div>
                  <div style={{ color: '#6ed79a' }}>
                    <span className='clickable' onClick={() => setPage(page + 1)}>
                      <span>Load More</span> &nbsp;
                      <i className='icon ion-arrow-down-c'></i>
                    </span>
                  </div>
                </div>
              </Col>
            </ShowIf>
          </Card>
        </ShowIf>

        <ShowIf show={isAnalytics() && itemsPurchased.length > 0}>
          <Card className="my-3">
            <Row>
              <Col sm={6} style={ {paddingRight : !isMobile ? 0 : ""} }>
                <ItemsPurchased items={ itemsPurchased } className="items-purchased"/>
              </Col>
              <Col sm={6} style={ {paddingLeft : !isMobile ? 0 : ""} }>
                <SavingsSuggestions suggestions={ itemsPurchased.map(item => {
                  return {
                    id: item.savingsItemDistributorId,
                    savings: item.savingsSavings,
                    merchantId: item.savingsMerchantId,
                    itemId: item.savingsItemId,
                    itemDistributorId: item.savingsItemDistributorId,
                    name: item.savingsName,
                    description: item.savingsDescription,
                    unitMeasure: item.savingsUnitMeasure,
                    brandImageUrl: item.savingsBrandImageUrl,
                    brand: item.savingsBrand,
                    distributorId: item.savingsDistributorId,
                    distributor: item.savingsDistributor,
                    price: item.savingsPrice,
                    packSize: item.savingsPackSize
                  };
                }) } className="no-border savings-suggestions "/>
              </Col>
            </Row>
            <ShowIf show={itemsPurchasedApi.canLoadMore}>
              <Col xs={12} className="p-3 clickable load-more-items" onClick={ () => { itemsPurchasedApi.nextPage(); } }>
                <div>
                  Load More
                  <FaArrowDown className="icon"/>
                </div>
              </Col>
            </ShowIf>
          </Card>
        </ShowIf>

      </Container>
    </Container>

  );
};

export default Analytics;
