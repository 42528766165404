import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { GoPlus } from 'react-icons/go';
import Header from '../../../../SharedComponents/Header/Header';
import SingleShoppingList from './SingleShoppingList/SingleShoppingList';
import ShoppingListForm from './ShoppingListForm/ShoppingListForm';
import ShoppingListItemsManager from './ShoppingListItemsManager/ShoppingListItemsManager';
import useShoppingLists from './useShoppingLists';
import './ShoppingLists.scss';

const ShoppingLists = () => {
  const { listsMap } = useShoppingLists.useApi();

  const history = useHistory();

  const goToNewShoppingList = () => {
    history.push('/shopping-lists/add-list');
  };

  const rightButton = { icon: <GoPlus onClick={goToNewShoppingList} className='clickable' size={22} /> };

  return (
    <Switch>
      <Route exact path='/shopping-lists'>
        <Container fluid={true} className='m-0 p-0'>
          <Header showMenu={true} showLogo={isMobile ? false : true} showBack={false} title='Manage Shopping Lists' showCart={false} rightButton={rightButton} />
          <Container fluid={true} className='shopping-lists my-5 rounded'>
            {listsMap}
          </Container>
        </Container>
      </Route>
      <Route exact path='/shopping-lists/add-list'>
        <ShoppingListForm />
      </Route>
      <Route exact path='/shopping-lists/update-list/:id'>
        <ShoppingListForm />
      </Route>
      <Route exact path='/shopping-lists/:id'>
        <SingleShoppingList />
      </Route>
      <Route exact path='/shopping-lists/items/:id'>
        <ShoppingListItemsManager />
      </Route>
      <Route>
        <Redirect to='/shopping-lists' />
      </Route>
    </Switch>
  );
};

export default ShoppingLists;
