import React from 'react';
import { Container } from "reactstrap";
import './AdminLogin.scss';
import AdminLoginHeader from './AdminLoginHeader';
import { useSelector } from 'react-redux';
import AdminLoginForm from './AdminLoginForm';
import useAdminLogin from './useAdminLogin';

const AdminLogin = (props) => {
  const logo = useSelector(state => state.company.logo);

  const adminLoginProps = useAdminLogin();

  return (
    <Container fluid={true} className='p-0 admin-login-app'>
      <AdminLoginHeader logo={logo} />
      <AdminLoginForm {...adminLoginProps} />
    </Container>
  )
};

export default AdminLogin;