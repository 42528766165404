import React, {Fragment} from 'react';
import { Card, Row, Col } from "reactstrap";
import { isMobile } from 'react-device-detect';
import {colors} from '../../../../../config/constants';
import noImage from '../../../../../assets/images/no-image.png';


const OrderReceiveRow = (props) => {
    const selectedOrder = props.selectedOrder;
    const item  = props.data;
    const imageUrl = item.imageUrl ? item.imageUrl : noImage;

    if(isMobile) {
        return (
            <Card className='px-2 pb-3 is-mobile'>
                <Row className='oh'>
                    <Col>
                        <span className='float-md-right text-uppercase order-none' >{(item.status === 'electronically_received' ? 'Received' : item.status)}</span>
                        <span className='float-md-right text-uppercase order-guide' style={{fontSize:'9px'}}>{item.isSpecial === 1 ? 'Special Request' : 'Order Guide'}</span>
                    </Col> 
                </Row>
                <Row className='pt-4'>
                    <Col xs={2} className='pt-4 text-center text-main'>
                        {item.quantity}
                    </Col>
                    <Col xs={2} className='text-center w-image-sm p-0'>
                        <img src={imageUrl} alt={item.name} className='no-image' />
                    </Col>
                    <Col xs={5}>
                        <Row>
                            <Col className='text-capitalize text-truncate text-main'>
                                {item.item}
                            </Col>
                        </Row>
                        <Row className='text-gray'>
                            <Col className='pl-3 text-truncate'>
                                {selectedOrder ? selectedOrder.distributor : ''}
                            </Col>
                        </Row>
                        <Row className='text-main'>
                            <Col className='pl-3'>
                                {item.packSize}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={3} className='d-flex align-items-center text-main'>
                        <Row>
                            <Col>
                                ${(item.price * item.quantity).toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        )
    }

    return (
        <Card className='px-2'>
            <Row className='oh'>
                <Col>
                    <span className='float-md-right text-uppercase order-guide'>{item.isSpecial === 1 ? 'Special Request' : 'Order Guide'}</span>
                    <span className='float-md-right text-uppercase order-none' >{(item.status === 'electronically_received' ? 'Received' : item.status)}</span>
                </Col> 
            </Row>
            <Row className='pt-2 pb-4'>
                <Col xs={2} className='pt-4 text-center text-main'>
                    {item.quantity}
                </Col>
                <Col xs={2} className='text-center'>
                    <img src={imageUrl} alt={item.name} className='no-image' />
                </Col>
                <Col xs={6}>
                    <Row>
                        <Col className='pl-0 pr-5 text-capitalize text-truncate text-main'>
                            {item.item}
                        </Col>
                    </Row>
                    <Row className='text-gray'>
                        <Col className='pl-0'>
                            {item.brand}
                        </Col>
                        <Col className='pl-0'>
                            {selectedOrder ? selectedOrder.distributor : ''}
                        </Col>
                    </Row>
                    <Row className='text-main'>
                        <Col className='pl-0'>
                            {item.packSize}
                        </Col>
                        <Col className='pl-0'>
                           
                        </Col>
                    </Row>
                </Col>
                <Col xs={2} className='d-flex align-items-center text-main'>
                    <Row>
                        <Col>
                            ${(item.price * item.quantity).toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>

    )
}

export default OrderReceiveRow;