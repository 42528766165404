import React from 'react';
import { Container, CardHeader, Row, Col, Card} from 'reactstrap';
import { MdCloudDownload } from 'react-icons/md';
import { IoIosArrowDown } from 'react-icons/io';
import {colors} from '../../../../../config/constants';
import { isMobile } from 'react-device-detect';
import Header from '../../../../../SharedComponents/Header/Header';
import useOrderView from './useOrderView';

const OrderView = (props) => {
    const {orderViewMap, selectedOrder, itemsCount, showDetails, openDownload} = useOrderView.useApi(props);

    const SubHeaderOrderView = (
        <Row className={'header h-inherit align-items-center py-1 m-0'}>
            <Col className='text-uppercase text-label-bold-sm pl-3'>
                On Hand Total:  ${selectedOrder ? selectedOrder.onHandTotal : 0}
            </Col>
            <Col className='text-center text-uppercase text-label-bold-sm clickable' onClick={showDetails}>
                Show Details <IoIosArrowDown color={colors.blueDark} size={16} />
            </Col>
            <Col className='text-uppercase text-label-bold-sm text-right'>
                Order Total: ${selectedOrder ? selectedOrder.total : 0}
            </Col>
        </Row>
    )

    let title = 'Order ';
    if (selectedOrder) {
        title = title + `${(selectedOrder.id ? selectedOrder.id : ' ') + ' ' + (selectedOrder.createTimeDisplay ? selectedOrder.createTimeDisplay : '')}`;
        title = title + "\n" + `Invoice ${(selectedOrder && selectedOrder.customInvoiceId ? selectedOrder && selectedOrder.customInvoiceId || '': selectedOrder && selectedOrder.id || '')}`;
    }

    if (isMobile) {
        return (
             <Container fluid={true} className={isMobile ? 'px-1' : 'mt-3 px-4'} >
                <Header showMenu={false} showLogo={false} showBack={true} title={title} showCart={false} />
                <Col className='px-1 py-2'>
                    <Card className='p-0 text-center text-uppercase text-main' style={{fontSize:'12px'}}>
                        <Col xs={12} className='py-2' onClick={showDetails}>
                            Show Details <IoIosArrowDown color={colors.blueDark} size={16} />
                        </Col>
                        <Col xs={12} className='py-2'>
                            <Row>
                                <Col xs={6}>
                                    On Hand Total {selectedOrder ? selectedOrder.onHandTotal : 0}
                                </Col>
                                <Col xs={6}>
                                    Order Total {selectedOrder ? selectedOrder.total : 0}
                                </Col>
                            </Row>
                        </Col>
                    </Card>
                    <CardHeader className='ov-row-header text-center clickable py-2' onClick={openDownload}>
                        <MdCloudDownload color={colors.white} size={20} /> Download
                    </CardHeader>
                </Col>
                {orderViewMap}
            </Container>
        )
    }

    return (
        <Container fluid={true}  >
            <Header showMenu={false} showLogo={false} showBack={true} title={title} showCart={false} >
                {SubHeaderOrderView}
            </Header>
            <CardHeader className='ov-row-header my-2 text-center clickable' onClick={openDownload}>
                <MdCloudDownload color={colors.white} size={20} /> Download
            </CardHeader>
            {orderViewMap}
            <Card style={{ backgroundColor:'#eef3f6'}}>
                <Row>
                    <Col xs={6}>
                    </Col>
                    <Col xs={6}>
                        <Row className='py-2'>
                            <Col className='text-left' xs={6}>
                                SUMMARY
                            </Col>
                            <Col xs={6}>
                            </Col>
                        </Row>
                        <Row className='py-2'>
                            <Col xs={8}>
                                Line Items
                            </Col>
                            <Col xs={3} className='text-right'>
                                {selectedOrder ? selectedOrder.lineItems : 0}
                            </Col>
                            <Col xs={1}>
                            </Col>
                        </Row>
                        <Row className='py-2'>
                            <Col xs={8} className='oh-line-box'>
                                Items
                            </Col>
                            <Col xs={3} className='oh-line-box text-right'>
                                {itemsCount}
                            </Col>
                            <Col xs={1}>
                            </Col>
                        </Row>
                        <Row className='py-2'>
                            <Col xs={8}>
                                Total Items Price
                            </Col>
                            <Col xs={3} className='text-right'>
                                ${selectedOrder ? selectedOrder.totalItemsPrice : 0}
                            </Col>
                            <Col xs={1}>
                            </Col>
                        </Row>
                        <Row className='py-2'>
                            <Col xs={8} className='pl-4'>
                                Delivery Fee
                            </Col>
                            <Col xs={3} className='text-right'>
                                ${selectedOrder ? selectedOrder.deliveryFee : 0}
                            </Col>
                            <Col xs={1}>
                            </Col>
                        </Row>
                        <Row className='py-2'>
                            <Col xs={8} className='pl-4'>
                                Same Day Delivery Fee
                            </Col>
                            <Col xs={3} className='text-right'>
                                ${selectedOrder ? selectedOrder.sameDayDeliveryFee : 0}
                            </Col>
                            <Col xs={1}>
                            </Col>
                        </Row>
                        <Row className='py-2'>
                            <Col xs={8} className='pl-4'>
                                Special Taxes
                            </Col>
                            <Col xs={3} className='text-right'>
                                ${selectedOrder ? selectedOrder.salesTax : 0}
                            </Col>
                            <Col xs={1} >
                            </Col>
                        </Row>
                        <Row className='py-2'>
                            <Col xs={8}>
                                {selectedOrder ? `${selectedOrder.distributor + ' SubTotal'}`  : ''}
                            </Col>
                            <Col xs={3} className='text-right'>
                                ${selectedOrder ? selectedOrder.subtotal : 0}
                            </Col>
                            <Col xs={1}>
                            </Col>
                        </Row>
                        <Row className='py-2'>
                            <Col xs={8} className='pl-4'>
                                Promo
                            </Col>
                            <Col xs={3} className='text-right'>
                                ${selectedOrder ? selectedOrder.promoDiscount : 0}
                            </Col>
                            <Col xs={1}>
                            </Col>
                        </Row>
                        <Row className='py-2'>
                            <Col xs={8} className='oh-line-box pl-4'>
                                Applied Credit
                            </Col>
                            <Col xs={3} className='oh-line-box text-right'>
                                ${selectedOrder ? selectedOrder.usedCredit : 0}
                            </Col>
                            <Col xs={1}>
                            </Col>
                        </Row>
                        <Row className='py-2 text-main'>
                            <Col xs={8}>
                                {selectedOrder ? `${selectedOrder.distributor + ' Total'}`  : ''}
                            </Col>
                            <Col xs={3} className='text-right'>
                                ${selectedOrder ? selectedOrder.total : 0}
                            </Col>
                            <Col xs={1}>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Container>


    )
}

export default OrderView;
