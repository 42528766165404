
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import OrderGuideList from './OrderGuideList/OrderGuideList';
import OrderGuideDetail from './OrderGuideDetail/OrderGuideDetail';
import useOrderGuideList from './OrderGuideList/useOrderGuideList';


const OrderGuide = () => {
    const { orderGuideList } = useOrderGuideList.useApi();

    return (
        <Switch>
            <Route exact path='/order-guide'>
                <Container fluid={true} className='pb-3' >
                    <OrderGuideList  />
                </Container>
            </Route>
            <Route exact path='/order-guide/detail/:id'>
                <Container fluid={true} className='pb-3'>
                     <OrderGuideDetail orderGuideList={orderGuideList} />
                </Container>
            </Route>
            <Route>
                <Redirect to='/order-guide' />
            </Route>
        </Switch>
    )

}

export default OrderGuide;