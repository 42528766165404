let innerHeight = require('ios-inner-height');

export default function reducer(state = {
  spinner: 0,
  isSidebarOpen: false,
  size: {
    width: window.innerWidth,
    height: innerHeight()
  }
}, action) {
  switch (action.type) {
    case 'SET_SPINNER':
      return { ...state, spinner: action.payload ? state.spinner + 1 : state.spinner - 1};
    case 'TOGGLE_SIDEBAR':
      return { ...state, isSidebarOpen: !state.isSidebarOpen };
    case 'CLOSE_SIDEBAR':
      return { ...state, isSidebarOpen: false };
    case 'SET_SIZE':
      return { ...state, size: action.payload };
    default:
      return state;
  }
};
