export const saveCartItems = (cartItems) => {
  return {
    type: 'SET_CART_ITEMS',
    payload: cartItems
  }
};

export const saveCartDistributor = (cartItemsByDistributor) => {
  return {
    type: 'SET_CART_DISTRIBUTOR',
    payload: cartItemsByDistributor
  }
};


export const saveCartCount = (date) => {
  return {
    type: 'SET_CART_COUNT',
    payload: date
  }
};

export const saveLastModified = (date) => {
  return {
    type: 'SET_LAST_MODIFIED',
    payload: date
  }
};

export const saveUnsyncedItems = (uitems) => {
  return {
    type: 'SET_UNSYNCED_ITEMS',
    payload: uitems
  }
};

export const saveAnimation = (animation) => {
  return {
    type: 'SET_ANIMATION',
    payload: animation
  }
};
export const saveOrderTotal = (orderTotal) => {
  return {
    type: 'SET_ORDER_TOTAL',
    payload: orderTotal
  }
};

export const saveSavingOptions = (savingOptions) => {
  return {
    type: 'SET_SAVING_OPTIONS',
    payload: savingOptions
  }
};

export const clearCartData = () => {
  return {
    type: 'CLEAR_CART_DATA',
  };
};