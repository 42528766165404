import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import orderGuideApi from '../../../../../api/orderGuideApi';
import OrderGuideDetailRow from './OrderGuideDetailRow';

const useApi = (props) => {
    const params = useParams();
    const id = parseInt(params.id);
    const [orderGuideDetailList, setOrderGuideDetailList] = useState([]);
    
    const [hasMore, setHasMore] = useState(true);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const selectedDistributor = props.orderGuideList.filter(m => m.og_id === id)[0];

    const getOrderGuideDetail = () => {
        setIsLoading(true);
        const params = {
          page : pageCount,
          limit : 20
        };
        orderGuideApi.getOrderGuideDetail(id, params).then((res) => {
            setOrderGuideDetailList(orderGuideDetailList.concat(res.data));
            if (res._meta.currentPage < res._meta.pageCount ) {              
              setHasMore(true);
              setPageCount(pageCount + 1);
            } else {
              setHasMore(false);
            }
            setIsLoading(false);
        }).catch(error => {
        });
    };

    const downloadOG = () => {
        orderGuideApi.downloadOrderGuide(id).then((res) => {
          if(res.data) {
            const excelElement = document.createElement('a');
            excelElement.href = res.data;
            excelElement.click();
          }
        }).catch(error => {
        });
    }
    
    useEffect(() => {
      getOrderGuideDetail();
    }, []);
    
    const orderGuideDetailMap = orderGuideDetailList.map((c, i) => 
        <OrderGuideDetailRow   key={`og-detail-${i}`} data={c} />
    )
    
    
    return { orderGuideDetailList, orderGuideDetailMap, getOrderGuideDetail, downloadOG, selectedDistributor, hasMore, pageCount, isLoading};

}


export default {
    useApi
};