import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button } from 'reactstrap';
import notAvailable from '../../../assets/images/not-available.png';
import useModal from '../useModal';
import { MdClose } from 'react-icons/md';
import { colors } from '../../../config/constants';

const ItemUnavailableModal = (props) => {
  const { id } = props;
  const modalprops = useModal(id);

  const onClickOk = () => {    
    modalprops.toggle();
  };

  return (
    <Modal {...modalprops} backdrop='static' keyboard={false}>
      <ModalHeader className='text-center'>
        <Row className='m-0'>
          <Col className='col-11 d-flex justify-content-center px-1 clickable'>
            Out of Stock!!! 
          </Col>
          <Col className='w-fc d-flex justify-content-end px-1 clickable' onClick={() => {modalprops.toggle()}}>
            <MdClose className='clickable' size={20} color={colors.lightBlueText}/>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody className='text-center'>
        <Row className='mb-2'>
          <Col className='text-center'><img src={notAvailable} alt='unavailable item'/></Col>
        </Row>
        <Row>
          <Col className='text-center'> Oops...</Col>
        </Row>
        <Row>
          <Col className='text-center'> This item is not in Stock.</Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color='blue' onClick={onClickOk}>Ok!</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ItemUnavailableModal;