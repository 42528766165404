import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import numeral from 'numeral';

const PosDetailRow = (props) => {
  const item = props.children;

  return (
    <Card className='pos-item'>
      <Row>
        <Col xs={3}>
          {item.item_name}
        </Col>
        <Col xs={3}>
          {item.pos_category}
        </Col>
        <Col xs={1} className='text-right'>
          {item.quantity_sold}
        </Col>
        <Col xs={1} className='text-right'>
          {item.quantity_void}
        </Col>
        <Col xs={1} className='text-right'>
          {item.quantity_net}
        </Col>
        <Col xs={3} className='text-right'>
          {numeral(item.price).format('$0,0.00')}
        </Col>
      </Row>
    </Card>
  );
};

export default PosDetailRow;
