import React, { useState, useEffect } from 'react';
import { mobileClassName } from '../../../config/constants';
import { Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import numeral from 'numeral';

const SubHeaderPlaceOrderOnHand = () => {
  const orderTotal = useSelector(({cart}) => cart.orderTotal);
  const onHandTotal = useSelector(({items}) => items.inventory.onHandTotal);

  useEffect(() => {

  }, [orderTotal, onHandTotal]);

  return (
    <Row className={'header h-inherit m-0 align-items-center' + mobileClassName + (isMobile ? ' py-1' : ' py-2')}>
      <Col className='text-uppercase text-label-bold-sm'>
        {`On Hand Total: ${numeral(onHandTotal).format('$0,0.00')}`}
      </Col>
      <Col className='text-uppercase text-label-bold-sm text-right'>
        {`Order Total: ${numeral(orderTotal).format('$0,0.00')}`}
      </Col>
    </Row>
  );
};

export default SubHeaderPlaceOrderOnHand;