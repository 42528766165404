import axios from 'axios';

const getDistributors = () => {
  return axios.get('merchant-accounting/get-distributors');
};

const getSuppliers = () => {
  return axios.get('merchant-accounting-supplier');
};

const getCategories = () => {
  return axios.get('merchant-accounting-category');
};

const getGlCodes = () => {
  return axios.get('merchant-accounting-gl-code');
};

const getTemplate = (filters) => {
  return axios.get('merchant-accounting/download-template');
};

const getItems = (filterOptions, page = 1) => {
  const params = {
    filterOptions: JSON.stringify(filterOptions),
    page
  };
  return axios.get('merchant-accounting', {params});
};

const updateDistributorPayableTerms = (distributorId, payableTerms) => {
  return axios.patch(`merchant-accounting/${distributorId}/update-payable-terms`, {terms: payableTerms});
}

const addMerchantAccounting = (params) => {
  return axios.post('merchant-accounting', params);
};

const updateMerchantAccounting = (id, params) => {
  return axios.patch(`merchant-accounting/${id}`, params);
};

const addSupplier = (name) => {
  return axios.post('merchant-accounting-supplier', {name});
};

const addCategory = (name) => {
  return axios.post('merchant-accounting-category', {name});
};

const addGlCode = (code) => {
  return axios.post('merchant-accounting-gl-code', {code});
};

const downloadOrderQuickbooks = (startDate, endDate) => {
  const params = {
    startDate,
    endDate
  };
  return axios.get('orders/download-order-quickbooks', {params});
};

const uploadFile = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('merchant-accounting/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export default {
  getDistributors,
  getSuppliers,
  getCategories,
  getGlCodes,
  getTemplate,
  getItems,
  updateDistributorPayableTerms,
  addMerchantAccounting,
  updateMerchantAccounting,
  addSupplier,
  addCategory,
  addGlCode,
  downloadOrderQuickbooks,
  uploadFile,
};
