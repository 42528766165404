import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import GroupItemDistributor from './GroupItemDistributor';
import { isMobile, isIOS } from 'react-device-detect';
import useShoppingCartItem from '../../../../../SharedComponents/ShoppingCartItem/useShoppingCartItem';
import { useDispatch, useSelector } from 'react-redux';
import { saveAmountItemGroup } from '../../../../../reducers/groupEvent/groupEventAction';
import Select from 'react-select';
import * as $ from 'jquery';

const ItemDistributorModal = (props) => {

    const {
        modal,
        toggle,
        items,
        className
    } = props;



    const [listItemsChange, setListItemsChange] = useState([]);
    const { updateCartAmountGroup } = useShoppingCartItem.useCartUpdate();
    const [group, setGroup] = useState(0);

    const dispatch = useDispatch();
    
    const options = items.map((_i, _index) => {
        return {
            label: _i.name,
            value: _index
        }
    });

    const changeItemGroup = (itemGroup, cartAmountFirst, newCartAmount) => {

        const ifExist = listItemsChange.find(res => res.item.itemDistributorId == itemGroup.itemDistributorId);
        if (ifExist) {
            listItemsChange.forEach(res => {
                if (res.item.itemDistributorId == itemGroup.itemDistributorId)
                    res.newCartAmount = newCartAmount;
            });
        } else {
            listItemsChange.push({
                item: itemGroup,
                newCartAmount,
                cartAmount: cartAmountFirst
            });
        };
        dispatch(saveAmountItemGroup({
            itemDistributorId: itemGroup.itemDistributorId,
            cartAmount: newCartAmount
        }));
        setListItemsChange(listItemsChange);
    };

    const updateAll = async () => {
        const result = listItemsChange.reduce((previousPromise, nextItem) => {
            return previousPromise.then(() => {
                return updateCartAmountGroup(nextItem.newCartAmount, nextItem.item, null, nextItem.cartAmount);
            });
        }, Promise.resolve());

        result.then(e => {
            toggle(true);
        });
    }

    const changeOption = ({ value }) => {
        setGroup(value);
    };


    const ListGroupItemDistributor = items[group].groups.map((item) => {

        return (
            <GroupItemDistributor key={'groupItemDis_' + item.id} item={item} changeItemGroup={changeItemGroup} />
        )
    })

    const vh = useSelector(({ userInterface }) => userInterface.size.height);
    const h = `${vh - 120 - 70 - 36 - (isIOS ? 110 : 0)}px`;

    return (
        <div>
            <Modal size='lg' isOpen={modal} toggle={() => toggle(false)} className={isMobile ? className + ' mobile mx-0 mt-4' : className}>
                <ModalHeader toggle={toggle}>
                    Add Linked Items to Cart
                    <Select
                        onChange={changeOption}
                        className='select-group'
                        placeholder='Select'
                        defaultValue={options[0]}
                        options={options} />
                </ModalHeader>
                <ModalBody className={isMobile ? 'px-0' : null} style={isMobile ? { height: h } : null}>
                    {/* <GroupItemDistributor items={items} group={item} key={`g_${id}_item_${it.id}`} elemId={`g_${id}_${i}`} {...it} /> */}

                    {ListGroupItemDistributor}

                </ModalBody>
                <ModalFooter style={{ padding: "0 10px" }}>
                    <Button color="green" onClick={updateAll}>Update</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ItemDistributorModal;