import { useEffect, useState } from 'react';
import cartApi from '../../api/cartApi';
import itemApi from '../../api/itemApi';
import { useSelector, useDispatch } from 'react-redux';
import { saveCartItems, saveLastModified, saveUnsyncedItems, saveCartCount, saveCartDistributor, saveOrderTotal, saveSavingOptions } from "../../reducers/cart/cartActions";
import { localStorageConst } from '../../config/constants';
import GoTo from '../../SharedComponents/GoTo/useGoTo';
import {showModal} from '../../reducers/modals/modalsActions';
import CartSyncModal from '../Sync/Modals/CartSyncModal';

const useApi = () => {
  const dispatch = useDispatch();

  const setCartStructure = (nCartItems) => {
    let structuredCart = [];
    let totalQty = 0;
    let totalOrder = 0;
    nCartItems.forEach(cItem => {
      if (!cItem.specialItem && !cItem.item) {
        return;
      }
      const newItem = (cItem.specialItem !== null) ? {
        caseQuantity: parseFloat(cItem.quantity),
        price: 0,
        id: Math.random(),
        distributorId: parseInt(cItem.specialItem.distributorId),
        din: cItem.specialItem.din,
        packSize: cItem.specialItem.packSize,
        // unitMeasure: cItem.specialItem.uom,
        unit: cItem.specialItem.uom,
        description: cItem.specialItem.description,
        searchText: undefined,
        rebate: undefined,
        swapAcceptId: undefined,
        isSpecialItem: true,
        distributor: cItem.specialItem.distributorName,
        shoppingCartId: cItem.id,
        isMarketPrice: 0,
        active: 0,
        caseSavings: undefined,
        belongsToGroup: 0
      } : {
          caseQuantity: parseFloat(cItem.quantity),
          price: (cItem.item.isMarketPrice === 1 ? 0 : parseFloat(cItem.item.price)),
          itemDistributorId: cItem.itemDistributorId,
          brand: cItem.item.brand,
          brandImageUrl: cItem.item.brandImageUrl,
          imageUrl: cItem.item.imageUrl,
          name: cItem.item.name,
          packSize: cItem.item.packSize,
          status: cItem.item.status,
          type: cItem.item.type,
          unitPrice: cItem.item.unitPrice,
          distributorId: parseInt(cItem.item.distributorId),
          unitMeasure: cItem.item.unitMeasure,
          description: cItem.item.description,
          searchText: cItem.searchText,
          rebate: parseFloat(cItem.item.rebate),
          swapAcceptId: cItem.swapAcceptId,
          isSpecialItem: false,
          distributor: cItem.item.distributor,
          shoppingCartId: cItem.id,
          isPriceOutdated: cItem.item.isPriceOutdated,
          isMarketPrice: cItem.item.isMarketPrice,
          active: cItem.item.active,
          din: cItem.item.din,
          isPunchedOut: cItem.item.isPunchedOut,
          belongsToGroup: cItem.item.belongsToGroup,
          parLevel: cItem.parLevel,
          onHand: cItem.onHand,
          isPartner: cItem.item.isPartner,
          isPurchased: cItem.item.isPurchased,
          lastPurchasedDate: cItem.item.lastPurchasedDate,
          lastPurchasedQty: cItem.item.lastPurchasedQty,
          savings: (cItem.swapAcceptId !== null && cItem.savings !== null) ? parseFloat(cItem.savings) : null
        };
      structuredCart.push(newItem);
      totalQty = totalQty + parseInt(newItem.caseQuantity);
      totalOrder = totalOrder + (parseFloat(newItem.caseQuantity) * parseFloat( newItem.price ));
    });
    return { structuredCart, totalQty, totalOrder };
  };

  const getCartItems = async () => {
    await cartApi.get()
      .then(res => {
        const { structuredCart, totalQty, totalOrder } = setCartStructure(res.data.shoppingCart);
        dispatch(saveCartItems(structuredCart));
        dispatch(saveCartCount(totalQty));
        dispatch(saveOrderTotal(totalOrder));
        localStorage.setItem(localStorageConst.keys.cartItems, JSON.stringify(structuredCart));
        dispatch(saveLastModified(res.data.lastModified));
        localStorage.setItem(localStorageConst.keys.lastModifiedCart, res.data.lastModified);
        dispatch(saveUnsyncedItems(res.data.unsyncedItems));
      })
      .catch(error => console.log(error));
  };

  return { getCartItems };
};

const useCartItems = () => {
  const { getCartItems } = useApi();
  const dispatch = useDispatch();
  const cartItems = useSelector(({ cart }) => cart.cartItems);
  const cartCount = useSelector(({ cart }) => cart.cartCount);
  const lastModified = useSelector(({ cart }) => cart.lastModified);
  const unsyncedItems = useSelector(({ cart }) => cart.unsyncedItems);

  return {
    cartItems, cartCount, lastModified, unsyncedItems, getCartItems
  };
};

const useDistributorConvert = () => {
  const dispatch = useDispatch();
  const storeCartItems = useSelector(({ cart }) => cart.cartItems);
  const localCartItems = JSON.parse(localStorage.getItem(localStorageConst.keys.cartItems));
  const cartItems = (storeCartItems.length > 0) ? storeCartItems : localCartItems;

  const cartByDistributor = cartItems.reduce((dist, ci) => {
    dist[ci.distributorId] = dist[ci.distributorId] || [];
    dist[ci.distributorId].push(ci);
    return dist;
  }, {});

  dispatch(saveCartDistributor(cartByDistributor));

  return {
    cartItemsByDistributor: cartByDistributor
  };
};

const useCartSavings = () => {

  const dispatch = useDispatch();

  const getCartMatches = async () => {

    const res = await itemApi.getCartMatches();
    const savings = res.data;
    localStorage.setItem(localStorageConst.keys.savingOptions, JSON.stringify(savings));
    dispatch(saveSavingOptions(savings));
    return savings;
  };

  return { getCartMatches };
};


const useBatchUpdateCart = () => {
  const lastModified = useSelector(({ cart }) => cart.lastModified);
  const { getCartItems } = useApi();
  const { reviewOrder } = GoTo.useActions();
  const { getCartMatches } = useCartSavings();
  const dispatch = useDispatch();

  const updateBatch = (nItems) => {
    const processItems = [];
    const itemsForSwapSuggestion = [];
    const savingOptions = {};
    const saving_option = false;

    nItems.map((item, i) => {
      if (item.itemDistributorId !== null) {
        const obj = {
          description: item.description,
          itemDistributorId: item.itemDistributorId,
          quantity: item.original_quantity,
          index: i
        };
        processItems.push(obj);
      }
    });

    const params = {
      itemList: processItems,
      lastModified: lastModified
    };
    cartApi.update(params)
      .then(res => {
        getCartMatches();
        getCartItems();
        reviewOrder();
      })
      .catch(error => console.log(error));
  };

  const syncBatch = async (nItems, lastModifiedSync) => {
    const syncItems = [];
    if (nItems.length === 0){ return ; }

    nItems.map((item, i) => {
      if (item.itemDistributorId !== null) {
        const obj = {
          description: item.description,
          itemDistributorId: item.itemDistributorId,
          quantity: item.quantity,
          index: i
        };
        syncItems.push(obj);
      }
    });

    const params = {
      itemList: syncItems,
      lastModified: lastModifiedSync
    };
    
    await cartApi.update(params)
    .then(res => {      
      const sameModifiedItems = res.data.sameModifiedItems;
      const lastModified = res.data.lastModified;
      if (sameModifiedItems.length === 0) {
        getCartItems();
        return;
      }
      const newModifiedItems = sameModifiedItems.map( mi => {
        const cItem = nItems.filter(ci => ci.itemDistributorId === mi.itemDistributorId);
        if (!cItem) return mi;
        return {
          ...mi,
          description: cItem[0].description,
          quantity: mi.newQuantity,
        }
      });
      
      const syncCartModalProps = {
        header: 'Alert',
        message: 'It appears, someone was shopping in your account at the same time. Please review the following items sure your order is correct.',          
        sameModifiedItems: newModifiedItems,
        lastModified : lastModified
      };
      dispatch(showModal(CartSyncModal, syncCartModalProps));
            
    })
    .catch(error => console.log(error));   
    
  };

  return { updateBatch, syncBatch }

};

export default {
  useCartItems, useDistributorConvert, useBatchUpdateCart, useCartSavings
};