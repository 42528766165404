import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import numeral from 'numeral';
import { colors } from '../../../../../../config/constants';
import useShowItemsIn from './useShowItemsIn';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

const ShowItemsIn = (props) => {
  const { onConvert, units, unit, parLevel, bottomMobile, groupTotalProps } = props;
  const displayInventory = useSelector(({user}) => user.activeLocation.display_inventory);
  const canEditComparisonView = useSelector(({user}) => user.editComparisonView) ? true : false;
  const {totalOnHandAmt, totalGroupSpent} = groupTotalProps;  
  const grayFirstStyle = {
    singleValue: (styles, { data }) => {
      if (data.value.id !== null) return styles;
      return {
        ...styles,
        color: colors.lightBlueText
      };
    },
    option: (styles, { data }) => {
      if (data.value.id !== null) return styles;
      return {
        ...styles,
        color: colors.lightBlueText
      };
    },
  };
  const { openParLevel } = useShowItemsIn.useParLevel(props);
  const dropDownParLevel = {
    label: parLevel ? parLevel.toString() : '0',
    value: parLevel ? parLevel : 0
  };
  return (
    <Fragment>
      <ShowIf show={!isMobile && !bottomMobile}>
        <Row className='align-items-center py-2 bg-white'>
          <Col className='w-fc text-label-sm text-uppercase'>
            Show Items in
          </Col>
          <Col className='w-200px'>
            <Select className='fb-select bg-white' classNamePrefix='select'
              styles={grayFirstStyle}
              options={units} onChange={onConvert}
              value={unit} isSearchable={false}
              isDisabled={!canEditComparisonView}
            />
          </Col>
          <Col></Col>
          <Col className='w-fc text-label-sm text-uppercase'>
            Par Level
          </Col>
          <Col className='w-100px border-right clickable'>
            <Select className='fb-select' classNamePrefix='select'
              value={dropDownParLevel}
              components={{Menu: () => null}}
              isSearchable={false} onMenuOpen={openParLevel}
            />
          </Col>
          <Col className='text-label-sm text-uppercase text-right'>
            <ShowIf show={displayInventory}>
              Amount on Hand: <span className='text-label-bold-sm'>{numeral(totalOnHandAmt).format('$0,0.00')}</span>
            </ShowIf>
          </Col>
        </Row>
      </ShowIf>
      <ShowIf show={isMobile && !bottomMobile}>
        <Row className='m-0 py-2 align-items-start'>
          <Col className='px-1'>
            <Row className='m-0'>
              <Col className='text-label-sm text-uppercase'>Par Level</Col>
            </Row>
            <Row className='m-0'>
              <Col className='w-100px pr-0'>
                <Select className='fb-select' classNamePrefix='select' value={dropDownParLevel}
                  components={{Menu: () => null}} isSearchable={false} onMenuOpen={openParLevel}
                />
              </Col>
            </Row>
          </Col>
          <ShowIf show={displayInventory}>
            <Col className='px-1'>
              <Row className='m-0'>
                <Col className='text-label-sm text-uppercase text-right'>Amount On Hand</Col>
              </Row>
              <Row className='m-0'>
                <Col className='text-right text-label-bold'>{numeral(totalOnHandAmt).format('$0,0.00')}</Col>
              </Row>
            </Col>
          </ShowIf>
        </Row>
      </ShowIf>
      <ShowIf show={isMobile && bottomMobile}>
        <Row className='m-0 align-items-center border-bottom pb-3'>
          <Col className='px-1'>
            <Row className='m-0'>
              <Col className='w-100px pr-0'>
                <Select className='fb-select' classNamePrefix='select' menuPlacement='auto'
                  styles={grayFirstStyle} components={{Menu: () => null}}
                  options={units} onMenuOpen={onConvert}
                  value={unit} isSearchable={false}
                />
              </Col>
            </Row>
          </Col>
          <ShowIf show={displayInventory}>
            <Col className='px-1'>
              <Row className='m-0'>
                <Col className='text-label-sm text-uppercase text-right'>Total Group Spend: {numeral(totalGroupSpent).format('$0,0.00')}</Col>
              </Row>
            </Col>
          </ShowIf>
        </Row>
      </ShowIf>
    </Fragment>
  );
};

export default ShowItemsIn;