import React from 'react';
import useConnection from '../OfflineMode/useConnection';
import SpinnerOnline from './SpinnerOnline';
import SpinnerOffline from './SpinnerOffline';
import './Spinner.scss';

const Spinner = () => {
  let {status} = useConnection.useStatus();

  return (
    status ? <SpinnerOnline /> : <SpinnerOffline />
  );
};

export default Spinner;