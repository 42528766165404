import React from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { isMobile } from 'react-device-detect';
import usePosDetail from './usePosDetail';
import PosDetailRow from './PosDetailRow';

const PosDetail = () => {

  const { items, page, isLoading, loadMore, getPosDetail } = usePosDetail.useApi();
  const itemsMap = items.map((item, index) => <PosDetailRow key={`pos-detail-${index}`}>{item}</PosDetailRow>);

  const header = (
    <Row>
      <Col xs={3} className='py-2 px-4 text-gray'>
        {isMobile ? 'Item Name' : 'Menu Item Name'}
      </Col>
      <Col xs={3} className='py-2 px-3 text-gray'>
        Menu Category
      </Col>
      <Col xs={1} className='py-2 px-3 text-gray text-right'>
        QTY Sold
      </Col>
      <Col xs={1} className='py-2 px-3 text-gray text-right'>
        QTY Void
      </Col>
      <Col xs={1} className='py-2 px-3 text-gray text-right'>
        Net QTY
      </Col>
      <Col xs={3} className='py-2 px-3 text-gray text-right'>
        {isMobile ? 'Total Amount' : 'Total Amount Sold'}
      </Col>
    </Row>
  );

  if (!isLoading && items && items.length == 0) {
    return (
      <Container fluid={true} className='pos-list my-5 rounded'>
        {header}
        <Card className='pos-item'>
          <Row>
            <Col xs={12}>
              No results found.
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }

  return (
    <Container fluid={true} className='pos-list my-5 rounded'>
      {header}
      <InfiniteScroll
        initialLoad={false}
        pageStart={page}
        loadMore={getPosDetail}
        hasMore={loadMore && !isLoading}
        useWindow={true}
      >
        {itemsMap}
      </InfiniteScroll>
    </Container>
  );
};

export default PosDetail;
