import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import ordersApi from '../../../../../api/ordersApi';
import OrderHistoryListRow from './OrderHistoryListRow';
import { showModal } from "../../../../../reducers/modals/modalsActions";
import OptionsModal from "../../../../../SharedComponents/Modals/OptionsModal/OptionsModal";
import { useSelector, useDispatch } from "react-redux";
import { colors } from '../../../../../config/constants'
import { isMobile } from "react-device-detect";
import { FaTruck, FaCheck, FaTimes, FaEye } from "react-icons/fa";
import { AiOutlineReload } from "react-icons/ai";
import { IoMdCloudUpload } from "react-icons/io";
import { FiMoreHorizontal } from 'react-icons/fi';
import { MdEdit } from 'react-icons/md';
import lightIcon from '../../../../../assets/images/lightning-bolt.svg';
import { useHistory } from "react-router-dom";
import useShoppingCart from '../../../../../SharedComponents/ShoppingCart/useShoppingCart';
import OrderUploadInvoiceModal from '../OrderUpload/OrderUploadInvoiceModal';
import DownloadModal from "./DownloadModal";
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';


const useApi = () => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const getOrders = (page) => {
    setIsLoading(true);
    ordersApi.getByPage(page)
      .then(res => {
        setOrders(orders.concat(res.data));
        setPage(page);
        setLoadMore(res._meta.pageCount > res._meta.currentPage);
        setIsLoading(false);
      })
      .catch(error => console.log(error));
  };

  const ordersMap = orders.map((order, index) => <OrderHistoryListRow key={index}>{order}</OrderHistoryListRow>);

  useEffect(() => {
    getOrders(page);
  }, []);

  return {
    orders, ordersMap, page, isLoading, loadMore, getOrders
  };
};

const useDownload = () => {
  const dispatch = useDispatch();
  const firstActiveLoc = useSelector(({ user }) => user.activeLocation);
  const activeLocation = JSON.parse((localStorage.getItem('activeLocation') !== undefined) ? localStorage.getItem('activeLocation') : firstActiveLoc);
  const [modalOptions, setModalOptions] = useState([]);
  const [downloadLink, setDownloadLink] = useState('');

  const generateObject = () => {
    if (downloadLink !== '') {
      let link = document.createElement('a');
      link.href = downloadLink;
      link.download = '';
      link.dispatchEvent(new MouseEvent('click'));
    }
  };

  const downloadExcel = () => {
    ordersApi.downloadExcel()
      .then(res => {
        setDownloadLink(res.data.orderHistoryUrl);
      })
      .catch(error => {
        console.log(error)
      });
  };

  const downloadQuickbooks = (startDate, endDate) => {

    ordersApi.downloadQuickbooks(startDate, endDate)
      .then(res => {
        setDownloadLink(res.data);
      })
      .catch(error => {
        console.log(error)
      });
  };

  const openDownloadModal = () => {
    const optionProps = {
      title: 'Select',
      options: modalOptions,
      menu: isMobile ? true : false,
      fullMenu: isMobile ? true : false
    }
    dispatch(showModal(DownloadModal, optionProps));
  };

  const buildModalOptions = () => {
    const mainOptions = [{ id: 1, label: 'Excel', selected: true, onClick: downloadExcel, more: false }];
    if (activeLocation.display_accounting === 1) {
      mainOptions.push({ id: 2, label: 'Quickbooks', selected: false, onClick: downloadQuickbooks, more: true });
    }
    setModalOptions(mainOptions);
  };

  useEffect(() => {
    generateObject();
  }, [downloadLink]);

  useEffect(() => {
    buildModalOptions();
  }, []);

  return {
    modalOptions, downloadExcel, openDownloadModal
  };

};

const useRow = (order) => {
  const isDisplayShoppingCart = useSelector(({ user }) => user.activeLocation.display_shopping_cart);
  const { id } = order;
  const [hover, setHover] = useState(false);
  const [statusOptions, setStatusOptions] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { updateBatch } = useShoppingCart.useBatchUpdateCart();


  const receive = () => {
    history.push(`/order-history/receive/${id}`);
  };

  const view = () => {
    history.push(`/order-history/view/${id}`);
  };

  const reorder = () => {
    let nItems = [];
    ordersApi.getOrdersById(id).then((res) => {
      nItems = res.data.items;
      updateBatch(nItems);
    }).catch(error => {
      console.log(error);
    });

  };

  const upload = () => {
    if (!isMobile) {
      const modalProps = {
        id: id,
        menu: isMobile ? true : false,
        fullMenu: true
      };
      dispatch(showModal(OrderUploadInvoiceModal, modalProps));
    } else {
      history.push(`/order-history/upload-invoice/${id}`);
    }

  };

  const openOrderOption = () => {
    const recieveEditOption = (order.status === 'received') ?
      <Fragment><Button size='circle' color='blue'><MdEdit color='white' /></Button> Edit</Fragment> :
      <Fragment><Button size='circle' color='blue'><FaTruck color='white' /></Button> Receive</Fragment>;
    const viewOption = <Fragment><Button size='circle' color='green'><FaEye color='white' /></Button> View</Fragment>;
    const reorderOption = <Fragment><Button size='circle' color='yellow'><AiOutlineReload color='white' /></Button> Reorder</Fragment>;
    const uploadOption = <Fragment><Button size='circle' color='orange'><IoMdCloudUpload color='white' /></Button> Upload or Take a Photo</Fragment>;

    const optionProps = {
      title: 'Select',
      options: [
        { label: recieveEditOption, onClick: receive },
        { label: viewOption, onClick: view },
        { label: reorderOption, onClick: reorder },
        { label: uploadOption, onClick: upload }
      ]
    }
    dispatch(showModal(OptionsModal, optionProps));
  };

  const buildStatusOptions = (status) => {
    let statusOnly = '';
    const recieveEditIcon = (status === 'received' || status === 'electronically_received') ? <Fragment><MdEdit color='white' /><br />Edit</Fragment> : <Fragment><FaTruck color='white' /><br />Receive</Fragment>;
    let statusButtons = <Fragment>
      <Row className='col-12 m-0 px-0'>
        <Col className='p-1 text-center'><Button className='size-14 button' color='blue' onClick={receive} block>{recieveEditIcon}</Button></Col>
        <Col className='p-1 text-center'><Button className='size-14 button' color='green' onClick={view} block><FaEye color='white' /><br />View</Button></Col>
        <ShowIf show={isDisplayShoppingCart !== 0}>
          <Col className='p-1 text-center'><Button className='size-14 button' color='yellow' onClick={reorder} block><AiOutlineReload color='white' /><br />Reorder</Button></Col>
        </ShowIf>
        <Col className='p-1 text-center'><Button className='size-14 button' color='orange' onClick={upload} block><IoMdCloudUpload color='white' /><br />Upload</Button></Col>
      </Row>
    </Fragment>;
    switch (status) {
      case 'received':
        statusOnly = <Fragment><FaCheck color={colors.green} size={20} /><span>&nbsp;</span>{isMobile ? <FiMoreHorizontal color={colors.lightBlueText} size={20} onClick={openOrderOption} /> : 'Received'}</Fragment>;
        break;
      case 'cancelled':
        statusOnly = <Fragment><FaTimes color={colors.orange} size={20} /><span>&nbsp;</span>{isMobile ? <FiMoreHorizontal color={colors.lightBlueText} size={20} onClick={openOrderOption} /> : 'Order Cancelled'}</Fragment>;
        statusButtons = statusOnly;
        break;
      case 'electronically_received':
        statusOnly = <Fragment><img src={lightIcon} alt='lightIcon' size={20} /><span>&nbsp;</span>{isMobile ? <FiMoreHorizontal color={colors.lightBlueText} size={20} onClick={openOrderOption} /> : 'Electronic Invoice'}</Fragment>;
        break;
      default: //Neither 'cancelled' nor 'received'
        statusOnly = <Fragment><FaTruck color={colors.blue} size={20} /><span>&nbsp;</span>{isMobile ? <FiMoreHorizontal color={colors.lightBlueText} size={20} onClick={openOrderOption} /> : 'On the Way'}</Fragment>;
        break;
    }

    const statusOptions = isMobile ? statusOnly : (hover ? statusButtons : statusOnly);
    setStatusOptions(statusOptions);
  };

  useEffect(() => {
    buildStatusOptions(order.status);
  }, [hover]);

  return {
    hover, setHover, openOrderOption, receive, view, reorder, upload, buildStatusOptions, statusOptions, order
  }

};


export default {
  useApi, useDownload, useRow
};
