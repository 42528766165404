import { useDispatch, useSelector } from "react-redux"
import { useState, useEffect } from "react";
import { showModal } from '../../../../../../../reducers/modals/modalsActions';
import OptionsModal from '../../../../../../../SharedComponents/Modals/OptionsModal/OptionsModal';
import { isMobile } from "react-device-detect";
import EditPackSizeModal from "../../../../../../../SharedComponents/Modals/EditPackSizeModal/EditPackSizeModal";
import comparisonViewsApi from "../../../../../../../api/comparisonViewsApi";
import { editOneInventoryGroupItem, removeInventoryGroupItem } from "../../../../../../../reducers/items/itemsActions";
import moment from "moment";
import useInput from "../../../../../../../SharedComponents/SharedEffects/useInput";
import genericItemApi from "../../../../../../../api/genericItemApi";
import AddCustomItemModal from "../../../../../../../SharedComponents/Modals/AddCustomItemModal/AddCustomItemModal";
import itemApi from "../../../../../../../api/itemApi";
import numeral from 'numeral';
import useConnection from '../../../../../../../SharedComponents/OfflineMode/useConnection';
import AlertModal from "../../../../../../../SharedComponents/Modals/AlertModal/AlertModal";
import QuestionModal from "../../../../../../../SharedComponents/Modals/QuestionModal/QuestionModal";

const useHistoricOnHand = ({comparisonViewId, group, itemDistributorId, unitMeasure}) => {
  const [showHistoric, setShowHistoric] = useState(false);
  const [historicData, setHistoricData] = useState([]);
  const [noData, setNoData] = useState(false);

  const getData = () => {
    comparisonViewsApi.getHistoricInventory(comparisonViewId, group.id, itemDistributorId).then(({data}) => {
      if (data.length === 0) {
        setNoData(true);
      }
      setHistoricData(data.sort((a, b) => moment(b.startDate) - moment(a.startDate)).map(d => {
        return {
          ...d,
          onHand: d.onHand ? d.onHand : `0 ${unitMeasure}`
        }
      }));
    }).catch(err => console.error(err));
  };

  const toggleShowHistoric = () => {
    setShowHistoric(!showHistoric);
  };

  useEffect(() => {
    if (showHistoric && !noData && historicData.length === 0) {
      getData();
    }
  }, [showHistoric])

  return {
    showHistoric, toggleShowHistoric, historicData, noData
  }
};

const usePriceAnalytics = ({itemDistributorId, unitMeasure}) => {
  const [showPriceAnalytics, setShowPriceAnalytics] = useState(false);
  const [priceData, setPriceData] = useState([]);
  const [noPriceData, setNoPriceData] = useState(false);

  const getData = () => {
    itemApi.getAnalytics(itemDistributorId).then(({data}) => {
      const analytics = data.analytics;
      const baseData = analytics.sort((a, b) => moment(a.startDate) - moment(b.startDate));
      let result = [];
      let none = false;
      if (baseData.length !== 0) {
        const months = [];
        const dateEnd = moment();
        const dateStart = moment().subtract(11, 'month');
        while (dateEnd.diff(dateStart, 'months') >= 0) {
          months.push(dateStart.format('MM/YY'))
          dateStart.add(1, 'month')
        }
        let existsMonth = false;
        let previousPrice = 0;
        for (let j = 0; j < months.length; j++) {
          existsMonth = false;
          for (let i = 0; i < baseData.length; i++) {
            const base = moment(baseData[i].startDate).format('MM/YY'); // previousPrice = baseData[i].price;
            if(months[j] === base){
              existsMonth = true;
              previousPrice = baseData[i].price;
              const exists = result.filter(r => r.date === months[j])[0];
              if(!exists){
                const obj = { price: (previousPrice === 0 ? '' : numeral(previousPrice).format('$0,0.00') + ` ${unitMeasure}`),  date: months[j] };
                result.push(obj);
                break;
              }
            }
          }
          if(!existsMonth){
            const obj = { price: (previousPrice === 0 ? '' : numeral(previousPrice).format('$0,0.00') + ` ${unitMeasure}`),  date: months[j] };
            result.push(obj);
          }
        }

        for (let k = 0; k < result.length; k++) {
          if(result[k].price !== ''){
            none = true;
          }
        }
      }
      if(!none){ result = [];}
      setPriceData(result);
      if(result.length === 0){
        setNoPriceData(true);
      }

    }).catch(err => console.error(err));
  };

  const togglePriceAnalytics = () => {
    setShowPriceAnalytics(!showPriceAnalytics);
  };

  useEffect(() => {
    if (showPriceAnalytics && !noPriceData && priceData.length === 0) {
      getData();
    }
  }, [showPriceAnalytics])

  return {
    showPriceAnalytics, togglePriceAnalytics, priceData, noPriceData
  }
};

const useOptions = (props) => {
  const { comparisonViewId, group, ...item } = props;
  const displayInventory = useSelector(({user}) => user.activeLocation.display_inventory);
  const dispatch = useDispatch();
  const showOptionsState = useInput.useObject(false);
  const clickOptionsState = useInput.useObject(false);
  const { toggleShowHistoric, historicData, showHistoric, noData } = useHistoricOnHand(props);
  const { showPriceAnalytics, togglePriceAnalytics, priceData, noPriceData } = usePriceAnalytics(props);

  let {status} = useConnection.useStatus();

  const clickHeart = () => {
    genericItemApi.togglePreferredBy(item.genericItemId).then(({data}) => {
      dispatch(editOneInventoryGroupItem(group.id, item.genericItemId, {isPreferred: data.is_preferred}, 'genericItemId'));
    }).catch(err => console.error(err));
  };

  const deleteItemApi = () => {
    if (item.isCustomItem) {
      comparisonViewsApi.deleteGroupCustomItem(comparisonViewId, group.id, item.genericCustomItemId).then(() => {
        dispatch(removeInventoryGroupItem(group.id, item.genericCustomItemId, 'genericCustomItemId'));
      }).catch(err => console.error(err));
    } else if (item.isSubRecipe) {
      comparisonViewsApi.deleteGroupSubRecipeItem(comparisonViewId, group.id, item.subRecipeId).then(() => {
        dispatch(removeInventoryGroupItem(group.id, item.subRecipeId, 'subRecipeId'));
      }).catch(err => console.error(err));
    } else {
      comparisonViewsApi.deleteGroupItem(comparisonViewId, group.id, item.itemDistributorId).then(() => {
        dispatch(removeInventoryGroupItem(group.id, item.itemDistributorId));
      }).catch(err => console.error(err));
    }
  };

  const optionsNoInventory = [
    {label: 'Price Analytics', disabled: item.isSubRecipe || item.isCustomItem, onClick: () => {
      if(!status){
        dispatch(showModal(AlertModal, {message:'You need internet connectivity to use this feature', footerClass: 'justify-content-center'}));
        return;
      }
      togglePriceAnalytics();
    }},
    {label: 'Edit Packsize', disabled: item.isCatchWeight, onClick: () => {
      if(!status){
        dispatch(showModal(AlertModal, {message:'You need internet connectivity to use this feature', footerClass: 'justify-content-center'}));
        return;
      }
      const modalProps = {
        menu: isMobile,
        defaultValues: {
          outerPack: item.unitQuantity,
          innerPack: item.unitSize,
          uom: item.packSizeUom
        },
        onSave: (data) => {
          if (item.isCustomItem) {
            comparisonViewsApi.patchGroupCustomItem(comparisonViewId, group.id, item.genericCustomItemId, {packSizeVal: data.packSize}).then(({data}) => {
              const newValues = {
                customPackSize: data.customPackSize,
                unitPrice: data.unitPrice,
                unitQuantity: data.unitQuantity,
                unitSize: data.unitSize,
                packSizeUom: data.packSizeUom,
                isCustomPackSize: data.customPackSize ? !(data.customPackSize === item.packSize) : false
              };
              dispatch(editOneInventoryGroupItem(group.id, item.genericCustomItemId, data, 'genericCustomItemId'));
            }).catch(err => console.error(err));
          } else if (item.isSubRecipe) {
            comparisonViewsApi.patchGroupSubrecipeItem(comparisonViewId, group.id, item.subRecipeId, {packSize: data.packSize}).then(({data}) => {
              const newValues = {
                customPackSize: data.customPackSize,
                unitPrice: data.unitPrice,
                unitQuantity: data.unitQuantity,
                unitSize: data.unitSize,
                packSizeUom: data.packSizeUom,
                isCustomPackSize: data.customPackSize ? !(data.customPackSize === item.packSize) : false
              };
              dispatch(editOneInventoryGroupItem(group.id, item.subRecipeId, newValues, 'subRecipeId'));
            }).catch(err => console.error(err));
          } else {
            comparisonViewsApi.patchGroupItem(comparisonViewId, group.id, item.itemDistributorId, {packSize: data.packSize}).then(({data}) => {
              const newValues = {
                customPackSize: data.customPackSize,
                unitPrice: data.unitPrice,
                unitQuantity: data.unitQuantity,
                unitSize: data.unitSize,
                packSizeUom: data.packSizeUom,
                isCustomPackSize: data.customPackSize ? !(data.customPackSize === item.packSize) : false
              }
              dispatch(editOneInventoryGroupItem(group.id, item.itemDistributorId, newValues));
            }).catch(err => console.error(err));
          }
        }
      };
      dispatch(showModal(EditPackSizeModal, modalProps));
    }},
    {label: 'Default Packsize', disabled: !item.isCustomPackSize, onClick: () => {
      if(!status){
        dispatch(showModal(AlertModal, {message:'You need internet connectivity to use this feature', footerClass: 'justify-content-center'}));
        return;
      }
      if (item.isCustomItem) {
        comparisonViewsApi.patchGroupCustomItem(comparisonViewId, group.id, item.genericCustomItemId, {packSize: ''}).then(({data}) => {
          const newValues = {
            customPackSize: data.packSize,
            unitPrice: data.unitPrice,
            unitQuantity: data.unitQuantity,
            unitSize: data.unitSize,
            isCustomPackSize: false
          };
          dispatch(editOneInventoryGroupItem(group.id, item.genericCustomItemId, newValues, 'genericCustomItemId'));
        }).catch(err => console.error(err));
      } else if (item.isSubRecipe) {
        comparisonViewsApi.patchGroupSubrecipeItem(comparisonViewId, group.id, item.subRecipeId, {packSize: ''}).then(({data}) => {
          const newValues = {
            customPackSize: data.packSize,
            unitPrice: data.unitPrice,
            unitQuantity: data.unitQuantity,
            unitSize: data.unitSize,
            isCustomPackSize: false
          };
          dispatch(editOneInventoryGroupItem(group.id, item.subRecipeId, newValues, 'subRecipeId'));
        }).catch(err => console.error(err));
      } else {
        comparisonViewsApi.patchGroupItem(comparisonViewId, group.id, item.itemDistributorId, {packSize: ''}).then(({data}) => {
          const newValues = {
            customPackSize: data.packSize,
            unitPrice: data.unitPrice,
            unitQuantity: data.unitQuantity,
            unitSize: data.unitSize,
            isCustomPackSize: false
          };
          dispatch(editOneInventoryGroupItem(group.id, item.itemDistributorId, newValues));
        }).catch(err => console.error(err));
      }
    }},
    {label: 'Delete', className:'text-orange', onClick: () => {
      if(!status){
        dispatch(showModal(AlertModal, {message:'You need internet connectivity to use this feature', footerClass: 'justify-content-center'}));
        return;
      }

      deleteItemApi();
    }}
  ];

  const historicOnHand = {
    label: 'Historic On Hand', disabled: item.isSubRecipe || item.isCustomItem, onClick: () => {
      toggleShowHistoric();
    }
  };

  const editCustomItem = {
    label: 'Edit Item',
    onClick: () => {
      const customModalProps = {
        title: 'Edit Custom Item',
        onSave: (itemEdit) => {
          const { packSize, unitMeasure, ...rest } = itemEdit
          const params = {
            ...rest,
            packSizeVal: packSize.outerPack+'/'+packSize.innerPack+' '+packSize.uom.shorthand,
            unitMeasure: unitMeasure.name
          }
          console.log(params);
          comparisonViewsApi.patchGroupCustomItem(comparisonViewId, group.id, item.genericCustomItemId, params).then(({data}) => {
            console.log(data);
            dispatch(editOneInventoryGroupItem(group.id, item.genericCustomItemId, data, 'genericCustomItemId'));
          });
        },
        hideSearch: true,
        init: item
      };
      dispatch(showModal(AddCustomItemModal, customModalProps));
    }
  };

  let options = !displayInventory ? optionsNoInventory : [
    ...optionsNoInventory.slice(0, 1),
    historicOnHand,
    ...optionsNoInventory.slice(1)
  ];

  if (item.isCustomItem) {
    options = [
      editCustomItem,
      ...options
    ];
  };

  const clickOptions = () => {
    if (isMobile) {
      const modalProps = {
        title: '',
        options: options
      };
      dispatch(showModal(OptionsModal, modalProps));
    } else {
      showOptionsState.set(true);
      clickOptionsState.set(true);
    }
  };

  const toggle = () => {
    showOptionsState.set(!showOptionsState.value);
  };

  useEffect(() => {
    if (!showOptionsState.value) {
      clickOptionsState.set(false);
    }
  }, [showOptionsState.value]);

  return {
    clickOptions, options: options, toggle,
    showOptionsState, clickOptionsState,
    historicData, showHistoric, toggleShowHistoric,
    clickHeart, historicNoData: noData,
    showPriceAnalytics, togglePriceAnalytics, priceData, noPriceData
  }
};

export default {
  useOptions
};
