import React, { useState, useEffect } from 'react';
import useModal from '../../../../../SharedComponents/Modals/useModal';
import { Modal, ModalHeader, ModalBody, ModalFooter, Card, Row, Col, Label, Button, CustomInput } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';

const DownloadModal = (props) => {

  const { id, title, options, menu, fullMenu } = props;
  const modalProps = useModal(id, menu, fullMenu);
  const [mainOptions, setMainOptions] = useState(options);
  const [optionSelected, setOptionSelected] = useState(mainOptions.filter(opt => opt.selected === true)[0]);
  const [optionsMap, setOptionsMap] = useState('');

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const selectOption = (option) => {
    setOptionSelected(option);
    let mOptions = [];
    mainOptions.forEach(opt => { 
      opt.selected = (opt.id === option.id) ? true : false; 
      mOptions.push(opt); 
    });  
    setMainOptions(mOptions);
  };

  const buildMap = () => {
    const map = mainOptions.map((option, index) => 
        <Row key={`option_${index}`} className='mb-2 mx-2'>
          <Col>
            <CustomInput key={index} type='checkbox' id={option.id} label={option.label} checked={option.selected} onChange={() => selectOption(option)}/>
          </Col>
        </Row>      
    );
    setOptionsMap(map);
  };

  useEffect(() => {
    buildMap();    
  }, [mainOptions]);

  return (
    <Modal {...modalProps}>
      <ModalHeader className='bg-white' toggle={modalProps.toggle}>{title}</ModalHeader>
      <ModalBody>
        {optionsMap}
        <ShowIf show={optionSelected.more}>
          <Row className='mb-1 mx-1'>
            <Col xs={isMobile ? 4 : 6}>
              <Label for='startDateInput'>Start Date:</Label>
            </Col>
            <Col xs={isMobile ? 4 : 6}>
              <DatePicker id='startDateInput' selected={startDate} onChange={date => setStartDate(date)} />
            </Col>
          </Row>
          <Row className='mb-1 mx-1'>
            <Col xs={isMobile ? 4 : 6}>
              <Label for='endDateInput'>End Date:</Label>
            </Col>
            <Col xs={isMobile ? 4 : 6}>
              <DatePicker id='endDateInput' selected={endDate} onChange={date => setEndDate(date)} />
            </Col>
          </Row>
        </ShowIf>
        
      </ModalBody>
      <ModalFooter>
        <Col size={12} className='mx-0'>
          <Button block={true} className='px-4' color='yellow' onClick={()=>optionSelected.onClick(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))}>Download</Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};


export default DownloadModal;
