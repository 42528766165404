import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { unitsOfMeasure } from '../../../../config/constants';
import distributorsApi from "../../../../api/distributorsApi";
import cartApi from "../../../../api/cartApi";
import { useSelector, useDispatch } from 'react-redux';
import { saveDistributorList } from "../../../../reducers/distributors/distributorsActions";
import { saveLastModified, saveCartCount, saveOrderTotal } from "../../../../reducers/cart/cartActions";
import { number } from 'mathjs';
import validate from "validate.js";
import useInput from "../../../../SharedComponents/SharedEffects/useInput";
import ConfirmationModal from '../../../../SharedComponents/Modals/ConfirmationModal/ConfirmationModal';
import { showModal } from '../../../../reducers/modals/modalsActions';
import useShoppingCartItem from '../../../../SharedComponents/ShoppingCartItem/useShoppingCartItem'
import { localStorageConst } from '../../../../config/constants';

const useDefaultInfo = (distributorsList, lastModified, item, setDistributor, setUnit) => {
  const dispatch = useDispatch();
  const [allDistributors, setAllDistributors] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [distributorSelected, setDistributorSelected] = useState([]);
  const [units, setUnits] = useState([]);
  const [unitSelected, setUnitSelected] = useState([]);

  const setDropdownData = (data) => {
    const nDistributors = data.map(d => {
      return {
        label: d.nickname,
        value: d.id
      }
    });
    setAllDistributors(nDistributors);
    setDistributors(nDistributors);
    
    const nUnits = unitsOfMeasure.map(u => {
      return {
        label: u.name,
        value: u.id
      }
    });
    setUnits(nUnits);   
  };

  useEffect( () => {
    if(lastModified === ''){
      cartApi.get()
      .then((res) => {
        dispatch(saveLastModified(res.data.lastModified));
      }).catch((err) => {
        console.error('CartApi get error:', err);
      });
    }

    distributorsApi.get()
    .then((res) => {
      dispatch(saveDistributorList(res.data));        
      setDropdownData(res.data);
    }).catch((err) => {
      console.error('DistritburorsApi List error:', err);
    });

  }, []);

  useEffect(() => {    
    if(item){
      const uniSel = item.unitMeasure;
      const nUnit = units.filter(u => (u.value.toLowerCase().indexOf(uniSel.toLowerCase()) !== -1));
      if(nUnit.length===1){
        setUnitSelected(nUnit[0]);
        setUnit(nUnit[0]);
      }else{
        setUnitSelected(null);
        setUnit(null);
      }
    }
  }, [units]);

  useEffect(() => {
    if(item){
      const distSel = item.distributor;
      const nDist = distributors.filter(d => (d.label.toLowerCase().indexOf(distSel.toLowerCase()) !== -1));
      if(nDist.length===1){
        setDistributorSelected(nDist[0]);
        setDistributor(nDist[0]);
      }else{
        setDistributorSelected(null);
        setDistributor(null);
      }
    }
  }, [distributors]);

  return {
    distributors, 
    distributorSel: { value: distributorSelected, set: setDistributorSelected }, 
    units, 
    unitSel: { value: unitSelected, set: setUnitSelected }
  }
};

const useFormInfo = ({modalProps}) => {
  const dispatch = useDispatch(); 
  const history = useHistory();
  const cartItems = useSelector(({ cart }) => cart.cartItems);
  const lastModified = useSelector(({cart}) => cart.lastModified);
  const { updateItemsInCart } = useShoppingCartItem.useCartItemUpdate(cartItems);
  const [distributor, setDistributor] = useState(null);
  const [unit, setUnit] = useState(null);
  const { set : setQuantity, value: quantity } = useInput.useText('', {isNumber: true});
  const { set : setDescription, value: description } = useInput.useText();

  const valid = (value) =>{
    const re = /^[0-9\b]+$/;
      if (value === '' || re.test(value)) {
         return true;
      }
      return false;
  };

  const submitDisabled = (e) => {
    const data = {
      distributor: distributor,
      unit:unit,
      quantity: quantity,
      description: description
    };
    const constrains = {
      distributor: {
        presence: true
      },
      unit: {
        presence: true
      },
      quantity: {
        presence: true, numericality: { greaterThan: 0}
      },
      description: {
        presence: {allowEmpty: false}, length: {minimum: 3}
      }
    }
    
    const result = validate(data, constrains);
    return result ? true : false;
  };

  const goBack = () => {
    history.goBack();
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const specialItem = {
      distributorId: distributor.value,
      packSize: null, 
      uom: unit.value,
      description: description
    }

    const params = {
      itemList: [
        {
          index:1,
          quantity: quantity, 
          description: description,          
          specialItem : specialItem
        }
      ],
      lastModified: lastModified
    };
    
    let res = await cartApi.update(params);
    if (res.data) {
      dispatch(saveLastModified(res.data.lastModified));
      localStorage.setItem(localStorageConst.keys.lastModifiedCart, res.data.lastModified);
      updateItemsInCart(specialItem,quantity);
      setTimeout(() => {
        const props = {
          header: 'Confirmation',
          message: `Special Request Added! `,
          onOk: () => { (modalProps) ? modalProps.toggle(): goBack();}
        };
        dispatch(showModal(ConfirmationModal, props));
      }, 500);
    }
  };

  return {
    setDistributor, 
    setUnit,
    setQuantity, quantity, valid,
    setDescription, description, 
    submitDisabled, onSubmit
  };
};

export default {
  useDefaultInfo, useFormInfo
};