import React from 'react';
import { isMobile } from "react-device-detect";
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import Header from '../../../../SharedComponents/Header/Header';
import SubHeaderOrderHistoryList from './OrderHistoryList/SubHeaderOrderHistoryList';
import OrderHistoryList from './OrderHistoryList/OrderHistoryList';
import OrderReceive from './OrderReceive/OrderReceive';
import OrderView from './OrderView/OrderView';
import OrderReceiveComplete from './OrderReceive/OrderReceiveComplete';
import OrderInventory from './OrderInventory/OrderInventory';
import OrderUploadInvoice from './OrderUpload/OrderUploadInvoiceMobile';
import TakePhoto from './OrderUpload/TakePhoto';
import './OrderHistory.scss';

const OrderHistory = (props) => {

  return (
    <Switch>
      <Route exact path='/order-history'>
        <Container fluid={true} className='m-0 p-0'>
          <Header showMenu={true} showLogo={isMobile ? false : true} showBack={false} title='Order History' showCart={isMobile ? false : true}>
            <SubHeaderOrderHistoryList/>
          </Header>
          <OrderHistoryList />
        </Container>
      </Route>
      <Route exact path='/order-history/:id'>
        <Container fluid={true} className='m-0 p-0'>
          <Header showMenu={false} showLogo={false} showBack={true} title='Order Detail' showCart={false} />
        </Container>
      </Route>
      <Route exact path='/order-history/receive/:id'>
          <Container fluid={true} className={isMobile ? 'px-0' : 'pb-3'}>
              <OrderReceive />
          </Container>
      </Route>
      <Route exact path='/order-history/receive/:id/order-complete'>
          <Container fluid={true} className={isMobile ? 'px-0' : 'pb-3'}>
              <OrderReceiveComplete props={props}/>
          </Container>
      </Route>
      <Route exact path='/order-history/view/:id'>
          <Container fluid={true} className={isMobile ? 'px-0' : 'pb-3'}>
              <OrderView />
          </Container>
      </Route>
      <Route exact path='/order-history/inventory/:id'>
          <Container fluid={true} className={isMobile ? 'px-0' : 'pb-3'}>
              <OrderInventory/>
          </Container>
      </Route>
      <Route exact path='/order-history/upload-invoice/:id'>
          <Container fluid={true} className={isMobile ? 'px-0' : 'pb-3'}>
              <OrderUploadInvoice/>
          </Container>
      </Route>
      <Route exact path='/order-history/upload-invoice/:id/take-photo'>
        <TakePhoto/>
      </Route>
      <Route>
        <Redirect to='/order-history' />
      </Route>
    </Switch>
  );
};

export default OrderHistory;
