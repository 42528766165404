import React from 'react';
import { Row, Col } from 'reactstrap';

const ItemBasicInfo = ({din, brand, distributor}) => {
  return (
    <Row style={{height: '80px'}}>
      <Col className='d-flex flex-column justify-content-end px-2'>
        <Row className='mt-1'>
          <Col className='text-label text-truncate'>{din}</Col>
        </Row>
        <Row className='mt-1'>
          <Col className='text-label text-truncate'>{brand}</Col>
        </Row>
        <Row className='mt-1'>
          <Col className='text-label text-truncate'>{distributor}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ItemBasicInfo;