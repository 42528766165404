import React, { useState, useEffect } from 'react'
import './ReviewOrder.scss'
import ReviewOrderRow from './ReviewOrderRow';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from "react-device-detect";
import SimpleModal from "../../../../../SharedComponents/Modals/SimpleModal/SimpleModal";
import { showModal } from '../../../../../reducers/modals/modalsActions';
import useShoppingCartReviewOrder from '../../../../../SharedComponents/ShoppingCart/useShoppingCartReviewOrder';
import { useDispatch, useSelector } from 'react-redux';
import ItemDistributorModal from './ItemDistributorModal';
import { saveHeaderIndexRevOrder } from '../../../../../reducers/header/headerActions';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';


const ReviewOrder = () => {

    const UseShoppingCartReviewOrder = useShoppingCartReviewOrder.useShoppingCart();
    const showDetailRd = false;
    const [cartItemsArray, setCartItemsArray] = useState([])
    const dispatch = useDispatch();
    const [itemsDistributor, setItemsDistributor] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const activeLocation = useSelector(({ user }) => user.activeLocation);

    const changeShowDetail = (cartItem) => {
        switch (cartItem.isSpecialItem) {
            case true:
                const props = {
                    header: 'Special Request',
                    message: `${cartItem.description} </br>
                    ${cartItem.caseQuantity} *  ${cartItem.unitMeasure}`,

                };
                dispatch(showModal(SimpleModal, props));
                break;
            default:
                // headerProps.showDet_ReviewOrder = !headerProps.showDet_ReviewOrder;
                // dispatch(saveHeaderContent(headerProps));
                break;
        }
    }


    const getCartItems = () => {
        setCartItemsArray([])
        UseShoppingCartReviewOrder.getCartItemsProm().then(res => {
            // console.log(res)
            setCartItemsArray(res);
        })
    }


    const toggleModal = (isUpdate) => {
        setOpenModal(!openModal);
        if (isUpdate == true)
            getCartItems();
    };


    const getItemDistributor = async (cartItem) => {

        const resItemsDistributor = await UseShoppingCartReviewOrder.doGetItemDistributor(cartItem.itemDistributorId);
        if (resItemsDistributor.length === 0) {
            dispatch(showModal(AlertModal, {
                message: 'This item has been removed from a generic group. Please re-link the item in Inventory View to proceed with using this feature',
                // onOk: () => history.push('/recipe-costing')
            }));
            return;
        }
        setItemsDistributor(resItemsDistributor);
        setOpenModal(true);

    }

    const updateListCart = () => {
        getCartItems();
    }


    useEffect(() => {
        getCartItems();

    }, [])


    const contentListCartsItems = cartItemsArray.map(([key, listCartItems]) => {
        return (
            <div className='contentRow' key={key + '-RoCardItem'}>
                {
                    <ReviewOrderRow
                        listCartItem={listCartItems}
                        changeShowDetail={changeShowDetail}
                        goToItemsDistributor={getItemDistributor}
                        updateListCart={updateListCart}
                        displayComparisonView={activeLocation.display_comparison_view} />
                }
            </div>
        )
    });


    const getCountOrder = () => {
        let count = 0;
        cartItemsArray.forEach(([key, listCartItems]) => {
            let haveItem = listCartItems.every(res => res.caseQuantity == 0);
            if (!haveItem) {
                count += 1;
            };

        });
        return count;
    }

    return (
        <div>
            <ShowIf show={cartItemsArray}>
                <ShowIf show={!isMobile}>
                    <div className={!showDetailRd ? 'contentOrdering' : 'contentOrdering displayNone'}>
                        <h1>{`You have ${getCountOrder()} order${(getCountOrder() === 1 ? `` : `s`)} to review for ${activeLocation.nickname}`}</h1>
                        {contentListCartsItems}
                    </div>
                </ShowIf>
                <ShowIf show={isMobile}>
                    <div className={!showDetailRd ? '' : 'displayNone'}>
                        {contentListCartsItems}
                    </div>
                </ShowIf>
            </ShowIf>

            <ShowIf show={openModal}>
                <ItemDistributorModal
                    modal={openModal}
                    toggle={toggleModal}
                    items={itemsDistributor}
                    className='modalDistributor' />
            </ShowIf>


        </div>

    )
}


export default ReviewOrder;
