import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import useHelpContent from './useHelpContent';

const HelpContent = () => {

  const { setDescription, description, error, onSubmit } = useHelpContent.useForm();

  return (
    <Container fluid={true} className="my-5 rounded">
      <Row>
        <Col xs="12" className="fb-content">
          <Form className="fb-form" noValidate="novalidate" onSubmit={onSubmit}>
            <FormGroup className="text-center">
              <h5 className="supporting-txt">How can I help?</h5>
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input id="Description" type="textarea" value={description} onChange={setDescription} className="fb-textarea" placeholder="Enter Description..." />
              <ShowIf show={error.description}><div className='text-danger'>{error.description ? error.description[0] : ''}</div></ShowIf>
              <ShowIf show={error.error}><div className="text-danger">{error.error ? error.error : ''}</div></ShowIf>
            </FormGroup>
            <Row>
              <Col xs={{size: 4, offset: 4}} className="mt-4 text-right">
                <Button type="submit" className="btn btn-block btn-lg btn-green">Submit</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default HelpContent;
