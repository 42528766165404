import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Col } from 'reactstrap';
import Analytics from './Views/Analytics/Analytics';
import PlaceOrder from './Views/PlaceOrder/PlaceOrder';
import RecipeCosting from './Views/RecipeCosting/RecipeCosting';
import OrderGuide from './Views/OrderGuide/OrderGuide';
import Pos from './Views/Pos/Pos';
import ManageCards from './Views/ManageCards/ManageCards';
import Help from './Views/Help/Help';
import Accounting from './Views/Accounting/Accounting';
import CheckoutOrdering from './Views/CheckoutOrdering/CheckoutOrdering';
import ShoppingLists from './Views/ShoppingLists/ShoppingLists';
import SpecialRequest from './Views/SpecialRequest/SpecialRequest';
import OrderHistory from './Views/OrderHistory/OrderHistory';
import SortInventory from './Views/SortInventory/SortInventory';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import InventoryView from './Views/InventoryView/InventoryView';

const MainAppRouter = (props) => {
  const { isSidebarOpen } = props;
  const activeLocation = useSelector(({user}) => user.activeLocation);
  return (
    <Col className={'p-0 main-app-content' + (isSidebarOpen ? ' sidebar-is-open' : '')}>
      <Switch>
        {
          !isMobile && activeLocation.display_accounting ?
            <Route path='/accounting' component={Accounting} />
          : null
        }
        <Route path='/analytics' component={Analytics} />
        <Route path='/sort-inventory/:inventory' component={SortInventory} />
        <Route path='/place-order/:view' component={PlaceOrder} />
        {
          activeLocation.display_recipe_costing ?
            <Route path='/recipe-costing' component={RecipeCosting} />
          : null
        }
        <Route path='/order-guide' component={OrderGuide} />
        {
          isMobile ?
            null
          : <Route path='/pos' component={Pos} />
        }
        <Route path='/manage-cards' component={ManageCards} />
        <Route path='/shopping-lists' component={ShoppingLists} />
        <Route path='/help' component={Help} />
        <Route path='/checkout-ordering' component={CheckoutOrdering} />
        <Route path='/inventory-view' component={InventoryView} />
        <Route exact path='/checkout-ordering/:view' component={CheckoutOrdering} />
        <Route path='/special-request' component={SpecialRequest} />
        <Route path='/order-history' component={OrderHistory} />
        <Route>
          <Redirect to={ activeLocation.display_comparison_view ? '/place-order/inventory' : '/place-order/list'} />
        </Route>
      </Switch>
    </Col>
  );
};

export default MainAppRouter;
