import React, { useState } from 'react';
import useModal from '../useModal';
import { Modal, ModalBody, ModalHeader, Row, Col, Input, ModalFooter, Button } from 'reactstrap';
import useInput from '../../SharedEffects/useInput';
import Select from 'react-select';
import { packSizeUomsConversion } from '../../../config/constants';
import { isMobile } from 'react-device-detect';

const EditPackSizeModal = (props) => {
  const { id, onSave, menu, defaultValues } = props;
  const modalProps = useModal(id, menu);
  const units = packSizeUomsConversion.map(u => { return { label: u.label, value: u }});
  const uomSelected = defaultValues ? (defaultValues.uom ? units.filter(u => u.label.toLowerCase() === defaultValues.uom.toLowerCase())[0] : null) : null;
  const uomState = useState(defaultValues ? (uomSelected ? uomSelected : units[0]) : units[0]);
  const uom = {
    value: uomState[0],
    onChange: uomState[1]
  };
  const outerPack = useInput.useText(defaultValues ? defaultValues.outerPack : '', {isNumber: {min: 0}});
  const innerPack = useInput.useText(defaultValues ? defaultValues.innerPack : '', {isNumber: {min: 0}});

  const clickSave = () => {
    const data = {
      innerPack: innerPack.value,
      outerPack: outerPack.value,
      uom: uom.value,
      packSize: `${outerPack.value}/${innerPack.value} ${uom.value.label}`
    };
    if (onSave) onSave(data);
    modalProps.toggle();
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader className='bg-white' toggle={modalProps.toggle}>Edit Packsize</ModalHeader>
      <ModalBody>
        <Row className='align-items-end'>
          <Col>
            <Row className='align-items-center'>
              <Col className='text-gray'>Outer Pack</Col>
            </Row>
            <Row>
              <Col className='mt-2 mb-4 align-items-center'>
                <Input type='number' value={outerPack.value} onChange={outerPack.set} placeholder='' />
              </Col>
            </Row>
          </Col>
          <Col className='w-fc text-gray mb-4 pb-1'>/</Col>
          <Col>
            <Row>
              <Col className='text-gray'>Inner Pack</Col>
            </Row>
            <Row>
              <Col className='mt-2 mb-4'>
                <Input type='number' value={innerPack.value} onChange={innerPack.set} placeholder='' />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col className='text-gray'>UOM</Col>
            </Row>
            <Row>
              <Col className='mt-2 mb-4'>
                <Select className='fb-select' classNamePrefix='select'
                  options={units} {...uom}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button block={isMobile} className='px-4' color='green' onClick={clickSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditPackSizeModal;