import React, { useState, Fragment } from 'react';
import { Row, Col, Button } from 'reactstrap';
import SavingsFirstCard from './SavingsFirstCard';
import SavingsSecondCard from './SavingsSecondCard';
import { FaTimes, FaExchangeAlt, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import SelectModal from '../../../../../SharedComponents/Modals/SelectModal/SelectModal';
import useSavings from './useSavings';
import ConfirmationModal from '../../../../../SharedComponents/Modals/ConfirmationModal/ConfirmationModal';
import { isMobile } from 'react-device-detect';

const SavingsRow = (props) => {

    const { itemSavings, changeSwap, changeReject } = props;
    const { optionsReject } = useSavings.useOptionsSavings();
    const [indexItem, setIndexItem] = useState(0)
    // console.log(itemSavings.matches)
    const [itemComparable, setItemComparable] = useState(itemSavings.matches[indexItem])
    // const [itemComparable, setItemComparable] = useState([])

    const dispatch = useDispatch();
    const [effectChange, setEffectChange] = useState('');

    const swapItem = () => {
        const newSwapValue = !itemSavings.swap;
        changeSwap(newSwapValue, itemSavings.itemDistributorId, itemComparable.itemDistributorId)
    };


    const goToChangeReject = (optionValue) => {
        const newRejectValue = !itemSavings.reject;
        changeReject(newRejectValue, optionValue, itemSavings.itemDistributorId, itemComparable.itemDistributorId);
    }


    const rejectItem = () => {
        const props = {
            header: `Reject`,
            message: `We'd like to improve your experience with Foodbam. What is your problem?`,
            options: optionsReject,
            onOk: (optionValue) => {
                openModalConfirm();
                goToChangeReject(optionValue);
            }
        };
        dispatch(showModal(SelectModal, props));
    }


    const openModalConfirm = () => {
        const props = {
            // header: 'Confirmation',
            message: `Thank you for your feedback!`,
            getProps: (props) => {
                setTimeout(() => {
                    props.toggle();
                }, 1500);
            }
        };
        dispatch(showModal(ConfirmationModal, props));
    };


    const showEffect = () => {
        setEffectChange('active');
        setTimeout(() => {
            setEffectChange('');
        }, 500);
    }


    const goNextItem = () => {
        if (indexItem == itemSavings.matches.length - 1)
            return;
        const newIndex = indexItem + 1;
        setIndexItem(newIndex);
        setItemComparable(itemSavings.matches[newIndex]);
        showEffect();

    };

    const goBackItem = () => {
        if (indexItem == 0)
            return;
        const newIndex = indexItem - 1;
        setIndexItem(newIndex);
        setItemComparable(itemSavings.matches[newIndex]);
        showEffect();

    };


    return (

        <Fragment>
            <ShowIf show={itemComparable}>
                <Row className={isMobile ? 'rowContent mobile' : 'rowContent'} >
                    <Col xs={isMobile ? '6' : ''} >
                        <SavingsFirstCard cartItem={itemSavings.detailItem} />
                    </Col>
                    <ShowIf show={!isMobile}>
                        <Col lg='2' className='contentButton'>
                            <ShowIf show={!itemSavings.reject}>
                                <Button color={itemSavings.swap ? 'orange' : 'green'} onClick={swapItem}>
                                    <FaExchangeAlt size='12'></FaExchangeAlt>{itemSavings.swap ? 'Swap back' : 'Swap'}</Button>
                                <ShowIf show={!itemSavings.swap}>
                                    <Button color='orange' onClick={rejectItem}>
                                        <FaTimes size='12' ></FaTimes>Reject</Button>
                                </ShowIf>
                            </ShowIf>
                        </Col>
                    </ShowIf>
                    <Col xs={isMobile ? '6' : ''} >
                        <ShowIf show={itemSavings.matches.length > 1 && !itemSavings.reject}>
                            <div className={!itemSavings.swap ? 'faArrows active' : 'faArrows'}>
                                <FaChevronLeft
                                    className={indexItem == 0 ? 'faLeft disabled' : 'faLeft'}
                                    onClick={goBackItem}>
                                </FaChevronLeft>
                                <FaChevronRight
                                    className={indexItem == itemSavings.matches.length - 1 ? 'faRight disabled' : 'faRight'}
                                    onClick={goNextItem}>
                                </FaChevronRight>
                            </div>

                        </ShowIf>

                        <SavingsSecondCard
                            itemComparable={itemComparable}
                            swapActive={itemSavings.swap}
                            rejectActive={itemSavings.reject}
                            goToChangeReject={goToChangeReject}
                            effectChange={effectChange} />
                    </Col>
                </Row>
                <ShowIf show={isMobile}>
                    <Row className='contentRowBtn'>
                        <Col xs='6' className='contentButton'>
                            <ShowIf show={!itemSavings.reject}>
                                <Button color={itemSavings.swap ? 'orange' : 'green'} onClick={swapItem}>
                                    <FaExchangeAlt size='12'></FaExchangeAlt>{itemSavings.swap ? 'Swap back' : 'Swap'}</Button>
                            </ShowIf>
                        </Col>
                        <Col xs='6' className='contentButton'>
                            <ShowIf show={!itemSavings.reject}>
                                <ShowIf show={!itemSavings.swap}>
                                    <Button color='orange' onClick={rejectItem}>
                                        <FaTimes size='12' ></FaTimes>Reject</Button>
                                </ShowIf>
                            </ShowIf>
                        </Col>
                    </Row>
                </ShowIf>

            </ShowIf>

        </Fragment>

    )

}

export default SavingsRow;
