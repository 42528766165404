import React, {Fragment} from 'react';
import { isMobile } from "react-device-detect";
import { Container } from "reactstrap";
import './SpecialRequest.scss';
import Header from '../../../../SharedComponents/Header/Header';
import SpecialRequestForm from './SpecialRequestForm';

const SpecialRequest = (props) => {

  return (
    <Fragment>
      <Header title='Add Special Request' showCart={false} />
      <Container fluid={true} fluid={true} className={'m-0 ' + (isMobile ? 'p-0' : 'pt-3')}>        
        <SpecialRequestForm/>
      </Container>
    </Fragment>
  )
};

export default SpecialRequest;