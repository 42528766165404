import React from 'react';
import { Col, Button } from 'reactstrap';
import './Header.scss';
import ShowIf from '../ShowIf/ShowIf';

const HeaderRightButton = ({button, icon, show}) => {
  const isArrayIcon = Array.isArray(icon);
  const isArrayButton = Array.isArray(button);

  const buttonArray = isArrayButton ? (button ? button : []) : (button ? [button] : []);
  const iconArray = isArrayIcon ? (icon ? icon : []) : ( icon ? [icon] : []);

  const buttonMap = buttonArray.map((b, i) =>
    <Col key={`icon_${i}`} className='text-right w-fc'>
      <Button onClick={b.onClick} size='lg' color={b.color} disabled={b.disabled}>{b.title}</Button>
    </Col>
  );

  const iconMap = iconArray.map((Icon, i) =>
    <Col key={`icon_${i}`} className='text-right w-fc pl-0'>
      {Icon}
    </Col>
  );

  return (
    <ShowIf show={show}>
      {buttonMap}
      {iconMap}
    </ShowIf>
  );
};

export default HeaderRightButton;