import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Row, Col } from 'reactstrap';
import * as $ from 'jquery';

const TooltipMenu = ({target, isOpen, options, toggle}) => {
  return (
    <Tooltip className='opacity-100' autohide={false}
      innerClassName='bg-white text-black px-0 border rounded-0 tooltip-menu' hideArrow
      target={target} placement='bottom-end' id={target+'_tooltip_menu'}
      isOpen={isOpen} toggle={toggle}
    >
      <div>
        {options.map((opt, i) =>
          <Row onMouseDown={ () => { if (opt.onClick && !opt.disabled) opt.onClick(); }} key={`${target}_opt_${i}`} className={'py-2 hover-light px-3 m-0'+ (opt.disabled ? ' bg-light text-light-blue' : ' clickable') + (opt.className ? ' '+opt.className : '')}>
            <Col className='p-0'>{opt.label}</Col>
          </Row>
        )}
      </div>
    </Tooltip>
  )
};

TooltipMenu.propTypes = {
  target: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  toggle: PropTypes.func.isRequired
};

export default TooltipMenu;