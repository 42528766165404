import React from 'react';
import { Container, Row, Col, Card, Input, Button} from 'reactstrap';
import { isMobile } from 'react-device-detect';
import Header from '../../../../../SharedComponents/Header/Header';
import useInput from '../../../../../SharedComponents/SharedEffects/useInput';
import useOrderReceive from './useOrderReceive';
import numeral from 'numeral';
import moment from 'moment';

const OrderReceiveComplete = (props) => {
    const stateLocation  =  props.props.history.location.state;
    const resPreReject = stateLocation ? stateLocation.resPreReject : null;
    const { value: valueCustomInvoiceId, set: setCustomInvoiceId } = useInput.useText();
    const { orderRedirect, orderComplete } = useOrderReceive.useReceiveComplete(stateLocation, valueCustomInvoiceId);
    orderRedirect(resPreReject);

    return (
        <Container fluid={true} className={isMobile ? 'mt-3' : 'mt-3 px-4'} >
            <Header showMenu={false} showLogo={false} showBack={false} title={'Order Complete'}  showCart={false} />
            <Card>
                <Col xs={isMobile ? 12 : 6}>
                    <Row className='py-2 text-gray'>
                        <Col xs={6}>
                            Distributor
                        </Col>
                    </Row>
                    <Row className='py-2'>
                        <Col xs={6}>
                            {resPreReject ? resPreReject.distributor : ''}
                        </Col>
                        <Col xs={6}>
                            Delivery Date
                        </Col>
                    </Row>
                    <Row className='py-2 text-gray'>
                        <Col xs={6}>
                            Submission Date
                        </Col>
                        <Col xs={6}>
                            Delivery Date
                        </Col>
                    </Row>
                    <Row className='py-2 '>
                        <Col xs={6}>
                            {resPreReject ? moment(resPreReject.submission_date).format("MM/DD/YY") : ''}
                        </Col>
                        <Col xs={6}>
                            {resPreReject ? moment(resPreReject.delivery_date).format("MM/DD/YY"): ''}
                        </Col>
                    </Row>
                    <Row className='py-2 text-gray'>
                        <Col xs={6}>
                            Item Count
                        </Col>
                        <Col xs={6}>
                        </Col>
                    </Row>
                    <Row className='py-2'>
                        <Col xs={6}>
                            {resPreReject ? resPreReject.line_items : ''}
                        </Col>
                        <Col xs={6}>
                        </Col>
                    </Row>
                    <Row className='py-2 text-gray'>
                        <Col xs={6}>
                            Original Total
                        </Col>
                        <Col xs={6}>
                            Total Price of Rejected Items
                        </Col>
                    </Row>
                    <Row className='py-2'>
                        <Col xs={6}>
                            {resPreReject ? numeral(resPreReject.original_total_price).format('$0,0.00') : ''}
                        </Col>
                        <Col xs={6}>
                            {resPreReject ? numeral(resPreReject.credit_refusal).format('$0,0.00'): ''}
                        </Col>
                    </Row>
                    <Row className='py-2 text-gray'>
                        <Col xs={6}>
                            New Total
                        </Col>
                        <Col xs={6}>
                            Invoice Number
                        </Col>
                    </Row>
                    <Row className='py-2'>
                        <Col xs={6}>
                            {resPreReject ?  numeral(resPreReject.new_total_price).format('$0,0.00') : ''}
                        </Col>
                        <Col xs={6}>
                            <Input value={valueCustomInvoiceId} onChange={setCustomInvoiceId}></Input>
                        </Col>
                    </Row>
                    <Row className='py-2 pb-5'>
                        <Col xs={isMobile ? 12 : 6} className={isMobile ? 'text-center': ''}>
                            <Button color='green'  size='lg' onClick={orderComplete}>Complete receiving</Button> 
                        </Col>
                        <Col xs={6}>
                        </Col>
                    </Row>
                </Col>
                <Col xs={6}>
                </Col>
            </Card>
        </Container>
    )

}

export default OrderReceiveComplete;