import React, { useState, useEffect } from "react";
import recipeApi from "../../../../../api/recipeApi";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../../../reducers/modals/modalsActions";
import AlertModal from "../../../../../SharedComponents/Modals/AlertModal/AlertModal";
import OptionsModal from "../../../../../SharedComponents/Modals/OptionsModal/OptionsModal";
import QuestionModal from "../../../../../SharedComponents/Modals/QuestionModal/QuestionModal";
import {
  setRecipeList,
  setIngredientsList,
  clearRecipeInfo,
} from "../../../../../reducers/recipeCosting/recipeCostingActions";
import RecipeCostingTable from "./RecipeCostingTable";
import moment from "moment";
import RecipeCostingSubHeader from "./RecipeCostingSubHeader";

const useApi = () => {
  const menuList = useSelector(({ recipeCosting }) => recipeCosting.menuList);
  const recipeList = useSelector(
    ({ recipeCosting }) => recipeCosting.recipeList
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const id = parseInt(params.menuId);
  const selected = menuList.filter((m) => m.value === id)[0];

  const getRecipes = (posBgnDate, posEndDate) => {
    recipeApi
      .getInRange(id, posBgnDate, posEndDate)
      .then(({ data }) => {
        dispatch(setRecipeList(data));
      })
      .catch((err) => {
        if (err.code === 404) {
          dispatch(
            showModal(AlertModal, {
              message: "Menu Not Found",
              onOk: () => {
                history.push("/recipe-costing");
              },
            })
          );
        }
        console.error("RecipeApi get Error:", err);
      });
  };

  const createRecipe = (params, redirect, replace) => {
    recipeApi
      .post(params)
      .then(({ data }) => {
        const path = `${history.location.pathname}/recipe/${data.id}`;
        getRecipes(posBgnDate, posEndDate);
        if (redirect) {
          if (replace) {
            history.replace(path);
          } else {
            history.push(path);
          }
        }
      })
      .catch((err) => {
        if (err.code === 400) {
          dispatch(showModal(AlertModal, { message: err.data.message }));
        } else {
          dispatch(showModal(AlertModal, { message: "Something wen't wrong" }));
        }
        console.error("RecipeApi post Error:", err);
      });
  };

  const tableTabs = ["Weekly", "Monthly", "Year to Date", "Custom"];
  const [tableSort, updateSortBy] = useState({ prop: "name", type: 1 });
  const [dateFilter, updateDateFilter] = useState(0);
  const [posBgnDate, setPOSBgnDate] = useState(
    moment().startOf("week").hour(0).minute(0).second(0)
  );
  const [posEndDate, setPOSEndDate] = useState(
    moment().subtract(1, "days").hour(23).minute(59).second(0)
  );
  const [selectedRecipes, updateSelectedRecipes] = useState([]);

  const changeTab = (tabIndex) => {
    updateDateFilter(tabIndex);
    switch (tableTabs[tabIndex]) {
      case "Monthly":
        setPOSBgnDate(moment().startOf("month").hour(0).minute(0).second(0));
        setPOSEndDate(
          moment().subtract(1, "days").hour(23).minute(59).second(0)
        );
        break;
      case "Year to Date":
        setPOSBgnDate(moment().startOf("year").hour(0).minute(0).second(0));
        setPOSEndDate(
          moment().subtract(1, "days").hour(23).minute(59).second(0)
        );
        break;
      case "Weekly":
      default:
        setPOSBgnDate(moment().startOf("week").hour(0).minute(0).second(0));
        setPOSEndDate(
          moment().subtract(1, "days").hour(23).minute(59).second(0)
        );
        break;
    }
  };

  const selectRecipe = (e) => {
    const updatedRecipeList = JSON.parse(JSON.stringify(selectedRecipes));
    const selectedRecipeId = parseInt(e.target.value);
    if (
      e.target.checked &&
      -1 === updatedRecipeList.indexOf(selectedRecipeId)
    ) {
      updatedRecipeList.push(selectedRecipeId);
    }
    if (
      !e.target.checked &&
      -1 !== updatedRecipeList.indexOf(selectedRecipeId)
    ) {
      updatedRecipeList.splice(updatedRecipeList.indexOf(selectedRecipeId), 1);
    }

    updateSelectedRecipes(updatedRecipeList);
  };

  const selectAllRecipes = (e) => {
    if (e.target.checked) {
      updateSelectedRecipes(recipeList.map((r) => parseInt(r.id)));

      return;
    }
    updateSelectedRecipes([]);
  };

  const duplicateRecipes = () => {
    const chain = selectedRecipes.map((recipeId) =>
      recipeApi.duplicate(recipeId)
    );
    Promise.all(chain)
      .then(() => {
        getRecipes(posBgnDate, posEndDate);
      })
      .catch((err) => {
        console.error("RecipeApi duplicate Error:", err);
      });
  };

  const deleteRecipes = () => {
    const questionProps = {
      message:
        "Delete selected recipe" + (selectedRecipes.length > 1 ? "s" : "") + "?",
      leftButton: {
        title: "Delete",
        color: "orange",
        onClick: () => {
          const chain = selectedRecipes.map((recipeId) =>
            recipeApi.delete(recipeId)
          );
          Promise.all(chain)
            .then(() => {
              getRecipes(posBgnDate, posEndDate);
            })
            .catch((err) => {
              console.error("RecipeApi delete Error:", err);
            });
        },
      },
      rightButton: {
        title: "Cancel",
        color: "green",
      },
    };
    dispatch(showModal(QuestionModal, questionProps));
  };

  useEffect(() => {
    if (
      params.menuId &&
      menuList.length &&
      params.menuId !== "new-recipe" &&
      posBgnDate &&
      posEndDate
    ) {
      const exist = menuList.filter((m) => m.value === id)[0];
      if (exist) {
        getRecipes(posBgnDate, posEndDate);
      } else {
        dispatch(
          showModal(AlertModal, {
            message: "Menu Category Not Found",
            onOk: () => {
              history.push("/recipe-costing");
            },
          })
        );
      }
    }
  }, [params.menuId, menuList, posBgnDate, posEndDate]);

  useEffect(() => {
    updateSelectedRecipes([]);
  }, [recipeList]);

  const sorted = recipeList.sort((a, b) => {
    if (a[tableSort.prop] === b[tableSort.prop]) {
      return 0;
    }

    if (a[tableSort.prop] === "-") {
      return tableSort.type;
    }

    return tableSort.type * (a[tableSort.prop] > b[tableSort.prop] ? 1 : -1);
  });

  useEffect(() => {
    console.log("hook", selectedRecipes);
  }, []);

  const recipeDateFilter = (
    <RecipeCostingSubHeader
      tabs={tableTabs}
      nRecipes={recipeList.length}
      {...{
        changeTab,
        dateFilter,
        posBgnDate,
        setPOSBgnDate,
        posEndDate,
        setPOSEndDate,
        selectAllRecipes,
        selectedRecipes,
        duplicateRecipes,
        deleteRecipes,
      }}
    />
  );

  const recipeListTable = (
    <RecipeCostingTable
      list={sorted}
      {...{
        tableSort,
        updateSortBy,
        selectRecipe,
        selectedRecipes,
      }}
    />
  );

  const menuName = selected ? selected.label : "...";

  return {
    recipeList,
    menuName,
    selected,
    createRecipe,
    menuList,
    recipeDateFilter,
    recipeListTable,
  };
};

const useRow = (props) => {
  const { id, getRecipes } = props;
  const [hover, setHover] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const goToRecipe = () => {
    dispatch(setIngredientsList([]));
    dispatch(clearRecipeInfo());
    history.push(`${history.location.pathname}/recipe/${id}`);
  };

  const duplicateRecipe = () => {
    recipeApi.duplicate(id).then(({data}) => {
      getRecipes();
    }).catch(err => {
      console.error('RecipeApi duplicate Error:', err);
    });
  };

  const removeRecipe = () => {
    recipeApi.delete(id).then(({data}) => {
      getRecipes();
    }).catch(err => {
      console.error('RecipeApi delete Error:', err);
    });
  };

  const deleteRecipe = () => {
    const questionProps = {
      message: 'Delete Recipe?',
      leftButton: {
        title: 'Delete',
        color: 'orange',
        onClick: () => removeRecipe(id)
      },
      rightButton: {
        title: 'Cancel',
        color: 'green'
      }
    }
    dispatch(showModal(QuestionModal, questionProps));
  };

  const openRecipeOptions = () => {
    const optionsProps = {
      title: 'Select',
      options: [
        {label: 'Duplicate', onClick: duplicateRecipe},
        {label: 'Delete', onClick: deleteRecipe}
      ]
    };
    dispatch(showModal(OptionsModal, optionsProps));
  };

  return {
    hover, setHover, goToRecipe, openRecipeOptions,
    duplicateRecipe, deleteRecipe
  };
};


export default {
  useApi, useRow
};
