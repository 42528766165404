import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import numeral from 'numeral';
import arrowRight from '../../../../../assets/images/arrow-right.png';

const PosListRow = (props) => {
  const item = props.children;

  const seeDetailsHandler = () => {
    return `/pos/${item.date}`;
  };

  return (
    <Card className='pos-item'>
      <Row>
        <Col xs={5}>
          {item.date}
        </Col>
        <Col xs={6}>
          {numeral(item.total).format('$0,0.00')}
        </Col>
        <Col xs={1}>
          <Link to={seeDetailsHandler()}>{!isMobile ? 'See Details' : null}<img src={arrowRight} title='See Details' style={{margin: '-2px 0 0 6px'}}/></Link>
        </Col>
      </Row>
    </Card>
  );
};

export default PosListRow;
