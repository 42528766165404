import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Button, NavLink } from 'reactstrap';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import useModal from '../../../../../SharedComponents/Modals/useModal';
import { FaChevronLeft, FaPen, FaTrash } from 'react-icons/fa';
import cardspApi from '../../../../../api/cardspApi';
import ShippingAndPaymentCardModal from './ShippingAndPaymentCardModal';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import useReviewOrder from '../ReviewOrder/useReviewOrder';
import { saveHeaderIndexRevOrder } from '../../../../../reducers/header/headerActions';
import ConfirmationModal from '../../../../../SharedComponents/Modals/ConfirmationModal/ConfirmationModal';
import useShoppingCart from '../../../../../SharedComponents/ShoppingCart/useShoppingCart';
import { cardIconHandler } from '../../ManageCards/cardIconHandler';
import CardForm from '../../ManageCards/CardForm/CardForm';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';

const ShippingAndPaymentMobile = (props) => {

    const { id, updateListCart } = props;

    const modalProps = useModal(id, true);
    const [listCard, setListCard] = useState([])
    const dispatch = useDispatch();
    const valuesCheckout = useSelector(({ reviewOrder }) => reviewOrder.values)
    const useOrdersApi = useReviewOrder.useOrdersApi();
    const { getCartItems } = useShoppingCart.useCartItems();
    const [textHeader, setTextHeader] = useState('CHECKOUT');
    const [showListCard, setShowListCard] = useState(false);
    const [showNewCard, setShowNewCard] = useState(false);
    const [cardToken, setCardToken] = useState(null);
    const [showCard, setShowCard] = useState();
    const [blockSaving, setBlockSaving] = useState(false);

    const validateShowCard = useReviewOrder.useValuesReviewOrder();


    const getCards = () => {
        cardspApi.getCards().then(({ data }) => {
            data = data.map(res => {
                res['checked'] = false;
                if (res.isDefault == 1)
                    res['checked'] = true;
                return res;
            })
            setListCard(data)
            // setCards(data);
        }).catch(err => {
            console.error('getCardDetails get Error:', err);
        });
    }


    const updateCard = (cardToken) => {
        goToNewCard(cardToken);
        setTextHeader('CARD DETAIL')

    };

    const goToNewCard = (token) => {
        setCardToken(token);
        setShowNewCard(true);
        setTextHeader('CARD DETAIL')

    }


    const deleteCard = (token) => {
        cardspApi.deleteCard(token).then(() => {
            const newCards = listCard.filter(card => card.token !== token);
            setListCard(newCards);
        }).catch(err => {
            console.log(err);
        });
    };


    const listCardItems = listCard.map(card => {
        return (
            <Row className='cardItem' key={'checkCardDMobile-' + card.token} >
                <Col size='1'>
                    <img src={cardIconHandler(card)} alt=''></img>
                </Col>
                <Col size='1'>
                    <p>{card.last4}</p>
                </Col>
                <Col size='2'>
                </Col>
                <Col size='2'>
                    <FaPen onClick={() => updateCard(card.token)} className='marginL200 clickable' />
                    <FaTrash onClick={() => deleteCard(card.token)} className='marginL30 clickable' />
                </Col>
            </Row>
        )
    });



    const toggle = () => {
        if (textHeader == 'CHECKOUT') {
            modalProps.toggle();
        } else if (textHeader == 'CREDIT CARD LIST') {
            setShowListCard(false);
            setTextHeader('CHECKOUT');
        } else if (textHeader == 'CARD DETAIL') {
            setShowNewCard(false)
            setTextHeader('CREDIT CARD LIST');
        }
    };



    const submitCheckoutByCard = () => {
        setBlockSaving(true);
        const props = {
            listCard,
            onSave: (card) => {
                const newValuesCheckout = {
                    ...valuesCheckout,
                    paymentMethod: {
                        type: 'credit_card',
                        card: card
                    }
                };

                useOrdersApi.useSaveOrder(newValuesCheckout)
                    .then(res => {
                        openModalConfirm();
                        getCartItems();
                        updateListCart();
                        setBlockSaving(false);
                    })
                    .catch(error => {
                        setBlockSaving(false);
                        let message = 'Something went wrong while processing the order.';
                        if (error.data) {
                            message = error.data.message;
                        }
                        const props = {
                            message: message,
                            onOk: () => {
                                // history.push('/logout');
                            }
                        };
                        dispatch(showModal(AlertModal, props));
                    });
            }
        };
        dispatch(showModal(ShippingAndPaymentCardModal, props));
    }

    const successSaveCard = () => {
        getCards();
        toggle();
    }

    const openModalConfirm = () => {
        const props = {
            header: 'Confirmation',
            message: `Foodbam order for ${valuesCheckout.dataDistributor.nickname} Submitted`,

            onOk: () => {
                toggle();
            }
        };
        dispatch(showModal(ConfirmationModal, props));
    };

    const submitCheckout = () => {
        setBlockSaving(true);

        const newValuesCheckout = {
            ...valuesCheckout,
            paymentMethod: {
                type: 'terms'
            }
        };
        // dispatch(spinner(true));
        // openModalProcessing();
        useOrdersApi.useSaveOrder(newValuesCheckout)
            .then(res => {
                // dispatch(spinner(false));
                setTimeout(() => {
                    openModalConfirm();
                    getCartItems();
                    updateListCart();
                    setBlockSaving(false);

                }, 500);

            })
            .catch(error => {
                setBlockSaving(false);
                let message = 'Something went wrong while processing the order.';
                if (error.data) {
                    message = error.data.message;
                }
                const props = {
                    message: message,
                    onOk: () => {
                        // history.push('/logout');
                    }
                };
                dispatch(showModal(AlertModal, props));
            });
    }

    const goShowListCard = () => {
        setShowListCard(true);
        setTextHeader('CREDIT CARD LIST')
    }



    useEffect(() => {
        getCards();
        const resValidate = validateShowCard.useShowCard(valuesCheckout);
        setShowCard(resValidate);
    }, [])

    return (
        <Modal {...modalProps} className='modal-shipping-payment'>
            <FaChevronLeft className='iconBack' size={20} onClick={toggle} />

            <ModalHeader className='bg-white' >{textHeader}</ModalHeader>
            <ModalBody>
                <ShowIf show={!showListCard}>
                    <div className='paymentMethod'>
                        <Row>
                            <p>Select a Payment Method : </p>
                        </Row>

                        <Row>
                            <Button color='green' disabled={blockSaving} onClick={submitCheckout}>Pay With Terms</Button>
                        </Row>
                        <ShowIf show={showCard}>
                            <Row>
                                <Button color='green' disabled={blockSaving} onClick={submitCheckoutByCard}>Pay With Credit Card</Button>
                            </Row>
                            <Row>
                                <NavLink href="#" onClick={goShowListCard} >Add New Credit Card</NavLink>
                            </Row>
                        </ShowIf>

                    </div>
                </ShowIf>
                <ShowIf show={showListCard}>

                    <ShowIf show={!showNewCard}>
                        <div className='text-center'>
                            {listCardItems}
                            <NavLink href="#" onClick={() => goToNewCard(null)}>Add New Credit Card</NavLink>
                        </div>
                    </ShowIf>


                    <ShowIf show={showNewCard}>
                        <CardForm cardId={cardToken} successSaveCard={successSaveCard} />
                    </ShowIf>
                </ShowIf>





            </ModalBody>
        </Modal>
    );
};


export default ShippingAndPaymentMobile;
