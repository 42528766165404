import React, { useEffect } from 'react';
import { Row, Col, CustomInput } from 'reactstrap';
import SignUpInput from '../Subcomponents/SignUpInput';
import useInput from '../../../SharedComponents/SharedEffects/useInput';

const SignUpAddDistributorLocationCheckbox = (props) => {
  const {
    check, index, name, updateData, all
  } = props;


  // console.log(props);

  // const dcn = useInput.useText(props.dcn, { value: props.dcn, set: (v) => { updateData({ ...all, dnc: v }) } });
  const dsr = {
    dcn: useInput.useText(props.dsr.dcn, { value: props.dsr.dcn, set: (v) => { updateData({ ...all, dsr: { ...all.dsr, dcn: v } }) } }),
    email: useInput.useText(props.dsr.email, { value: props.dsr.email, set: (v) => { updateData({ ...all, dsr: { ...all.dsr, email: v } }) } }),
    phoneNumber: useInput.useText(props.dsr.phoneNumber, { value: props.dsr.phoneNumber, set: (v) => { updateData({ ...all, dsr: { ...all.dsr, phoneNumber: v } }) } }),
    fullName: useInput.useText(props.dsr.fullName, { value: props.dsr.fullName, set: (v) => { updateData({ ...all, dsr: { ...all.dsr, fullName: v } }) } })
  };

  return (
    <Row className='mb-3'>
      <Col xs={4} className='p-0 pt-4'>
        <Row className='m-0 mt-3'>
          <Col className='w-fc pr-0'>
            <CustomInput className='squared dark-blue' checked={check.value} onChange={check.set} type='checkbox' id={`loc_${index}`} />
          </Col>
          <Col className='pl-0'>
            <label className='m-0' htmlFor={`loc_${index}`}>{name}</label>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row className='align-items-end'>
          <SignUpInput mb label='Customer Accounts # (DCN)' placeholder='DCN' value={dsr.dcn.value} set={dsr.dcn.set} />
          <SignUpInput mb label='Distributor Sales Rep Email *' placeholder='Email' value={dsr.email.value} set={dsr.email.set} />
        </Row>
        <Row className='align-items-end'>
          <SignUpInput label='Distributor Sales Rep Full Name *' placeholder='Full Name' value={dsr.fullName.value} set={dsr.fullName.set} />
          <SignUpInput label='Distributor Sales Rep Phone Number' placeholder='(123) 123-1234' value={dsr.phoneNumber.value} set={dsr.phoneNumber.set} />
        </Row>
      </Col>
    </Row>
  );
};

export default SignUpAddDistributorLocationCheckbox;