import React, { useState, useEffect } from "react";
import RecipeCostingMenuRow from "./RecipeCostingMenuRow";
import OneInputModal from "../../../../../SharedComponents/Modals/OneInputModal/OneInputModal";
import { showModal } from "../../../../../reducers/modals/modalsActions";
import OptionsModal from "../../../../../SharedComponents/Modals/OptionsModal/OptionsModal";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QuestionModal from "../../../../../SharedComponents/Modals/QuestionModal/QuestionModal";
import recipeMenuCategoryApi from "../../../../../api/recipeMenuCategoryApi";
import { setMenuList, setRecipeList } from "../../../../../reducers/recipeCosting/recipeCostingActions";
import { closeSidebar } from "../../../../../reducers/userInterface/userInterfaceActions";
import AlertModal from "../../../../../SharedComponents/Modals/AlertModal/AlertModal";

const useApi = () => {
  const dispatch = useDispatch();
  const menuList = useSelector(({recipeCosting}) => recipeCosting.menuList);

  const getMenulist = () => {
    recipeMenuCategoryApi.get().then(({data}) => {
      dispatch(setMenuList(data));
    }).catch(err=> {
      console.error('RecipeMenuCategoryApi get Error:', err);
    });
  };

  const deleteMenu = (id) => {
    recipeMenuCategoryApi.delete(id).then(({data}) => {
      getMenulist();
    }).catch(err => {
      console.error('RecipeMenuCategoryApi delete Error:', err);
    });
  };

  const createMenu = (name) => {
    recipeMenuCategoryApi.post({name: name}).then(({data}) => {
      getMenulist();
    }).catch(err => {
      if (err.code === 400) {
        dispatch(showModal(AlertModal, {message: err.data.message}));
      } else {
        dispatch(showModal(AlertModal, {message: "Something wen't wrong"}));
      }
      console.error('RecipeMenuCategoryApi post Error:', err);
    });
  };

  useEffect(() => {
    getMenulist();
  }, []);

  const menuListMap = menuList.map(m =>
    <RecipeCostingMenuRow deleteMenu={deleteMenu} getMenulist={getMenulist} key={'menu_'+m.value} id={m.value} name={m.label} />
  );

  return { menuList, menuListMap, createMenu, getMenulist };
};

const useRow = (props) => {
  const { id, name, getMenulist, deleteMenu } = props;
  const [hover, setHover] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const goToCategory = () => {
    dispatch(setRecipeList([]));
    dispatch(closeSidebar());
    history.push(`/recipe-costing/${id}`);
  };

  const deleteCategory = () => {
    const questionProps = {
      message: 'Delete Category?',
      leftButton: {
        title: 'Delete',
        color: 'orange',
        onClick: () => deleteMenu(id)
      },
      rightButton: {
        title: 'Cancel',
        color: 'green'
      }
    };
    dispatch(showModal(QuestionModal, questionProps));
  };

  const renameCategory = (newName) => {
    recipeMenuCategoryApi.patch(id, {name: newName}).then(({data}) => {
      getMenulist();
    }).catch(err => {
      if (err.code === 400) {
        dispatch(showModal(AlertModal, {message: err.data.message}));
      } else {
        dispatch(showModal(AlertModal, {message: "Something wen't wrong"}));
      }
      console.error('RecipeApi patchMenuCategory Error:', err);
    })
  };

  const editCategory = () => {
    const editProps = {
      title: 'Edit Menu Category',
      label: 'Edit Category Name',
      initValue: name,
      menu: isMobile,
      fullMenu: true,
      onSave: renameCategory
    };
    dispatch(showModal(OneInputModal, editProps));
  };

  const openCategoryOption = () => {
    const optionProps = {
      title: 'Select',
      options: [
        {label: 'Edit Category', onClick: editCategory},
        {label: 'Delete Category', onClick: deleteCategory}
      ]
    }
    dispatch(showModal(OptionsModal, optionProps));
  };

  return {
    setHover, hover, goToCategory, editCategory,
    deleteCategory, openCategoryOption
  }
}

export default {
  useApi, useRow
};