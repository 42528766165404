import React from 'react'
import Header from '../../../../SharedComponents/Header/Header';
import CheckoutOrderingContent from '../../Views/CheckoutOrdering/CheckoutOrderingContent'
import { useSelector } from 'react-redux';

const CheckoutOrdering = () => {


    const headerContents = useSelector(({ header }) => header.contents)

    return (

        <div>
            <Header {...headerContents} />
            <CheckoutOrderingContent />
        </div>

    )
}

export default CheckoutOrdering;        