import React, { useRef } from 'react';
import { Container, Button } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Header from '../../../../SharedComponents/Header/Header';
import './SortInventory.scss';
import useSortInventory from './useSortInventory';
import GroupRow from './GroupRow';
import useDragDrop from '../../../../SharedComponents/SharedEffects/useDragDrop';
import comparisonViewsApi from '../../../../api/comparisonViewsApi';

const SortInventory = (props) => {
  const history = useHistory();
  const param = useParams();
  const { groups, setGroups } = useSortInventory.useApi(props);
  const {sortItems} = useDragDrop.useSort();

  const goPlaceOrderInventory = () => {
    history.push('/place-order/inventory');
  };

  const save = () => {
    const params = [];
    groups.map((group, i) => {
      params.push(group.id);
    });

    comparisonViewsApi.saveSorts(param.inventory, params)
    .then((res) => {
      goPlaceOrderInventory();
    }).catch(err => {
      console.error(err)
    });
  };

  const rightButton = { icon: <Button className='clickable' color='green' onClick={save}>Save</Button> };

  const isDragging = useRef(false);
  let dragApi;

  const onDragEnd = (result) => {
    isDragging.current = false;
    if (!result.destination || result.destination.index === result.source.index) return;
    sortItems(groups, result.source.index, result.destination.index);
    setGroups(groups.map((g, i) => {
        return { ...g, sort: i }
      })
    );
  };

  const cancelDragSensor = (api) => {
    dragApi = api;
  };


  return (
    <Container fluid={true} className='m-0 p-0'>
      <Header showMenu={false} showLogo={false} showBack={true} title='Rearrange Generic Groups' showCart={false} rightButton={rightButton} />

      <Container fluid={true} className='sort-inventory my-3 rounded'>
        <DragDropContext onDragEnd={onDragEnd} sensors={[cancelDragSensor]} onBeforeCapture={() => isDragging.current = true}>
          <Droppable droppableId='groupsList'>
            {(provided, snapshot) => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {
                    groups.map((group, i) =>
                      <Draggable key={`group_${group.id}`} draggableId={`drag_group_${group.id}`} index={i} isDragDisabled={false}>
                        {(provided, snapshot) => {
                          return (
                            <div ref={provided.innerRef} {...provided.draggableProps} id={`draggable_${i}`}>
                              <GroupRow key={`group_${i}`} isDragging={snapshot.isDragging} dragHandleProps={provided.dragHandleProps} group={group} index={i}/>
                            </div>
                          );
                        }}
                      </Draggable>
                    )
                  }
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Container>
    </Container>
  );
};

export default SortInventory;
