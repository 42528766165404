import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import posApi from '../../../../../api/posApi';

const useApi = () => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const params = useParams();
  const date = params.date;

  const getPosDetail = (page) => {

    setIsLoading(true);
    posApi.getPosDetail(date, page)
      .then(res => {
        setItems(items.concat(res.data));
        setPage(page);
        setLoadMore(res._meta.pageCount > res._meta.currentPage);
        setIsLoading(false);
    })
    .catch(error => console.log(error));

  };

  useEffect(() => {
    getPosDetail(page);
  }, []);

  return {
    items, page, isLoading, loadMore, getPosDetail
  };
};

export default {
  useApi
};
