import React from 'react';
import ShowIf from '../../../SharedComponents/ShowIf/ShowIf';
import SignUpLegalInformation from '../Sections/SignUpLegalInformation';

const SignUpStepLegal = (props) => {
  return (
    <ShowIf show={props.step === 3}>
      <SignUpLegalInformation {...props} />
    </ShowIf>
  );
};

export default SignUpStepLegal;