import React, {useState} from 'react';
import { Container, CardHeader, Row, Col, Card, Button} from 'reactstrap';
import { FaPen, FaCheck } from 'react-icons/fa';
import {colors} from '../../../../../config/constants';
import { isMobile } from 'react-device-detect';
import {showModal} from '../../../../../reducers/modals/modalsActions';
import { useDispatch } from 'react-redux';
import Header from '../../../../../SharedComponents/Header/Header';
import useOrderReceive from './useOrderReceive';
import PlusButton from '../../../../../SharedComponents/PlusButton/PlusButton';
import useModal from '../../../../../SharedComponents/Modals/useModal';
import AddNewItem from '../Modals/OrderReceive/AddNewItem';
import { IoIosArrowUp } from "react-icons/io";
import useOrderView from '../OrderView/useOrderView';

const OrderReceive = (props) => {
    const dispatch = useDispatch();
    const { id } = props;
    const modalProps = useModal(id, isMobile, true);
    const [editStatus, setEditStatus] = useState(false);
    const [allState, setAllState] = useState(false);
    const {orderReceiveMap, selectedOrder, acceptAllItems, orderReceiveList, getOrdersById} = useOrderReceive.useApi(props, editStatus);
    const {orderChecked} = useOrderReceive.useReceive(orderReceiveList.value, getOrdersById);
    const {showDetails} = useOrderView.useApi(props);

    const SubHeaderOrderReceive = (
        <Row className={'header h-inherit align-items-center py-1 m-0'}>
            <Col className='text-uppercase text-label-bold-sm pl-3'>
                On Hand Total:  ${selectedOrder ? selectedOrder.onHandTotal : 0}
            </Col>
            <Col className='text-center text-uppercase text-label-bold-sm clickable' onClick={showDetails}>
                Show Details <IoIosArrowUp color={colors.blueDark} size={16} />
            </Col>
            <Col className='text-uppercase text-label-bold-sm text-right'>
                Order Total: ${selectedOrder ? selectedOrder.total : 0}
            </Col>
        </Row>
    )

    const clickAll = () => {
        setAllState(!allState);
        acceptAllItems(!allState)
    }

    const clickCustom = () => {
        const customModalProps = {
            selectedOrder : selectedOrder,
            getOrdersById: getOrdersById
        };
        modalProps.toggle();
        dispatch(showModal(AddNewItem, customModalProps));
    };

    if(isMobile) {
        return (
             <Container fluid={true} className='p-0'>
                <Header showMenu={false} showLogo={false} showBack={true} title={selectedOrder ? `Order ${selectedOrder.id + ' ' + selectedOrder.createTimeDisplay}` : 'Order'} showCart={false} />
                <Card>
                    <Row className='py-2 px-3'>
                        <Col className='col-9'>
                        </Col>
                        <Col xs={12} className='text-right'>
                            <Button color={editStatus ? 'green':'gray'} onClick={() => setEditStatus(!editStatus)} >
                                <FaPen color={editStatus ? colors.white : colors.lightBlueText} /> Edit
                            </Button>
                            &nbsp;
                            <Button color={allState ? 'green':'gray'} onClick={clickAll} >
                                <FaCheck color={allState ? colors.white : colors.lightBlueText} /> Accept All Items
                            </Button>
                        </Col>
                    </Row>
                </Card>
                {orderReceiveMap}
                <Col className='fixed-down p-0'>
                    <Card>
                        <Row className='py-3 '>
                            <Col className='clickable text-center'>
                                <Button color='green' onClick={() => {orderChecked()}} size='lg'>Order Checked</Button> 
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Container>
        )
    }

    return (
        <Container fluid={true} className='mt-3 px-4'>
            <Header showMenu={false} showLogo={false} showBack={true} title={selectedOrder ? `Order ${selectedOrder.id} ${selectedOrder.createTimeDisplay}` : 'Order'} showCart={false} >
                {SubHeaderOrderReceive}
            </Header>
            <CardHeader className='or-row-header'>
                {selectedOrder ? selectedOrder.distributor : ''}
                <span className='float-md-right'>${selectedOrder ? selectedOrder.totalPrice : '0.00'}</span>
            </CardHeader>
            <Card>
                <Row className='py-2 px-3 d-flex align-items-center text-gray'>                    
                    <Col xs={2}></Col>
                    <Col xs={8} className="d-flex alig-item-center flex-row px-0">
                      <Col xs={4} className="px-0">Item</Col>
                      <Col xs={4} className="px-0">Pack Size</Col>
                      <Col xs={2} className="px-0">Quantity</Col>
                      <Col xs={2} className="px-0">Subtotal</Col>
                    </Col>
                    <Col xs={2} className='justify-content-end text-right'>
                        <Button color={editStatus ? 'green':'gray'} onClick={() => setEditStatus(!editStatus)}>
                            <FaPen color={editStatus ? colors.white : colors.lightBlueText} /> Edit
                        </Button>
                        &nbsp;
                        <Button color={allState ? 'green':'gray'} onClick={clickAll} >
                            <FaCheck color={allState ? colors.white : colors.lightBlueText} /> Accept All Items
                        </Button>
                    </Col>
                </Row>
            </Card>
            {orderReceiveMap}
            <Row className='p-4' style={{backgroundColor: '#f5f9fc'}}>
                <Col onClick={clickCustom} className='border-dash align-items-center justify-content-center flex-column d-flex clickable' style={{height: '150px'}}>
                    <Row>
                        <Col className='text-center'>
                            <PlusButton large outline />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col className='text-label-lg text-center'>
                            Add New Item
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='py-3'>
                <Col xs={9}>
                </Col>
                <Col xs={3} className='text-right clickable'>
                    <Button color='green' onClick={() => {orderChecked()}} size='lg'>Order Checked</Button> 
                </Col>
            </Row>
        </Container>
    )


}

export default OrderReceive;