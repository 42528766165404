import React from "react";
import { Table, Card, Row } from "reactstrap";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import ShowIf from "../../../../../SharedComponents/ShowIf/ShowIf";
import RecipeCostingTableRow from "./RecipeCostingTableRow";

const styles = {
  tableHeader: {
    fontSize: "0.85rem",
  },
  inputWrapper: {
    alignItems: "center",
  },
  inputLabel: {
    margin: 0,
  },
};

const RecipeCostingTable = (props) => {
  const {
    list,
    tableSort,
    updateSortBy,
    selectRecipe,
    selectedRecipes,
  } = props;

  return (
    <ShowIf show={list.length > 0}>
      <Card className="m-2">
        <Row className="m-0 p-0">
          <Table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th style={styles.tableHeader} className="py-4 text-dark-blue">
                  Menu Item Name
                </th>
                <th
                  style={styles.tableHeader}
                  className="py-4 clickable text-dark-blue"
                  onClick={() =>
                    updateSortBy({
                      prop: "price",
                      type:
                        tableSort.prop !== "price" ? 1 : tableSort.type * -1,
                    })
                  }
                >
                  Menu Price
                  <ShowIf
                    show={tableSort.prop !== "price" || 1 === tableSort.type}
                  >
                    <MdArrowDropUp />
                  </ShowIf>
                  <ShowIf
                    show={tableSort.prop === "price" && -1 === tableSort.type}
                  >
                    <MdArrowDropDown />
                  </ShowIf>
                </th>
                <th
                  style={styles.tableHeader}
                  className="py-4 clickable text-dark-blue"
                  onClick={() =>
                    updateSortBy({
                      prop: "totalCost",
                      type:
                        tableSort.prop !== "totalCost"
                          ? 1
                          : tableSort.type * -1,
                    })
                  }
                >
                  Food Cost
                  <ShowIf
                    show={
                      tableSort.prop !== "totalCost" || 1 === tableSort.type
                    }
                  >
                    <MdArrowDropUp />
                  </ShowIf>
                  <ShowIf
                    show={
                      tableSort.prop === "totalCost" && -1 === tableSort.type
                    }
                  >
                    <MdArrowDropDown />
                  </ShowIf>
                </th>
                <th
                  style={styles.tableHeader}
                  className="py-4 clickable text-dark-blue"
                  onClick={() =>
                    updateSortBy({
                      prop: "foodCostPercent",
                      type:
                        tableSort.prop !== "foodCostPercent"
                          ? 1
                          : tableSort.type * -1,
                    })
                  }
                >
                  Food Cost %
                  <ShowIf
                    show={
                      tableSort.prop !== "foodCostPercent" ||
                      1 === tableSort.type
                    }
                  >
                    <MdArrowDropUp />
                  </ShowIf>
                  <ShowIf
                    show={
                      tableSort.prop === "foodCostPercent" &&
                      -1 === tableSort.type
                    }
                  >
                    <MdArrowDropDown />
                  </ShowIf>
                </th>
                <th
                  style={styles.tableHeader}
                  className="py-4 clickable text-dark-blue"
                  onClick={() =>
                    updateSortBy({
                      prop: "posQuantitySold",
                      type:
                        tableSort.prop !== "posQuantitySold"
                          ? 1
                          : tableSort.type * -1,
                    })
                  }
                >
                  POS: QTY
                  <ShowIf
                    show={
                      tableSort.prop !== "posQuantitySold" ||
                      1 === tableSort.type
                    }
                  >
                    <MdArrowDropUp />
                  </ShowIf>
                  <ShowIf
                    show={
                      tableSort.prop === "posQuantitySold" &&
                      -1 === tableSort.type
                    }
                  >
                    <MdArrowDropDown />
                  </ShowIf>
                </th>
                <th
                  style={styles.tableHeader}
                  className="py-4 clickable text-dark-blue"
                  onClick={() =>
                    updateSortBy({
                      prop: "posTotalSold",
                      type:
                        tableSort.prop !== "posTotalSold"
                          ? 1
                          : tableSort.type * -1,
                    })
                  }
                >
                  POS: Total Sold
                  <ShowIf
                    show={
                      tableSort.prop !== "posTotalSold" || 1 === tableSort.type
                    }
                  >
                    <MdArrowDropUp />
                  </ShowIf>
                  <ShowIf
                    show={
                      tableSort.prop === "posTotalSold" && -1 === tableSort.type
                    }
                  >
                    <MdArrowDropDown />
                  </ShowIf>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {list.map((recipe) => {
                return (
                  <RecipeCostingTableRow
                    key={recipe.id}
                    id={recipe.id}
                    selected={
                      -1 !== selectedRecipes.indexOf(parseInt(recipe.id))
                    }
                    selectRecipe={selectRecipe}
                    name={recipe.name}
                    price={recipe.price}
                    totalCost={recipe.totalCost}
                    foodCostPercent={recipe.foodCostPercent / 100}
                    posQuantitySold={recipe.posQuantitySold}
                    posTotalSold={recipe.posTotalSold}
                  />
                );
              })}
            </tbody>
          </Table>
        </Row>
      </Card>
    </ShowIf>
  );
};

export default RecipeCostingTable;
