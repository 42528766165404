import React, { Fragment, useEffect } from 'react';
import { Row, Col, CustomInput } from 'reactstrap';
import SignUpInput from '../Subcomponents/SignUpInput';
import SignUpAddDistributorLocationCheckbox from './SignUpAddDistributorLocationCheckbox';
import useSignUp from '../useSignUp';

const SignUpAddDistributor = (props) => {
  const {
    distributorsAdded, index
  } = props;

  const {
    distributorName, locations, toggleLocation, allLocations,
    setLocation, distributorsOptions, getDistributorsList
  } = useSignUp.useDistributor(props);

  const locationsMap = locations.map((l, i) =>
    <SignUpAddDistributorLocationCheckbox all={l} dcn={l.dcn} dsr={l.dsr} key={`locdis_${i}`} index={i} check={{ set: () => { toggleLocation(i) }, value: l.check }} name={l.locationName} updateData={(data) => setLocation(i, data)} />
  );
  
  return (
    <Fragment>
      <Row>
        <Col className='font-weight-bold mb-3'>{(distributorsAdded.length === index ? 'Add' : 'Edit') + ' Distributor'}</Col>
      </Row>
      <Row className='mb-3'>
        <SignUpInput xs={6} label='Distributor Name *' placeholder='Distributor Name *' isDropdown {...distributorName} options={distributorsOptions} onInputChange={(s) => getDistributorsList(s)} />
      </Row>
      <Row>
        <Col className='mb-3'>Add this distributor to:</Col>
      </Row>
      <Row className='align-items-center mb-3'>
        <Col className='w-fc pr-0'>
          <CustomInput checked={allLocations.value} onChange={allLocations.toggle} className='squared dark-blue' type='checkbox' id='all_loc' />
        </Col>
        <Col className='pl-0'><label htmlFor='all_loc' className='m-0'>All Locations</label></Col>
      </Row>
      {locationsMap}
    </Fragment>
  );
};

export default SignUpAddDistributor;