import React, { useState } from 'react';
import { Col, Card, Row } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import ItemHeader from './Subcomponents/ItemHeader';
import noImage from '../../../../../../assets/images/no-image.png';
import subrecipeImage from '../../../../../../assets/images/subrecipe-image.png';
import ItemBasicInfo from './Subcomponents/ItemBasicInfo';
import ItemPriceInfo from './Subcomponents/ItemPriceInfo';
import ShoppingCartItem from '../../../../../../SharedComponents/ShoppingCartItem/ShoppingCartItem';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';
import moment from 'moment';
import useItemHeader from './Subcomponents/useItemHeader';
import { useSelector } from 'react-redux';
import { InventoryContextProvider } from "./context/InventoryContext";
import ItemInventory from './Subcomponents/ItemInventory';
import ItemTimestampedInventory from './Subcomponents/ItemTimestampedInventory';

const PlaceOrderInventoryItem = (props) => {
  const displayInventory = useSelector(({ user }) => user.activeLocation.display_inventory);
  const isStartInventory = useSelector(({ user }) => user.activeLocation.activeInventory);
  const isDisplayShoppingCart = useSelector(({ user }) => user.activeLocation.display_shopping_cart);


  const {
    imageUrl, brandImageUrl, elemId, group,
    type, isPurchased, isSubRecipe, isPunchedOut, isUnpurchasable,
    items, itemDistributorId, isCustomItem, groupTotalProps,
    packSizeUom, unitMeasure, comparisonViewId, unitToConvert, isCustomPackSize
  } = props;


  const image = isSubRecipe ? subrecipeImage : (imageUrl ? imageUrl : (brandImageUrl ? brandImageUrl : noImage));

  let isLastPurchased = false;

  if (isPurchased) {
    const lastPurchasedItem = items.filter(i => i.isPurchased).sort((a, b) => { return moment(a.lastPurchasedDate) < moment(b.lastPurchasedDate) ? 1 : -1 })[0];
    if (lastPurchasedItem.itemDistributorId === itemDistributorId) {
      isLastPurchased = true;
    }
  }

  const { showHistoric, historicData, historicNoData, showPriceAnalytics, priceData, noPriceData, ...itemHeaderProps } = useItemHeader.useOptions(props);


  return (
    <InventoryContextProvider {...props}>
      <Col className={isMobile ? 'px-1 col-6 d-inline-block' : 'px-0 pr-3 mw-260px'} id={elemId}>
        <Card className={'d-flex border rounded-0 col' + (isLastPurchased ? ' border-green' : '') + (isSubRecipe ? ' border-purple' : '') + (isUnpurchasable ? ' border-blue' : '') + (isPunchedOut ? ' bg-dim' : '')} style={{
          minHeight: displayInventory ?
            ((!isSubRecipe && !isCustomItem && isDisplayShoppingCart !== 0) ? '583px' : '500px')
            : '468px'
        }}>
          <ItemHeader showHistoric={showHistoric} showPriceAnalytics={showPriceAnalytics} {...props} itemHeaderProps={itemHeaderProps} image={image} orderGuide={type === 'order_guide'} />
          <ShowIf show={!showHistoric && !showPriceAnalytics}>
            <ItemBasicInfo {...props} />
            <ItemPriceInfo {...props} />
            <ShowIf show={!isStartInventory}>
              <ItemInventory {...props} />
            </ShowIf>
            <ShowIf show={isStartInventory}>
              <ItemTimestampedInventory {...props} />
            </ShowIf>
          </ShowIf>
          <ShowIf show={showHistoric}>
            <Row className='mb-3'>
              <Col className='text-label-lg px-2'>Date</Col>
              <Col className='text-label-lg px-2'>On Hand</Col>
              <Col className='text-label-lg px-2'>Ordered</Col>
            </Row>
            <ShowIf show={!historicNoData}>
              {historicData.map((h, i) => {
                if (i > 11) return null;
                return (
                  <Row className='mb-1' key={`historic_row_${elemId}_${i}`}>
                    <Col className='text-label px-2' >{moment(h.startDate).format('MM/DD/YY')}</Col>
                    <Col className='text-label px-2' >{h.onHand}</Col>
                    <Col className='text-label px-2' >{h.ordered}</Col>
                  </Row>
                );
              })}
            </ShowIf>
            <ShowIf show={historicNoData}>
              <Row>
                <Col className='px-2'>No Data Found!</Col>
              </Row>
            </ShowIf>
          </ShowIf>
          <ShowIf show={showPriceAnalytics}>
            <Row className='mb-3'></Row>
            <ShowIf show={!noPriceData}>
              {priceData.map((p, i) => {
                return (
                  <Row className={`mb-1 ${(priceData.length === i + 1) ? ` text-main` : ` text-label`}`} key={`price_row_${elemId}_${i}`}>
                    <Col className='pl-4' xs={5}>{moment(p.date).format('MMM')}</Col>
                    <Col className={`pl-2 ${isMobile ? ` pr-0` : ``}`} xs={7}>{p.price}</Col>
                  </Row>
                );
              })}
            </ShowIf>
            <ShowIf show={noPriceData}>
              <Row>
                <Col className='px-2'>No Data Found!</Col>
              </Row>
            </ShowIf>
          </ShowIf>
        </Card>
      </Col>
    </InventoryContextProvider>
  );
};

export default PlaceOrderInventoryItem;