import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap';
import './SelectModal.scss'
import useModal from '../useModal';
import { isMobile } from "react-device-detect";
import Select from 'react-select';

const SelectModal = (props) => {
    const { id, header, message, options, onOk } = props;
    const [valueOption, setValueOption] = useState();
    const modalprops = useModal(id);

    const onClickOk = () => {
        if (onOk) {
            onOk(valueOption);
        };
        modalprops.toggle();
    };

    const changeOption = ({ value }) => {
        setValueOption(value);
    };

    return (

        <Modal {...modalprops} backdrop='static' keyboard={false} className={isMobile ? 'selectModal mobile' : 'selectModal'} >
            <ModalHeader toggle={modalprops.toggle} >{header}</ModalHeader>
            <ModalBody>
                <p>
                    {message}
                </p>
                <Select
                    onChange={changeOption}
                    className='fb-select'
                    placeholder='Select'
                    options={options} />
            </ModalBody>
            <ModalFooter>
                <Button disabled={valueOption ? false : true} color="green" onClick={onClickOk}>Submit </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SelectModal;

