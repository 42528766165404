import '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../config/api';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useLogin from '../../Views/Login/useLogin';
import usersApi from '../../api/usersApi';

const useApi = () => {
  const [mount, setMount] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isLogin = useSelector(state => state.user.isLogin);
  const currentRole = useSelector(state => state.user.currentRole);
  const { doLogin } = useLogin.useLogin();
  
  if (!mount) {
    api(dispatch, history);
    if (isLogin && currentRole !== 'Foodbam Admin') {
      usersApi.currentuser().then(({data}) => {
        doLogin(data);
      }).catch(err => {
        console.error('UsersApi currentUser error:', err);
      });
    }
  };

  useEffect(() => {
    setMount(true);
  }, []);

  return { mount };
};

export default useApi;