import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { ListGroupItem, Row, ListGroup, Button, Alert } from 'reactstrap';
import timestampedInventoryApi from '../../../../../api/timestampedInventoryApi';
import ListComparisonViewDetail from './ListComparisonViewDetail';
import numeral from 'numeral';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import { setActiveInventoryEdit } from '../../../../../reducers/user/userAction';
import { saveInventoryOnHandTotal } from '../../../../../reducers/items/itemsActions';
import ConfirmationModal from '../../../../../SharedComponents/Modals/ConfirmationModal/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import QuestionModal from '../../../../../SharedComponents/Modals/QuestionModal/QuestionModal';
import usePlaceOrderInventory from '../../PlaceOrder/PlaceOrderInventory/usePlaceOrderInventory';
import { saveInventoryEditAll, textSearchInventory } from '../../../../../reducers/inventoyView/inventoryViewActions';
import HeaderReviewInventory from '../ReviewInventory/HeaderReviewInventory';
import useInput from '../../../../../SharedComponents/SharedEffects/useInput';

const InventoriesDetail = (props) => {
    const dispatch = useDispatch();
    const itemInventory = JSON.parse(localStorage.getItem('itemInventory'));
    const idInventory = itemInventory.id;
    const [listComparisonViewGroups, setListComparisonViewGroups] = useState([]);
    const [inventory, setInventory] = useState(null);
    const { items, page, loadMore, isLoading, getInventoryItems, listSelected } = usePlaceOrderInventory.useApi();
    const [nameButton, setNameButton] = useState("Reopen");
    const updateInventoryEditAll = useSelector(({ inventoryView }) => inventoryView.updateInventoryEditAll);
    const userTitle = useSelector(({ user }) => user.title) || '';
    const [disabledReopen, setDisabledReopen] = useState(false);
    const search = useInput.useText();
    const [showOnlyMissingHv, setShowOnlyMissingHv] = useState(false);
    const history = useHistory();
    const textSearchValue = useSelector(({ inventoryView }) => inventoryView.textSearchValue);


    useEffect(() => {
        if (userTitle !== undefined) {
            setDisabledReopen(!userTitle.toLowerCase().includes('owner'));
        };
    }, [userTitle])
    const getAllItems = () => {
        timestampedInventoryApi.getItemsInventorySelect(idInventory)
            .then(res => {
                setListComparisonViewGroups(res.data.comparisonViews)
                setInventory(res.data.inventory)
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        if (updateInventoryEditAll) {
            getAllItems();
            dispatch(saveInventoryEditAll(false))
        };
    }, [updateInventoryEditAll])


    const listComparisonView = useMemo(() => listComparisonViewGroups.map((item, index) => {
        let ifExist = false;

        item.groups.forEach(itemsGroup => {
            let resultFind = itemsGroup.items.some(i => String(i.description).toLowerCase().includes(textSearchValue.toLowerCase()));
            if (resultFind) ifExist = true;
        });

        return (
            ifExist &&
            <ListComparisonViewDetail
                key={`itemComparisonDetail${item.id}`}
                listGroups={item}
                getInventoryItems={getInventoryItems}
                showOnlyMissingHv={showOnlyMissingHv}
            />
        );
    }), [listComparisonViewGroups, showOnlyMissingHv, textSearchValue]);

    const reOpenInventory = () => {

        if (nameButton === "Complete") {
            openModalConfirm();
        } else {
            dispatch(setActiveInventoryEdit(nameButton == "Reopen" ? true : false));
            setNameButton(nameButton == "Reopen" ? "Complete" : "Reopen");
        };

    };

    const openModalConfirm = () => {
        const props = {
            header: 'Confirmation',
            message: `The inventory's complete!`,
            onOk: () => {
                dispatch(setActiveInventoryEdit(nameButton == "Reopen" ? true : false));
                setNameButton(nameButton == "Reopen" ? "Complete" : "Reopen");
                history.push({
                    pathname: `/place-order/inventory`
                });
            }
        };
        dispatch(showModal(ConfirmationModal, props));
    };

    const openModalConfirmReopen = (params) => {
        const props = {
            message: `Are you sure you want to reopen the inventory?`,
            menu: isMobile ? true : false,
            leftButton: {
                title: 'Cancel',
                color: 'orange',
            },
            rightButton: {
                title: 'Confirm',
                color: 'green',
                onClick: () => reOpenInventory()
            }
        };
        dispatch(showModal(QuestionModal, props));
    };

    // const onSearch = () => {
    //     dispatch(textSearchInventory(search.value));
    // };

    useEffect(() => {
        getAllItems();
    }, [idInventory])

    return (
        <div style={{ marginRight: (isMobile) ? "" : "-5px" }}>
            <Row className={isMobile ? "" : "m-0 pl-4"}>
                <HeaderReviewInventory
                    setShowOnlyMissingHv={setShowOnlyMissingHv}
                    search={search}
                    showOnlyMissing={false}
                />
            </Row>
            <Row className={"contentGroupRI" + (isMobile ? " pl-1" : " px-5 mt-3 ")}>
                <ListGroupItem className="itemHeader w-100" tag="a" >
                    <div className="d-flex justify-content-between">
                        <p className="text-sm">Storage</p>
                        <p className="text-sm">Total On Hand Value</p>
                    </div>
                </ListGroupItem>
                <ListGroup className="w-100">
                    {listComparisonView}
                </ListGroup>
                <ListGroupItem className="itemFooter w-100" tag="a" >
                    <div className="d-flex justify-content-between">
                        <p className="text-sm">Total On Hand</p>
                        <p className="text-sm mr5">{inventory && numeral(inventory.total_on_hand_value).format('$0,0.00')}</p>
                    </div>
                </ListGroupItem>
            </Row>
            <div className="float-left buttonComplete">
                <ShowIf show={nameButton === 'Reopen' && disabledReopen }>
                    <Alert color='info'>Only users with the owner title can reopen inventories.</Alert>
                </ShowIf>
                <Button
                    disabled={disabledReopen}
                    className='buttonComplete'
                    color='green'
                    onClick={reOpenInventory}>{nameButton}</Button>
            </div>

        </div>
    );
};

export default InventoriesDetail;
