
const itemsFiedlds = 'id,imageUrl,brand,brandImageUrl,description,name,foodbamCustomDescription,din,distributor,distributorId,type,purchasedPrice,price,unitPrice, \
  unitMeasure,unitQuantity,unitSize,unitType,uom,packSize,packSizeUom,rebate,savings,showSavings,savingItems,sortIndex,status, \
  itemDistributorId,itemId,lastPurchasedDate,lastPurchasedQty,matchType,merchantId,midpId,notes,orderGuideId,endDate,caseQuantity,count, \
  isCatchWeight,isFixedWeight,isPunchedOut,isPurchased,isMarketPrice,isPartner,isPriceOutdated,isUnpurchasable,isElectronicInvoiceItem,isSubRecipe, \
  subRecipeId,updateTime,averageWeight,belongsToGroup';
const itemsBaseFiedlds = '++id, sortByOptions, sortBy, product, search, filters';

const listsFields= 'id, name, merchantId, hasRecipeGroups, sort, userId, createTime, updateTime';
const groupsFiedlds = 'id, comparisonViewId, name, systemComparisonGroupId, sort, parLevel, purchased, totalOnHandAmount, belongsToRecipe, unitId, units, items, createTime, updateTime';
const groupsBaseFiedlds = '++id, listSelected, sortBy, search, onHandTotal';

const cartFiedlds = '++id, shoppingCartId, isSpecialItem, itemDistributorId, description, distributorId, unit, uom, packsize, quantity, toUpdate';
const cartBaseFields = '++id, lastModified, cartCount, orderTotal';

export const syncParam = {field: 'toUpdate', value: 1} ;

export const dbMain = {
  name : 'foodbam',
  version: 2,
};

export const dbStores = {
  tables: {
    items : 'items',
    itemsBase : 'itemsBase',
    lists : 'lists',
    groups : 'groups',
    groupsBase : 'groupsBase',
    cart : 'cart',
    cartBase: 'cartBase',
  },
  structure : [
    { name: 'items', fields: itemsFiedlds },
    { name: 'itemsBase', fields: itemsBaseFiedlds },
    { name: 'lists', fields: listsFields },
    { name: 'groups', fields: groupsFiedlds },
    { name: 'groupsBase', fields: groupsBaseFiedlds },
    { name: 'cart', fields: cartFiedlds },
    { name: 'cartBase', fields: cartBaseFields },    
  ]
};