import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import noImage from '../../../../../../../assets/images/no-image.png';
import numeral from 'numeral';
import ItemDetailsInfoCol from '../SharedComponents/ItemDetailsInfoCol';
import ItemDetailsImage from '../SharedComponents/ItemDetailsImage';

const ItemDetailsFoodbamInfo = (props) => {
  const {
    item
  } = props;

  const image = item.imageUrl ? item.imageUrl : (item.brandImageUrl ? item.brandImageUrl : noImage);

  return (
    <Fragment>
      <ItemDetailsImage image={image} name={item.description} />
      <Row className='py-3 align-items-center border-top'>
        <Col className='text-label font-weight-bold text-uppercase'>Item Details</Col>
      </Row>
      <Row className='mb-3'>
        <ItemDetailsInfoCol label='DIN' value={item.din} />
        <ItemDetailsInfoCol label='Price' value={numeral(item.price).format('$0,0.00')} />
      </Row>
      <Row className='mb-3'>
        <ItemDetailsInfoCol label='Brand' value={item.brand} />
        <ItemDetailsInfoCol label='Distributor' value={item.distributor} />
      </Row>
      <Row className='mb-3'>
        <ItemDetailsInfoCol label='UOM' value={item.packSizeUom} />
        <ItemDetailsInfoCol label='Pack Size' value={item.packSize} />
      </Row>
    </Fragment>
  );
};

export default ItemDetailsFoodbamInfo;