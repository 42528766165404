import React, { useState, useContext, Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import OnHandItem from '../../../../../../../SharedComponents/OnHandItem/OnHandItem';
import { colors, onHandTypes } from '../../../../../../../config/constants';
import ShowIf from '../../../../../../../SharedComponents/ShowIf/ShowIf';
import { useSelector } from 'react-redux';
import { InventoryContext } from "../context/InventoryContext";
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import ShoppingCartItem from '../../../../../../../SharedComponents/ShoppingCartItem/ShoppingCartItem';
import numeral from 'numeral';

const ItemTimestampedInventory = (props) => {
  const displayInventory = useSelector(({ user }) => user.activeLocation.display_inventory);
  const [showMore, setShowMore] = useState(false);
  const isDisplayShoppingCart = useSelector(({ user }) => user.activeLocation.display_shopping_cart);


  const {
    group, collectiveOnHand, collectiveOnHandUom,
    unitMeasure, packSizeUom, comparisonViewId, unitToConvert, isCustomPackSize, groupTotalProps,
    isSubRecipe, isCustomItem, itemProps
  } = props;
  const finalPackSizeUom = (unitToConvert.label !== 'Original') ? unitToConvert.label : packSizeUom;

  const inventoryContext = useContext(InventoryContext);
  const {
    totalOH,
    totalOHAmount,
    collectiveOH,
    collectiveOHUom,
    totalCase,
    totalPack,
    totalUnit,
  } = inventoryContext;

  return (
    <Fragment>
      <ShowIf show={displayInventory}>
        <Row style={{ height: '250px' }} className='align-items-center my-0 py-0'>
          <Col className='px-2  d-flex justify-content-end flex-column'>
            <Row className='align-items-center'>
              <Col className='text-truncate  text-center my-0'>
                On Hand ({numeral(totalOHAmount).format('$0,0.00')})
              </Col>
            </Row>

            <Row className='mx-n2 align-items-center justify-content-center'>
              <Col className='text-label-sm text-center my-1'>
                Case  ({numeral(totalCase).format('$0,0.00')})
              </Col>
            </Row>
            <Row className='m-0 align-items-center justify-content-center'>
              <OnHandItem onHandType={onHandTypes.cs} item={props} group={group} comparisonViewId={comparisonViewId} groupTotalProps={groupTotalProps} />
            </Row>
            <Row className='m-0 align-items-center justify-content-center'>
              <Col className='text-label-sm  text-center my-1'>
                Pack ({numeral(totalPack).format('$0,0.00')})
              </Col>
            </Row>
            <Row className='m-0 align-items-center justify-content-center'>
              <OnHandItem onHandType={onHandTypes.pack} item={props} group={group} comparisonViewId={comparisonViewId} groupTotalProps={groupTotalProps} />
            </Row>
            <Row className='m-0 align-items-center justify-content-center'>
              <Col className='text-label-sm text-center my-1'>
                Unit  ({numeral(totalUnit).format('$0,0.00')})
              </Col>
            </Row>
            <Row className='m-0 align-items-center justify-content-center'>
              <OnHandItem onHandType={onHandTypes.uom} item={props} group={group} comparisonViewId={comparisonViewId} groupTotalProps={groupTotalProps} />
            </Row>


          </Col>
        </Row>

        <ShowIf show={!showMore && isDisplayShoppingCart !== 0}>
          <Row className='align-items-center border-top py-2 clickable mt-3' onClick={() => setShowMore(true)}>
            <Col className='text-center text-label-sm'>Show More <FaCaretDown color={colors.lightBlueText} /></Col>
          </Row>
        </ShowIf>

        <ShowIf show={showMore}>
          <Row style={{ height: '80px' }} className='align-items-center' >
            <ShowIf show={!isSubRecipe && !isCustomItem && isDisplayShoppingCart !== 0}>
              <Col className='px-2  d-flex justify-content-end flex-column'>
                <Row className='mx-n2 align-items-center justify-content-center'>
                  <Col className='text-label-sm text-center my-1'>
                    Add to Cart
                  </Col>
                </Row>
                <Row className='m-0 align-items-center justify-content-center'>
                  <ShoppingCartItem item={props} showAll overlayProps groupTotalProps={groupTotalProps} />
                </Row>
              </Col>
            </ShowIf>
          </Row>
          <Row className='align-items-center border-top py-2 clickable mt-3' onClick={() => setShowMore(false)}>
            <Col className='text-center text-label-sm'>Show Less <FaCaretUp color={colors.lightBlueText} /></Col>
          </Row>
        </ShowIf>

      </ShowIf>
    </Fragment>

  );
};

export default ItemTimestampedInventory;
