import axios from 'axios';

const getOrderGuideByDistributor = (params) => {
  return axios.get('/order-guides/get-by-distributor', {params});
};

const getOrderGuideDetail = (id, params) => {
  return axios.get(`/order-guides/${id}/items`, {params});
};

const downloadOrderGuide = (id) => {
  return axios.get(`/order-guides/${id}/download`);
};


export default {
    getOrderGuideByDistributor,
    getOrderGuideDetail,
    downloadOrderGuide
};