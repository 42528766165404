import React from 'react';
import { Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import PlusButton from '../../../../../../SharedComponents/PlusButton/PlusButton';
import { useSelector } from 'react-redux';

const AddNewItem = ({id, openAdd}) => {
  const displayInventory = useSelector(({user}) => user.activeLocation.display_inventory);
  return (
    <Col className={isMobile ? 'px-1 col-6' : 'px-0 pr-3 mw-260px'} id={`g_${id}_new`}>
      <Col onClick={openAdd} className='border-dash align-items-center justify-content-center flex-column d-flex clickable' style={{minHeight: displayInventory ? '583px' : '468px'}}>
        <Row>
          <Col className='text-center'>
            <PlusButton large outline />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col className='text-label-lg text-center'>
            Add New Item
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default AddNewItem;