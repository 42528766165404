import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button, ModalFooter } from 'reactstrap';
import useModal from '../useModal';
import InfiniteScroll from 'react-infinite-scroller';
import useAddFoodbamItemModal from './useAddFoodbamItemModal';
import AddFoodbamItemModalRow from './AddFoodbamItemModalRow';
import ShowIf from '../../ShowIf/ShowIf';
import useSearchFiltersModal from '../SearchFiltersModal/useSearchFiltersModal';
import SearchFilterModalCheckbox from '../SearchFiltersModal/SearchFilterModalCheckbox';
import { isMobile } from 'react-device-detect';
import AddFoodbamItemModalHeader from './AddFoodbamItemModalHeader';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../reducers/modals/modalsActions';
import AddCustomItemModal from '../AddCustomItemModal/AddCustomItemModal';
import AlertModal from '../AlertModal/AlertModal';

const AddFoodbamItemModal = (props) => {
  const dispatch = useDispatch();
  const {
    id, title, isLink, onAdd, onAddCustom,
    initSearch, initItems, idLabel, includeSubrecipe,
    clickCustomCustom, isOrder
  } = props;
  
  const { disabled } = useAddFoodbamItemModal.useModalConnection();

  const modalProps = useModal(id, isMobile, true);

  const linkItems = useAddFoodbamItemModal.useLink();
  const { distributorsMapDesktop, distributorsMapMobile, allDistributors, toggleAllDistributors, distributors, setDistributors } = useSearchFiltersModal.useDistributors([]);
  const { shoppingMapDesktop, shoppingMapMobile, allShoppingLists, toggleAllShoppingLists, shoppingLists, setShoppingLists } = useSearchFiltersModal.useShoppingLists([]);
  const { orderMapDesktop, orderMapMobile, allPastOrders, toggleAllPastOrders, pastOrders, setPastOrders } = useSearchFiltersModal.usePastOrders([]);
  const { filters, showFilters, toggleFilters, actions } = useAddFoodbamItemModal.useFilters({ value: distributors, set: setDistributors }, { value: shoppingLists, set: setShoppingLists }, { value: pastOrders, set: setPastOrders });
  const {
    page, items, getListItems, loadMore, isLoading, searchEnter,
    linkAll, isAllLink, onSearch, onClear, search, subrecipeItems, editQuantity
  } = useAddFoodbamItemModal.useApi(filters, linkItems, idLabel, initSearch, includeSubrecipe, disabled);

  const clickSave = () => {
    if(disabled){
      dispatch(showModal(AlertModal, {message:'Please enable online mode to add a new generic ingredient.'}));
      return;
    }
    if (onAdd) {
      if (isOrder) {
        const newItems = items.filter(it => it.quantity);
        onAdd([...initItems, ...newItems], newItems);
      } else {
        onAdd([...initItems, ...linkItems.items], linkItems.items);
      }
    }
    modalProps.toggle();
  };

  const clickCustom = () => {
    const { id, ...allProps } = props;
    if (clickCustomCustom) {
      modalProps.toggle();
      clickCustomCustom();
    } else {
      const customModalProps = {
        title: 'Add Custom Item',
        onSave: onAddCustom,
        foodbamItemProps: allProps
      };
      modalProps.toggle();
      dispatch(showModal(AddCustomItemModal, customModalProps));
    }
  };

  const initItemsIds = initItems.filter(i => !i.isSubRecipe && !i.isCustomItem).map(i => i[idLabel]);
  const initItemsSubrecipeIds = initItems.filter(i => i.isSubRecipe).map(i => i.subRecipeId);

  const itemsArray = [...subrecipeItems.filter(s => !initItemsSubrecipeIds.includes(s.id)).filter(s => searchEnter && s.name.toLowerCase().includes(searchEnter.toLowerCase())), ...items.filter(i => !initItemsIds.includes(i[idLabel]) && !i.isPunchedOut)];

  const noItems = itemsArray.length === 0 && !isLoading;

  const itemsMap = itemsArray.map((item, i) =>
    <AddFoodbamItemModalRow key={`fbItem_${item.isSubRecipe ? 'sub_' + item.id : item.id}`} {...item} item={item} isLink={isLink} linkItems={linkItems} onAdd={onAdd} exit={modalProps.toggle} idLabel={idLabel} isOrder={isOrder} editQuantity={editQuantity} />
  );

  return (
    <Modal {...modalProps} size='lg'>
      <ModalHeader className='bg-white border-0' toggle={modalProps.toggle}>
        <Row>
          <Col>{title}</Col>
        </Row>
        <AddFoodbamItemModalHeader search={search} onClear={onClear} onSearch={onSearch} toggleFilters={toggleFilters} showFilters={showFilters} isLink={isLink} linkAll={linkAll} isAllLink={isAllLink} disabled={disabled}/>
      </ModalHeader>
      <ModalBody className={isMobile ? 'border-top px-0 overflow-x-hidden' : ''}>
        <ShowIf show={!showFilters && !isMobile}>
          <Row className='border m-0 my-3 h-300px mh-300px overflow-auto'>
            <ShowIf show={noItems}>
              <Col className='mt-3 text-center'>
                No results found
              </Col>
            </ShowIf>
            <InfiniteScroll
              className='w-100'
              initialLoad={false}
              pageStart={page}
              loadMore={() => { getListItems(page, items, filters, search.value) }}
              hasMore={loadMore && !isLoading}
              useWindow={false}
            >
              {itemsMap}
            </InfiniteScroll>
          </Row>
        </ShowIf>
        <ShowIf show={!showFilters && isMobile}>
          <ShowIf show={noItems}>
            <Col className='w-100 text-center'>
              No results found
            </Col>
          </ShowIf>
          <InfiniteScroll
            initialLoad={false}
            pageStart={page}
            loadMore={() => getListItems(page, items, filters, search.value)}
            hasMore={loadMore && !isLoading}
            useWindow={false}
          >
            {itemsMap}
          </InfiniteScroll>
        </ShowIf>
        <ShowIf show={showFilters}>
          <ShowIf show={isMobile}>
            <SearchFilterModalCheckbox rowClassName={'m-0 py-3' + (allDistributors || !distributors.length ? '' : ' border-bottom')} id='allDistributors' value={allDistributors} set={toggleAllDistributors} label='All Distributors' />
            <div className={allDistributors ? 'h-anim h-0' : 'h-anim'} >
              {distributorsMapMobile}
            </div>
            <SearchFilterModalCheckbox rowClassName={'m-0 py-3' + (allShoppingLists || !shoppingLists.length ? '' : ' border-bottom')} id='allShoppingLists' value={allShoppingLists} set={toggleAllShoppingLists} label='All Shopping Lists' />
            <div className={allShoppingLists ? 'h-anim h-0' : 'h-anim'} >
              {shoppingMapMobile}
            </div>
            <SearchFilterModalCheckbox rowClassName={'m-0 py-3' + (allPastOrders || !pastOrders.length ? '' : ' border-bottom')} id='allPastOrders' value={allPastOrders} set={toggleAllPastOrders} label='Past Orders' />
            <div className={allPastOrders ? 'h-anim h-0' : 'h-anim'} >
              {orderMapMobile}
            </div>
          </ShowIf>
          <ShowIf show={!isMobile}>
            <Row className='py-3 m-0'>
              <SearchFilterModalCheckbox colClassName='mx-2 pb-3 border-bottom' col id='allDistributors' value={allDistributors} set={toggleAllDistributors} label='All Distributors' />
              <SearchFilterModalCheckbox colClassName='mx-2 pb-3 border-bottom' col id='allShoppingLists' value={allShoppingLists} set={toggleAllShoppingLists} label='All Shopping Lists' />
              <SearchFilterModalCheckbox colClassName='mx-2 pb-3 border-bottom' col id='pastOrders' value={allPastOrders} set={toggleAllPastOrders} label='Past Orders' />
            </Row>
            <Row className='m-0'>
              <Col className='mx-2 h-300px overflow-auto'>
                {distributorsMapDesktop}
              </Col>
              <Col className='mx-2 h-300px overflow-auto'>
                {shoppingMapDesktop}
              </Col>
              <Col className='mx-2 h-300px overflow-auto'>
                {orderMapDesktop}
              </Col>
            </Row>
          </ShowIf>
        </ShowIf>
      </ModalBody>
      <ShowIf show={!showFilters}>
        <ShowIf show={(isLink || isOrder) && !isMobile}>
          <ModalFooter className='px-0'>
            <Row className='w-100 m-0'>
              <Col>
                <Button onClick={clickCustom} className='px-5' color='green' outline size='lg'>Add Custom Item</Button>
              </Col>
              <Col className='text-right'>
                <Button onClick={clickSave} disabled={isOrder ? items.filter(it => it.quantity).length === 0 : linkItems.items.length === 0} className='px-5' color='green' size='lg'>{isOrder ? 'Save' : 'Add'}</Button>
              </Col>
            </Row>
          </ModalFooter>
        </ShowIf>
        <ShowIf show={(isLink || isOrder) && isMobile}>
          <ModalFooter className='px-0'>
            <Row className='w-100 m-0'>
              <Col>
                <Button onClick={clickCustom} className='px-5' block color='green' outline>Add Custom Item</Button>
              </Col>
            </Row>
            <Row className='w-100 m-0 mt-2'>
              <Col className='text-right'>
                <Button onClick={clickSave} disabled={isOrder ? items.filter(it => it.quantity).length === 0 : linkItems.items.length === 0} className='px-5' block color='green'>{isOrder ? 'Save' : 'Add'}</Button>
              </Col>
            </Row>
          </ModalFooter>
        </ShowIf>
      </ShowIf>
      <ShowIf show={showFilters && !isMobile}>
        <ModalFooter >
          <Row className='w-100 m-0'>
            <Col>
              <Button onClick={actions.reset} className='px-5' color='light' size='lg'>Reset</Button>
            </Col>
            <Col className='text-right'>
              <Button onClick={actions.apply} className='px-5' color='green' size='lg'>Apply</Button>
            </Col>
          </Row>
        </ModalFooter>
      </ShowIf>
      <ShowIf show={showFilters && isMobile}>
        <ModalFooter className='px-0'>
          <Row className='w-100 m-0'>
            <Col>
              <Button onClick={actions.reset} block color='light'>Reset</Button>
            </Col>
            <Col className='text-right'>
              <Button onClick={actions.apply} block color='green'>Apply</Button>
            </Col>
          </Row>
        </ModalFooter>
      </ShowIf>
    </Modal>
  );
};

export default AddFoodbamItemModal;