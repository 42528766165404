import React, { useLayoutEffect } from 'react';
import numeral from 'numeral';
import Highcharts from 'highcharts';
import { Row, Col } from 'reactstrap';

const ItemGraph = (props) => {

  const _analyticsItem = () => {
    if (!props.analyticsData || !props.analyticsData.series) {
      return;
    }

    let analyticsItem = new Highcharts.chart('containerAnalytics', {
      chart: {
        type: 'spline'
      },
      title: {
        text: ''
      },
      tooltip: {
        backgroundColor: '#000000',
        borderColor: '#000000',
        borderRadius: 5,
        formatter: function () {
          return '<span style="color:#FFFFFF;">$' + Highcharts.numberFormat(this.y, 2, '.', ',') + ' cs</span>';
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: props.analyticsData.categories,
        labels: {
          style: {
            color: '#376386'
          }
        }
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: ''
        },
        labels: {
            formatter: function () {
              return '$' + this.value;
            }
        },
        style: {
          color: '#376386'
        }
      },
      series: [{
        data: props.analyticsData.series,
        showInLegend: false,
        dataLabels: {
          enabled: false
        },
        cursor: 'pointer',
      }]
    });

  };

  useLayoutEffect(() => {
    _analyticsItem();
  }, [props.analyticsData]);

  return (
    <Row className='text-center mb-2'>
      <Col xs={12}>
        <div id='containerAnalytics' style={{height: '350px', minWidth: '90%', margin: '20px'}}></div>
      </Col>
    </Row>
  );
};

export default ItemGraph;
