import React, { useEffect, useRef } from 'react';
import { Container } from 'reactstrap';
import usePlaceOrderInventoryOffline from './usePlaceOrderInventoryOffline';
import { isMobile } from 'react-device-detect';
import PlaceOrderInventoryGroup from './PlaceOrderInventoryGroup';
import InfiniteScroll from 'react-infinite-scroller';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { saveInventoryItems } from '../../../../../reducers/items/itemsActions';
import comparisonViewsApi from '../../../../../api/comparisonViewsApi';
import GroupTitleFirst from './Subcomponents/GroupTitleFirst';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { Switch, Route, Redirect } from 'react-router-dom';
import PlaceOrderInventoryAnalytics from './PlaceOrderInventoryAnalytics/PlaceOrderInventoryAnalytics';
import useDragDrop from '../../../../../SharedComponents/SharedEffects/useDragDrop'


const PlaceOrderInventoryOffline = (props) => {
  const dispatch = useDispatch();
  const { items, page, loadMore, isLoading, getInventoryItems, sort, listSelected } = usePlaceOrderInventoryOffline.useApi();
  const canEditComparisonView = useSelector(({user}) => user.editComparisonView) ? true : false;

  const {sortItems} = useDragDrop.useSort();

  const isAllCollapse = items.every((i) => !i.expand);

  const isDragging = useRef(false);
  let dragApi;

  const onDragEnd = (result) => {
    isDragging.current = false;
    if (!result.destination || result.destination.index === result.source.index) return;
    const source = items[result.source.index];   
    let params = { new_sort: result.destination.index, old_sort: result.source.index };

    sortItems(items, result.source.index, result.destination.index);
    dispatch(saveInventoryItems(items.map((it, i) => {
      return {
        ...it,
        sort: i
      };      
    })
    // .sort((a, b) => {
    //   return a.sort > b.sort ? 1 : -1
    // })
    ));

    comparisonViewsApi.patchGroup(source.comparisonViewId, source.id, params).then(() => {
    }).catch(err => console.error(err));    
    
  };

  const cancelDragSensor = (api) => {
    dragApi = api;
  };

  return (
    <Switch>
      <Route exact path='/place-order/inventory'>
        <Container fluid={true} className={ isMobile ? 'p-0 overflow-hidden' : 'px-5'}>
          <GroupTitleFirst id={'add_first'} type='first' index={0} title={isMobile ? 'Tap to add new generic group' : 'Click to add new generic group'} listSelected={listSelected}/>
          <ShowIf show={items.length > 0}>            
            <InfiniteScroll
              className='w-100'
              initialLoad={false}
              pageStart={page}
              loadMore={() => {if (isDragging.current) { dragApi.tryReleaseLock(); } getInventoryItems(page);}}
              hasMore={loadMore && !isLoading}
              useWindow={true}
            >
              <DragDropContext sensors={[cancelDragSensor]} onDragEnd={onDragEnd} onBeforeCapture={() => isDragging.current = true}>
                <Droppable droppableId='groupsList'>
                  {(provided, snapshot) => {
                    return (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {
                          items.map((item, i) =>
                            <Draggable key={`group_${item.id}`} draggableId={`drag_group_${item.id}`} index={i} isDragDisabled={false}>
                              {(provided, snapshot) => {
                                return (
                                  <div ref={provided.innerRef} {...provided.draggableProps} id={`draggable_${i}`}>
                                    <PlaceOrderInventoryGroup getInventoryItems={getInventoryItems} isDragging={snapshot.isDragging} dragHandleProps={provided.dragHandleProps} item={item} index={i}/>
                                  </div>
                                );
                              }}
                            </Draggable>
                          )
                        }
                      {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            </InfiniteScroll>
            <GroupTitleFirst id={'add_last'} type='last' index={items.length} title={isMobile ? 'Tap to add new generic group' : 'Click to add new generic group'} listSelected={listSelected}/>
          </ShowIf>
        </Container>
      </Route>
      <Route exact path='/place-order/inventory/analytics'>
        <PlaceOrderInventoryAnalytics groups={items} />
      </Route>
      <Route>
        <Redirect to='/place-order/inventory' />
      </Route>
    </Switch>
    
  );
};
      
export default PlaceOrderInventoryOffline;