import React, { useState, useEffect } from 'react'
import distributorsApi from '../../../../../api/distributorsApi';
import ordersApi from '../../../../../api/ordersApi';

import useShoppingCartItem from '../../../../../SharedComponents/ShoppingCartItem/useShoppingCartItem';
import moment from 'moment';


const useDataForDistributor = (distributorId) => {
    const [dataDistributor, setDataDistributor] = useState();

    const getData = () => {

        distributorsApi.getById(distributorId)
            .then(res => {
                res.data = {
                    ...res.data,
                    isFoodbamDelivery: res.data.isDelivery === 1 ? true : false,
                    deliveryDates: res.data.deliveryDates.map(dt => {
                        if (String(dt).indexOf('Today') == 0) {
                            var d = new Date();
                            dt = `Today, ${moment(d).format('MM/DD/YYYY')}`;
                        } else {
                            dt = `${moment(dt).format('dddd')}, ${moment(dt).format('MM/DD/YYYY')} `;
                        }
                        return dt;
                    })
                };
                setDataDistributor(res.data);
            })
            .catch(error => console.log(error));

    };

    useEffect(() => {
        getData();
    }, [distributorId])

    return {
        dataDistributor
    };
}

const useUpdateCart = () => {
    const { updateCartAmount } = useShoppingCartItem.useCartUpdate();

    const useUpdateCartAmount = (newCaseQuantity, item) => {
        const nitem = {
            quantity: newCaseQuantity,
            status: item.status,
            isPunchedOut: item.isPunchedOut,
            isUnpurchasable: item.isUnpurchasable,
            description: item.name ? item.name : item.description,
            itemDistributorId: item.itemDistributorId,
            shoppingCartId: item.shoppingCartId
        };
        updateCartAmount(newCaseQuantity, nitem, null);
    }

    return { useUpdateCartAmount }
}

const useOrdersApi = () => {
    const transformDate = (date) => {
        let newDate = date.split(',')[1].trim().split('/');
        // 06/15/2020
        return newDate[2] + '-' + newDate[0] + '-' + newDate[1];
    }
    const useSaveOrder = async (valuesCheckout) => {

        const params = {
            payment_token: valuesCheckout.paymentMethod.type == 'credit_card'
                ? valuesCheckout.paymentMethod.card.token
                : null,
            paymentType: valuesCheckout.paymentMethod.type,
            requestedDeliveryDate: transformDate(valuesCheckout.deliveryDetail.valueDeliveryDate),
            distributorId: valuesCheckout.dataDistributor.id,
            itemList: JSON.stringify(valuesCheckout.cartList.map(res => {
                res['quantity'] = res.caseQuantity;
                return res;
            })),
            isSameDayDeliveryFee: false, //falta
            notes: valuesCheckout.deliveryDetail.valueNote,
            foodbamPrice: valuesCheckout.priceTotalCard.priceReal,
            requestedDeliveryTime: valuesCheckout.deliveryDetail.valueDeliveryTime,
            skipOrderMinimum: false, //falta
            timezone: ""
        }

        let res = await ordersApi.post(params);
        return res
    };
    return { useSaveOrder }

}

const useValuesReviewOrder = () => {

    const useShowCard = (valuesCheckout) => {

        const acceptCC = valuesCheckout.dataDistributor.acceptCC;

        const isOnlySpecialLength = valuesCheckout.cartList.filter(res => res.isSpecialItem).length;
        const isOnlyMktPriceLength = valuesCheckout.cartList.filter(res => res.isMarketPrice == 1).length;
        const isOnlySpecialItems = isOnlySpecialLength == valuesCheckout.cartList.length ? true : false;
        const isOnlyMktPrice = isOnlyMktPriceLength == valuesCheckout.cartList.length ? true : false;
        const isOnlyMktSplLength = isOnlySpecialLength + isOnlyMktPriceLength;
        const isOnlyMktSplItem = isOnlyMktSplLength == valuesCheckout.cartList.length ? true : false;

        if (
            (acceptCC == 1 || acceptCC == 2) &&
            !isOnlySpecialItems &&
            !isOnlyMktPrice &&
            !isOnlyMktSplItem
        ) {
            return true;
        } else {
            return false;
        }
    }

    const useOptionsDeliveryTime = (valueDelDate, idDistributor) => {

        let options = [];
        if (idDistributor == 531 || idDistributor == 558) {
            options.push(
                { value: '9am-1pm', label: '9am-1pm' },
                { value: '1pm-5pm', label: '1pm-5pm' }
            );
        } else if (idDistributor !== 531 && idDistributor !== 558) {
            options.push(
                { value: '8am-11am', label: '8am-11am' },
                { value: '11am-2pm', label: '11am-2pm' }
            )
        };

        if (
            (idDistributor !== 531 && idDistributor !== 558) &&
            valueDelDate.indexOf('Saturday') < 0 &&
            valueDelDate.indexOf('Sunday') < 0
        ) {
            options.push(
                { value: '2pm-5pm', label: '2pm-5pm' }
            )
        };
        return options;
    }

    const useValShowDelTime = (valueDelDate, isFoodbamDelivery) => {
        if (valueDelDate &&
            valueDelDate.indexOf('Today') < 0 &&
            isFoodbamDelivery) {
            return true;
        } else {
            return false;
        }
    }

    return { useShowCard, useOptionsDeliveryTime, useValShowDelTime }


}
export default {
    useDataForDistributor, useUpdateCart, useOrdersApi, useValuesReviewOrder
}
