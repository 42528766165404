import React from 'react';
import { colors } from '../../../config/constants';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import IconBadgeSolid from '../../IconBadge/IconBadgeSolid';
import { FaExclamation } from 'react-icons/fa';
import usePriceOutdatedIcon from './usePriceOutdatedIcon';

const PriceOutdatedIcon = (props) => { 
  const {elemId, item} = props;
  const {email} = usePriceOutdatedIcon.useDataForEmail(item);
  const {sendEmail} = usePriceOutdatedIcon.useDataOnEmail();

  return (
    <IconBadgeSolid Icon={FaExclamation} colorIcon='white' color={colors.orange} elemId={`outdated`+elemId}>
      <Row className='pt-3 px-3 text-16'>
        <Col>This price is outdated</Col>
      </Row>
      <Row className='p-3'>
        <Col>
          <Button block color='green' onClick={() => sendEmail(email)}>Notify My Rep</Button>
        </Col>
      </Row>
    </IconBadgeSolid>
  );
};

PriceOutdatedIcon.propTypes = {
  item: PropTypes.any
};

PriceOutdatedIcon.defaultProps = {
  item: {}
};

export default PriceOutdatedIcon;