import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import useOrderGuideList from './useOrderGuideList';
import { isMobile } from 'react-device-detect';
import Header from '../../../../../SharedComponents/Header/Header';

const OrderGuideList = (props) => {
    const {orderGuideMap } = useOrderGuideList.useApi(props);

    const SubHeaderOrderGuide= (
        <Row className='header h-inherit py-3 align-items-center m-0'>
            <Col xs={4} className='pl-4'>
                Distributor
            </Col>
            <Col xs={3} className='pl-4'>
                Upload Date
            </Col>
            <Col xs={3}>
                Number of Items
            </Col>
            <Col xs={1}>
            </Col>
            <Col xs={1}>
            </Col>
        </Row>
    )

    if (isMobile) { 
        return (
            <Container fluid={true} className='px-0'>
                <Header showMenu={true} showLogo={isMobile ? false : true} showBack={false} title='Order Guide' showCart={false} />
                {orderGuideMap}
            </Container>
        )
    }

    return (
        <Container fluid={true}>
            <Header showMenu={true} showLogo={isMobile ? false : true} showBack={false} title='Order Guide' showCart={false} >
                {SubHeaderOrderGuide}
            </Header>
            {orderGuideMap}
        </Container>
    )
}

export default OrderGuideList;