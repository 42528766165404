import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import ItemDetailsInputCol from './ItemDetailsInputCol';
import ItemDetailsUnitsCol from './ItemDetailsInputUnitsCol';
import ShowIf from '../../../../../../../SharedComponents/ShowIf/ShowIf';

const ItemDetailsHowMuch = (props) => {
  const {
    recipeAmount, uom, waste, disabled, isInventory, noMar
  } = props;


  return (
    <Fragment>
      <ShowIf show={!isInventory}>
        <Row className={(noMar ? 'm-0 ' : '') + 'border-top mb-3 pt-3 align-items-center'}>
          <Col className='text-label text-center font-weight-bold text-uppercase'>How Much ?</Col>
        </Row>
      </ShowIf>
      <Row className={(noMar ? 'm-0 ' : '') +'mb-3 align-items-end'}>
        <ItemDetailsInputCol disabled={disabled} label='Recipe Amount' type='text' {...recipeAmount} />
        <ItemDetailsUnitsCol disabled={disabled} label='Recipe Unit Measure (UOM)' {...uom} />
        <ShowIf show={!isInventory}>
          <ItemDetailsInputCol disabled={disabled} label='Waste %' sideText='%' type='number' {...waste} />
        </ShowIf>
      </Row>
    </Fragment>
  );
};

export default ItemDetailsHowMuch;