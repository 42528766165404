export default function reducer(state = {
  menuList: [],
  recipeList: [],
  alphaSort: { value: true, label: 'A-Z' },
  recipeInfo: {
    id: null,
    recipeName: '',
    menuCategory: null,
    menuPrice: 0,
    isSubrecipe: false,
    isSubrecipeUsed: false,
    recipeYield: '',
    recipeYieldUom: '',
    description: '',
    procedure: '',
    totalCost: 0,
    marginGrossProfit: 0,
    foodCostPercent: 0,
    hasInactiveItems: 0
  },
  ingredientsList: []
}, action) {
  switch(action.type) {
    case 'SET_MENU_LIST':
      return {
        ...state,
        menuList: action.payload.map(m => {
          return {
            label: m.name,
            value: m.id
          }
        })
      };
    case 'SET_RECIPE_LIST':
      return {
        ...state,
        recipeList: action.payload
      };
    case 'SET_INGREDIENTS_LIST':
      return {
        ...state,
        ingredientsList: action.payload.map(i => {
          return {
            ...i,
            item: i.item !== undefined ? i.item : {}
          }
        })
      };
    case 'SET_RECIPE_INFO':
      const ap = action.payload;
      const s = state.recipeInfo;
      return {
        ...state,
        recipeInfo: {
          id: ap.id !== undefined ? ap.id : s.id,
          recipeName: ap.recipeName !== undefined ? ap.recipeName : s.recipeName,
          menuCategory: ap.menuCategory !== undefined ? ap.menuCategory : s.menuCategory,
          menuPrice: ap.menuPrice !== undefined ? ap.menuPrice : s.menuPrice,
          isSubrecipe: ap.isSubrecipe !== undefined ? ap.isSubrecipe : s.isSubrecipe,
          isSubrecipeUsed: ap.isSubrecipeUsed !== undefined ? ap.isSubrecipeUsed : s.isSubrecipeUsed,
          recipeYield: ap.recipeYield !== undefined ? ap.recipeYield : s.recipeYield,
          recipeYieldUom: ap.recipeYieldUom !== undefined ? ap.recipeYieldUom : s.recipeYieldUom,
          description: ap.description !== undefined ? ap.description : s.description,
          procedure: ap.procedure !== undefined ? ap.procedure : s.procedure,
          totalCost: ap.totalCost !== undefined ? ap.totalCost : s.totalCost,
          marginGrossProfit: ap.marginGrossProfit !== undefined ? ap.marginGrossProfit : s.marginGrossProfit,
          foodCostPercent: ap.foodCostPercent !== undefined ? ap.foodCostPercent : s.foodCostPercent,
          hasInactiveItems: ap.hasInactiveItems !== undefined ? ap.hasInactiveItems : s.hasInactiveItems
        }
      };
    case 'SET_ALPHA_SORT':
      return {
        ...state,
        alphaSort: action.payload
      };
    default:
      return state;
  }
};