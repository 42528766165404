import React, { useState, useEffect } from 'react';
import cardspApi from '../../../../../api/cardspApi';
import CardRow from './CardRow';

const useApi = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    cardspApi.getCards().then(({data}) => {
      setCards(data);
    }).catch(err=> {
      console.error('getCardDetails get Error:', err);
    });

  }, []);

  const deleteCard = (token) => {
    cardspApi.deleteCard(token).then(() => {
      const newCards = cards.filter(card => card.token !== token);
      setCards(newCards);
    }).catch(err=> {
      console.log(err);
    });
  };

  const cardsMap = cards.map((card, index) => <CardRow key={`card-${card.token}`} deleteCard={deleteCard}>{card}</CardRow>);

  return { cards, cardsMap };
};

export default {
  useApi
};
