import axios from "axios";
import { spinner } from "../reducers/userInterface/userInterfaceActions";
import { showModal } from "../reducers/modals/modalsActions";
import AlertModal from "../SharedComponents/Modals/AlertModal/AlertModal";

export default (dispatch, history) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers['Pragma'] = 'no-cache';
  axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';

  axios.interceptors.request.use(config => {
    dispatch(spinner(true));
    const authKey = localStorage.getItem('authKey') ? localStorage.getItem('authKey') : 'g31cr6dupg0qxvvIcGVm1UnIBXcHhVDB';
    config.headers['Authorization'] = `Bearer ${authKey}`;
    return config;
  }, error => {
    return Promise.reject(error);
  });


  axios.interceptors.response.use(response => {
    dispatch(spinner(false));
    return response.data;
  }, error => {
    dispatch(spinner(false));
    if (error.response && error.response.status === 401) {
      const props = {
        message: 'Please login with proper credentials.',
        onOk: () => {
          history.push('/logout');
        }
      };
      dispatch(showModal(AlertModal, props));
    }
    if (error.response) {
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error);
    }
  });
};