
import axios from "axios";

const getInventorySpreadsheet = () => {
    return axios.get('inventory/spreadsheet');
};

const newInventory = (params) => {
    return axios.post('inventory', params);
};

const completeInventory = () => {
    return axios.post('inventory/complete');
};

const comparisonAllViews = () => {
    return axios.get('comparison-views/all-views', {
        params: {
            expand: 'items,purchased,onHandTotal,orderTotal,globalOnHandTotal,units,comparisonView,comparisonViewTotalOnHandAmount'
        }
    });
};

const getInventoriesComplete = (page) => {
    const params = {
        page: page,
        limit: 12
    };
    return axios.get('inventory', { params });
};

const getItemsInventorySelect = (idInventory) => {
    return axios.get(`inventory/${idInventory}/items`);
};

const reopenInventory = (idInventory) => {
    return axios.patch(`inventory/${idInventory}/reopen`);
};

export default {
    getInventorySpreadsheet,
    newInventory,
    comparisonAllViews,
    completeInventory,
    getInventoriesComplete,
    getItemsInventorySelect,
    reopenInventory
};
