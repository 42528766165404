import React, {useState, Fragment} from 'react';
import { Card, Row, Col, Button } from "reactstrap";
import { isMobile } from 'react-device-detect';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {colors} from '../../../../../config/constants';
import noImage from '../../../../../assets/images/no-image.png';
import useOrderInventory from '../OrderInventory/useOrderInventory';
import moment from 'moment';


const OrderInventoryRow = (props) => {
    const { setHover, hover, showDetailsInventory } = useOrderInventory.useRow(props.data);
    const item  = props.data;
    const imageUrl = item.imageUrl ? item.imageUrl : noImage;
    const [showDetails, setShowDetails] = useState(true);

    if(isMobile) {
        return (
            <Card>
                <Row className='p-2'>
                    <Col xs={2}>
                        <Col className='w-image p-0'>
                            <img src={imageUrl} alt={item.name} className='no-image' />
                        </Col>
                    </Col>
                    <Col xs={10} className='text-capitalize text-main'>
                        {item.description}
                    </Col>
                </Row>
                { showDetails &&
                   <Fragment>
                        <Row className='clickable m-0' onClick={() => setShowDetails(false)}>
                            <Col className='border-top border-bottom py-2'>
                                Show Details 
                            </Col>
                            <Col className='text-right border-top border-bottom py-2'>
                                <IoIosArrowDown size={22} color={colors.lightBlueText} />
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col className='text-gray'>
                                Par Level
                            </Col>
                            <Col className='text-right'>
                                {item.parLevel}
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col className='text-gray'>
                                On Hand
                            </Col>
                            <Col className='text-right'>
                                {item.onHand}
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col className='text-gray'>
                                On Hand Value
                            </Col>
                            <Col className='text-right'>
                                {item.onHandAmount}
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col className='text-gray'>
                                Last Update Date
                            </Col>
                            <Col className='text-right'>
                                {moment(item.updateTime).format('dddd')}, {moment(item.updateTime).format("MM/DD/YY")}
                            </Col>
                        </Row>
                    </Fragment>
                }
                { !showDetails &&
                    <Fragment>
                        <Row className='clickable m-0' onClick={() => setShowDetails(true)}>
                            <Col className='border-top border-bottom py-2'>
                                Hide Details 
                            </Col>
                            <Col className='text-right border-top border-bottom py-2'>
                                <IoIosArrowUp color={colors.lightBlueText} />
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col className='text-gray'>
                                DIN
                            </Col>
                            <Col className='text-right'>
                                {item.din}
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col className='text-gray'>
                                Price
                            </Col>
                            <Col className='text-right'>
                                ${item.din}
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col className='text-gray'>
                                Brand
                            </Col>
                            <Col className='text-right'>
                                {item.brand}
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col className='text-gray'>
                                Distributor
                            </Col>
                            <Col className='text-right'>
                                {item.distributor}
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col className='text-gray'>
                                UOM
                            </Col>
                            <Col className='text-right'>
                                {item.unitMeasure}
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col className='text-gray'>
                                Pack Size
                            </Col>
                            <Col className='text-right'>
                                {item.packSize}
                            </Col>
                        </Row>
                        <Row className='p-2 pb-4'>
                            <Col className='text-gray'>
                                View
                            </Col>
                            <Col className='text-right'>
                                {item.comparisonView}
                            </Col>
                        </Row>
                    </Fragment>
                }
                <Row className='p-2'>
                </Row>
            </Card>
        )
    }

    return (
        <Card className='px-2'>
            <Row className='py-3 align-items-center' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
                <Col xs={2} className='text-center'>
                    <Col className='w-image'>
                        <img src={imageUrl} alt={item.name} className='no-image' />
                    </Col>
                </Col>
                <Col xs={3}>
                    <Row>
                        <Col className='text-capitalize text-truncate'>
                            {item.description}
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}>
                    <Row>
                        <Col>
                            {item.parLevel}{item.unitMeasure}
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}>
                    <Row>
                        <Col>
                            {item.onHand}{item.unitMeasure}
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}>
                    <Row>
                        <Col className='p-0'>
                           ${item.onHandAmount ? item.onHandAmount : 0}
                        </Col>
                    </Row>
                </Col>
                <Col xs={2}>
                    <Row>
                        <Col>
                            {moment(item.updateTime).format('dddd')}, {moment(item.updateTime).format("MM/DD/YY")}
                        </Col>
                    </Row>
                </Col>
                <Col xs={2}>
                    <Row>
                        {   hover &&
                            <Col className='clickable' onClick={showDetailsInventory} >
                                <Button color='green' >See details</Button>
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default OrderInventoryRow;