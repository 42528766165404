import React, { Fragment } from 'react';
import { Row, Col, Input } from 'reactstrap';
import ShowIf from '../../../../../../../SharedComponents/ShowIf/ShowIf';
import IconBadgeSolid from '../../../../../../../SharedComponents/IconBadge/IconBadgeSolid';
import { colors } from '../../../../../../../config/constants';
import { FaExclamation } from 'react-icons/fa';

const ItemDetailsUnitConversion = (props) => {
  const {
    disabled, item, recipe, noMar, canConvert
  } = props;

  return (
    <Fragment>
      <Row className={(noMar ? 'm-0 ' : '') + 'border-top mb-3 pt-3 align-items-center'}>
        <Col className='text-label text-center font-weight-bold text-uppercase'>Unit of Measure Conversion</Col>
      </Row>
      <ShowIf show={!canConvert}>
        <Row className={noMar ? 'm-0 mb-3' : 'mb-3'}>
          <Col className='text-label text-center font-weight-bold text-uppercase'>
            Not Auto-Convertible
            <IconBadgeSolid Icon={FaExclamation} colorIcon='white' color={colors.yellow} elemId={'cant_convert'}>
              <Row className='p-3 text-16'>
                <Col>We are not able to convert the packsize for you selection</Col>
              </Row>
            </IconBadgeSolid>
          </Col>
        </Row>
      </ShowIf>
      <Row className={(noMar ? 'm-0 ' : '') + 'mb-3 align-items-center'}>
        <Col className='w-squared-input'>
          <Input className='bg-white' type='text' disabled={disabled} onChange={item.packSize.set} value={item.packSize.value} />
        </Col>
        <Col className='text-center'>
          {item.uom}
        </Col>
        <Col className='w-fc'>=</Col>
        <Col className='w-squared-input'>
          <Input className='bg-white' type='text' disabled={disabled} onChange={recipe.packSize.set} value={recipe.packSize.value} />
        </Col>
        <Col className='text-center'>
          {recipe.uom}
        </Col>
      </Row>
    </Fragment>
  );
};

export default ItemDetailsUnitConversion;